import React, { useEffect } from 'react';
import { PublicRoute, PrivateRoute, isLoggedIn } from 'helper';
import { Switch, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { DefaultLayout } from 'layout';
import Routes from 'config/Route';
import { useAction, useAlertToast } from 'hooks';
import { hideConfirmationModal } from 'store/actions/confirmationModal';
import 'App.scss';
import 'react-notifications/lib/notifications.css';

const history = createBrowserHistory()
const App = () => {
    const { hasFetch } = useAction();
    const { NotificationContainer } = useAlertToast()
    useEffect(() => {
        const onFocus = () => {
            // if in public route & has logged in, redirect to home
            if (Routes.public.map(route => route.path).includes(window.location.pathname)) {
                if (isLoggedIn()) window.location.reload()
            } else {
                if (!isLoggedIn()) window.location.replace('/login')
            }
        }

        window.addEventListener('focus', onFocus)
        return () => window.removeEventListener('focus', onFocus)
    }, [])

    useEffect(() => {
        /* detect back button event */
        window.addEventListener('popstate', function (event) {
            hasFetch(hideConfirmationModal());
        });
    }, [hasFetch]);


    return (
        <>
            <NotificationContainer />
            <Router basename="/" history={history}>
                <Switch>
                    {Routes.public.map((route, idx) => {
                        return route.component ? (
                            <PublicRoute
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                component={props => {
                                    return <route.component {...props} title={route.name} />
                                }}
                            />
                        ) : null
                    })}
                    <PrivateRoute path="/" component={DefaultLayout} />
                </Switch>
            </Router>
        </>
    )
}

export default App
