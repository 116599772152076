import React, { useCallback } from 'react'
import {
    Card,
    CardBody,
    Row,
    Col,
    CardHeader,
    Label,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Breadcrumb } from 'components';
import { useFetch } from 'hooks';
import Error404 from 'page/error/Error404';
import { format } from 'date-fns';

const getColorMutationType = (type) => {
    switch (type) {
        case "pickup":
            return <div className="text-danger">Penjemputan</div>
        case "withdraw":
            return <div className="text-success">Pencairan</div>
        case "compensation":
            return <div className="text-warning">Ganti Rugi & Setor Kas</div>
        case "direct-purchase":
            return <div style={{ color: "#7B61FF" }}>Pembelian Langsung</div>
        default:
            return type
    }
}

const App = ({
    title: documentTitle,
    access: permissionAccess,
}) => {
    document.title = documentTitle;
    const { id } = useParams();
    const dataBreadcrumb = [
        { to: '/cash-mutation', label: 'Mutasi Kas' },
        { to: null, label: 'Detail Mutasi Kas' }
    ]

    const { data, status, error } = useFetch(
        `/api/back-office/petty-cash/mutation-cash/${id}`,
        {},
        useCallback(data => ({ data: data?.data }), [])
    )

    if ((status === 'rejected' && error.code === 400) ||
        (status === 'resolved' && data === null)) return <Error404 />
    return status === 'resolved' && (
        <>
            <Row className="row mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Detail Mutasi Kas</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Card>
                <CardHeader className="pb-0">
                    Informasi Mutasi
                </CardHeader>
                <CardBody>
                    <Row className="mb-3">
                        <Label sm={3}>No Referensi Top Up</Label>
                        <Col sm={9}>
                            : {data?.referenceNumber ?? "-"}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Label sm={3}>Tanggal</Label>
                        <Col sm={9}>
                            : {format(new Date(data?.transactionDate), 'dd/MM/yyyy - HH:mm') ?? "-"}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Label sm={3}>Tipe Mutasi</Label>
                        <Col sm={9}>
                            :  <div className="d-inline-block">{getColorMutationType(data?.mutationType) ?? "-"}</div>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Label sm={3}>Branch</Label>
                        <Col sm={9}>
                            : {data?.branchName ?? "-"}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Label sm={3}>Stockpoint</Label>
                        <Col sm={9}>
                            : {data?.stockpointName ?? "-"}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Label sm={3}>Jumlah</Label>
                        <Col sm={9}>
                            : {data?.amount ? `Rp ${data?.amount?.toLocaleString('id-ID')}` : "-"}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Label sm={3}>Saldo Akhir Kas</Label>
                        <Col sm={9}>
                            : {data?.totalAfter ? `Rp ${data?.totalAfter?.toLocaleString('id-ID')}` : "-"}
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Label sm={3}>Oleh</Label>
                        <Col sm={9}>
                            : {data?.transactionBy ?? "-"}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </>
    )
}

export default App