import React, { useCallback } from 'react';
import { Link } from 'react-router-dom'
import {
    Card, Row, Col, Form, CardBody, Input, Badge,
} from 'reactstrap';
import { Pagination, Table as TableGenerator } from 'components';
import Select from 'react-select';
import { useFetch } from "hooks";
import { Breadcrumb } from 'components';
import { debounce } from 'helper';

// import IcMore from 'assets/svg/ic_more.svg'
// import IcPlus from 'assets/svg/ic_plus.svg'

const rootStyle = (variable) => {
    return getComputedStyle(document.body).getPropertyValue(variable)
}

const OptionActiveInActive = [{
    value: "waiting_driver",
    label: "Menunggu Driver"
}, {
    value: "picking_up_process",
    label: "Dalam Perjalanan"
}, {
    value: "canceled_by_admin",
    label: "Dibatalkan Admin"
}, {
    value: "completed",
    label: "Selesai"
}]
const theme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: rootStyle('--light'),
        primary: rootStyle('--primary'),
    }
})

const styles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#e4e7ea' : provided.backgroundColor,
        border: '1px solid #DBDCDD',
        boxShadow: 'none',
        '&:hover': {
            borderColor: state.selectProps.readonly ? '#DBDCDD' : rootStyle('--primary')
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0.475rem 0.625rem',
        height: 'calc(1.5em + 1.25rem + 5px)'
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
    })
}

const getColorStatus = (status) => {
    switch (status) {
        case "waiting_driver":
            return <Badge color="info" className='text-10 text-white p-1'>
                Menunggu Driver
            </Badge>
        case "picking_up_process":
            return <Badge color="warning" className='text-10 text-white p-1'>
                Dalam Perjalanan
            </Badge>
        case "completed":
            return <Badge color="success" className='text-10 text-white p-1'>
                Selesai
            </Badge>
        case "canceled_by_admin":
            return <Badge color="danger" className='text-10 text-white p-1'>
                Dibatalkan Admin
            </Badge>
        default:
            return status
    }
}

const App = ({
    title: documentTitle,
    access: permissionAccess
}) => {
    document.title = documentTitle;
    const { data, loading: isLoading, totalPage, params, setParams, error } = useFetch(
        `/api/back-office/trip-plan/pickup`,
        currentParams => ({
            size: 10,
            sort: '',
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
        }),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        { withQueryParams: false, showAlertOnError: false }
    )


    const TableConfiguration = {
        columns: [
            {
                key: 'tripCode', title: 'No Tiket', onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <Link to={`/pickup/${row.id}`}>{value}</Link>
                }
            },
            {
                key: 'driverName', title: 'Driver', onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value}</span>
                }
            },
            {
                key: 'totalOutlet', title: 'Jumlah Outlet', onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value}</span>
                }
            },
            {
                key: 'completedOutlet', title: 'Progress Outlet', onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value}</span>
                }
            },
            {
                key: 'status', title: 'Status',
                onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value) => getColorStatus(value)
            }
        ]
    }

    const dataBreadcrumb = [
        { to: '#', label: 'Penjemputan' }
    ]

    /* set params for searching */
    const setParamsSearch = useCallback(debounce((name, value) => setParams(prev => ({ ...prev, page: 1, [name]: typeof value === 'string' ? value?.trim() : value })), 600), [])

    /* handle searching for input type */
    const handleSearchChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setParamsSearch(name, value);
    }

    return (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Rute Perjalanan</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                    {/* {
                        permissionAccess?.create &&
                        <div className="d-inline-block float-right">
                            <Link to="/pickup/create">
                                <Button color="primary">
                                    <img src={IcPlus} alt='create' className='mr-2' height={19} /> Penjemputan Baru
                                </Button>
                            </Link>
                        </div>
                    } */}
                </Col>
            </Row>
            <Card body>
                <Form>
                    <Row className="justify-content-between">
                        <Col lg={3}>
                            <Select
                                name="status"
                                id="roleId"
                                placeholder="Pilih Status"
                                className="basic-single"
                                // defaultValue={params?.roleId}
                                options={OptionActiveInActive}
                                isClearable={true}
                                theme={theme}
                                styles={styles}
                                onChange={(e) => setParams(params => ({
                                    ...params,
                                    status: e?.value,
                                    page: 1
                                }))}
                            />
                        </Col>
                        <Col lg={3}>
                            <div className="input-search">
                                <i className="fa fa-search text-muted"></i>
                                <Input className="pl-5" type="text" name="search" id="name" placeholder="Cari Driver / No Tiket" onChange={handleSearchChange} defaultValue={params?.name} />
                            </div >
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Card>
                <CardBody className="p-0">
                    <TableGenerator
                        {...TableConfiguration}
                        dataList={data}
                        params={params}
                        loading={isLoading}
                        error={error} />
                </CardBody>
            </Card>
            <Row className="pagination mr-1">
                <Col>
                    <Pagination
                        showRowsPerPage={false}
                        onChangePage={({ page, size }) => setParams(prev => ({ ...prev, page, size }))}
                        totalPage={totalPage}
                        currentPage={params.page}
                        limitPage={params.size}
                    />
                </Col>
            </Row>
        </>
    )
}

export default App;
