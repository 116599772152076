import Input from 'components-form/Input';
import Form from 'components-form/Form';
import React, { useState } from 'react';
import logo from 'assets/image/logo-gabp.png';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, InputGroup, Row } from 'reactstrap';
import { Services } from 'service';
import { useAction } from 'hooks';
import ModalSetPassword from './_component/ModalSetPassword';

const ForgotPassword = (props) => {
    const [eyenewpass, setEyeNewPass] = useState(true)
    const [eyeconfpass, setEyeConfPass] = useState(true)
    const [open, setopen] = useState(false)
    const params = useLocation()
    const history = useHistory()
    const { hasFetch } = useAction()
    const onSubmit = (password) => {
        const { token } = JSON.parse('{"' + decodeURI(params.search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
        Services().post('/api/back-office/user/set-password', {
            "token": token,
            "password": password.newPassword,
            "confirmPassword": password.passwordConfirmation
        }).then(r => {
            setopen(!open)
        }).catch(e => {
            hasFetch({
                type: 'ALERT_TOAST_ERROR',
                payload: { message: e.message }
            })
        })
    }
    if (params.search === "") return null
    return (
        <div className="background-login app flex-row align-items-center">
            <Container>
                <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={6}>
                        <Card className="card-login">
                            <CardBody className='p-4 p-lg-5'>
                                <div className='d-flex justify-content-center mb-4'>
                                    <img src={logo} alt="logo-gurih" />
                                </div>
                                <Form id="change-pass-form" onSubmit={onSubmit} autoComplete="off">
                                    <label>Password Baru</label>
                                    <InputGroup className="mb-2">
                                        <Input
                                            type={!eyenewpass ? "text" : "password"}
                                            name="newPassword"
                                            placeholder="Masukan Password Baru"
                                            validation={['required', 'passwordWithCharacter', "minLength[8]"]}
                                            autoComplete="off" />
                                        <span
                                            onClick={() => setEyeNewPass(!eyenewpass)}
                                            className='cursor-pointer'>
                                            <i className={`text-14 icon-password fa ${eyenewpass ? "fa-eye" : "fa fa-eye-slash"}`} aria-hidden="true" />
                                        </span>
                                    </InputGroup>
                                    <label>Konfirmasi Password Baru</label>
                                    <InputGroup>
                                        <Input
                                            type={!eyeconfpass ? "text" : "password"}
                                            name="passwordConfirmation"
                                            placeholder="Masukan Konfirmasi Password Baru"
                                            validation={['required', 'equals[newPassword]']}
                                            autoComplete="off" />
                                        <span
                                            onClick={() => setEyeConfPass(!eyeconfpass)}
                                            className='cursor-pointer'>
                                            <i className={`text-14 icon-password fa ${eyeconfpass ? "fa-eye" : "fa fa-eye-slash"}`} aria-hidden="true" />
                                        </span>
                                    </InputGroup>
                                    <div className='text-right mt-4'>
                                        <Button onClick={() => history.push('/login')} size='sm' type="button" color="secondary" >
                                            Batal
                                        </Button>
                                        <Button size='sm' type="submit" color="primary" >
                                            Simpan
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalSetPassword open={open} setopen={() => setopen(!open)} />
        </div>
    )
}

export default ForgotPassword