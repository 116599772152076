import { getToken, removeToken } from "helper"

export const requestLoggingOut = () => {
    const { refreshToken } = getToken();
    return {
        type: "FETCH_CONTROLLERS",
        request: "REQUEST_LOGOUT",
        payload: {
            http: "post",
            url: '/api/authentication/logout',
            parameter: {
                refreshToken
            },
            callback: (rawResponse) => (dispatch) => {
                return removeToken()
            }
        }
    }
}

export const requestForgotPassword = ({ properties }) => {
    return {
        type: "FETCH_CONTROLLERS",
        request: "REQUEST_LOGOUT",
        payload: {
            http: "post",
            url: '/api/back-office/user/change-password',
            parameter: {
                "oldPassword": properties.oldPassword,
                "newPassword": properties.newPassword,
                "confirmPassword": properties.passwordConfirmation
            },
            callback: (raw) => (dispatch) => {
                dispatch({
                    type: "ALERT_TOAST_SUCCESS",
                    payload: {
                        message: "Password Berhasil Diperbarui"
                    }
                })
            }
        }
    }
}

export const reqeustForgotPassword = ({ email }) => {
    return {
        type: "FETCH_CONTROLLERS",
        request: "REQUEST_FORGOT_PASSWORD",
        payload: {
            http: "post",
            url: '/api/back-office/user/forgot-password',
            parameter: {
                "email": email
            },
            callback: (raw) => (dispatch) => {
                dispatch({
                    type: "ALERT_TOAST_SUCCESS",
                    payload: {
                        message: `Reset password telah berhasil dikirim ke ${email}`,
                    }
                })
            }
        }
    }
}