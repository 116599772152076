import React, { useCallback } from 'react';
import { Link } from 'react-router-dom'
import {
    Card, Row, Col, Form, CardBody, Input, Button, Badge,
} from 'reactstrap';
import { Pagination, Table as TableGenerator } from 'components';
import Select from 'react-select';
import { useFetch } from "hooks";
import { Breadcrumb } from 'components';
import { debounce } from 'helper';

// import IcMore from 'assets/svg/ic_more.svg'
import IcPlus from 'assets/svg/ic_plus.svg'

const rootStyle = (variable) => {
    return getComputedStyle(document.body).getPropertyValue(variable)
}

const OptionActiveInActive = [{
    value: "active",
    label: "Aktif"
}, {
    value: "inactive",
    label: "Tidak Aktif"
}]

const theme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: rootStyle('--light'),
        primary: rootStyle('--primary'),
    }
})

const styles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#e4e7ea' : provided.backgroundColor,
        border: '1px solid #DBDCDD',
        boxShadow: 'none',
        '&:hover': {
            borderColor: state.selectProps.readonly ? '#DBDCDD' : rootStyle('--primary')
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0.475rem 0.625rem',
        height: 'calc(1.5em + 1.25rem + 5px)'
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
    })
}

const getColorStatus = (status) => {
    switch (status) {
        case true:
            return <Badge color="success" className='text-10 p-1'>
                Aktif
            </Badge>
        case false:
            return <Badge color="danger" className='text-10 p-1'>
                Tidak  Aktif
            </Badge>
        default:
            return <Badge color="black" className='text-10 p-1'>
                {status}
            </Badge>
    }
}
// \totalPage: data?.data?.totalPages
const App = ({
    title: documentTitle,
    access: permissionAccess
}) => {
    document.title = documentTitle;
    const { data, loading: isLoading, totalPage, params, setParams, error } = useFetch(
        `/api/back-office/user`,
        currentParams => ({
            size: 10,
            sort: 'name,asc',
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
        }),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        { withQueryParams: false, showAlertOnError: false }
    )

    /* fetching role list */
    const { data: roleList, loading: isLoadingRole } = useFetch(
        `/api/back-office/roles`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );

    const TableConfiguration = {
        columns: [
            {
                key: 'userCode', title: 'ID pengguna',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <Link to={`/user/${row.id}`}>{value}</Link>
                }
            },
            {
                key: 'username', title: 'Username',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value}</span>
                }
            },
            {
                key: 'name', title: 'Nama Pengguna',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value}</span>
                }
            },
            {
                key: 'roleName', title: 'Role',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value}</span>
                }
            },
            {
                key: 'active', title: 'Status',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value) => getColorStatus(value)
            }
        ]
    }

    const dataBreadcrumb = [
        { to: '#', label: 'Manajemen Pengguna' }
    ]

    /* set params for searching */
    const setParamsSearch = useCallback(debounce((name, value) => setParams(prev => ({ ...prev, page: 1, [name]: typeof value === 'string' ? value?.trim() : value })), 600), [])

    /* handle searching for input type */
    const handleSearchChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setParamsSearch(name, value);
    }

    /* handle searching for select type */
    const handleSelectChange = (name, e) => {
        let value = e?.value;

        setParamsSearch(name, value);
    }

    return (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Admin</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                    {
                        permissionAccess?.create &&
                        <div className="d-inline-block float-right">
                            <Link to="/user/create">
                                <Button color="primary">
                                    <img src={IcPlus} alt='create' className='mr-2' height={19} /> Tambah Admin
                                </Button>
                            </Link>
                        </div>
                    }
                </Col>
            </Row>
            <Card body>
                <Form>
                    <Row className="justify-content-between">
                        <Col lg={3}>
                            <Select
                                name="roleId"
                                id="roleId"
                                placeholder="Pilih Role"
                                className="basic-single"
                                defaultValue={params?.roleId}
                                isLoading={isLoadingRole}
                                options={roleList?.map(obj => ({ label: obj?.name, value: obj?.id }))}
                                isClearable={true}
                                theme={theme}
                                styles={styles}
                                onChange={(e) => handleSelectChange('roleId', e)}
                            />
                        </Col>
                        <Col lg={3} className="align-self-end">
                            <Select
                                name="status"
                                id="roleId"
                                placeholder="Pilih Status"
                                className="basic-single"
                                // defaultValue={params?.roleId}
                                isLoading={isLoadingRole}
                                options={OptionActiveInActive}
                                isClearable={true}
                                theme={theme}
                                styles={styles}
                                onChange={(e) => setParams(params => ({
                                    ...params,
                                    page: 1,
                                    status: e?.value
                                }))}
                            />
                        </Col>
                        <Col lg={6} className="align-self-end">
                            <Row className='justify-content-end'>
                                <Col lg={6}>
                                    <div className="input-search">
                                        <i className="fa fa-search text-muted"></i>
                                        <Input className="pl-5" type="text" name="name" id="name" placeholder="Cari Nama" onChange={handleSearchChange} defaultValue={params?.name} />
                                    </div >
                                </Col>
                            </Row>
                        </Col>
                    </Row >
                </Form >
            </Card >
            <Card>
                <CardBody className="p-0">
                    <TableGenerator
                        {...TableConfiguration}
                        dataList={data}
                        params={params}
                        loading={isLoading}
                        error={error} />
                </CardBody>
            </Card>
            <Row className="pagination mr-1">
                <Col>
                    <Pagination
                        showRowsPerPage={false}
                        onChangePage={({ page, size }) => setParams(prev => ({ ...prev, page, size }))}
                        totalPage={totalPage}
                        currentPage={params.page}
                        limitPage={params.size}
                    />
                </Col>
            </Row>
        </>
    )
}

export default App;
