import React, { useEffect, useRef, useState } from 'react';
import { useContext } from "react";
import { Modalcontext } from "../detail";
import ModalPage from "components/Modal";
import { Button, Col, Label, Row } from "reactstrap";
import Form from 'components-form/Form';
import InputCounter from 'components-form/InputCounter';
import TextInput from 'components-form/textInput';
import icCancel from 'assets/svg/ic_cancel.svg'
import { useFormContext } from 'react-hook-form';
import TextInputHolder from 'components-form/textInputHolder';
import { Services } from 'service';
import { useAction } from 'hooks';
import { useReactToPrint } from 'react-to-print';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Spinner } from 'reactstrap';

const Penyelesaian = () => {
    const { resolved, setresolved, data } = useContext(Modalcontext);
    const defaultValue = {
        jeriken18L: data?.oilDeliverySummary?.oilDeliveryDetails?.filter(item => item.volume === "18")?.find(item => item.type === "jeriken")?.qty,
        jeriken20L: data?.oilDeliverySummary?.oilDeliveryDetails?.filter(item => item.volume === "20")?.find(item => item.type === "jeriken")?.qty,
        jumlahTitikJemput: data?.totalCompleteDelivery,
        noTiket: data?.tripPlanCode,
        warehouse: data?.warehouseName,
        oilCollected: 0,
        OilMissed: 0
    }
    const [isConfirm, setConfirm] = useState(false);
    const [dataSubmit, setDataSubmit] = useState('')
    const history = useHistory();
    const { hasFetch } = useAction();
    const [qr, setqr] = useState(null);
    const [imageurl, setimgurl] = useState(null);
    const refs = useRef(null)
    const handlePrint = useReactToPrint({
        content: () => refs.current
    });
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const onSubmitted = (input) => {

        setDataSubmit(input)

        if (isConfirm) {
            setIsLoadingSubmit(true);
            const jeriken18L = data?.oilDeliverySummary?.oilDeliveryDetails?.filter(item => item.type === "jeriken").find(item => item.volume === "18");
            const jeriken20L = data?.oilDeliverySummary?.oilDeliveryDetails?.filter(item => item.type === "jeriken").find(item => item.volume === "20")
            Services().post('/api/back-office/trip-plan/delivery-settlement', {
                "tripCode": input?.noTiket,
                "completedDelivery": input?.jumlahTitikJemput,
                "oilContainerBeans": [{
                    ...jeriken18L,
                    qty: input?.jeriken18L
                }, {
                    ...jeriken20L,
                    qty: input?.jeriken20L
                }],
                // "lossCash": input?.oilmissed.split(" ")?.[1],
                "totalWeight": parseFloat(input?.oilCollected),
                "notes": ""
            }, { responseType: "arraybuffer" })
                .then((result) => {
                    setIsLoadingSubmit(false);
                    const blob = new Blob([result?.data], { type: "image/jpg" });
                    const uris = URL.createObjectURL(blob);
                    setimgurl(uris)
                    setqr(!qr)
                })
                .catch(err => {
                    setIsLoadingSubmit(false);
                    hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: err?.message } });
                });
        }
        else {
            setConfirm(!isConfirm)
        }
    }
    return <ModalPage
        isOpen={resolved}
        handleClose={() => setresolved(!resolved)}
        component={<>
            {qr && <div className='text-right'>
                <span onClick={() => {
                    history.go(0)
                    setresolved(!resolved)
                }} style={{ cursor: "pointer" }}><img src={icCancel} alt="pict-cancel" /></span>
            </div>}
            {!qr && <div className='moda-password-title font-weight-bold'>Konfirmasi Penyelesaian</div>}
            {qr && <Row className='justify-content-center align-items-center flex-column'>
                <div className='moda-password-title align-items-center m-0'>Qr Code Penugasan</div>
                <img id='print-out' ref={refs} style={{ maxWidth: "100%", width: 250 }} src={imageurl} alt="pict-qr" />
                <Button
                    onClick={handlePrint}
                    type="button" outline color='primary'>
                    <i className='fa fa-print mr-2'></i>
                    Print Rute Jemput
                </Button>
            </Row>}
            {!qr &&
                <Form onSubmit={onSubmitted} id="penggunaForm" autoComplete="off" defaultValues={defaultValue}>
                    <div style={{ display: !isConfirm ? 'block' : "none" }}>
                        <Row>
                            <Col md={6}>
                                <Label style={{ fontSize: 14, fontWeight: 500, color: "#9C9DA6" }}>No Tiket</Label>
                                <span>
                                    <TextInput
                                        name="noTiket"
                                    />
                                </span>
                            </Col>
                            <Col md={6}>
                                <Label style={{ fontSize: 14, fontWeight: 500, color: "#9C9DA6" }}>Warehouse</Label>
                                <span>
                                    <TextInput
                                        name="warehouse"
                                    />
                                </span>
                            </Col>
                            <Col className='py-3' md={12}>
                                <Label style={{ fontSize: 14, fontWeight: 500, color: "#9C9DA6" }}>Jumlah Titik jemput</Label>
                                <span>
                                    <TextInput
                                        name="jumlahTitikJemput"
                                    />
                                </span>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} className='pb-3' style={{ fontSize: 14, fontWeight: 500 }}>Minyak Dijemput</Col>
                            <Col md={6}>
                                <Label>Jeriken 18L</Label>
                                <InputCounter
                                    name="jeriken18L"
                                    max={data?.oilDeliverySummary?.oilDeliveryDetails?.filter(item => item.volume === "18")?.find(item => item.type === "jeriken")?.qty}
                                />
                            </Col>
                            <Col md={6}>
                                <Label>Jeriken 20L</Label>
                                <InputCounter
                                    name="jeriken20L"
                                    max={data?.oilDeliverySummary?.oilDeliveryDetails?.filter(item => item.volume === "20")?.find(item => item.type === "jeriken")?.qty}
                                />
                            </Col>
                        </Row>
                        <div className='d-flex justify-content-end pt-4'>
                            <Button onClick={() => setresolved(!resolved)} className="mr-2" size='sm' outline color="primary">Batal</Button>
                            <Button type='submit' size='sm' color="primary">Selesaikan</Button>
                        </div>
                    </div>

                    {isConfirm && <DetailPenyelesaian setConfirm={() => setConfirm(!isConfirm)} isConfirm={isConfirm} data={data} dataSubmit={dataSubmit} isLoadingSubmit={isLoadingSubmit} />}
                    {/* {<div style={{ display: isConfirm ? 'block' : "none" }}>
                        <DetailPenyelesaian setConfirm={() => setConfirm(!isConfirm)} isConfirm={isConfirm} data={data} dataSubmit={dataSubmit}/>
                    </div>} */}
                </Form>
            }
        </>
        }
    />
}

export const DetailPenyelesaian = ({ setConfirm, isConfirm, data, dataSubmit, isLoadingSubmit }) => {

    const [dataConfirm, setDataConfirm] = useState('')
    const [loading, setLoading] = useState(false)

    const getJeriken18L = (data) => data?.filter(item => item.type === "jeriken").find(item => item.volume === "18");
    const getJeriken20L = (data) => data?.filter(item => item.type === "jeriken").find(item => item.volume === "20");

    useEffect(() => {
        const jeriken18L = getJeriken18L(data?.oilDeliverySummary?.oilDeliveryDetails)
        const jeriken20L = getJeriken20L(data?.oilDeliverySummary?.oilDeliveryDetails);
        // const qtyJeriken18L = data?.oilDeliverySummary?.oilDeliveryDetails?.filter(item => item.volume === "18")?.find(item => item.type === "jeriken")?.qty;
        // const qtyJeriken20L = data?.oilDeliverySummary?.oilDeliveryDetails?.filter(item => item.volume === "20")?.find(item => item.type === "jeriken")?.qty;

        const requestBody = {
            "id": data?.id,
            "oilContainerBeans": [
                {
                    ...jeriken18L,
                    qty: dataSubmit?.jeriken18L
                }, {
                    ...jeriken20L,
                    qty: dataSubmit?.jeriken20L
                }
            ]
        }

        if (isConfirm) {
            Services().post('/api/back-office/trip-plan/delivery/confirm', requestBody)
                .then(({ data }) => {
                    setLoading(true)
                    setDataConfirm(data?.data);
                });
        }

    }, [data, isConfirm, dataSubmit]);

    const IdrFormat = (number) => new Intl.NumberFormat('id-ID', { style: "currency", currency: "IDR", minimumFractionDigits: 0 }).format(number)

    if (!loading) return <div className='d-flex justify-content-center'><Spinner size="lg" color="primary" /></div>
    return (
        <div>
            <div style={{ gap: "1rem" }} className='d-flex flex-column'>
                <div>
                    <div className='d-flex flex-column'>
                        <Label style={{ color: "#70727D" }}>No Tiket</Label>
                        <span style={{ fontSize: 24, fontWeight: 700 }}>{dataConfirm?.tripCode}</span>
                    </div>
                </div>
                <Row className='d-flex justify-content-between'>
                    <Col md={8}>
                        <div className='d-flex flex-column'>
                            <Label style={{ color: "#70727D" }}>Warehouse</Label>
                            <span style={{ fontSize: 24, fontWeight: 700 }}>{dataConfirm?.destinationName}</span>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='d-flex flex-column'>
                            <Label style={{ color: "#70727D" }}>Titik Jemput</Label>
                            <span style={{ fontSize: 24, fontWeight: 700 }}>{dataConfirm?.completedPickup}</span>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <div className='d-flex flex-column'>
                            <Label style={{ color: "#70727D" }}>Jeriken 18 L</Label>
                            <span style={{ fontSize: 24, fontWeight: 700 }}>{getJeriken18L(dataConfirm?.settlementContainerBeans)?.qty}</span>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='d-flex flex-column'>
                            <Label style={{ color: "#70727D" }}>Jeriken 20 L</Label>
                            <span style={{ fontSize: 24, fontWeight: 700 }}>{getJeriken20L(dataConfirm?.settlementContainerBeans)?.qty}</span>
                        </div>
                    </Col>
                </Row>
                <Row className='d-flex justify-content-between'>
                    <Col md={8}>
                        <div className='d-flex flex-column'>
                            <Label style={{ color: "#70727D" }}>Jumlah Minyak Diangkut</Label>
                            <div className='d-flex' style={{ fontSize: 24, gap: "1rem", fontWeight: 700 }}>{dataConfirm?.totalWeight}<span>Kg</span> </div>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className='d-flex flex-column'>
                            <Label style={{ color: "#70727D" }}>Harga per Kg</Label>
                            <div className='d-flex' style={{ fontSize: 24, gap: "1rem", fontWeight: 700 }}>{IdrFormat(dataConfirm?.averagePrice)}</div>
                        </div>
                    </Col>
                </Row>

                <div className='d-none'><TotalWeight data={dataConfirm?.totalWeight} /></div>
                {/* <div>
                    <div className='d-flex flex-column'>
                        <Label style={{ color: "#70727D" }}>Jumlah Kerugian</Label>
                        <span style={{ fontSize: 24, fontWeight: 700 }}><OilMissed data={data} /></span>
                    </div>
                </div> */}
                <div className='d-flex justify-content-end pt-4'>
                    <Button onClick={setConfirm} type="button" size='sm' outline color="primary">Kembali</Button>
                    <Button size='sm' color="primary" disabled={isLoadingSubmit}>
                        {isLoadingSubmit &&
                            <span className='mr-2'>
                                <Spinner size="sm" />
                            </span>}
                        Konfirmasi
                    </Button>
                </div>
            </div>
        </div>
    )
}

export const Jeriken18L = () => {
    return (
        <TextInput name="jeriken18L" />
    )
}

export const Jeriken20L = () => {
    return (
        <TextInput name="jeriken20L" />
    )
}

export const TotalWeight = ({ data }) => {
    const { setValue } = useFormContext();

    useEffect(() => {
        setValue("oilCollected", data)
    }, [setValue, data])

    return (
        <TextInput name="oilCollected" />
    )
}

export const OilCollected = ({ data }) => {
    const { watch, setValue } = useFormContext();
    const jeriken18L = watch('jeriken18L');
    const jeriken20L = watch('jeriken20L');
    useEffect(() => {
        setValue("oilCollected", ((jeriken18L * 16.4) + (jeriken20L * 18.2)).toFixed(1))
    }, [jeriken18L, jeriken20L, setValue, data])
    return (
        <TextInputHolder name="oilCollected" />
    )

}

export const OilMissed = ({ data }) => {
    const { watch, setValue } = useFormContext();
    const jeriken18L = watch('jeriken18L');
    const jeriken20L = watch('jeriken20L');
    const IdrFormat = (number) => new Intl.NumberFormat('id-ID', { style: "currency", currency: "IDR", minimumFractionDigits: 0 }).format(number)
    useEffect(() => {
        const margin = parseFloat(
            ((jeriken18L * 16.4) + (jeriken20L * 18.2)).toFixed(1)
        )
        const poin = data?.oilDeliverySummary?.totalOilWeight
        const result = (poin - margin) * data?.averageOilPrice
        setValue("oilmissed", IdrFormat(parseFloat(result?.toFixed(1))))
    }, [jeriken18L, jeriken20L, setValue, data])
    return (
        <TextInputHolder name="oilmissed" />
    )

}
export default Penyelesaian;