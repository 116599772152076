import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom'
import { Card, Row, Col, CardBody, Input, Button, Badge } from 'reactstrap';
import IcPlus from 'assets/svg/ic_plus.svg';
import { Pagination, Table as TableGenerator } from 'components';
import { useFetch } from "hooks";
import { Breadcrumb } from 'components';
import { debounce } from 'helper';
import { Form, Select } from 'components-form';
import { styles } from 'components-form/Select';

const statusOption = [
    {
        value: "active",
        label: "Aktif"
    }, {
        value: "inactive",
        label: "Tidak Aktif"
    }
];

const searchOption = [
    {
        value: "name",
        label: "Nama Stockpoint"
    }, {
        value: "code",
        label: "Kode Stockpoint"
    }
];

const dataBreadcrumb = [
    { to: '#', label: 'Master Data Stockpoint' }
];

const getColorStatus = (status) => {
    switch (status) {
        case 'active':
            return <Badge color="success" className='text-10 p-1'>
                Aktif
            </Badge>
        case 'inactive':
            return <Badge color="danger" className='text-10 p-1'>
                Tidak  Aktif
            </Badge>
        default:
            return <Badge color="light" className='text-10 p-1'>
                {status ?? '-'}
            </Badge>
    };
};

const App = ({
    title: documentTitle,
    access: permissionAccess
}) => {
    document.title = documentTitle;

    const { data, loading: isLoading, totalPage, params, setParams, error } = useFetch(
        `/api/back-office/stockpoint`,
        currentParams => ({
            size: 10,
            // sort: '',
            searchType: searchOption[0].value,
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
        }),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        { withQueryParams: false }
    );

    const TableConfiguration = {
        columns: [
            {
                key: 'code', title: 'Kode Stockpoint',
                render: (value, row) => {
                    return <Link to={`/stockpoint/${row.id}`}>{value ?? "-"}</Link>
                }
            },
            {
                key: 'name', title: 'Nama Stockpoint',
                render: (value) => {
                    return <span>{value ?? "-"}</span>
                }
            },
            {
                key: 'branch_code', title: 'Branch',
                render: (value) => {
                    return <span>{value ?? "-"}</span>
                }
            }, {
                key: 'branch_name', title: 'Nama Branch',
                render: (value) => {
                    return <span>{value ?? "-"}</span>
                }
            },
            {
                key: 'province', title: 'Provinsi',
                render: (value) => {
                    return <span>{value ?? "-"}</span>
                }
            },
            {
                key: 'city', title: 'Kota',
                render: (value) => {
                    return <span>{value ?? "-"}</span>
                }
            },
            {
                key: 'status', title: 'Status',
                render: (value) => {
                    return getColorStatus(value);
                }
            }
        ]
    }

    return (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Stockpoint</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                    <div className="d-inline-block float-right">
                        {permissionAccess?.create && (
                            <Link to="/stockpoint/create">
                                <Button color="primary">
                                    <img src={IcPlus} alt='create' className='mr-2' height={19} /> Tambah Stockpoint
                                </Button>
                            </Link>
                        )}
                    </div>
                </Col>
            </Row>
            <Card body>
                <Form>
                    <SearchRow setParams={setParams} />
                </Form >
            </Card>
            <Card>
                <CardBody className="p-0">
                    <TableGenerator
                        {...TableConfiguration}
                        dataList={data}
                        params={params}
                        loading={isLoading}
                        error={error} />
                </CardBody>
            </Card>
            <Row className="pagination mr-1">
                <Col>
                    <Pagination
                        showRowsPerPage={false}
                        onChangePage={({ page, size }) => setParams(prev => ({ ...prev, page, size }))}
                        totalPage={totalPage}
                        currentPage={params.page}
                        limitPage={params.size}
                    />
                </Col>
            </Row>
        </>
    );
};

const SearchRow = ({ setParams }) => {
    const [search, setSearch] = useState('');
    const [searchType, setSearchType] = useState(searchOption[0].value);

    /* set params for searching */
    const setParamsSearch = useCallback(debounce((value) => {
        setParams(prev => ({
            ...prev,
            page: 1,
            searchType,
            search: typeof value === 'string' ? value?.trim() : value
        }));
    }, 600), [searchType]);

    /* set params for filtering */
    const setParamsFilter = useCallback(debounce((value) => {
        setParams(prev => ({
            ...prev,
            page: 1,
            searchType,
            search: '',
            status: value
        }));
    }, 600), [searchType]);

    /* handle searching for input type */
    const handleSearchChange = (e) => {
        // update state
        let value = e.target.value;
        setSearch(value);

        setParamsSearch(value);
    };

    /* handle search type change */
    const handleTypeChange = (e) => {
        setSearchType(e?.value)

        if (search) {
            setSearch('');
            setParamsSearch('');
        };
    };

    const handleStatusChange = (e) => {
        const value = e?.value;

        setSearch('');
        setParamsFilter(value);
    };

    return (
        <Row className='justify-content-end'>
            <Col sm={2}>
                <Select
                    name="statusType"
                    id="statusType"
                    className="basic-single"
                    placeholder="Pilih Status"
                    styles={styles}
                    options={statusOption}
                    isClearable
                    onChange={handleStatusChange}
                />
            </Col>
            <Col sm={3}>
                <Select
                    name="searchType"
                    id="searchType"
                    className="basic-single"
                    options={searchOption}
                    defaultValue={searchOption[0].value}
                    styles={styles}
                    onChange={handleTypeChange}
                />
            </Col>
            <Col sm={3}>
                <div className="input-search">
                    <i className="fa fa-search text-muted"></i>
                    <Input
                        className="pl-5"
                        type="text"
                        name="search"
                        id="name"
                        placeholder={`Cari ${searchType === 'name' ? 'Nama' : 'Kode'} Stockpoint`}
                        onChange={handleSearchChange}
                        value={search}
                    />
                </div >
            </Col>
        </Row >
    );
};

export default App;
