import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Row, Col, FormGroup, Label, Button, } from 'reactstrap';
import { useFetch, useAction } from 'hooks';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import Form from 'components-form/Form';
import Select from 'components-form/Select';
import DatePicker from "components-form/DatePicker";
import "react-datepicker/dist/react-datepicker.css";
import Error404 from 'page/error/Error404';
import TableRow from './row-table';
import InputMoneyValidateRequest from 'components-form/InputMoneyValidateRequest';
import { useFormContext, useWatch } from 'react-hook-form';
import { addHours, format } from 'date-fns';
import { debounce } from 'helper';
import AsyncSelect from 'components-form/AsyncSelect';

const findDriver = [{
    label: "Internal Stockpoint",
    value: "internal"
}, {
    label: "External Stockpoint",
    value: "external"
}]

const App = ({
    title: documentTitle,
    history
}) => {
    document.title = documentTitle;
    const currentDate = new Date();
    const [startDate, setStartDate] = useState(null);
    const params = useParams();
    const { hasFetch } = useAction();
    const isCreate = params?.id ? false : true;
    const [flex, setflex] = useState("internal")
    const [stateRequest, setStateRequest] = useState({
        outlet: 0,
        price: 0,
        qty: 0
    })
    const [raw, setraw] = useState([])
    const dataBreadcrumb = isCreate ?
        [
            { to: '/pickup', label: 'Penjemputan' },
            { to: null, label: 'Penjemputan Baru' }
        ]
        : [
            { to: '/pickup', label: 'Pengguna' },
            { to: `/pickup/${params.id}`, label: 'Detail Pengguna' },
            { to: null, label: 'Edit Pengguna' }
        ]

    /* fetching role list */
    const { data: roleList, loading: isLoadingRole, setParams: setParamsDriver } = useFetch(
        `/api/back-office/driver/assignment`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );

    /* fecthing data for detail page */
    const { data, status, error } = useFetch(
        `/api/back-office/user/${params?.id}`,
        {},
        useCallback(data => ({
            data: {
                ...data?.data
            }
        }), []),
        { onMount: !isCreate }
    )
    const [update, setupdate] = useState(false)
    const handleSubmit = (value) => {
        const convertNumber = parseInt(value?.uang?.replaceAll(".", ""))
        const requestBody = {
            "driverId": value.driver,
            "pickupRequestId": raw.filter(i => i !== "all").map(item => item.id),
            "assignmentDate": format(addHours(value?.startDate, 7), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
            "assignmentCash": convertNumber
        }
        const action = ({ requestBody, id = null }) => Services().post('/api/back-office/trip-plan/pickup', requestBody)

        action({ requestBody })
            .then((result) => {
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: `Penjemputan baru berhasil Ditambah` }
                })
                setraw([]);
                setupdate(!update);
            })
            .catch(e => {
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message } })
                setupdate(!update);
            })
    }

    const handleDateChange = (date) => {
        setStartDate(date);
        setParamsDriver(prev => ({
            ...prev,
            date: format(date, "dd-MM-yyyy")
        }))
    }
    const isBatchCalculated = useCallback((isCalculate) => {
        const data = isCalculate.filter(i => i !== "all")
        const outlet = data.length;
        const price = data.reduce((acc, value, key) => {
            acc = value.totalPrice + acc
            return acc
        }, 0)
        const qty = data.reduce((acc, value, key) => {
            acc = value.oilWeight + acc
            return acc
        }, 0)
        setraw(isCalculate)
        setStateRequest({ outlet, qty: qty.toFixed(1), price });
    }, [])
    const formatNumber = (number) => "Rp " + new Intl.NumberFormat('id-ID').format(number)
    const defaultValues = {
        uang: "",
        driver: "",
        startDate: startDate,
        branchId: '',
        sotckpointId: ''
    }
    const { data: cashData, setParams: setStockPointList } = useFetch(
        `/api/back-office/petty-cash/balance`,
        {
            unpaged: true
        },
        useCallback(data => ({
            data: data?.data
        }), []),
        { onMount: raw.length !== 0 }
    );
    const numerals = (number) => new Intl.NumberFormat('id-ID').format(number);
    if ((status === 'rejected' && error.code === 400) || (status === 'resolved' && data === null)) return <Error404 />
    return (status === 'resolved' || isCreate) && (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Row className='pb-4'>
                <Col>
                    <div className='d-flex'>
                        <Col className='d-inline-flex flex-wrap flex-column'>
                            <span className='text-color-gray'>Jumlah Outlet</span>
                            <h2>{stateRequest?.outlet}</h2>
                        </Col>

                        <Col className='d-inline-flex flex-wrap flex-column'>
                            <span className='text-color-gray'>Jumlah Minyak</span>
                            <h2>{stateRequest.qty} KG</h2>
                        </Col>
                        <Col className='d-inline-flex flex-wrap flex-column'>
                            <span className='text-color-gray'>Jumlah Penjualan</span>
                            <h2 className=''>{formatNumber(stateRequest.price)}</h2>
                        </Col>
                    </div>
                </Col>
                <Col lg={4}>
                    <div className='d-flex border border-light justify-content-between px-4' style={{ padding: 12, borderRadius: 6 }}>
                        <Col md={3} className='p-0 d-flex flex-column'>
                            <Label className='mb-1' style={{ color: '#70727D', fontSize: 14, fontWeight: 700 }}>Petty Cash</Label>
                            <span style={{ color: '#151823', fontSize: 14, fontWeight: 600 }}>{raw.length === 0 ? 0 : numerals(cashData?.pettyCash ?? 0)}</span>
                        </Col>
                        <Col md={3} className='p-0  d-flex flex-column'>
                            <Label className='mb-1' style={{ color: '#70727D', fontSize: 14, fontWeight: 700 }}>Sisa kas</Label>
                            <span style={{ color: '#151823', fontSize: 14, fontWeight: 600 }}>{raw.length === 0 ? 0 : numerals(cashData?.cash ?? 0)}</span>
                        </Col>
                        <Col md={3} className='p-0  d-flex flex-column'>
                            <Label className='mb-1' style={{ color: '#70727D', fontSize: 14, fontWeight: 700 }}>Total Modal</Label>
                            <span style={{ color: '#1178D4', fontSize: 14, fontWeight: 600 }}>{raw.length === 0 ? 0 : numerals(cashData?.totalAmount ?? 0)}</span>
                        </Col>
                    </div>
                </Col>
            </Row>
            <Form id="pickupForm" onSubmit={handleSubmit} autoComplete="off" defaultValues={defaultValues}>
                <Row>
                    <Col className='p-0' lg={3}>
                        <FormGroup>
                            <Col>
                                <Label>Tanggal Penugasan</Label>
                                <DatePicker
                                    placeholderText="Tanggal Penugasan"
                                    selected={startDate}
                                    onChangeRaw={(e) => { e.preventDefault(); }}
                                    onChange={handleDateChange}
                                    selectsStart
                                    name='startDate'
                                    startDate={startDate}
                                    className="form-control"
                                    minDate={currentDate}
                                    dateFormat={"dd/MM/yyyy"}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className='p-0' lg={3}>
                        <FormGroup>
                            <Col>
                                <Label htmlFor="roleId">Cari Driver Berdasarkan</Label>
                                <Select
                                    id="roleId"
                                    onChange={(finds) => {
                                        setflex(finds?.value)
                                    }}
                                    // validation={['required']}
                                    name="findDriver"
                                    className="basic-single"
                                    classNamePrefix="select"
                                    options={findDriver}
                                    placeholder={`Pilih Driver`}
                                    // isLoading={isLoadingRole}
                                    defaultValue={findDriver[0]?.value}
                                />
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className='p-0' lg={3}>
                        <FormGroup>
                            <Col>
                                <Label>Driver</Label>
                                {flex === "internal" && <Select
                                    id={"driver"}
                                    name={"driver"}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    options={roleList?.map(obj => ({ label: `${obj?.name}`, value: obj?.id }))}
                                    placeholder={`Pilih Driver`}
                                    isLoading={isLoadingRole}
                                    validation={['required']}
                                />}
                                {flex === "external" && <DriverExternal />}
                            </Col>
                        </FormGroup>
                    </Col>
                    <Col className='p-0' lg={3}>
                        <FormGroup>
                            <Col>
                                <Label>Uang yang dibawa</Label>
                                <TrigerValue stateRequest={stateRequest} cash={cashData} />
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="d-inline-block">
                            <h3>Daftar Permintaan</h3>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TableRow
                            update={update}
                            setupdate={setupdate}
                            setStockPointList={setStockPointList}
                            isBatchCalculated={isBatchCalculated}
                        />
                    </Col>
                </Row>
                <Row className='pt-5'>
                    <Col className="text-right">
                        <Link to={(isCreate) ? "/user" : `/user/${params.id}`}>
                            <Button type="button" color="secondary" className="m-1" onClick={() => window.history.back()}>
                                Batal
                            </Button>
                        </Link>
                        <CreatePenugasan stateRequest={stateRequest} />
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export const CreatePenugasan = ({ stateRequest }) => {
    const { formState: { isValid } } = useFormContext();
    const arrValid = Object.values(stateRequest);
    return (
        <Button
            disabled={arrValid.includes(0) || !isValid}
            size='md'
            type="submit"
            color={arrValid.includes(0) || !isValid ? "secondary" : "primary"}
        >
            Tugaskan
        </Button>
    )
}

export const TrigerValue = ({ stateRequest, cash }) => {
    const { register, setValue } = useFormContext();
    const formatNumeric = (number) => new Intl.NumberFormat('id-ID').format(number)
    useEffect(() => {
        setValue("uang", formatNumeric(stateRequest.price))
    }, [setValue, stateRequest.price])
    return (
        <InputMoneyValidateRequest
            id="uang"
            validation={[`required`, `minThan[${stateRequest.price}]`, `moreThanCash[${cash}]`]}
            name="uang"
            type="text"
            placeholder="0"
            autoComplete="off"
            {...register}
        />
    )
}
export const DriverExternal = ({ price, control }) => {
    const val = useWatch()
    const { setValue } = useFormContext()
    const { hasFetch } = useAction();
    const loadOptions = debounce(async (inputValue, callback) => {
        try {
            const date = format(new Date(val?.startDate), "dd-MM-yyyy")
            const raw = await Services().get('/api/back-office/driver/stc-assignment/cross', {
                driverCode: inputValue,
                date
            })
            setValue('driver', raw?.data?.data?.id)
            callback([{
                label: raw?.data?.data?.name,
                value: raw?.data?.data?.id,
            }])
        } catch (err) {
            hasFetch({
                type: 'ALERT_TOAST_ERROR',
                payload: { message: err.message }
            })
        }
    }, 1000);
    return (
        <AsyncSelect
            id="roleId"
            loadOptions={loadOptions}
            validation={['required']}
            name="roleId"
            className="basic-single"
            classNamePrefix="select"
            placeholder={`Pilih Driver`}
            isLoading={false}
        />
    )
}
export default App