import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Input as ReactStrapInput, FormFeedback, InputGroup } from 'reactstrap'
import validationRule from 'helper/ValidationRule'

const get = (obj, path, def) => (() => typeof path === 'string' ? path.replace(/\[(\d+)]/g, '.$1') : path.join('.'))()
    .split('.')
    .filter(Boolean)
    .every(step => ((obj = obj[step]) !== undefined)) ? obj : def

const defaultTransform = {
    output: val => val,
    input: val => val,
}

const Input = ({
    validation = [],
    validationMessage = [],
    inputGroupAddon = null,
    name,
    defaultValue = '',
    shouldUnregister = false,
    transform = defaultTransform,
    defaulWeight,
    ...props
}) => {
    const { control, getValues, } = useFormContext()
    const transformer = { ...defaultTransform, ...transform }
    return (
        <Controller
            shouldUnregister={shouldUnregister}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validationRule({ rule: validation, validationMessage }, getValues, name)}
            render={({
                field: { ref, onChange, value, ...fieldInput },
                formState: { errors } }) => {

                const filterDecimal = (str) => {
                    let filteredStr = str?.toString()?.replace(/[^\d.]/g, ""); // hapus karakter selain angka dan titik
                    let decimalIndex = filteredStr.indexOf("."); // dapatkan indeks tanda desimal pertama

                    if (decimalIndex === 0) { // jika tanda desimal ditemukan di awal
                        filteredStr = filteredStr.slice(1); // hapus tanda desimal di awal
                        decimalIndex = filteredStr.indexOf("."); // dapatkan indeks tanda desimal pertama setelah penghapusan
                    }

                    if (decimalIndex !== -1) { // jika ada tanda desimal
                        // hapus semua tanda desimal setelah indeks pertama
                        let str = ""
                        const regex = /^\d*\.?\d{0,1}$/; // Menggunakan regular expression untuk membatasi input
                        if (regex.test(value)) {
                            str = filteredStr.slice(decimalIndex + 1).replace(/\./g, "").slice(0, 1)
                        }
                        filteredStr = filteredStr.slice(0, decimalIndex + 1) + str;
                    }
                    return parseFloat(filteredStr) > parseFloat(defaulWeight) ? defaulWeight : filteredStr;
                }

                return (
                    <InputGroup>
                        <ReactStrapInput
                            invalid={!!get(errors, name)}
                            innerRef={ref}
                            {...fieldInput}
                            maxLength={250}
                            onChange={e => onChange(transformer.output(e))}
                            value={transformer.input(filterDecimal(value))}
                            {...props} />
                        {inputGroupAddon}
                        {get(errors, name) && <FormFeedback invalid={"true"} style={{ display: 'block' }}>{get(errors, name)?.message}</FormFeedback>}
                    </InputGroup>
                )
            }}
        />
    )
}

export default Input