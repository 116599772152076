import React from "react";
import { format } from "date-fns";

export const editStatusOptions = [
    {
        value: "pending",
        label: "Menunggu Disetujui",
        textClass: "text-warning",
    },
    {
        value: "accepted",
        label: "Disetujui",
        textClass: "text-success",
    },
    {
        value: "rejected",
        label: "Ditolak",
        textClass: "text-danger",
    },
    {
        value: "expired",
        label: "Kedaluwarsa",
        format: (label) => (
            <>
                <span className="text-purple font-weight-bold">{label}</span>
                <span className="text-12 text-black-50 ml-1">(Melewati batas waktu perubahan)</span>
            </>
        )
    },
];

const editableStatusList = [
    null, // initial status
    "accepted",
    "rejected",
];

export const isDirectPurchaseEditable = (chgStatus) => editableStatusList.includes(chgStatus);

export const formatDate = (date) => format(new Date(date), "dd/MM/yyyy");
