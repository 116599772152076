import React, { createContext, useCallback, useRef, useState } from 'react'
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { useParams } from 'react-router-dom';

import { Breadcrumb } from 'components';
import { useFetch } from 'hooks';
import Error404 from 'page/error/Error404';

import CardSpinner from './components/CardSpinner';
import TabEditHistory from './components/TabEditHistory';
import TabInformation from './components/TabInformation';

export const DetailContext = createContext();

const App = ({
    title: documentTitle,
    access: permissionAccess,
    history
}) => {
    document.title = documentTitle;

    const { id } = useParams();
    const [activeTab, setActiveTab] = useState("info");

    const dataBreadcrumb = [
        { to: '/direct-purchase', label: 'Pembelian Langsung' },
        { to: null, label: 'Detail' }
    ]

    const fetchDetail = useFetch(
        `/api/back-office/direct-purchase/${id}`,
        {},
        useCallback((res) => {
            const hasPendingReview = res?.data?.chgStatus === "pending";

            return {
                data: {
                    ...res?.data,
                    hasPendingReview
                }
            };
        }, [])
    );

    const historyLoaded = useRef(false);
    const shouldFetchHistory = !historyLoaded.current && activeTab === "history";

    const fetchEditHistory = useFetch(
        `/api/back-office/direct-purchase/history/${id}`,
        {},
        useCallback((res) => {
            historyLoaded.current = true;
            const length = res?.data?.content?.length;

            return {
                data: res?.data?.content?.map((item, i) => ({
                    ...item,
                    perubahanKe: length - i - 1
                }))
            };
        }, []),
        { onMount: shouldFetchHistory }
    );

    const allFetches = [
        fetchDetail,
        fetchEditHistory
    ];

    const hasError = allFetches.some(({ data, error, status }) => ([
        status === "rejected" && error.code === 400,
        status === "resolved" && data === null
    ].some(Boolean)));

    if (hasError) return <Error404 />;

    const tabConfig = [
        {
            id: "info",
            title: "Informasi Pembelian Langsung",
            Component: () => (
                <TabInformation
                    data={fetchDetail.data}
                    refetch={fetchDetail.fetchData}
                    permissionAccess={permissionAccess}
                />
            )
        },
        {
            id: "history",
            title: "Riwayat Perubahan",
            hasPendingReview: fetchDetail.data?.hasPendingReview,
            Component: () => (
                <TabEditHistory
                    data={fetchEditHistory.data}
                    referenceCode={fetchDetail?.data?.referenceCode}
                />
            )
        }
    ];

    return (
        <>
            <Row className="row mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Pembelian</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Nav tabs className="mb-4">
                {tabConfig.map(({ id: tabId, title, hasPendingReview }, i) => (
                    <NavItem key={`nav-item-${i}`} className={hasPendingReview ? "position-relative" : ""}>
                        <NavLink className={activeTab === tabId ? "active" : ""} onClick={() => setActiveTab(tabId)}>
                            {title}
                        </NavLink>
                        {hasPendingReview && (
                            <div className="bg-danger position-absolute" style={{ height: '10px', width: '10px', borderRadius: '100%', top: '8px', right: 0 }} />
                        )}
                    </NavItem>
                ))}
            </Nav>
            <TabContent activeTab={activeTab}>
                {tabConfig.map(({ id: tabId, Component }, i) => (
                    <TabPane key={`tab-pane-${i}`} tabId={tabId}>
                        {allFetches[i].loading ? <CardSpinner /> : <Component />}
                    </TabPane>
                ))}
            </TabContent>
        </>
    )
}

export default App