import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import {
    Badge, Card, Row, Col, CardBody, Input, Button, Tooltip,
} from 'reactstrap';
import { Pagination, Table as TableGenerator } from 'components';
// import Select from 'react-select';
import { useFetch } from "hooks";
import { Breadcrumb } from 'components';
import { capitalize, debounce } from 'helper';
import icFilter from 'assets/svg/ic_filter.svg'

// import IcMore from 'assets/svg/ic_more.svg'
import IcPlus from 'assets/svg/ic_plus.svg';
import DatePicker from 'react-datepicker';
import { Form } from 'components-form';
import { addDays, format } from 'date-fns';
import { Select } from 'components-form';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import IcEdit from 'assets/svg/ic_edit_natural_50.svg';
import IcInformation from 'assets/svg/ic_information_orange.svg';
import DownloadCsv from 'components/DownloadCsv';
import { Services } from 'service';
import { isDirectPurchaseEditable } from './constants';

const apiClient = Services();
const baseEndpoint = "/api/back-office/direct-purchase";

const rootStyle = (variable) => {
    return getComputedStyle(document.body).getPropertyValue(variable)
}

const theme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: rootStyle('--light'),
        primary: rootStyle('--primary'),
    }
})

const styles = {
    control: (provided, state) => ({
        ...provided,
        borderRadius: '99px',
        backgroundColor: state.isDisabled ? '#e4e7ea' : provided.backgroundColor,
        border: '1px solid #DBDCDD',
        boxShadow: 'none',
        '&:hover': {
            borderColor: state.selectProps.readonly ? '#DBDCDD' : rootStyle('--primary'),
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0.475rem 0.625rem',
        height: 'calc(1.5em + 1.25rem + 5px)'
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        borderRadius: '99px',
        display: 'none'
    })
}

const selfDeclarationStatus = (callback, row) => {
    const TooltipOpen = ({ id }) => {
        const [tooltipOpen, setTooltipOpen] = useState(false);
        return (
            <div>
                <span className="ml-1" color="secondary" id={'tooltip' + id}>
                    <img src={IcInformation} alt='Info Terupdate' />
                </span>
                <Tooltip
                    isOpen={tooltipOpen}
                    target={'tooltip' + id}
                    toggle={() => setTooltipOpen(!tooltipOpen)}
                    className='tooltip-sd'
                >
                    Document telah diupdate
                </Tooltip>
            </div>
        )
    }
    const danger = () => <span className='text-danger'>Tidak Ada</span>
    const normal = () => <div className='d-flex' style={{ gap: 4 }}>
        <span>Ada</span>
        {row?.updatedSD && <TooltipOpen id={row?.id} />}
    </div>
    return !callback() ? danger() : normal();
}

const verifiedSelfDeclarationStatus = (callback) => {
    const warning = () => <Badge color="warning">Belum Terverifikasi</Badge>
    const danger = () => <Badge color="danger">Ditolak</Badge>
    const success = () => <Badge color="success">Terverifikasi</Badge>
    const expired = () => <Badge color="purple">Kedaluwarsa</Badge>
    switch (callback()) {
        case 'verified':
            return success();
        case 'unverified':
            return warning();
        case 'expired':
            return expired();
        case 'rejected':
            return danger();
        default:
            return '-'
    }
}

const PembelianContext = createContext();
const PrivillageFilters = (data, level) => {
    return data?.filter(itemByUserAccess => (
        level === "Stockpoint Officer" ? itemByUserAccess.key !== "branchName" && itemByUserAccess.key !== "stockpointName" : itemByUserAccess
    ))
}
const App = ({
    title: documentTitle,
    access: permissionAccess
}) => {
    document.title = documentTitle;
    const { level } = useSelector(state => state?.homesidemenu?.profile);
    const { data, loading: isLoading, totalPage, params, setParams, error } = useFetch(
        baseEndpoint,
        currentParams => ({
            size: 10,
            sort: '',
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
        }),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        { withQueryParams: false, showAlertOnError: false }
    )

    const TableConfiguration = {
        columns: PrivillageFilters([
            {
                key: 'referenceCode', title: 'No Tiket',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <Link to={`/direct-purchase/${row.id}`}>{value}</Link>
                }
            },
            {
                key: 'supplierName', title: 'Outlet',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value ?? "-"}</span>
                }
            },
            {
                key: 'salespersonName', title: 'Salesperson',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <Link to={row?.salespersonUrl}>{capitalize(value) ?? "-"}</Link>
                }
            },
            {
                key: 'phone', title: 'No HP Supplier',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value ?? "-"}</span>
                }
            },
            {
                title: 'Jumlah Minyak',
                children: [{
                    key: 'totalContainer18L',
                    title: 'Jeriken 18L',
                    render: (value, row, key) => {
                        return <>{value ?? "-"}</>
                    }
                }, {
                    key: 'totalContainer20L',
                    title: 'Jeriken 20L',
                    render: (value, row, key) => {
                        return <>{value ?? "-"}</>
                    }
                }, {
                    key: 'totalOilWeight',
                    title: 'Kg',
                    render: (value, row, key) => {
                        return <>{value ?? "-"}</>
                    }
                }]
            },
            {
                key: 'branchName', title: 'Branch',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value) => value
            },
            {
                key: 'stockpointName', title: 'Stockpoint',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value) => value
            },
            {
                key: 'purchaseDate', title: 'Tanggal Pembelian',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{format(new Date(value), 'dd/MM/yyyy - HH:mm')}</span>
                }
            },
            {
                key: 'selfDeclaration', title: 'Self Declaration',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row) => {
                    return selfDeclarationStatus(() => {
                        return value;
                    }, row)
                }
            },
            {
                key: 'statusSD', title: 'Verifikasi Self Declaration',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value) => verifiedSelfDeclarationStatus(() => {
                    return value;
                })
            },
            {
                key: "chgStatus", title: "Status Perubahan",
                render: (value) => value === "pending"
                    ? <span className="text-b2 text-warning">Menunggu Persetujuan</span>
                    : "-"
            },
            {
                key: 'createdBy', title: 'Dibuat Oleh',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value) => capitalize(value)
            },
            permissionAccess?.update && {
                key: "",
                render: (_value, row) => isDirectPurchaseEditable(row?.chgStatus)
                    ? (
                        <Link to={`/direct-purchase/${row?.id}/edit`}>
                            <img src={IcEdit} alt="Edit icon" />
                        </Link>
                    )
                    : null
            }
        ], level)
    }

    const dataBreadcrumb = [
        { to: null, label: 'Pembelian Langsung' }
    ]

    /* set params for searching */
    const setParamsSearch = useCallback(debounce((name, value) => setParams(prev => ({ ...prev, page: 1, [name]: typeof value === 'string' ? value?.trim() : value })), 600), [])

    /* handle searching for input type */
    const handleSearchChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setParamsSearch(name, value);
    }

    return (
        <PembelianContext.Provider
            value={{
                setParams,
                params
            }}
        >
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Pembelian</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                    {
                        permissionAccess?.create &&
                        <div className="ml-4 d-inline-block float-right">
                            <Link to="/direct-purchase/create">
                                <Button color="primary">
                                    <img src={IcPlus} alt='create' className='mr-2' height={19} /> Pembelian Langsung
                                </Button>
                            </Link>
                        </div>
                    }
                    <div className="d-inline-block float-right">
                        <DownloadCsv
                            validation={[
                                {
                                    condition: (data?.length > 0) && !isLoading,
                                    message: "Data tidak ditemukan"
                                },
                                {
                                    condition: (params?.startDate?.length > 1) && (params?.endDate?.length > 1),
                                    message: "Kedua filter tanggal wajib diisi"
                                }
                            ]}
                            onClick={({ filterParams }) => ({
                                downloadRequest: apiClient.get(`${baseEndpoint}/export`, filterParams(params), {
                                    responseType: "blob"
                                })
                            })}
                            onBeforeSave={({ timestamp }) => ({
                                filename: `PEMBELIANLANGSUNG${timestamp}.csv`
                            })}
                        />
                    </div>
                </Col>
            </Row>
            <Form>
                <Card className='' body>
                    <Row className={'justify-content-between position-relative '}>
                        <Col lg={3}>
                            <FilterDate />
                        </Col>
                        {<Col lg={4}>
                            <Row className='align-items-center justify-content-end'>
                                <Filters />
                                <Col lg={10}>
                                    <div className="input-search">
                                        <i className="fa fa-search text-muted"></i>
                                        <Input onChange={handleSearchChange} className="pl-5" type="text" name="search" id="search" placeholder="Cari Supplier/Outlet atau No HP Supplier" />
                                    </div >
                                </Col>
                            </Row>
                        </Col>}
                    </Row >
                </Card >
                <Card>
                    <CardBody className="p-0">
                        <TableGenerator
                            {...TableConfiguration}
                            dataList={data}
                            params={params}
                            loading={isLoading}
                            error={error} />
                    </CardBody>
                </Card>
                <Row className="pagination mr-1">
                    <Col>
                        <Pagination
                            showRowsPerPage={false}
                            onChangePage={({ page, size }) => setParams(prev => ({ ...prev, page, size }))}
                            totalPage={totalPage}
                            currentPage={params.page}
                            limitPage={params.size}
                            withSelector
                        />
                    </Col>
                </Row>
            </Form >
        </PembelianContext.Provider >
    )
}

const Filters = () => {
    const { setParams, params } = useContext(PembelianContext);
    const { watch, reset } = useFormContext();
    const [openFilter, setOpenFilter] = useState(false);
    const { level } = useSelector(state => state?.homesidemenu?.profile);
    const { data: sales, loading: isLoadingRole } = useFetch(
        `/api/back-office/direct-purchase/salesperson-filter`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );

    const branch = watch('branchId');
    const stockpoint = watch('stockpointId');
    const salesperson = watch('salesId');
    const selfDeclaration = watch('selfDeclaration');
    const chgStatus = watch('chgStatus');
    const statusSD = watch('statusSD');

    const atLeastOne = () => {
        return branch || stockpoint || salesperson || selfDeclaration || statusSD || chgStatus;
    };


    const approved = () => {
        setParams(prev => ({
            ...prev,
            page: 1,
            salesperson,
            branch,
            stockpoint,
            chgStatus,
            selfDeclaration: selfDeclaration,
            statusSD: statusSD
        }))
        setOpenFilter(!openFilter);
    }

    const isReset = () => {
        setParams(prev => ({
            size: 10,
            startDate: prev?.startDate,
            endDate: prev?.endDate,
            search: prev?.search,
            sort: '',
            page: 1
        }));
        setOpenFilter(!openFilter);
        reset({
            salesId: null,
            branchId: null,
            stockpointId: null,
            selfDeclaration: null,
            chgStatus: null,
            statusSD: null
        });
    }
    const cancels = () => {
        setOpenFilter(!openFilter);
        reset({
            salesId: params?.salesperson,
            branchId: params?.branch,
            stockpointId: params?.stockpoint,
            selfDeclaration: params?.selfDeclaration,
            chgStatus: params?.chgStatus,
            statusSD: params?.statusSD
        });
    }

    return (
        <div className='d-flex justify-content-end' lg={2}>
            <button onClick={() => setOpenFilter(!openFilter)} type='button' className='d-flex position-relative justify-content-center align-items-center' style={{ borderRadius: "100%", background: "#E9F4FF", border: "1.5px solid #1178D4", width: '2.5rem', height: '2.5rem' }}>
                {!!atLeastOne() && <div className='position-absolute' style={{ height: '6px', top: 0, right: '4px', width: '6px', background: '#ee2b2d', borderRadius: '100%' }} />}
                <img width={24} src={icFilter} alt='ic_filter' />
            </button>
            {openFilter && <div className='d-flex flex-column px-4 py-3' style={{
                position: 'absolute',
                top: '6vh',
                gap: '1rem',
                width: ' 458px',
                background: 'white',
                zIndex: 10,
                boxShadow: ' 0px 1px 4px 0px rgba(112, 114, 125, 0.20)',
                right: -5,
                borderRadius: '12px'
            }}>
                <span className='text-14' style={{ fontWeight: 700 }}>FIlTER DATA</span>
                <Row className='align-items-center justify-content-between'>
                    <Col lg={5}>Salesperson</Col>
                    <Col lg={7} className="align-self-end">
                        <Select
                            name="salesId"
                            id="roleId"
                            placeholder="Pilih Salesperson"
                            className="basic-single"
                            isLoading={isLoadingRole}
                            options={sales?.map(item => ({ label: item?.name, value: item.code }))}
                            isClearable={true}
                            theme={theme}
                            styles={styles}
                        />
                    </Col>
                </Row>
                {level !== "Stockpoint Officer" && <Row className='align-items-center justify-content-between'>
                    <Col lg={5}>Branch</Col>
                    <Col style={{ display: level !== "Stockpoint Officer" ? "block" : "none" }} lg={7} className="align-self-end">
                        <FilterBranchField />
                    </Col>
                </Row>}
                {level !== "Stockpoint Officer" && <Row className='align-items-center justify-content-between'>
                    <Col lg={5}>Stockpoint</Col>
                    <Col style={{ display: level !== "Stockpoint Officer" ? "block" : "none" }} lg={7} className="align-self-end">
                        <FilterStockpointField />
                    </Col>
                </Row>}
                <Row className='align-items-center justify-content-between'>
                    <Col lg={5}>Self Declartion</Col>
                    <Col lg={7}>
                        <SelfDeclaration />
                    </Col>
                </Row>
                <Row className="align-items-center justify-content-between">
                    <Col lg={5}>Status Perubahan</Col>
                    <Col lg={7}>
                        <RequestEditStatus />
                    </Col>
                </Row>
                <Row className='align-items-center justify-content-between'>
                    <Col lg={5}>Verifikasi Self Declartion</Col>
                    <Col lg={7}>
                        <StatusSD />
                    </Col>
                </Row>
                <div style={{ borderTop: '1px solid #DCDEE3' }} />
                <div className='d-flex justify-content-between align-items-center'>
                    <span onClick={() => {
                        !!atLeastOne() && isReset();
                        !!atLeastOne() && setOpenFilter(!openFilter);
                    }} className={`p-2 ${!!atLeastOne() ? "text-danger" : "text-normal"}`} style={{ cursor: 'pointer', color: '#9C9DA6' }}>Reset Semua Filter</span>
                    <div>
                        <Button onClick={() => {
                            cancels();
                        }} size='sm' className='p-2' style={{ borderRadius: 24 }} type="button" outline color="primary" >
                            Batal
                        </Button>
                        <Button disabled={!atLeastOne()} color={!atLeastOne() ? "secondary" : "primary"} onClick={approved} size='sm' className='p-2' style={{ borderRadius: 24 }} type="button" >
                            Terapkan
                        </Button>
                    </div>
                </div>
            </div>}
        </div>
    )
}
const FilterDate = () => {
    const allowedRangeDay = 31;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const { setParams } = useContext(PembelianContext);

    useEffect(() => {
        if (startDate && endDate) {
            setParams(prev => ({
                ...prev,
                page: 1,
                startDate: format(new Date(startDate), 'dd-MM-yyyy'),
                endDate: format(new Date(endDate), 'dd-MM-yyyy')
            }))
        }
    }, [startDate, endDate, setParams]);

    const maxDate = () => {
        const currentDate = new Date();
        if (addDays(startDate, allowedRangeDay - 1) > currentDate || (startDate !== null && endDate !== null)) {
            return currentDate;
        }
        return startDate ? addDays(startDate, allowedRangeDay - 1) : new Date();
    };

    return (
        <DatePicker
            id="filterDateRange"
            name="filterDateRange"
            placeholderText="Pilih Tanggal"
            autoComplete='off'
            onChangeRaw={(e) => e.preventDefault()}
            onChange={(dates) => {
                const [start, end] = dates;
                setStartDate(start);
                setEndDate(end);
                if (!dates[0] && !dates[1]) {
                    setParams(prev => ({
                        ...prev,
                        page: 1,
                        startDate: null,
                        endDate: null
                    }))
                }
            }}
            maxDate={maxDate()}
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            className="form-control"
            dateFormat={"dd/MM/yyyy"}
            selectsRange
            isClearable
        />
    )
}

const FilterBranchField = () => {
    const { setValue } = useFormContext();
    const { data, loading } = useFetch(
        `/api/back-office/branch`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );
    return (
        <Select
            name="branchId"
            id="branchId"
            placeholder="Pilih Branch"
            className="basic-single"
            isLoading={loading}
            options={data?.map(item => ({ label: item?.name, value: item?.id, id: item?.id }))}
            isClearable={true}
            theme={theme}
            styles={styles}
            onChange={(branch) => {
                if (branch) {
                    setValue("branchId", branch?.value);
                    setValue("stockpointId", null)
                }
                else {
                    setValue("branch", null);
                    setValue("branchId", null);
                    setValue("stockpointId", null)
                }
            }}
        />
    )
}

const FilterStockpointField = () => {
    const { watch, setValue, } = useFormContext();
    const branchId = watch('branchId');
    const { data, loading, setParams: fetchParamsSpo } = useFetch(
        `/api/back-office/stockpoint`,
        currentParams => ({
            unpaged: true
        }),
        useCallback(data => ({
            data: data?.data?.content,
        }), [])
    );
    useEffect(() => {
        if (branchId) {
            fetchParamsSpo(prev => ({
                ...prev,
                branchId: branchId
            }))
        }
    }, [branchId, fetchParamsSpo]);
    return (
        <Select
            id="stockpointId"
            name="stockpointId"
            placeholder="Pilih Stock Point"
            className="basic-single"
            isLoading={loading}
            options={data?.map(item => ({ label: item?.name, value: item?.id }))}
            isClearable={true}
            isDisabled={!branchId}
            theme={theme}
            styles={styles}
            onChange={(stockpoint) => {
                if (stockpoint) {
                    setValue("stockpointId", stockpoint.value)
                }
                else {
                    setValue("stockpointId", null)
                }
            }}
        />
    )
}

const SelfDeclaration = () => {
    const data = [{
        label: 'Ada',
        value: 'checked',
    }, {
        label: 'Tidak Ada',
        value: 'unchecked',
    }]
    return (
        <Select
            id="selfDeclaration"
            name="selfDeclaration"
            placeholder="Declaration"
            className="basic-single"
            options={data}
            isClearable={true}
            theme={theme}
            styles={styles}
        />
    )
}

const RequestEditStatus = () => {
    const options = [
        { label: "Menunggu Persetujuan", value: "pending" },
        { label: "Lainnya", value: "others" },
    ];

    return (
        <Select
            id="chgStatus"
            name="chgStatus"
            placeholder="Status Perubahan"
            className="basic-single"
            options={options}
            isClearable={true}
            theme={theme}
            styles={styles}
        />
    )
}

const StatusSD = () => {
    const data = [{
        label: 'Terverifikasi',
        value: 'verified',
    }, {
        label: 'Belum Terverifikasi',
        value: 'unverified',
    }, {
        label: 'Ditolak',
        value: 'rejected',
    }, {
        label: 'Kedaluwarsa',
        value: 'expired',
    }]
    return (
        <Select
            id="statusSD"
            name="statusSD"
            placeholder="Verifikasi Self Declaration"
            className="basic-single"
            options={data}
            isClearable={true}
            theme={theme}
            styles={styles}
        />
    )
}


export default App;
