import ModalPage from 'components/Modal';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Row } from 'reactstrap';
import { Services } from 'service';
import icCancel from 'assets/svg/ic_cancel.svg'
import { useReactToPrint } from "react-to-print";
import 'assets/css/printable.css';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ModalFormsQR = ({ open, close }) => {
    const { id } = useParams()
    const [imageUrl, setImageUrl] = useState(null)
    const history = useHistory()
    useEffect(() => {
        open && Services().get(`/api/back-office/trip-plan/assignment-qr-code/${id}`, { tripId: id }, { responseType: "arraybuffer" })
            .then((response) => {
                const blob = new Blob([response?.data], { type: "image/jpg" });
                const uris = URL.createObjectURL(blob);
                setImageUrl(uris)
            });
    }, [id, open])
    const refs = useRef(null)
    const handlePrint = useReactToPrint({
        content: () => refs.current
    });
    return (
        <ModalPage
            isOpen={open}
            handleClose={close}
            component={<>
                <div className='text-right'>
                    <span onClick={() => {
                        history.go(0)
                        close()
                    }} style={{ cursor: "pointer" }}><img src={icCancel} alt="pict-cancel" /></span>
                </div>
                <Row className='justify-content-center align-items-center flex-column'>
                    <div className='moda-password-title align-items-center m-0'>Qr Code Penugasan</div>
                    <img id='print-out' ref={refs} style={{ maxWidth: "100%", width: 250 }} src={imageUrl} alt="pict-qr" />
                    <Button
                        onClick={handlePrint}
                        type="button" outline color='primary'>
                        <i className='fa fa-print mr-2'></i>
                        Print
                    </Button>
                </Row>
            </>
            }
        />
    )
}

export default ModalFormsQR