import React from 'react'
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

const ContentConfirmClose = ({ close, step }) => {
    const history = useHistory()

    const handleClose = () => {
        close()
        history.go(0)
    }

    return (
        <>
            <div className='font-weight-bold text-16 mb-4'>Yakin Keluar ?</div>
            <div className="my-4">
                Semua perubahan yang anda buat tidak akan tersimpan.
            </div>
            <div className='text-right mt-4'>
                <Button onClick={() => step("confirm")} size='sm' type="button" color="secondary" >
                    Batal
                </Button>
                <Button type="button" size='sm' color="primary" onClick={handleClose}>
                    Keluar
                </Button>
            </div>
        </>
    )
}

export default ContentConfirmClose