import React from 'react'
import { Button } from 'reactstrap';
import ModalPage from 'components/Modal';
import { useHistory } from 'react-router-dom';
const ModalSetPassword = ({ open, setopen }) => {
    const history = useHistory()
    return (
        <ModalPage
            isOpen={open}
            handleClose={() => setopen(!open)}
            component={<>
                <div className='moda-password-title font-weight-bold'>Berhasil</div>
                <p style={{ width: "18rem" }} className='font-weight-normal'>
                    Password anda berhasil diperbarui. Silahkan login kembali
                </p>
                <div className='text-right mt-4'>
                    <Button onClick={() => history.push('/login')} size='md' type="submit" color="primary" >
                        <span className='px-2'>Login</span>
                    </Button>
                </div>
            </>}
        />
    )
}

export default ModalSetPassword