export const showConfirmationModal = properties => {
    return {
        type: "CONFIRMATION_MODAL_SHOW",
        payload: { ...properties }
    }
}

export const hideConfirmationModal = properties => {
    return {
        type: "CONFIRMATION_MODAL_HIDE",
        payload: { ...properties }
    }
}

export const sendConfirmation = properties => {
    return {
        type: "CONFIRMATION_MODAL_ACTION_START",
        payload: { ...properties }
    }
}
