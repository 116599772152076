import React from "react";
import { Button, Card, CardBody, CardHeader } from "reactstrap";

import { IdrFormat, capitalize } from "helper";

import { editStatusOptions, formatDate } from "../constants";
import ModalPurchaseDetail, { useModalPurchaseDetail } from "./ModalPurchaseDetail";
import RowInfo from "./RowInfo";

const formatStatus = (status) => {
    const editStatus = editStatusOptions.find(({ value }) => value === status);

    const {
        label = capitalize(status),
        textClass,
        format
    } = editStatus ?? {};

    return format?.(label) ?? (
        <span className={`font-weight-bold ${textClass ?? ""}`}>
            {label}
        </span>
    );
};

export const IncomingChange = ({ data }) => {
    const modalDetail = useModalPurchaseDetail();
    
    return (
        <>
            <ModalPurchaseDetail
                directPurchaseId={data?.directPurchaseId}
                config={modalDetail.config}
            />
            
            <Card>
                <CardBody>
                    <CardHeader className="px-0 pt-0 pb-3">Informasi Perubahan Pembelian Langsung</CardHeader>
                    <RowInfo label="Status" value={data?.status} format={formatStatus} />
                    <RowInfo
                        label="No. Tiket Pembelian Langsung"
                        value={data?.reffNo}
                        format={(reffNo) => (
                            <Button
                                color="link"
                                className="p-0 font-weight-normal"
                                style={{ color: "#1178D4" }}
                                onClick={modalDetail.open}
                            >
                                {reffNo}
                            </Button>
                        )}
                    />
                    <RowInfo label="No. Tiket Perubahan" value={data?.reffNoChg} />
                    <RowInfo label="Perubahan ke" value={data?.chgNum} />
                    <RowInfo label="Tanggal Perubahan" value={data?.createdDate} format={formatDate} />
                    <RowInfo label="Diubah Oleh" value={data?.createdBy} format={capitalize} />
                    <hr />
                    <CardHeader className="px-0 pt-0 pb-3">Data Perubahan</CardHeader>
                    <ChangesInfo data={data} />
                </CardBody>
            </Card>
        </>
    );
}

export const CurrentValue = ({ data }) => (
    <Card>
        <CardHeader className="pb-0">Data Sebelum Perubahan</CardHeader>
        <CardBody>
            <RowInfo label="Perubahan ke" value={data?.chgNum} />
            <ChangesInfo data={data} />
        </CardBody>
    </Card>
);

export const ChangesInfo = ({ data }) => (
    <>
        <RowInfo label="Jumlah Minyak Jeriken 18L" value={data?.totalContainer18} />
        <RowInfo label="Jumlah Minyak Jeriken 20L" value={data?.totalContainer20} />
        <RowInfo label="Estimasi Jumlah Kg" value={data?.totalOilWeight} format={(value) => `${value} Kg`} />
        <RowInfo label="Total Harga Pembelian" value={data?.purchasePrice} format={IdrFormat} />
    </>
);

