import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Container, InputGroup, Row, Spinner } from 'reactstrap';
// import { NotificationContainer } from 'react-notifications'
import Input from 'components-form/Input';
import Form from 'components-form/Form';
import { setToken } from "helper";
import { Services } from 'service';
import { version } from 'config/Config'
import logo from 'assets/image/logo-gabp.png';
import ModalForgotPassword from './_components/ModalForgotPassword';
import { useDispatch } from 'react-redux';

const Login = props => {
    document.title = props.title;
    const [isLoading, setIsLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showForgotPasswordModal, setForgotPasswordModal] = useState(false)
    const dispatch = useDispatch()
    const onSubmit = (account) => {
        setIsLoading(true);
        Services().post('/api/authentication/back-office/login', account)
            .then((response) => {
                const { data } = response;
                setToken({ accessToken: data?.data?.accessToken, refreshToken: data?.data?.refreshToken, isLogin: true });
                localStorage.setItem("user", account?.username)
                setIsLoading(false);
                window.location.reload();
            })
            .catch(e => {
                setIsLoading(false);
                dispatch({ type: "ALERT_TOAST_ERROR", payload: { message: e?.message ?? 'Login Failed' } })
            });
    }
    return (
        <div className="background-login app flex-row align-items-center">
            <ModalForgotPassword open={showForgotPasswordModal} setopen={setForgotPasswordModal} />
            <Container>
                <Row className="justify-content-center">
                    <Col sm={12} md={8} lg={6}>
                        <Card className="card-login">
                            <CardBody className='p-4 p-lg-5'>
                                <div className='d-flex justify-content-center mb-4'>
                                    <img className='img-fluid' src={logo} alt="logo-gurih" width={234} height={120} />
                                </div>
                                <Row>
                                    <Col sm={12} md={12} className="pl-md-2 py-md-3">
                                        <Form id="loginForm" onSubmit={onSubmit} autoComplete="off">
                                            <label>Username</label>
                                            <InputGroup className="mb-3">
                                                <Input
                                                    validation={['required', 'minLength[2]']}
                                                    placeholder="Masukkan Username " name="username" type="text" autoComplete="off" />
                                            </InputGroup>
                                            <label>Password</label>
                                            <InputGroup>
                                                <Input type={`${showPassword ? "text" : "password"}`} name="password" placeholder="Masukkan Password" validation={['required']} autoComplete="off" />
                                                <i className={`text-14 icon-password fa ${(showPassword) ? "fa-eye" : "fa-eye-slash"}`} aria-hidden="true" onClick={() => setShowPassword(!showPassword)} />
                                            </InputGroup>
                                            <Row className='d-flex flex-row-reverse'>
                                                <Button color="link" className='font-weight-normal' onClick={() => setForgotPasswordModal(true)} >
                                                    Lupa Password?
                                                </Button>
                                            </Row>
                                            <Row>
                                                <Col xs={12} className="mt-4">
                                                    <Button type="submit" color="primary" className="btn-login px-4" block disabled={isLoading}>
                                                        {isLoading ? <Spinner size="sm" color="secondary" /> : 'Masuk'}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </Col>
                                </Row>
                            </CardBody >
                        </Card >
                    </Col >
                </Row >
                <Row>
                    <Col xs={12} className="text-center mt-3">
                        <span className="ml-auto">
                            <span className="d-none d-md-inline-block"> {version} | </span> Develop by <a href="https://sg-edts.com" rel="noopener noreferrer" target="_blank">PT Elevenia Digital Teknologi Sukses</a>.
                        </span>
                    </Col>
                </Row>
            </Container >
        </div >
    );
}

export default Login
