import React, { useCallback } from 'react';
import { Table as TableGenerator } from 'components';
import { useFetch } from "hooks";
import { Badge, Card } from 'reactstrap';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { format } from 'date-fns';
import { parseValue } from 'helper';

const getColorStatus = (status) => {
    switch (status) {
        case "completed":
            return <Badge color="success" className='text-10 p-1'>
                Selesai
            </Badge>
        case "canceled_by_admin":
            return <Badge color="danger" className='text-10 p-1'>
                Dibatalkan Admin
            </Badge>
        case "picking_up_process":
            return <Badge color="warning" className='text-10 p-1' style={{ color: "white" }}>
                Dalam Perjalanan
            </Badge>
        case "canceled":
            return <Badge color="danger" className='text-10 p-1' style={{ color: "white" }}>
                Dibatalkan
            </Badge>
        default:
            return <Badge color="info" className='text-10 p-1'>
                Menunggu Driver
            </Badge>
    };
};

const TableDetail = () => {
    const { id } = useParams();
    const { data, loading: isLoading, params, error } = useFetch(
        `/api/back-office/delivery-report/${id}/origins`,
        currentParams => ({
            size: 10,
            sort: '',
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
        }),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        { withQueryParams: false, showAlertOnError: false }
    );

    const TableConfiguration = {
        columns: [
            {
                key: 'date',
                title: 'Tanggal Jemput',
                render: (value) => <span>{format(new Date(value), 'dd/MM/yyyy')}</span>
            }, {
                key: 'originName',
                title: 'Titik Jemput',
                render: (value) => parseValue(value)
            }, {
                key: 'oilPickupDetails',
                title: 'Jeriken 18 L',
                render: (value) => <span>{value?.filter(item => item.volume === "18")?.find(item => item.type === "jeriken")?.qty ?? 0}</span>
            }, {
                key: 'oilPickupDetails',
                title: 'Jeriken 20 L',
                render: (value) => <span>{value?.filter(item => item.volume === "20")?.find(item => item.type === "jeriken")?.qty ?? 0}</span>
            }, {
                key: 'oilWeight',
                title: 'Jumlah Minyak (Kg)',
                render: (value) => parseValue(value)
            }, {
                key: 'status',
                title: 'Status',
                render: (value) => getColorStatus(value)
            }
        ]
    };

    return (
        <Card>
            <TableGenerator
                {...TableConfiguration}
                dataList={data}
                params={params}
                loading={isLoading}
                error={error}
                showNumber={false}
            />
        </Card>
    );
};

export default TableDetail;
