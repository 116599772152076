import React, { createContext, useCallback, useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, Row, Col, CardHeader, CardBody, FormGroup, Label, Button, Spinner } from 'reactstrap';
import { useFormContext } from 'react-hook-form';
import { components } from 'react-select';
import { useFetch, useAction } from 'hooks';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import Form from 'components-form/Form';
import Input from 'components-form/Input';
import Select from 'components-form/Select';
import Error404 from 'page/error/Error404';
import IconPinPoint from 'assets/svg/ic_pin-point.svg';
import { Switches, TextArea } from 'components-form';
import { capitalize } from 'helper';
import ModalPilihLocation from './components/ModalPilihLocation';
import axios from 'axios';

const latLongValidation = (value) => {
    const regex = /^-?(\d{1,3}\.?\d*)$/;
    const isValid = regex.test(value);

    return isValid ? null : 'Format tidak benar';
};

export const AddressContext = createContext();
const App = ({
    title: documentTitle,
    history
}) => {
    document.title = documentTitle;

    const params = useParams();
    const { hasFetch } = useAction();
    const id = params?.id;
    const isCreate = id ? false : true;
    const [dataAddress, setDataAddress] = useState(null);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [loadingLocation, setLoadingLocation] = useState(false);

    const dataBreadcrumb = isCreate ?
        [
            { to: '/warehouse', label: 'Master Data Warehouse' },
            { to: null, label: 'Tambah Warehouse' }
        ]
        : [
            { to: '/warehouse', label: 'Master Data Warehouse' },
            { to: `/warehouse/${params.id}`, label: 'Detail Warehouse' },
            { to: null, label: 'Edit Warehouse' }
        ]

    /* fecthing data for detail page */
    const { data, status, error } = useFetch(
        `/api/back-office/warehouse/${params?.id}`,
        {},
        useCallback(data => ({
            data: {
                ...data?.data
            }
        }), []),
        { onMount: !isCreate }
    );

    const onSubmit = async (input) => {
        setLoadingSubmit(true);

        const action = (requestBody) => isCreate
            ? Services().post('/api/back-office/warehouse', requestBody)
            : Services().put(`/api/back-office/warehouse`, { ...requestBody, id: parseInt(id) });

        try {
            const payload = {
                active: input.active,
                code: input.warehouseCode,
                name: input.warehouseName,
                latitude: input.latitude,
                longitude: input.longitude,
                address: input.address,
                location: input.location,
                province: input.province,
                city: input.city,
                branchIds: input.branch
            };

            await action(payload);

            setLoadingSubmit(false);

            hasFetch({
                type: 'ALERT_TOAST_SUCCESS',
                payload: { message: isCreate ? 'Data baru berhasil ditambah' : 'Data berhasil diperbarui' }
            });

            history.push(isCreate ? '/warehouse' : `/warehouse/${id}`);
        } catch (e) {
            setLoadingSubmit(false);
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Tidak berhasil ${id}` } });
        };
    };

    const defaultValues = {
        active: data?.active ?? true,
        warehouseCode: data?.code ?? '',
        warehouseName: data?.name ?? '',
        branch: data?.branchIds ?? [],
        province: data?.province ? capitalize(data?.province) : '',
        city: data?.city ? capitalize(data?.city) : '',
        address: data?.address ?? '',
        location: data?.location ?? '',
        latitude: data?.latitude ?? '',
        longitude: data?.longitude ?? '',
        driver: data?.driver ?? ''
    };

    if ((status === 'rejected' && error.code === 400) || (status === 'resolved' && data === null)) return <Error404 />;

    return (status === 'resolved' || isCreate) && (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>{isCreate ? "Tambah" : "Edit"} Warehouse</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <AddressContext.Provider
                value={{
                    setDataAddress,
                    dataAddress,
                    setLoadingSearch,
                    loadingSearch
                }}
            >
                <Form id="warehouseForm" onSubmit={onSubmit} autoComplete="off" defaultValues={defaultValues}>
                    <Card>
                        <CardHeader className="pb-0">
                            Informasi Warehouse
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <InformationFields isCreate={isCreate} />
                                    <LocationFields hasFetch={hasFetch} loadingLocation={loadingLocation} setLoadingLocation={setLoadingLocation} />
                                    {!isCreate && (
                                        <FormGroup row>
                                            <Label htmlFor="driver" sm={3}>Driver DC</Label>
                                            <Col sm={9}>
                                                <Input id="driver" name="driver" type="text" autoComplete="off" disabled />
                                            </Col>
                                        </FormGroup>
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Row>
                        <Col className="text-right">
                            <Link to={(isCreate) ? "/warehouse" : `/warehouse/${params.id}`}>
                                <Button type="button" color="secondary" className="m-1" onClick={() => window.history.back()}>
                                    Batal
                                </Button>
                            </Link>
                            <Button
                                type="submit"
                                color="primary"
                                className="m-1"
                                disabled={loadingSubmit || loadingLocation}
                            >
                                {(loadingSubmit) && (
                                    <span className="mr-2">
                                        <Spinner size="sm" />
                                    </span>
                                )}
                                {(isCreate) ? 'Buat' : 'Simpan'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </AddressContext.Provider>
        </>
    );
};

const InformationFields = ({ isCreate }) => {
    const { setValue } = useFormContext();

    // fetch branch list
    const { data: branchOption, loading: isLoadingBranchOption } = useFetch(
        `/api/back-office/branch/list`,
        {},
        useCallback(data => ({
            data: data?.data?.content?.map((branch) => ({
                label: branch.name,
                value: branch.id
            })),
        }), []),
        { withQueryParams: false }
    );

    return (
        <>
            <FormGroup row>
                <Label htmlFor="active" sm={3}>Status</Label>
                <Col sm={9}>
                    <Switches
                        className="custom-switch-success mt-1"
                        name="active"
                        label="Aktif"
                    />
                </Col>
            </FormGroup>
            {!isCreate && (
                <FormGroup row>
                    <Label htmlFor="warehouseCode" sm={3}>Kode Warehouse</Label>
                    <Col sm={9}>
                        <Input maxLength="150" id="warehouseCode" validation={['required']} disabled name="warehouseCode" type="text" autoComplete="off" />
                    </Col>
                </FormGroup>
            )}
            <FormGroup row>
                <Label htmlFor="warehouseName" sm={3}>Nama Warehouse</Label>
                <Col sm={9}>
                    <Input maxLength="150" id="warehouseName" validation={['required']} name="warehouseName" type="text" placeholder="DC" autoComplete="off" />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="branch" sm={3}>Branch</Label>
                <Col sm={9}>
                    <Select
                        name="branch"
                        id="branch"
                        placeholder="Pilih Branch"
                        options={branchOption ?? []}
                        validation={['required']}
                        isLoading={isLoadingBranchOption}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        isMulti
                        onChange={(e) => {
                            if (!e) {
                                setValue('branch', [])
                            }
                        }}
                        components={{
                            Option,
                            ValueContainer
                        }}
                    />
                </Col>
            </FormGroup>
        </>
    );
};

const CancelToken = axios.CancelToken;
const LocationFields = ({ hasFetch, loadingLocation, setLoadingLocation }) => {
    const { setDataAddress } = useContext(AddressContext);
    const [showModal, setShowModal] = useState(false);
    const [cancel, setCancelLocation] = useState(() => () => { });
    const { watch, setValue, trigger } = useFormContext();

    const province = watch('province');

    // fetch province list
    const { data: provinceOption, loading: isLoadingProvinceOption } = useFetch(
        `/api/back-office/coverage-area/province`,
        {},
        useCallback(data => ({
            data: data?.data?.content?.map((province) => ({
                label: province,
                value: province
            })),
        }), []),
        { withQueryParams: false }
    );

    // fetch city list
    const { data: cityOption, loading: isLoadingCityOption } = useFetch(
        `/api/back-office/coverage-area/city/${province}`,
        {},
        useCallback(data => ({
            data: data?.data?.content?.map((city) => ({
                label: city,
                value: city
            })),
        }), []),
        { withQueryParams: false, onMount: province }
    );

    const onLatLongChange = async (e) => {
        const { name, value } = e.target;

        // update and validate lat / long
        setValue(name, value);
        trigger(name);

        // lat long validation
        const latitude = watch('latitude');
        const longitude = watch('longitude');
        if (latLongValidation(latitude) !== null || latLongValidation(longitude) !== null) {
            setValue('location', '');
            return;
        };

        setLoadingLocation(true);

        // cancel previous fetch location
        cancel('canceled');

        try {
            const data = await Services().get('/api/open/map/location',
                {
                    latitude,
                    longitude
                },
                {
                    cancelToken: new CancelToken(function executor(c) {
                        setCancelLocation(() => c);
                    })
                });
            setValue('location', data?.data?.data?.content?.[0]?.address ?? '');
            setDataAddress({
                address: data?.data?.data?.content?.[0]?.address,
                lat: data?.data?.data?.content?.[0]?.lat,
                lon: data?.data?.data?.content?.[0]?.lon,
                name: data?.data?.data?.content?.[0]?.name
            });
            setLoadingLocation(false);
        } catch (e) {
            if (e?.message !== 'canceled') {
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? 'Lokasi tidak ditemukan' } });
                setValue('location', '')
                setLoadingLocation(false);
            };
        };
    };

    return (
        <>
            <FormGroup row>
                <Label htmlFor="province" sm={3}>Provinsi</Label>
                <Col sm={9}>
                    <Select
                        name="province"
                        id="province"
                        placeholder="Pilih Provinsi"
                        options={provinceOption ?? []}
                        isLoading={isLoadingProvinceOption}
                        isDisabled={isLoadingProvinceOption}
                        validation={['required']}
                        onChange={() => setValue('city', null)}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="city" sm={3}>Kota</Label>
                <Col sm={9}>
                    <Select
                        name="city"
                        id="city"
                        placeholder="Pilih Kota"
                        options={cityOption ?? []}
                        isLoading={isLoadingCityOption}
                        isDisabled={!province || isLoadingCityOption}
                        validation={['required']}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="address" sm={3}>Detail Alamat</Label>
                <Col sm={9}>
                    <TextArea
                        id="address"
                        rows="4"
                        name="address"
                        validation={['required']}
                        placeholder="Ketik Detail Alamat"
                        maxLength="250"
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="location" sm={3}>Lokasi Alamat (Opsional)</Label>
                <Col sm={9} className="d-flex">
                    <div className='mr-2'>
                        <Button type="button"
                            onClick={() => { setShowModal(!showModal) }}
                            color="primary" className='font-weight-medium d-inline-flex align-items-center py-2' style={{ minWidth: 132, height: 42 }}
                            disabled={loadingLocation}
                        >
                            <img src={IconPinPoint} alt="maps" className='mr-2' />
                            Pilih Lokasi
                        </Button>
                    </div>
                    <Input id="location" name="location" type="text" placeholder="Lokasi Alamat" autoComplete="off" disabled style={{ paddingRight: '32px' }} />
                    {loadingLocation && (
                        <span style={{
                            position: 'absolute',
                            right: '24px',
                            top: '50%',
                            transform: 'translateY(-50%)'
                        }}>
                            <Spinner size="sm" />
                        </span>
                    )}
                    <ModalPilihLocation
                        open={showModal}
                        setOpen={setShowModal}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="latitude" sm={3}>Latitude</Label>
                <Col sm={9}>
                    <Input
                        id="latitude"
                        name="latitude"
                        type="text"
                        placeholder="Latitude"
                        validation={['required', latLongValidation]}
                        autoComplete="off"
                        onChange={(e) => onLatLongChange(e)}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="longitude" sm={3}>Longitude</Label>
                <Col sm={9}>
                    <Input
                        id="longitude"
                        name="longitude"
                        type="text"
                        placeholder="Longitude"
                        validation={['required', latLongValidation]}
                        autoComplete="off"
                        onChange={(e) => onLatLongChange(e)}
                    />
                </Col>
            </FormGroup>
        </>
    );
};

const Option = ({
    children,
    innerProps,
    ...props
}) => {
    const optionId = innerProps.id.slice(-1);
    const isFirstOption = optionId === '0';

    // styles
    const style = {
        alignItems: "center",
        backgroundColor: 'transparent',
        color: "inherit",
        display: "flex ",
        padding: '0px 8px'
    };

    // prop assignment
    const customInnerProps = {
        ...innerProps,
        style
    };

    return (
        <components.Option
            {...props}
            innerProps={customInnerProps}
        >
            <div style={{
                backgroundColor: props.isFocused && '#eff3f6',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '4px',
                padding: '8px 12px',
                marginBottom: isFirstOption ? '4px' : '',
                marginTop: isFirstOption ? '8px' : ''
            }}>
                <input onChange={() => { }} type="checkbox" checked={props.isSelected} style={{ marginRight: '12px' }} />
                {children}
            </div>
        </components.Option>
    );
};

const ValueContainer = ({ children, ...props }) => {
    const values = props.getValue();
    const content = values.length > 0 ? values.map((value) => value.label).join(', ') : null;

    return (
        <components.ValueContainer {...props}>
            {content
                ?
                <>
                    <span
                        style={{
                            color: '#333333',
                            textWrap: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {content}
                    </span>
                    {children[1]}
                </>
                :
                [...children]
            }
        </components.ValueContainer>
    );
};

export default App;
