import React from 'react'
import { Select } from 'components-form';
import { components } from 'react-select';
import { useFormContext } from 'react-hook-form';
import { styles, theme } from 'components-form/Select';

const customStyles = {
    ...styles,
    input: (provided, state) => ({
        ...provided,
        position: !state.selectProps.menuIsOpen ? 'absolute' : ' relative',
        width: '100%'
    })
};

const MultipleSelect = ({ optionAll, name, placeholder, options, isLoading }) => {
    const { setValue } = useFormContext();

    return (
        <Select
            name={name}
            id={name}
            placeholder={placeholder}
            className="basic-single"
            theme={theme}
            styles={customStyles}
            isLoading={isLoading}
            options={[
                optionAll,
                ...options
            ]}
            isMulti
            isClearable={true}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{
                Option,
                ValueContainer
            }}
            onChange={(selectedOption, _, event) => {
                // console.log(`onChange ${name} : `, selectedOption);

                if (selectedOption?.length > 0) {
                    const selected = selectedOption.map((item) => item.value);

                    // check if 'Select All' is selected
                    if (selected[selected.length - 1] === '*') {
                        setValue(name, [
                            optionAll.value,
                            ...options.map((item) => item.value)
                        ]);
                    }

                    else if (selected.length === options.length) {
                        // remove 'Select All' on deselect option
                        if (selected.includes(optionAll.value)) {
                            const removedSelectAll = selected.filter((item) => item !== optionAll.value);
                            setValue(name, removedSelectAll.map((item) => item));
                        }
                        // add 'Select All' on selecting all option
                        else if (event.action === 'select-option') {
                            setValue(name, [
                                optionAll.value,
                                ...options.map((item) => item.value)
                            ]);
                        }
                        // deselect all
                        else {
                            setValue(name, null);
                        }
                    };
                };
            }}
        />
    )
}

MultipleSelect.defaultProps = {
    optionAll: {
        label: "Pilih Semua",
        value: "*"
    }
};

const Option = ({
    children,
    innerProps,
    ...props
}) => {
    const optionId = innerProps.id.slice(-1);
    const isFirstOption = optionId === '0';

    // styles
    const style = {
        alignItems: "center",
        backgroundColor: 'transparent',
        color: "inherit",
        display: "flex ",
        padding: '0px 8px'
    };

    // prop assignment
    const customInnerProps = {
        ...innerProps,
        style
    };

    return (
        <components.Option
            {...props}
            innerProps={customInnerProps}
        >
            <div style={{
                backgroundColor: props.isFocused && '#eff3f6',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                borderRadius: '4px',
                padding: '8px 12px',
                marginBottom: isFirstOption ? '4px' : '',
                marginTop: isFirstOption ? '8px' : ''
            }}>
                <input onChange={() => { }} type="checkbox" checked={props.isSelected} style={{ marginRight: '12px' }} />
                {children}
            </div>
        </components.Option>
    );
};


const ValueContainer = ({ children, ...props }) => {
    // remove label 'Select All' from showing in select
    const values = props.getValue().filter((value) => value !== props.options[0]);

    const content = values.length > 0 ? values.map((value) => value.label).join(', ') : null;

    return (
        <components.ValueContainer {...props}>
            {content
                ?
                <>
                    <span
                        style={{
                            color: '#333333',
                            textWrap: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {content}
                    </span>
                    {children[1]}
                </>
                :
                [...children]
            }
        </components.ValueContainer>
    );
};

export default MultipleSelect;
