import React, { useCallback } from 'react'
import {
    Card, CardBody, Row, Col,
    CardHeader,
    FormGroup,
    Label,
    Button,
} from 'reactstrap';
import {
    Link,
    useParams
} from 'react-router-dom';
import { Breadcrumb } from 'components';
import { useFetch } from 'hooks';
import Error404 from 'page/error/Error404';
import { format } from 'date-fns';
import localeId from 'date-fns/locale/id';

const formatDate = (date) => date ? format(new Date(date), 'EEEE, dd/MM/yyyy - HH:mm', { locale: localeId }) : '-';

const App = ({
    title: documentTitle,
    access: permissionAccess,
    history
}) => {
    document.title = documentTitle;
    const { id } = useParams();

    const dataBreadcrumb = [
        { to: '/warehouse', label: 'Master Data Warehouse' },
        { to: null, label: 'Detail Warehouse' }
    ];

    const { data, status, error } = useFetch(
        `/api/back-office/warehouse/${id}`,
        {},
        useCallback(data => ({ data: data?.data }), [])
    );

    const getColorStatus = (status) => {
        switch (status) {
            case true:
                return <span color="success" className='text-14 text-success'>
                    Aktif
                </span>
            case false:
                return <span color="danger" className='text-14 text-danger'>
                    Tidak  Aktif
                </span>
            default:
                return <span color="black" className='text-14'>
                    {status}
                </span>
        };
    };

    if ((status === 'rejected' && error.code === 400) ||
        (status === 'resolved' && data === null)) return <Error404 />

    return status === 'resolved' && (
        <>
            <Row className="row mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Detail Warehouse</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Card>
                <CardHeader className="pb-0">
                    Informasi Warehouse
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <DetailField
                                label='Status'
                                value={getColorStatus(data?.active)}
                            />
                            <DetailField
                                label='Kode Warehouse'
                                value={data?.code}
                            />
                            <DetailField
                                label='Nama Warehouse'
                                value={data?.name}
                            />
                            <DetailField
                                label='Branch'
                                value={data?.branch}
                            />
                            <DetailField
                                label='Provinsi'
                                value={data?.province}
                            />
                            <DetailField
                                label='Kota'
                                value={data?.city}
                            />
                            <DetailField
                                label='Detail Alamat'
                                value={data?.address}
                            />
                            <DetailField
                                label='Lokasi Alamat'
                                value={data?.location}
                            />
                            <DetailField
                                label='Latitude'
                                value={data?.latitude}
                            />
                            <DetailField
                                label='Longitude'
                                value={data?.longitude}
                            />
                            <DetailField
                                label='Driver DC'
                                value={data?.driver}
                            />
                            <DetailField
                                label='Dibuat Oleh'
                                value={`${data?.createdBy ?? '-'} | ${formatDate(data?.createdDate)}`}
                            />
                            <DetailField
                                label='Diperbarui Oleh'
                                value={`${data?.modifiedBy ?? '-'} | ${formatDate(data?.modifiedDate)}`}
                            />
                        </Col>
                    </Row>

                </CardBody>
            </Card>
            <div className='d-flex justify-content-end mt-2 mt-md-0'>
                {permissionAccess?.update && (
                    <Link to={`/warehouse/${id}/edit`}>
                        <Button type="button" color="primary">
                            <i className='fa fa-pencil mr-3'></i>
                            Ubah
                        </Button>
                    </Link>
                )}
            </div>
        </>
    );
};

const DetailField = ({ label, value }) => (
    <FormGroup row>
        <Label md={3}>{label}</Label>
        <Col md={9}>
            : {Boolean(value) ? value : "-"}
        </Col>
    </FormGroup>
);


export default App;