import React, { Fragment, useCallback, useState } from 'react'
import { Card, CardBody, Row, Col, FormGroup, Label, CardHeader, Button, Badge } from 'reactstrap'
import { Link, useParams } from 'react-router-dom';
import { Breadcrumb } from 'components';
import { useFetch, useAction } from 'hooks';
import { Services } from 'service';
import { hideConfirmationModal } from 'store/actions/confirmationModal';
import IcEdit from 'assets/svg/ic_edit.svg'
import ModalPage from 'components/Modal';
import DeleteIcon from '@elevenia/edts-icon/glyph/Delete';
import { actionOptions } from './constant';

const getColorStatus = (status) => {
    switch (status) {
        case true:
        case "active":
            return <span className='text-success'>Aktif</span>
        case false:
        case "inactive":
            return <span className='text-danger'>Tidak Aktif</span>
        default:
            return <span>{status}</span>
    }
}

const App = ({
    title: documentTitle,
    access: permissionAccess,
    history
}) => {
    document.title = documentTitle;
    const params = useParams();
    const { hasFetch } = useAction();
    const [open, setopen] = useState(false)

    const dataBreadcrumb = [
        { to: '/roles', label: 'Otorisasi' },
        { to: null, label: 'Detail Otorisasi' }
    ]

    /* fecthing data detail */
    const { data } = useFetch(
        `/api/back-office/roles/${params?.id}`,
        {},
        useCallback(data => ({
            data: {
                ...data?.data
            }
        }), []),
        { onMount: true }
    )
    /* handle delete role */
    const deleteAction = (data) => {
        let id = data?.id;
        Services().delete('/api/back-office/roles/' + id)
            // Promise.resolve(true)
            .then(() => {
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: `Otorisasi berhasil dihapus` }
                });
                hasFetch(hideConfirmationModal());
                history.push('/roles');
            })
            .catch(err => {
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: err?.message } });
                hasFetch(hideConfirmationModal());
            });
    }

    return (
        <>
            <Row className="row mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Detail Otorisasi</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>

            <Card>
                <CardHeader className="pb-0">
                    Informasi Otorisasi
                </CardHeader>
                <CardBody>
                    <FormGroup row>
                        <Label sm={3}>Nama Role</Label>
                        <Col sm={9}>
                            : {data?.name ?? '-'}
                        </Col>
                    </FormGroup>
                    <FormGroup row className="mb-0">
                        <Label sm={3}>Status</Label>
                        <Col sm={9}>
                            :  {data?.status ? getColorStatus(data?.status) : "-"}
                        </Col>
                    </FormGroup>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    {data?.menuGroups?.map((obj, key) => {
                        return (
                            <Fragment key={key}>
                                {key !== 0 && <hr className="mt-2 mb-4" />}
                                <Row>
                                    <Col>
                                        <h5 className="mb-4">{obj?.name}</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {obj?.menus?.map((objMenu, key) => {
                                            const actions = actionOptions.filter(({ value }) => objMenu?.accessRights?.[value] === true);
                                            
                                            return (
                                                <FormGroup row key={key}>
                                                    <Label sm={3}>{objMenu?.label}</Label>
                                                    <Col sm={9}>
                                                        {actions.length > 0
                                                            ? actions?.map(({ label }) => (
                                                                <Badge color="primary" pill style={{ marginRight: '12px' }}>
                                                                    {label}
                                                                </Badge>
                                                            ))
                                                            : "-"}
                                                    </Col>
                                                </FormGroup>
                                            )
                                        })}
                                    </Col>
                                </Row>
                            </Fragment>
                        );
                    })}
                    
                    {data?.actionGroups?.map((obj, key) => {
                        return (
                            <Fragment key={key}>
                                <hr className="mt-2 mb-4" />
                                <Row>
                                    <Col>
                                        <h5 className="mb-4">{obj?.name}</h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {obj?.actions?.map((objMenu, key) => {
                                            return (
                                                <FormGroup row key={key}>
                                                    <Label sm={3}>{objMenu.label}</Label>
                                                    <Col sm={9}>
                                                        : {getColorStatus(objMenu?.allowed)}
                                                    </Col>
                                                </FormGroup>
                                            )
                                        })}
                                    </Col>
                                </Row>
                            </Fragment>
                        );
                    })}
                </CardBody>
            </Card>
            <Row>
                <Col className="text-right">
                    <div className="d-inline-block float-right mt-2 mt-md-0">
                        {permissionAccess?.delete && (
                            <Button outline color="danger"
                                onClick={(e) => { setopen(!open); e.currentTarget.blur(); }}
                            >
                                <DeleteIcon color="#EE2B2E" size={17} />
                                <span className="ml-2">Hapus</span>
                            </Button>
                        )}

                        {permissionAccess?.update && (
                            <Link to={`/roles/${data?.id}/edit`} className="ml-1">
                                <Button color="primary">
                                    <img src={IcEdit} alt='edit' className='mr-2' height={19} />
                                    Ubah
                                </Button>
                            </Link>
                        )}
                    </div>
                </Col>
            </Row>
            <ModalPage
                isOpen={open}
                handleClose={() => { setopen(!open) }}
                component={<>
                    <div className='moda-password-title font-weight-bold'>Hapus Otorisasi?</div>
                    <p className='font-weight-normal'>
                        Otorisasi akan dihapus permanen dari daftar
                    </p>
                    <div className='text-right mt-4'>
                        <Button onClick={() => { deleteAction(data) }} size='sm' outline type="submit" color="primary" >
                            Hapus
                        </Button>
                        <Button className='font-weight-bold' onClick={() => setopen(!open)} size='sm' type="button" color="primary" >
                            Batal
                        </Button>
                    </div>
                </>}
            />
        </>
    )
}

export default App