import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormFeedback } from 'reactstrap'
import validationRule from 'helper/ValidationRule';
const get = (obj, path, def) => (() => typeof path === 'string' ? path.replace(/\[(\d+)]/g, '.$1') : path.join('.'))()
    .split('.')
    .filter(Boolean)
    .every(step => ((obj = obj[step]) !== undefined)) ? obj : def

const Input = ({
    validation = [],
    validationMessage = [],
    inputGroupAddon = null,
    name,
    label,
    containerClass = '',
    defaultValue = '',
    shouldUnregister = false,
    ...props
}) => {
    const { control, getValues } = useFormContext()
    return (
        <Controller
            shouldUnregister={shouldUnregister}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validationRule({ rule: validation, validationMessage }, getValues, name)}
            render={({ field: { ref, ...fieldInput }, formState: { errors } }) => {
                return (
                    <>
                        <RegularCheckbox
                            {...props}
                            {...fieldInput}
                            label={label}
                            id={props?.id}
                            checked={fieldInput.value}
                            containerClass={containerClass}
                        />
                        {get(errors, name) && <FormFeedback invalid="true" style={{ display: 'block' }}>{get(errors, name)?.message}</FormFeedback>}
                    </>
                )
            }}
        />
    )
}

export const RegularCheckbox = ({ label, containerClass, ...props }) => (
    <div className={`custom-checkbox mb-0 ${containerClass}`}>
        <input type="checkbox" {...props} />
        <label htmlFor={props?.id}>{label}</label>
    </div>
);

export default Input