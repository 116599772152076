import React from "react";
import { components, mergeStyles } from "react-select";
import { Button } from "reactstrap";
import CancelIcon from "@elevenia/edts-icon/glyph/Cancel";

import Select from 'components-form/Select';

const commonOptionStyle = {
    borderRadius: "4px",
    padding: "8px 12px",
};

const baseSelectStyle = {
    menuList: (base) => ({
        ...base,
        padding: "4px",
    }),
    option: (base) => ({
        ...base,
        ...commonOptionStyle,
    }),
    valueContainer: (base) => ({
        ...base,
        flexWrap: "nowrap",
    }),
};

const MultiSelectAlternative = (props) => (
    <Select
        isMulti
        closeMenuOnScroll={false}
        {...props}
        components={{
            MenuList,
            NoOptionsMessage,
            ValueContainer,
        }}
        styles={mergeStyles(
            props?.styles ?? {}, // allow customization via props,
            baseSelectStyle // but always prefer the defined base styles
        )}
    />
);

const MenuList = (props) => {
    const selectedOptions = props.getValue();

    const removeValue = (value) => {
        const filteredOptions = selectedOptions.filter((option) => option.value !== value);
        props.setValue(filteredOptions);
    };

    return (
        <components.MenuList {...props}>
            <div>
                {selectedOptions.map((option, i) => (
                    <div
                        key={`${i}-${option.value}`}
                        className="d-flex align-items-center justify-content-between"
                        style={{
                            ...commonOptionStyle,
                            backgroundColor: "#EFF3F6",
                            marginBottom: (props.selectProps.options.length - 1 > i) ? "4px" : undefined,
                        }}
                    >
                        <span>{option.label}</span>
                        <Button color="link" className="border-0 m-0 p-0" onClick={() => removeValue(option.value)}>
                            <CancelIcon color="#EE2B2E" />
                        </Button>
                    </div>
                ))}
            </div>
            {props.children}
        </components.MenuList>
    );
};

const NoOptionsMessage = (props) => {
    const selectedOptions = props.getValue();
    const typing = Boolean(props.selectProps.inputValue.length);

    return (selectedOptions.length === props.selectProps.options.length) && !typing
        ? null
        : <components.NoOptionsMessage {...props} />;
};

const ValueContainer = ({ children, ...props }) => {
    const labels = props.getValue().map(({ label }) => label);
    const title = labels.join(", ");
    const [placeholderOrSelected, input] = children;
    const typing = Boolean(props.selectProps.inputValue.length);

    return (
        <components.ValueContainer {...props}>
            {labels.length
                ? (
                    <>
                        <div
                            title={title}
                            className="text-truncate"
                            style={{
                                color: props.selectProps.isDisabled ? "#999999" : undefined,
                            }}
                        >
                            {title}
                        </div>
                        {typing && ', '}
                    </>
                )
                : placeholderOrSelected}

            {input}
        </components.ValueContainer>
    );
};

export default MultiSelectAlternative;
