import React, { useCallback } from 'react';
import { Link } from 'react-router-dom'
import {
    Card, Row, Col, Form, CardBody, Input, Button
} from 'reactstrap';
import { Pagination, Table as TableGenerator } from 'components';
import { useFetch } from "hooks";
import { Breadcrumb } from 'components';
import { debounce } from 'helper';

import IcPlus from 'assets/svg/ic_plus.svg'
import { useSelector } from 'react-redux';
import { addHours, format } from 'date-fns';

const App = ({
    title: documentTitle,
    access: permissionAccess
}) => {
    document.title = documentTitle;
    const { data, loading: isLoading, totalPage, params, setParams, error } = useFetch(
        `/api/back-office/stockpoint-price`,
        currentParams => ({
            size: 10,
            sort: 'name,asc',
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
        }),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        { withQueryParams: false, showAlertOnError: false }
    )

    const IdrFormat = (number) => new Intl.NumberFormat('id-ID', { style: "currency", currency: "IDR", minimumFractionDigits: 0 }).format(number)

    const TableConfiguration = {
        columns: [
            {
                key: 'stockpointName', title: 'Nama Stock Point',
                //  onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <Link to={`/stockpoint-price/${row.id}`}>{value ?? "-"}</Link>
                }
            },
            {
                key: 'price', title: 'Harga Standar Stock Point (Rp)',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value ? IdrFormat(value) : "-"}</span>
                }
            },
            {
                key: 'maximumPrice', title: 'Batas Maksimum (Rp)',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value ? IdrFormat(value) : "-"}</span>
                }
            },
            {
                key: 'minimumTopUp', title: 'Minimum Top Up (Rp)',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value ? IdrFormat(value) : "-"}</span>
                }
            },
            {
                key: 'maximumBalance', title: 'Maksimum Top Up (Rp)',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value ? IdrFormat(value) : "-"}</span>
                }
            },
            {
                key: 'topUpThreshold', title: 'Rekomendasi Top Up',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value ? value + ' %' : "-"}</span>
                }
            },
            {
                key: 'lastModifiedDate', title: 'Tanggal Dibuat',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{format(addHours(new Date(value), 7), "dd/MM/yyyy") ?? "-"}</span>
                }
            },
            {
                key: 'user', title: 'Dibuat Oleh',
                //  onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value ?? "-"}</span>
                }
            }
        ]
    }

    const dataBreadcrumb = [
        { to: '#', label: 'Harga Stock Point' }
    ]

    /* set params for searching */
    const setParamsSearch = useCallback(debounce((name, value) => setParams(prev => ({ ...prev, page: 1, [name]: typeof value === 'string' ? value?.trim() : value })), 600), [])

    /* handle searching for input type */
    const handleSearchChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setParamsSearch(name, value);
    }
    const { profile } = useSelector(state => state.homesidemenu);
    return (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Harga Stock Point</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                    {
                        profile?.stockpointName !== null || profile?.branchName !== null || profile?.warehouseName !== null ? permissionAccess?.create &&
                            <div className="d-inline-block float-right">
                                <Link to="/stockpoint-price/create">
                                    <Button color="primary">
                                        <img src={IcPlus} alt='create' className='mr-2' height={19} /> Tambah Harga Stock Point
                                    </Button>
                                </Link>
                            </div> : null
                    }
                </Col>
            </Row>
            <Card body>
                <Form>
                    <Row className='justify-content-end'>
                        <Col lg={4}>
                            <div className="input-search">
                                <i className="fa fa-search text-muted"></i>
                                <Input className="pl-5" type="text" name="search" id="name" placeholder="Cari Stock Point" onChange={handleSearchChange} />
                            </div >
                        </Col>
                    </Row>
                </Form >
            </Card >
            <Card>
                <CardBody className="p-0">
                    <TableGenerator
                        {...TableConfiguration}
                        dataList={data}
                        params={params}
                        loading={isLoading}
                        error={error} />
                </CardBody>
            </Card>
            <Row className="pagination mr-1">
                <Col>
                    <Pagination
                        showRowsPerPage={false}
                        onChangePage={({ page, size }) => setParams(prev => ({ ...prev, page, size }))}
                        totalPage={totalPage}
                        currentPage={params.page}
                        limitPage={params.size}
                    />
                </Col>
            </Row>
        </>
    )
}

export default App;
