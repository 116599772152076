import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import {
    Card, Row, Col, CardBody, Input, Badge
} from 'reactstrap';
import Form from 'components-form/Form';
import { Pagination, Table as TableGenerator } from 'components';
import Select from 'components-form/Select';
import { useFetch } from "hooks";
import { Breadcrumb } from 'components';
import { debounce } from 'helper';
import OrderPickup from './order-pickup';
import { addHours, format } from 'date-fns';
import DatePicker from "react-datepicker";
import ModalForms from './modal-forms';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
const FieldContext = createContext();
const rootStyle = (variable) => {
    return getComputedStyle(document.body).getPropertyValue(variable)
}

const time = {
    1: "09.00 - 12.00",
    2: "13.00 - 16.00"
}

const hari = {
    1: "Senin",
    2: "Selasa",
    3: "Rabu",
    4: "Kamis",
    5: "jum'at"
};
const daysFilter = Object.values(hari).map((item, index) => ({ value: index + 1, label: item }));
const theme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: rootStyle('--light'),
        primary: rootStyle('--primary'),
    }
})

const styles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#e4e7ea' : provided.backgroundColor,
        border: '1px solid #DBDCDD',
        boxShadow: 'none',
        '&:hover': {
            borderColor: state.selectProps.readonly ? '#DBDCDD' : rootStyle('--primary')
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0.475rem 0.625rem',
        height: 'calc(1.5em + 1.25rem + 5px)'
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
    })
}

const getColorStatus = (status) => {
    switch (status) {
        case "waiting_pick_up":
            return <Badge color="warning" className='text-10 text-white p-1'>
                Menunggu Jemput
            </Badge>
        default:
            return <Badge color="light" className='text-10 p-1'>
                {status}
            </Badge>
    }
}

const App = ({
    title: documentTitle,
    access: permissionAccess
}) => {
    document.title = documentTitle;
    const { stockpointId, branchId } = useSelector(state => state.homesidemenu?.profile);

    const { data, loading: isLoading, totalPage, params, setParams, error, fetchData } = useFetch(
        `/api/back-office/pickup-request`,
        currentParams => ({
            size: 10,
            sort: '',
            branch: branchId,
            stockpoint: stockpointId,
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
        }),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        { withQueryParams: false, showAlertOnError: false }
    )
    const [open, setopen] = useState(false)
    const [isCalculate, setCalculate] = useState([])
    const handleChanges = (e, row) => {
        const { checked } = e.target
        const dt = {
            ...row,
            checked
        }
        let arr = []
        if (checked) {
            arr.push(dt)
            setCalculate(prev => [...prev, ...arr])
        }
        else {
            arr = isCalculate.filter(item => item.id !== row.id);
            setCalculate([...arr])

        }
    }
    const formatNumber = (number) => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number)
    const TableConfiguration = {
        columns: [
            permissionAccess?.create && {
                key: 'userCode', title: null,
                render: (value, row, key) => {
                    return <span>
                        <input checked={!!isCalculate.filter(i => i.id === row.id)[0]?.checked} onChange={(e) => handleChanges(e, row)} name="row-name" type={'checkbox'} />
                    </span>
                }
            },
            {
                key: 'supplierName', title: 'Nama Supplier',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <Link to={`/pickup-request/${row.id}`}>{value}</Link>
                }
            },
            {
                key: 'outletName', title: 'Outlet',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value}</span>
                }
            }, {
                key: 'branchName', title: 'Branch',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value}</span>
                }
            }, {
                key: 'stockpointName', title: 'Stockpoint',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value}</span>
                }
            },
            {
                key: 'pickupAddress', title: 'Alamat Penjemputan',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value ?? "-"}</span>
                }
            },
            {
                key: 'pickupDate', title: 'Tanggal Permintaan Disetujui',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{format(new Date(value), "dd/MM/yyyy")}</span>
                }
            },
            {
                key: 'pickupDay', title: 'Hari Jemput',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{hari[value]}</span>
                }
            },
            {
                key: 'pickupTime', title: 'Jam Jemput',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{time[value]}</span>
                }
            },
            {
                key: 'oilContainers', title: 'Jeriken 18 L',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value?.[0]?.qty ?? "-"}</span>
                }
            },
            {
                key: 'oilContainers', title: 'Jeriken 20 L',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value?.[1]?.qty ?? "-"}</span>
                }
            },
            {
                key: 'oilWeight', title: 'Minyak(Kg)',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value + " Kg"}</span>
                }
            },
            {
                key: 'totalPrice', title: 'Penjualan (Rp)',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{formatNumber(value)}</span>
                }
            },
            {
                key: 'status', title: 'Status',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value) => getColorStatus(value)
            }
        ].filter(Boolean)
    }
    const [fillDate, setFillDate] = useState(null)
    const dataBreadcrumb = [
        { to: '#', label: 'Daftar Permintaan' }
    ]
    /* set params for searching */
    const setParamsSearch = useCallback(debounce((name, value) => setParams(prev => ({ ...prev, page: 1, [name]: typeof value === 'string' ? value?.trim() : value })), 600), [])

    /* handle searching for input type */
    const handleSearchChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setParamsSearch(name, value);
    }
    useEffect(() => {
        fillDate && setParamsSearch("date", format(addHours(fillDate, 7), "dd-MM-yyyy"));
    }, [setParamsSearch, fillDate])
    const onSubmit = (input) => {

    }
    const [triggers, setTriggers] = useState(null);
    const reuseTriggers = (value) => {
        setTriggers(value);
    }
    const defaultValue = {
        branchfield: "",
        stockpointfield: ""
    }
    return (
        <FieldContext.Provider
            value={{
                triggers,
                reuseTriggers,
                fetchReq: setParams
            }}
        >

            <Row className="mb-4">
                <Col>
                    <div style={{ gap: "2rem" }} className='d-flex flex-wrap justify-content-between'>
                        <div className="d-inline-block">
                            <h2>Daftar Permintaan</h2>
                            <Breadcrumb data={dataBreadcrumb} />
                        </div>
                        <div className='d-inline-flex'>
                            {(permissionAccess?.create && isCalculate.length) ? <OrderPickup raw={isCalculate} setopen={() => setopen(!open)} /> : null}
                        </div>
                    </div>
                </Col>
            </Row>
            <Card body>
                <Form onSubmit={onSubmit} defaultValues={defaultValue}>
                    <Row>
                        <Col lg={2}>
                            <DatePicker
                                placeholderText="Pilih Tanggal"
                                selected={fillDate}
                                isClearable
                                onChangeRaw={(e) => { e.preventDefault(); }}
                                onChange={(value) => {
                                    setFillDate(value)
                                    !value && setParams(params => ({
                                        ...params,
                                        date: null
                                    }))
                                }}
                                value={fillDate}
                                selectsStart
                                // startDate={new Date()}
                                className="form-control"
                                maxDate={new Date()}
                                dateFormat={"dd/MM/yyyy"}
                            />
                        </Col>
                        <Col lg={2}>
                            <Select
                                name="status"
                                id="roleId"
                                placeholder="Pilih Hari Jemput"
                                className="basic-single"
                                // defaultValue={params?.roleId}
                                options={daysFilter}
                                isClearable={true}
                                theme={theme}
                                styles={styles}
                                onChange={(e) => setParams(params => ({
                                    ...params,
                                    page: 1,
                                    day: e?.value
                                }))}
                            />
                        </Col>
                        <Col lg={2}>
                            <BranchField name="branchfield" />
                        </Col>
                        <Col lg={2}>
                            <StockPoint name="stockpointfield" />
                        </Col>
                        <Col lg={4}>
                            <div className="input-search">
                                <i className="fa fa-search text-muted"></i>
                                <Input className="pl-5" type="text" name="search" id="name" placeholder="Cari Nama Supplier / Outlet" onChange={handleSearchChange} defaultValue={params?.name} />
                            </div >
                        </Col>
                    </Row >
                </Form >
            </Card >
            <Card>
                <CardBody className="p-0">
                    <TableGenerator
                        {...TableConfiguration}
                        dataList={data}
                        showNumber={false}
                        params={params}
                        loading={isLoading}
                        error={error} />
                </CardBody>
            </Card>
            <Row className="pagination mr-1">
                <Col>
                    <Pagination
                        showRowsPerPage={false}
                        onChangePage={({ page, size }) => setParams(prev => ({ ...prev, page, size }))}
                        totalPage={totalPage}
                        currentPage={params.page}
                        limitPage={params.size}
                    />
                </Col>
            </Row>
            <ModalForms open={open} refetch={() => fetchData()} setCalculate={() => setCalculate([])} setopen={() => setopen(!open)} raw={isCalculate} />
        </FieldContext.Provider>
    )
}

const BranchField = ({ name }) => {
    const { branchCode } = useSelector(state => state.homesidemenu?.profile);
    const { fetchReq } = useContext(FieldContext)
    const { setValue, trigger } = useFormContext();
    const { data, loading } = useFetch(
        `/api/back-office/branch`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );
    useEffect(() => {
        setValue(name, branchCode);
    }, [branchCode, name, setValue])
    return (
        <Select
            id={name}
            name={name}
            className="basic-single"
            classNamePrefix="select"
            options={data?.map(obj => ({ label: `${obj?.name}`, value: obj?.code, id: obj?.id })).sort((a, b) => a.label.localeCompare(b.label))}
            placeholder={`Pilih Branch`}
            isLoading={loading}
            isClearable={true}
            onChange={(ev) => {
                if (ev) {
                    setValue('branchfield', ev.value)
                    trigger('branchfield')
                    fetchReq(prev => ({
                        ...prev,
                        branch: ev?.id
                    }))
                }
                else {
                    setValue('branchfield', null)
                    setValue('stockpointfield', null)
                    fetchReq(prev => ({
                        ...prev,
                        branch: 0
                    }))
                }
            }}
        />
    )
}

const StockPoint = ({ name }) => {
    const { stockpointId } = useSelector(state => state.homesidemenu?.profile);
    const { fetchReq } = useContext(FieldContext)
    const { watch, setValue } = useFormContext();
    const branchValue = watch('branchfield');
    const { data: stockPointList, loading: isLoadingStockPoint, setParams } = useFetch(
        `/api/back-office/stockpoint`,
        currentParams => ({
            unpaged: true,
        }),
        useCallback(data => ({
            data: data?.data?.content,
        }), []),
        { onMount: true, withQueryParams: false, showAlertOnError: false }
    )
    useEffect(() => {
        setParams(prev => ({
            ...prev,
            branch: branchValue
        }))
    }, [branchValue, setParams])
    useEffect(() => {
        setValue(name, stockpointId)
    }, [stockpointId, name, setValue])
    return (
        <>
            <Select
                id={name}
                name={name}
                className="basic-single"
                classNamePrefix="select"
                options={stockPointList?.map(obj => ({ label: `${obj?.name}`, value: obj?.id }))}
                placeholder={`Pilih Stockpoint`}
                isLoading={isLoadingStockPoint}
                isDisabled={!branchValue}
                isClearable={true}
                onChange={(ev) => {
                    if (ev) {
                        fetchReq(prev => ({
                            ...prev,
                            stockpoint: ev?.value
                        }))
                    }
                    else {
                        fetchReq(prev => ({
                            ...prev,
                            stockpoint: 0
                        }))
                    }
                }}
            />
        </>
    )
}

export default App;
