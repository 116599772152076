import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";

import SmallButton from "components/SmallButton";
import { IdrFormat, parseValue } from "helper";
import { Services } from "service";
import { format } from "date-fns";

const apiClient = Services();

export const useConfirmEdit = () => {
    const config = useState(null);
    const [, setState] = config;

    const confirmEdit = (mode, payload) => setState({ mode, payload });

    return {
        config,
        confirmEdit
    };
};

const ModalConfirmEdit = ({ config }) => {
    const [state, setState] = config;
    const [submitting, setSubmitting] = useState(false);

    const history = useHistory();
    const dispatch = useDispatch();

    const confirming = state?.mode === "confirm";
    const insufficient = state?.mode === "insufficient";

    const close = () => setState(null);

    const submitEdit = async () => {
        if (submitting) return;

        try {
            setSubmitting(true);

            await apiClient.post("/api/back-office/direct-purchase/request-edit", state?.payload);

            close();
            dispatch({ type: "ALERT_TOAST_SUCCESS", payload: { message: "Penjualan langsung berhasil diperbarui" } });
            history.push("/direct-purchase");
        } catch (e) {
            /**
             * Expected known error "insufficient petty cash" will follow
             * `insufficient petty cash:::{currentBalance}` message format.
             * `insufficient modal balance:::{currentBalance}` message format.
             */
            let toastMessage;
            const [errorCode, currentBalance] = parseValue(
                e?.message,
                (message) => message.split(":::"),
                []
            );

            switch (errorCode) {
                case "insufficient petty cash":
                    setState({
                        mode: "insufficient",
                        payload: {
                            title: 'Dana Kas Tidak Mencukupi',
                            messages: ['Silahkan lakukan pencairan kas terlebih dahulu', 'Kas Saat Ini : '],
                            currentBalance,
                        }
                    });
                    toastMessage = 'Dana Kas Tidak Mencukupi';
                    break;
                case "insufficient modal balance":
                    const purchaseDate = parseValue(state?.payload?.purchaseDate, (val) => format(new Date(val), 'dd/MM/yyyy - hh:mm'), '');
                    setState({
                        mode: "insufficient",
                        payload: {
                            title: 'Modal Tidak Mencukupi',
                            messages: ['Perubahan gagal karena modal anda tidak cukup.', `Modal pada saat transaksi ${purchaseDate}`],
                            currentBalance,
                        }
                    });
                    toastMessage = 'Modal Tidak Mencukupi';
                    break;
                default:
                    break;
            };

            dispatch({
                type: "ALERT_TOAST_ERROR",
                payload: {
                    message: toastMessage ?? e?.message
                }
            });
        } finally {
            setSubmitting(false);
        };
    };

    return (
        <Modal
            centered
            size="sm"
            backdrop="static"
            keyboard={!submitting}
            returnFocusAfterClose={false}
            isOpen={Boolean(state?.mode)}
            toggle={close}
        >
            <ModalBody className="pb-3">
                <h5 className="b1 mb-4" style={{ fontWeight: 600 }}>
                    {confirming
                        ? "Koreksi Pembelian Langsung?"
                        : insufficient
                            ? state?.payload?.title
                            : null}
                </h5>
                <div>
                    {confirming
                        ? "Data Pembelian Langsung akan diubah"
                        : insufficient
                            ? (
                                <>
                                    <p className="mb-2">{state?.payload?.messages?.[0]}</p>
                                    <p className="mb-0">
                                        {state?.payload?.messages?.[1]} <span className="text-primary">{parseValue(state?.payload?.currentBalance, IdrFormat)}</span>
                                    </p>
                                </>
                            )
                            : null}
                </div>
            </ModalBody>
            <ModalFooter className="pt-0 pb-3">
                {confirming
                    ? (
                        <>
                            <SmallButton disabled={submitting} outline={!submitting} color={submitting ? "secondary" : "primary"} onClick={close}>
                                Kembali
                            </SmallButton>
                            <SmallButton disabled={submitting} color="primary" className={submitting ? "position-relative" : ""} onClick={submitEdit}>
                                {submitting && <Spinner size="sm" className="position-absolute" style={{ top: "25%", left: "42%" }} />}
                                <span className={submitting ? "invisible" : ""}>Konfirmasi</span>
                            </SmallButton>
                        </>
                    )
                    : insufficient
                        ? (
                            <SmallButton color="primary" onClick={close}>
                                Mengerti
                            </SmallButton>
                        )
                        : null}
            </ModalFooter>
        </Modal>
    );
};

export default ModalConfirmEdit;
