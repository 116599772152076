import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, Row, Col, CardBody, FormGroup, Label, Button, Spinner } from 'reactstrap';
import { useFetch, useAction } from 'hooks';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import Error404 from 'page/error/Error404';
import Form from 'components-form/Form';
import InputDecimal from './components/InputDecimal';
import IcInfo from 'assets/svg/ic_information.svg';
import { useFormContext } from 'react-hook-form';

const App = ({
    title: documentTitle,
    history
}) => {
    document.title = documentTitle;

    const params = useParams();
    const { hasFetch } = useAction();
    const isCreate = params?.id ? false : true;
    const [visible, setvisible] = useState(false);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const dataBreadcrumb = isCreate ?
        [
            { to: '/default-price', label: 'Harga Standar' },
            { to: null, label: 'Tambah Harga Standar' }
        ]
        : [
            { to: '/default-price', label: 'Harga Standar' },
            { to: null, label: 'Edit Harga Standar' }
        ]

    /* fetching default price */
    const { data: dataPrice, status, error } = useFetch(
        `/api/back-office/default-price/latest`,
        { unpaged: false },
        useCallback(data => ({
            data: data?.data
        }), []),
        { onMount: true }
    );

    const { data: defaultBranch } = useFetch(
        `/api/back-office/branch-price/template`,
        {},
        useCallback(data => ({
            data: {
                ...data?.data
            }
        }), []),
        { onMount: isCreate }
    )

    const onSubmit = (input) => {
        setIsLoadingSubmit(true);
        const id = params?.id;
        const removeDeciFormat = (number) => parseFloat(number.replace(/[.,\s]/g, ''))
        const requestBody = {
            price: input.price !== null ? removeDeciFormat(input.price) : [],
            maximumPrice: input.maxPrice !== null ? removeDeciFormat(input.maxPrice) : [],
            topUpThreshold: removeDeciFormat(input?.recomendedtopup),
            minimumTopUp: removeDeciFormat(input?.minPrice),
            maximumBalance: removeDeciFormat(input?.maxModal)
        };

        const action = ({ requestBody, id = null }) => isCreate
            ? Services().post('/api/back-office/default-price/add', requestBody)
            : Services().post(`/api/back-office/default-price/edit`, { ...requestBody, id: parseInt(id) })

        action({ requestBody, id: params?.id })
            .then(() => {
                setIsLoadingSubmit(false);
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: isCreate ? `Harga baru berhasil dibuat` : `Harga baru berhasil diperbarui` }
                })
                history.push("/default-price");
            })
            .catch(e => {
                setIsLoadingSubmit(false);
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Cannot update ${id}` } })
            })
    }

    const defaultValues = {
        price: dataPrice?.price ? Intl.NumberFormat('id-ID').format(dataPrice?.price) : '',
        maxPrice: dataPrice?.maximumPrice ? Intl.NumberFormat('id-ID').format(dataPrice?.maximumPrice) : '',
        recomendedtopup: Intl.NumberFormat('id-ID').format(dataPrice?.topUpThreshold) ?? "",
        minPrice: Intl.NumberFormat('id-ID').format(dataPrice?.minimumTopUp) ?? "",
        maxModal: Intl.NumberFormat('id-ID').format(dataPrice?.maximumBalance) ?? ""
    }

    if ((status === 'rejected' && error.code === 400) || (status === 'resolved' && dataPrice === null)) return <Error404 />
    return (status === 'resolved' || isCreate) && (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>{isCreate ? "Tambah" : "Edit"} Harga Standar</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Form id="form-default-price" onSubmit={onSubmit} autoComplete="off" defaultValues={defaultValues} >
                <Card>
                    <div className="pt-4 px-4 text-16 font-weight-medium">
                        Informasi Harga Standar
                    </div>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label htmlFor="harga-standar" sm={3}>Harga Standar / Kg</Label>
                                    <Col sm={9}>
                                        <InputDecimal maxLength={6} max={999999999} maxed={5} id="harga-standar" validation={['required , `minLength[4]`']} name="price" type="text" placeholder="Harga Standar" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label htmlFor="maxPrice" sm={3}>Batas Maksimum</Label>
                                    <Col sm={9}>
                                        <InputMax defaultBranch={defaultBranch} />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label htmlFor="maxModal" sm={3}>Maksimum Modal</Label>
                                    <Col sm={9}>
                                        <InputDecimal maxLength={10} max={999999999} maxed={8} id="maxModal" validation={['required']} name="maxModal" type="text" placeholder="Maksimum Modal" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label className='relative d-flex' style={{ gap: 2 }} sm={3}>
                                        <span className='pr-1'>Rekomendasi Top Up</span>
                                        <div
                                            style={{ cursor: "pointer", position: "relative" }}
                                        >
                                            <img
                                                onMouseOver={() => setvisible(true)}
                                                onMouseLeave={() => setvisible(false)}
                                                style={{ cursor: "pointer" }}
                                                src={IcInfo} alt='create' className='mr-2' height={14}
                                            />
                                            {visible && <div className='text-12 mt-2 tooltip-text' style={{
                                                position: 'absolute',
                                                color: "#151823",
                                                zIndex: 50,
                                                right: 0,
                                                borderRadius: "4px",
                                                padding: "8px",
                                                width: "155px",
                                                background: "#ffff",
                                                boxShadow: "0px 1px 4px 0px rgba(112, 114, 125, 0.40)"
                                            }}>
                                                <span>Pengingat rekomendasi top up akan aktif sesuai dengan angka % yang diinput</span>
                                            </div>}
                                        </div>
                                    </Label>
                                    <Col sm={9}>
                                        <InputTreshold defaultBranch={defaultBranch} />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label htmlFor="minPrice" sm={3}>Minimum Top Up</Label>
                                    <Col sm={9}>
                                        <InputDecimal maxLength={10} max={999999999} id="minPrice" maxed={8} validation={['required']} name="minPrice" type="text" placeholder="Minimum Top Up" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Row>
                    <Col className="text-right">
                        <Link to={"/default-price"}>
                            <Button type="button" color="secondary" className="m-1" >
                                Batal
                            </Button>
                        </Link>
                        <Button type="submit" color="primary" className="m-1" disabled={isLoadingSubmit}>
                            {isLoadingSubmit &&
                                <span className='mr-2'>
                                    <Spinner size="sm" />
                                </span>}
                            {(isCreate) ? 'Buat' : 'Simpan'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export const InputMax = ({ defaultBranch }) => {
    const { id } = useParams()
    const { setValue } = useFormContext();
    useEffect(() => {
        !id && setValue('maxPrice', Intl.NumberFormat('id-ID').format(defaultBranch?.maximumPrice))
    }, [id, setValue, defaultBranch])
    return (
        <InputDecimal id="maxPrice" maxLength={6} max={999999} maxed={999999} validation={['required', `minLength[4]`, `moreThanStandar[${defaultBranch?.maximumPrice}]`, 'valueOfnull']} name="maxPrice" type="text" placeholder="Batas Maksimum" autoComplete="off" />
    )
}

export const InputTreshold = ({ defaultBranch }) => {
    const { id } = useParams()
    const { setValue } = useFormContext();
    useEffect(() => {
        !id && setValue('recomendedtopup', defaultBranch?.topUpThreshold.toString())
    }, [id, setValue, defaultBranch])
    return (
        <InputDecimal id="recomendedtopup" maxLength={3} max={100} maxed={100} validation={['required', 'valueOfnull']} name="recomendedtopup" type="text" placeholder="0%" autoComplete="off" />

    )
}

export default App