import { useAction } from 'hooks';
import { useState } from 'react'
import { Services } from 'service';

const useDownloadTemplate = ({ url, filename }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { hasFetch } = useAction();

    const download = async () => {
        setIsLoading(true);
        try {
            const { data } = await Services().get(url, null, { responseType: 'blob' });
            const blobUrl = URL.createObjectURL(data);

            const link = document.createElement('a');
            link.download = filename;
            link.href = blobUrl;
            link.click();
            setIsLoading(false);
        } catch (e) {
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? 'Gagal download template' } })
            setIsLoading(false);
        };
    };

    return {
        download,
        isLoading
    };
};

export default useDownloadTemplate;
