import React, { useCallback, useEffect, useState } from 'react'
import {
    Card, CardBody, Row, Col,
    CardHeader,
    FormGroup,
    Label,
    Button,
} from 'reactstrap';
import {
    Link,
    useParams
} from 'react-router-dom';
import { Breadcrumb } from 'components';
import { useAction, useFetch } from 'hooks';
import Error404 from 'page/error/Error404';
import IconDelivery from 'assets/svg/ic_delivery.svg';
import KtpImgDefault from 'assets/svg/ic_ktp_default.svg';
import KtpSelfieDefault from 'assets/image/default-image.png';
import cancel from 'assets/svg/ic_cancel.svg';
import IcDownload from '../../assets/svg/ic_download.svg';
import IcOrder from '../../assets/svg/ic_order.svg';
import Modal from 'components/Modal';
import { DatePicker, Form, TextArea } from 'components-form';
import { Services } from 'service';
import { useFormContext } from 'react-hook-form';
import { format } from 'date-fns';

const App = ({
    title: documentTitle,
    access: permissionAccess,
    actionGroup,
    history
}) => {
    document.title = documentTitle;
    const { id } = useParams();

    const [detailImage, setDetailImage] = useState(false);
    const [rejectedModal, setRejectedModal] = useState(false);
    const [approveModal, setApproveModal] = useState(false);

    const dataBreadcrumb = [
        { to: '/supplier', label: 'Master Data Supplier' },
        { to: null, label: 'Detail Supplier' }
    ]

    const { data, status, error, fetchData } = useFetch(
        `/api/back-office/supplier/${id}`,
        {},
        useCallback(data => ({ data: data?.data }), [])
    )

    const time = {
        1: "09.00 - 12.00",
        2: "13.00 - 16.00"
    }
    const hari = {
        1: "Senin",
        2: "Selasa",
        3: "Rabu",
        4: "Kamis",
        5: "jum'at"
    };

    const imgContainerStyle = {
        alignItems: "center",
        width: "160px",
        height: "100px",
        overflow: "hidden"
    }

    const getColorVerifiedSD = (value) => {
        switch (value) {
            case 'verified':
                return <div className='d-inline-block py-1' style={{ color: "#6FAA1E", whiteSpace: "nowrap" }}>
                    Terverifikasi
                </div>
            case 'unverified':
                return <div className='d-inline-block py-1' style={{ color: "#FF7D1D", whiteSpace: "nowrap" }}>
                    Belum Terverfikasi
                </div>
            case 'rejected':
                return <div className='d-inline-block py-1' style={{ color: "#EE2B2E", whiteSpace: "nowrap" }}>
                    Ditolak
                </div>
            case 'expired':
                return <div className='d-inline-block py-1' style={{ color: "#7B61FF", whiteSpace: "nowrap" }}>
                    Kedaluwarsa
                </div>

            default:
                return value
        }
    }

    const gmapsLink = (lat, lng) => {
        return `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`
    }

    // const money = ({ uang }) => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(uang);
    const numeric = ({ number }) => new Intl.NumberFormat('id-ID').format(number);

    const hasSelfDeclaration = data?.supplyCommitment?.selfDeclaration;
    const getStatusSD = data?.supplierInformation?.statusSD;
    const isSupplierVerified = data?.supplierInformation?.statusSD !== "verified";

    const allowReviewSD = actionGroup?.verification?.["sd-supplier"];
    const shouldShowContent = allowReviewSD && hasSelfDeclaration && isSupplierVerified;
    const isStatusValid = getStatusSD !== "rejected" && getStatusSD !== "expired";

    if ((status === 'rejected' && error.code === 400) ||
        (status === 'resolved' && data === null)) return <Error404 />
    return status === 'resolved' && (
        <>
            <Row className="row mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Detail Supplier</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                    {shouldShowContent && isStatusValid && (
                        <div className="d-inline-block float-right">
                            <Button color="danger" outline onClick={() => setRejectedModal(!rejectedModal)}>
                                Tolak Verifikasi
                            </Button>
                            <Button color="success" onClick={() => setApproveModal(!approveModal)}>
                                Verifikasi Self Declaration
                            </Button>
                        </div>

                    )}
                </Col>
            </Row>
            <Card>
                <CardHeader className="pb-0">
                    Informasi Supplier
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <FormGroup row>
                                <Label md={3}>Verifikasi Self Declaration</Label>
                                <Col md={9}>
                                    : {getColorVerifiedSD(data?.supplierInformation?.statusSD) ?? "-"}
                                </Col>
                            </FormGroup>
                            {data?.supplierInformation?.sdRejectReason &&
                                <FormGroup row>
                                    <Label md={3}>Alasan Penolakan</Label>
                                    <Col md={9}>
                                        : {data?.supplierInformation?.sdRejectReason ?? "-"}
                                    </Col>
                                </FormGroup>
                            }
                            <FormGroup row>
                                <Label md={3}>Nama Lengkap</Label>
                                <Col md={9}>
                                    : {data?.supplierInformation?.name ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Nama Bank</Label>
                                <Col md={9}>
                                    : {data?.supplierInformation?.bankName ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Nomor Rekening</Label>
                                <Col md={9}>
                                    : {data?.supplierInformation?.bankAccount ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Nomor Handphone</Label>
                                <Col md={9}>
                                    : {data?.supplierInformation?.phone ?? "-"}
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col lg={6} className='d-none'>
                            <div className='d-flex flex-wrap justify-content-start' style={{ gap: "2rem" }}>
                                <div>
                                    <div className='text-14 text-color-gray mb-2'>Foto KTP</div>
                                    <div className='d-flex justify-content-center' style={imgContainerStyle}>
                                        <img
                                            src={data?.supplierInformation?.ktp?.ktpPict || KtpImgDefault}
                                            className="img-fluid"
                                            alt="ktp"
                                            style={{ objectFit: "cover" }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <div className='text-14 text-color-gray mb-2'>Foto KTP Selfie</div>
                                    <div className='d-flex justify-content-center' style={imgContainerStyle}>
                                        <img
                                            src={data?.supplierInformation?.ktp?.selfieKtpPict || KtpSelfieDefault}
                                            className="img-fluid"
                                            alt="ktp selfie"
                                            style={{ objectFit: "cover" }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </CardBody>
            </Card>
            <Card>
                <CardHeader className="pb-0">
                    Informasi Usaha
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <FormGroup row>
                                <Label md={3}>Nama Outlet</Label>
                                <Col md={9}>
                                    : {data?.outletInformation?.outletName ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Id Outlet</Label>
                                <Col md={9}>
                                    : {data?.supplierInformation?.id ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Kategori</Label>
                                <Col md={9}>
                                    : {data?.outletInformation?.supplierCategoryName ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Alamat</Label>
                                <Col md={9}>
                                    : {data?.outletInformation?.address ?? "-"}

                                    <br />
                                    <Button
                                        color="primary"
                                        href={gmapsLink(data?.outletInformation?.latitude, data?.outletInformation?.longitude)}
                                        tag="a"
                                        target="_blank"
                                        outline
                                        className='font-weight-medium d-inline-flex align-items-center py-2 mt-4'
                                    >
                                        <img src={IconDelivery} alt="maps" className='mr-2' />
                                        Lihat Peta
                                    </Button>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Latitude</Label>
                                <Col md={9}>
                                    : {data?.outletInformation?.latitude ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Longitude</Label>
                                <Col md={9}>
                                    : {data?.outletInformation?.longitude ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Detail Alamat</Label>
                                <Col md={9}>
                                    : {data?.outletInformation?.detailAddress ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Alamat Penjemputan</Label>
                                <Col md={9}>
                                    : {data?.outletInformation?.pickupAddress ?? "-"}

                                    <br />
                                    <Button
                                        color="primary"
                                        href={gmapsLink(data?.outletInformation?.pickupLatitude, data?.outletInformation?.pickupLongitude)}
                                        tag="a"
                                        target="_blank"
                                        outline
                                        className='font-weight-medium d-inline-flex align-items-center py-2 mt-4'
                                    >
                                        <img src={IconDelivery} alt="maps" className='mr-2' />
                                        Lihat Peta
                                    </Button>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Latitude</Label>
                                <Col md={9}>
                                    : {data?.outletInformation?.pickupLatitude ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Longitude</Label>
                                <Col md={9}>
                                    : {data?.outletInformation?.pickupLongitude ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Detail Alamat Penjemputan</Label>
                                <Col md={9}>
                                    : {data?.outletInformation?.detailPickupAddress ?? "-"}
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>

                </CardBody>
            </Card>
            <Card>
                <CardHeader className="pb-0">
                    Komitment Supply
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <FormGroup row>
                                <Label md={3}>Jumlah Minyak / Minggu (Kg)</Label>
                                <Col md={9}>
                                    : {numeric({ number: data?.supplyCommitment?.oilAmountCommitment }) ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Harga Minyak / (Kg)</Label>
                                <Col md={9}>
                                    : {numeric({ number: data?.supplyCommitment?.priceCommitment }) ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Hari Penjemputan</Label>
                                <Col md={9}>
                                    : {hari[data?.supplyCommitment?.pickupDay] ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Jam Penjemputan</Label>
                                <Col md={9}>
                                    : {time[data?.supplyCommitment?.pickupTime] ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Self Declaration</Label>
                                <Col md={9}>
                                    : {
                                        data?.supplyCommitment?.selfDeclaration
                                            ?
                                            <Button
                                                size='sm'
                                                type="button"
                                                color="primary"
                                                className='d-inline-flex align-items-center'
                                                onClick={() => setDetailImage(!detailImage)}
                                            >
                                                <img src={IcOrder} alt="Self Declaration" className='mr-2' />
                                                Lihat File
                                            </Button>
                                            :
                                            <span style={{ color: '#EE2B2E' }}>Tidak Ada</span>
                                    }
                                </Col>
                            </FormGroup>
                            {
                                data?.supplyCommitment?.signedDateSD && <FormGroup row>
                                    <Label md={3}>Tanggal Tanda Tangan</Label>
                                    <Col md={9}>
                                        : {format(new Date(data?.supplyCommitment?.signedDateSD), 'dd/MM/yyyy')}
                                    </Col>
                                </FormGroup>
                            }
                            {
                                data?.supplyCommitment?.expiredDateSD &&
                                <FormGroup row>
                                    <Label md={3}>Tanggal Kedaluwarsa</Label>
                                    <Col md={9}>
                                        : {format(new Date(data?.supplyCommitment?.expiredDateSD), 'dd/MM/yyyy')}
                                    </Col>
                                </FormGroup>
                            }

                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardHeader className="pb-0">
                    Supervisor
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <FormGroup row>
                                <Label md={3}>Branch</Label>
                                <Col md={9}>
                                    : {data?.supervisorInformation?.branchName ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Stockpoint</Label>
                                <Col md={9}>
                                    : {data?.supervisorInformation?.stockpointName ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Employee ID</Label>
                                <Col md={9}>
                                    : {data?.supervisorInformation?.userCode ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Nama Employee</Label>
                                <Col md={9}>
                                    : {data?.supervisorInformation?.name ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label md={3}>Dibuat Oleh</Label>
                                <Col md={9}>
                                    : {data?.supervisorInformation?.createdBy ?? "-"}
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <div className='d-flex justify-content-end mt-2 mt-md-0'>
                {permissionAccess?.update && (
                    <Link to={`/supplier/${id}/edit`}>
                        <Button type="button" color="primary">
                            <i className='fa fa-pencil mr-3'></i>
                            Ubah
                        </Button>
                    </Link>
                )}
            </div>

            <ModalImage isOpen={detailImage} setOpen={setDetailImage} dataImg={data} />
            <ModalRejectSD isOpen={rejectedModal} setOpen={setRejectedModal} fetchData={fetchData} />
            <ModalApproveSD isOpen={approveModal} setOpen={setApproveModal} fetchData={fetchData} />

        </>
    )
}

const ModalImage = ({ isOpen, setOpen, dataImg }) => {

    const handleDownloadImg = (imgSrc) => {
        fetch(imgSrc)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const a = document.createElement('a');
                a.href = url;
                a.download = "self-declaration.jpg";
                document.body.appendChild(a);
                a.click();
            })
            .catch(error => console.error('Error downloading file:', error));
    };

    return <Modal
        isOpen={isOpen}
        handleClose={() => setOpen(!isOpen)}
        component={<>
            <div className='d-flex justify-content-end mb-4'>
                <span onClick={() => setOpen(!isOpen)} style={{ cursor: "pointer" }}><img src={cancel} alt="pict-cancel" /></span>
            </div>
            <div>
                <img src={dataImg?.supplyCommitment?.selfDeclaration} alt="sd-pic" className='img-fluid' />
            </div>
            <div className='d-flex justify-content-end mt-4'>
                <Button size='sm' color="primary" outline onClick={() => handleDownloadImg(dataImg?.supplyCommitment?.selfDeclaration)}>
                    <img src={IcDownload} alt='download' className='mr-1' /> Download File
                </Button>
            </div>
        </>
        }
    />
}

const ModalRejectSD = ({ isOpen, setOpen, fetchData }) => {

    const { id } = useParams();
    const { hasFetch } = useAction();

    const handleSubmit = (input) => {
        const requestBody = {
            supplierId: id,
            rejectReason: input.rejectReason,
        };
        const action = ({ requestBody }) => Services().post('/api/back-office/supplier/sd-reject', requestBody);
        action({ requestBody })
            .then(() => {
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: `Penolakan Verifikasi Berhasil` }
                })
                setOpen(!isOpen);
                fetchData()
            })
            .catch(e => {
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Data Self Declaration gagal Diperbarui.` } });
            });
    };

    const ButtonConfirm = () => {
        const { formState: { isValid } } = useFormContext();

        return <Button size='sm' type="submit" color={isValid ? "primary" : "secondary"} disabled={!isValid}>
            Konfirmasi
        </Button>
    }

    return <Modal
        isOpen={isOpen}
        handleClose={() => setOpen(!isOpen)}
        component={<>
            <div className='moda-password-title font-weight-bold'>Konfirmasi Tolak Verifikasi</div>
            <p className='font-weight-normal'>Alasan Penolakan Verifikasi</p>

            <Form onSubmit={handleSubmit}>
                <div className='d-flex flex-column' style={{ gap: "1rem" }}>
                    <div className='d-flex flex-column' style={{ gap: 2 }}>
                        <TextArea
                            name="rejectReason"
                            id="rejectReason"
                            rows="4"
                            validation={['required']}
                            placeholder="Ketik Alasan Penolakan"
                            maxLength="150"
                        />
                    </div>
                    <div className='d-flex justify-content-end' style={{ gap: "6px" }}>
                        <Button onClick={() => setOpen(!isOpen)} size='sm' type="button" color="primary" outline >
                            Batal
                        </Button>
                        <ButtonConfirm />
                    </div>
                </div>
            </Form>
        </>
        }
    />
}

const ModalApproveSD = ({ isOpen, setOpen, fetchData }) => {

    const { id } = useParams();
    const { hasFetch } = useAction();
    // const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    const handleStartDateChange = date => {
        // Mengatur endDate menjadi 1 tahun setelah startDate
        setEndDate(new Date(date.getFullYear() + 1, date.getMonth(), date.getDate()));
    };

    const handleSubmit = (input) => {

        const requestBody = {
            supplierId: id,
            signedDateSD: format(new Date(input?.signedDateSD), 'dd-MM-yyyy'),
            expiredDateSD: format(new Date(input?.expiredDateSD), 'dd-MM-yyyy')
        };

        const action = ({ requestBody }) => Services().post('/api/back-office/supplier/sd-approve', requestBody);
        action({ requestBody })
            .then(() => {
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: `Verifikasi Berhasil` }
                })
                setOpen(!isOpen);
                fetchData()
            })
            .catch(e => {
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Data Self Declaration gagal Diperbarui.` } });
            });
    };

    const InputExpiredDate = () => {
        const { setValue, watch } = useFormContext();
        const watchSignDate = watch('signedDateSD');

        useEffect(() => {
            if (watchSignDate) setValue('expiredDateSD', endDate)
        }, [watchSignDate, setValue])

        return <DatePicker
            disabled
            selected={endDate}
            name="expiredDateSD"
            id="expiredDateSD"
            validation={['required']}
            dateFormat={"dd/MM/yyyy"}
            placeholderText="Pilih Tanggal"
        />
    }

    const ButtonConfirm = () => {
        const { formState: { isValid } } = useFormContext();

        return <Button size='sm' type="submit" color={isValid ? "primary" : "secondary"} disabled={!isValid}>
            Konfirmasi
        </Button>
    }

    return <Modal
        isOpen={isOpen}
        handleClose={() => setOpen(!isOpen)}
        component={<>
            <div className='moda-password-title font-weight-bold'>Konfirmasi Verifikasi</div>
            <p className='font-weight-normal'>Status akan diubah menjadi ‘Terverifikasi’</p>

            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label>Tanggal Tanda Tangan Self Declaration</Label>
                    <DatePicker
                        name="signedDateSD"
                        id="signedDateSD"
                        validation={['required']}
                        maxDate={new Date()}
                        placeholderText="Pilih Tanggal"
                        dateFormat={"dd/MM/yyyy"}
                        onChange={handleStartDateChange}
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Tanggal Kedaluwarsa</Label>
                    <InputExpiredDate />
                </FormGroup>

                <div className='d-flex justify-content-end' style={{ gap: "6px" }}>
                    <Button onClick={() => setOpen(!isOpen)} size='sm' type="button" color="primary" outline >
                        Batal
                    </Button>
                    <ButtonConfirm />
                </div>
            </Form>
        </>
        }
    />
}

export default App;