import React, { useState, useCallback, useEffect, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import * as router from 'react-router-dom';
import { Container, Spinner } from 'reactstrap';
import logo from 'assets/image/logo-gabp.png';
import {
    AppFooter,
    AppHeader,
    AppSidebar,
    AppSidebarFooter,
    AppSidebarForm,
    AppSidebarHeader,
    AppSidebarNav2 as AppSidebarNav,
} from '@coreui/react';
import Routes from 'config/Route';
import { useDispatch, useSelector } from 'react-redux';
import { useAlertToast } from 'hooks';
import { useAction } from 'hooks';
import { requestHomeSideMenu } from 'store/actions/homesidemenu';
import Error404 from 'page/error/Error404';
import Loadable from 'helper/Loadable';
import { requestLoggingOut } from 'store/actions/authorization';

const Footer = Loadable(React.lazy(() => import('./Footer')));
const Header = Loadable(React.lazy(() => import('./Header')));

const Layout = (props) => {
    // const [navigation] = useState(treeMenu);
    const [navigation, setNavigation] = useState({ items: [] });
    const dispatch = useDispatch()
    // const loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

    const signOut = (e) => {
        e.preventDefault()
        dispatch(requestLoggingOut())
    }

    const { hasFetch } = useAction();
    useEffect(() => {
        hasFetch(requestHomeSideMenu());
    }, [hasFetch])

    const checkMenuGroup = useCallback((obj) => {
        if (obj.children && obj.children.length > 0) {
            obj.children.forEach((objChildren) => {
                if (objChildren.url.replace("/", "") === props.location.pathname.split('/')[1]) {
                    obj.url = objChildren.url;
                } else if (obj.url === "") {
                    delete obj.url;
                }
            });
        }
    }, [props.location.pathname]);

    /* get payload from redux store */
    const payload = useSelector(state => {
        let tempNavigation = state.homesidemenu.menus;
        if (tempNavigation.length > 0) {
            tempNavigation.forEach((el) => checkMenuGroup(el));
        }
        return {
            permission: {
                isLoading: state.homesidemenu.loading,
                actionGroup: state.homesidemenu.actionGroup,
                permissionList: state.homesidemenu.pola
            },
            menu: {
                isLoading: state.homesidemenu.loading,
                menuList: tempNavigation
            }
        }
    });
    useEffect(() => {
        if (navigation?.items.length > 0) {
            navigation.items.forEach((el) => checkMenuGroup(el));
        }

    }, [props.location.pathname, navigation, checkMenuGroup])

    useEffect(() => {
        /* filter menu (hide menu if children menu not found) */
        let tempNavigation = { items: payload.menu.menuList.filter(obj => !obj.enabled) }
        setNavigation(tempNavigation);
    }, [setNavigation, payload.menu.menuList]);
    /* alert toast configuration */
    const { NotificationContainer } = useAlertToast();
    const parentFlag = navigation.items.reduce((acc, item, index) => {
        acc[item.code] = item.menus.map(i => i.enabled).every(v => v === false)
        return acc
    }, {});
    const navs = {
        items: navigation.items.filter(i => parentFlag[i.code] !== true).map(items => ({
            ...items,
            children: items.menus.filter(v => v.enabled === true).map(isItems => ({
                "name": isItems.label,
                "url": isItems.url,
                "code": isItems.code
            })),
            menus: items.menus.filter(v => v.enabled === true)
        }))
    }
    return (
        <div className="app">
            <NotificationContainer />
            <AppHeader fixed>
                <Header onLogout={e => signOut(e)} />
            </AppHeader>
            <div className="app-body">
                <AppSidebar fixed display="lg" className="bg-white">
                    <AppSidebarHeader className="bg-white">
                        <img src={logo} className="navbar-brand w-50 mt-4" alt="logo" />
                    </AppSidebarHeader>
                    <AppSidebarForm />
                    <AppSidebarNav navConfig={navs} {...props} isOpen={true} router={router} />
                    <AppSidebarFooter>
                        <span className="d-inline-block d-md-none">v{process.env.REACT_APP_VERSION} - {process.env.REACT_APP_ENVIRONMENT} </span>
                    </AppSidebarFooter>
                </AppSidebar>
                <main className="main">
                    <Container fluid>
                        <Suspense fallback={"loading..."}>
                            <Switch>
                                {Routes.private.map((route, idx) => {
                                    return route.component ? (
                                        <Route
                                            key={idx}
                                            path={route.path}
                                            exact={route.exact}
                                            name={route.name}
                                            render={props => {
                                                return (!payload?.permission.isLoading) ?
                                                    (route.action && payload?.permission?.permissionList[route.access]?.[route.action]) ?
                                                        <route.component {...props} title={route.name} access={payload?.permission?.permissionList[route.access]} allAccess={payload?.permission?.permissionList} action={route.action} actionGroup={payload?.permission?.actionGroup} /> : <Error404 />
                                                    : <Spinner />
                                            }}
                                        />
                                    ) : (null);
                                })}
                                <Redirect from="/" to="/dashboard" />
                            </Switch>
                        </Suspense>
                    </Container>
                </main>
            </div>
            <AppFooter>
                <Footer />
            </AppFooter>
        </div>
    );
}

export default Layout;
