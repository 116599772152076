import { catchError, map, mergeMap, takeUntil } from 'rxjs/operators'
import { ofType } from 'redux-observable'
import { Services } from "service"
import store from 'store'
import { EMPTY, from } from 'rxjs'
export const RequestEpicController = (action$, states) =>
    action$.pipe(
        ofType("FETCH_CONTROLLERS"),
        mergeMap(action =>
            from(AsyncExecuteMiddleWares({ action, states })).pipe(
                map(raw => {
                    return action?.payload?.callback(raw, states) && action.payload.callback(raw, states);
                }), catchError(err => {
                    return AsyncRequestedError(err, action) || EMPTY
                }),
                takeUntil(action$.pipe(
                    ofType("CANCELATION"),
                ))
            )
        ))
export const AsyncRequestedError = (err, action) => {
    const { message } = err
    if (action?.payload?.catchReject === undefined) {
        store.dispatch({ type: "REQUEST_RESTARTED" })
        store.dispatch({ type: 'ALERT_TOAST_ERROR', payload: { message: message } })
    }
    action?.payload?.catch && action.payload.catch(err)
}
export const AsyncExecuteMiddleWares = async ({ action }) => {
    store.dispatch({ type: action.request })
    return await Services()[action.payload.http](action.payload.url, action.payload.parameter, action.payload.config);
}