import React, { createContext, useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Row, Col, CardBody, FormGroup, Label, Button, Input, TabContent, TabPane, NavLink, NavItem, Nav } from 'reactstrap';
import { useFetch, useAction } from 'hooks';
import { Services } from 'service';
import { Spinner } from 'reactstrap';
import { Breadcrumb } from 'components';
import Form from 'components-form/Form';
import IcDownload from '../../assets/svg/ic_download.svg';
import IcDownloadOrange from '../../assets/svg/ic_download_orange.svg';
import TableSuccess from './components/TableUploadSuccess';
import TableFailed from './components/TableUploadFailed';
import * as XLSX from 'xlsx/xlsx.mjs';
import useDownloadTemplate from './components/useDownloadTemplate';

export const FileUploadContext = createContext();

const JsonToXlsx = (e, data, filename) => {
    e.preventDefault()
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, filename);
}

const DownloadFailedUploadData = ({ id }) => {
    /* get data failed upload */
    const { data } = useFetch(
        `/api/back-office/petty-cash/upload/${id}/review/error-list`,
        currentParams => ({
            unpaged: true
        }),
        useCallback(data => ({
            data: data?.data?.content
        }), [])
    )

    return <Link
        to={"#"}
        className='ml-2' style={{ fontSize: 12 }}
        onClick={(e) => JsonToXlsx(e, data, 'Data Gagal Upload.xlsx')}
    >
        <img src={IcDownload} alt='download' className='mr-1' />
        Download Data Gagal Upload
    </Link>
};

const App = ({
    title: documentTitle,
    history
}) => {
    document.title = documentTitle;

    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState('');
    const [dataResultUpload, setDataResultUpload] = useState('')
    const [totalSuccessUpload, setTotalSuccessUpload] = useState('')
    const [totalFailedUpload, setTotalFailedUpload] = useState('')
    const [isUpload, setIsUpload] = useState(false)
    const [isLoadingUpload, setLoadingUpload] = useState(false);
    const [isLoadingSubmit, setLoadingSubmit] = useState(false);

    const { hasFetch } = useAction();
    const [activeTabs, setActiveTabs] = useState('berhasil')
    const dataBreadcrumb = [
        { to: '/cash-planning', label: 'Perencanaan Kas' },
        { to: null, label: 'Bulk Top Up' }
    ]

    const handleButtonClick = () => {
        // Trigger the file input click event when the button is clicked
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const getSelectedFile = e.target.files[0];
        if (getSelectedFile) {
            // Handle the selected file here (e.g., send it to the server)
            setSelectedFile(getSelectedFile);
            setIsUpload(true)
        }
    };

    /* fetching data stockpoint for donwload */
    const { data: dataStockpoint, loading: loadingStockpoint } = useFetch(
        `/api/back-office/stockpoint`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );

    const onSubmit = (id) => {
        setLoadingSubmit(true);

        const action = ({ id = null }) => Services().post(`/api/back-office/petty-cash/process/upload/${id}`)

        action({ id })
            .then(() => {
                setLoadingSubmit(false);
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: 'Top Up Berhasil. Lihat detail di halaman Riwayat Top Up' }
                })
                history.push("/cash-planning");
            })
            .catch(e => {
                setLoadingSubmit(false);
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Cannot update ${id}` } })
            })
    }

    const handleUpload = () => {
        const dataForm = new FormData()
        dataForm.append('file', selectedFile)
        setLoadingUpload(true)

        const action = ({ requestBody }) => Services().post('/api/back-office/petty-cash/upload', requestBody)
        action({ requestBody: dataForm })
            .then((res) => {
                setDataResultUpload(res?.data?.data)
                setIsUpload(false)
                setLoadingUpload(false)
            })
            .catch(e => {
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Cannot update` } })
                setLoadingUpload(false)
            })
    };

    const template = useDownloadTemplate({
        url: '/api/back-office/petty-cash/download-template',
        filename: 'Template Upload.xlsx'
    });

    return (
        <FileUploadContext.Provider
            value={{
                setDataResultUpload,
                dataResultUpload,
                setTotalFailedUpload,
                totalFailedUpload,
                setTotalSuccessUpload,
                totalSuccessUpload,
            }}
        >

            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Bulk Top Up</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Form id="form-topup" autoComplete="off">
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label htmlFor="harga-standar" md={3} className='pt-2'>File</Label>
                                    <Col>
                                        <Input style={{ height: "40px" }} placeholder="Pilih File" value={selectedFile.name ?? ""} disabled />
                                        <div className="mt-1" style={{ fontSize: 12, color: "#70727D" }}>
                                            Format .xlsx
                                        </div>
                                        <div className='mt-4 d-flex flex-wrap' style={{ fontSize: 12 }}>
                                            <Button
                                                color='link'
                                                className='p-0 my-0 mr-4'
                                                style={{ fontSize: '12px', fontWeight: '400' }}
                                                onClick={template.download}
                                                disabled={template.isLoading}
                                            >
                                                <img src={IcDownload} alt='download' className='mr-1' />
                                                Download Template
                                            </Button>

                                            {!loadingStockpoint &&
                                                <Link
                                                    to={"#"}
                                                    className='text-warning'
                                                    onClick={(e) => JsonToXlsx(e, dataStockpoint, 'Kode Stockpoint.xlsx')}
                                                >
                                                    <img src={IcDownloadOrange} alt='download' className='mr-1' />
                                                    Download Kode Stockpoint
                                                </Link>
                                            }
                                        </div>
                                    </Col>
                                    <div className='px-3'>
                                        <Button type="button" color="primary" className="py-1 m-0" style={{ height: 40 }} onClick={handleButtonClick}>
                                            Pilih File

                                            {/* hidden input for handle upload file */}
                                            <input
                                                type="file"
                                                accept=".xlsx"
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange}
                                            />
                                        </Button>
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                {
                    !isUpload ?
                        dataResultUpload?.id && <Card>
                            <CardBody>
                                <FormGroup row className='align-items-center'>
                                    <Label htmlFor="harga-standar" md={3} className='pt-2'>Berhasil Upload</Label>
                                    <Col>
                                        : {(`${totalSuccessUpload} data berhasil upload`)}
                                    </Col>
                                </FormGroup>
                                <FormGroup row className='align-items-center'>
                                    <Label htmlFor="harga-standar" md={3} className='pt-2'>Gagal Upload</Label>
                                    <Col>
                                        : {`${totalFailedUpload} data gagal upload`}

                                        {totalFailedUpload > 0 &&
                                            <DownloadFailedUploadData id={dataResultUpload?.id} />
                                        }
                                    </Col>
                                </FormGroup>
                                <div className="dropdown-divider" />
                                <div>
                                    <Nav tabs className='mb-4 mt-3'>
                                        <NavItem>
                                            <NavLink
                                                style={{ fontSize: 14, padding: "0 0 5px 0" }}
                                                className={`mr-3 font-weight-bold ${activeTabs === 'berhasil' ? 'active' : 'inactive'}`}
                                                onClick={() => { setActiveTabs('berhasil') }}
                                            >
                                                Berhasil Upload
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ fontSize: 14, padding: "0 0 5px 0 " }}
                                                className={`font-weight-bold  ${activeTabs === 'gagal' ? 'active' : 'inactive'}`}
                                                onClick={() => { setActiveTabs('gagal') }}
                                            >
                                                Gagal Upload
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTabs}>
                                        <TabPane tabId="berhasil">
                                            <TableSuccess />
                                        </TabPane>
                                        <TabPane tabId="gagal">
                                            <TableFailed />
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </CardBody>
                        </Card>
                        : isLoadingUpload && <div className='text-center mt-2'>
                            <Spinner size="lg" color="primary" />
                        </div>
                }
                <Row>
                    <Col className="text-right">
                        <Link to={"/cash-planning"}>
                            <Button type="button" color="secondary" className="m-1" >
                                Batal
                            </Button>
                        </Link>

                        {!isUpload && dataResultUpload?.id
                            ? <Button
                                type="button"
                                color="primary"
                                className="m-1"
                                disabled={totalSuccessUpload === 0 || isLoadingSubmit}
                                onClick={() => onSubmit(dataResultUpload?.id)}
                            >
                                {isLoadingSubmit &&
                                    <span className='mr-2'>
                                        <Spinner size="sm" />
                                    </span>}
                                Top Up
                            </Button>
                            : <Button
                                type="button"
                                color="primary"
                                className="m-1"
                                disabled={(selectedFile === '' ? true : false) || isLoadingUpload}
                                onClick={handleUpload}
                            >
                                {isLoadingUpload &&
                                    <span className='mr-2'>
                                        <Spinner size="sm" />
                                    </span>}
                                Upload
                            </Button>
                        }

                    </Col>
                </Row>
            </Form>
        </FileUploadContext.Provider>
    )
}

export default App