import ModalPage from 'components/Modal';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, FormGroup, Label, Row, Spinner } from 'reactstrap';
import Form from 'components-form/Form';
import { useFormContext } from 'react-hook-form';
import { Select } from 'components-form';
import { useAction, useFetch } from 'hooks';
import InputMoneyValidate from 'components-form/InputMoneyValidate';
import { Services } from 'service';
import { useSelector } from 'react-redux';

const PettyContext = createContext();
const ModalSettlement = ({ context }) => {
    const { open, setopen, getMoney, br, sp, setreload } = useContext(context);

    const [id, setcode] = useState(null);
    const [move, setmove] = useState('cairkan')
    const [state, setState] = useState({
        branchName: br,
        stockpointName: sp,
        uang: ""
    })
    const handleSubmit = (values) => {
        setmove('konfirm')
        setState(values)
    }
    const close = () => {
        setopen(!open)
    }
    const defaultValues = {
        ...state
    }
    const calledBranch = (id) => {
        setcode(id)
    }
    return (
        <PettyContext.Provider
            value={{
                id,
                setcode: (isId) => calledBranch(isId),
                setmove,
                state,
                br,
                sp,
                close,
                setreload
            }}
        >
            <ModalPage
                isOpen={open}
                handleClose={close}
                component={<>
                    {<div className={move === "konfirm" ? "d-block" : "d-none"}><PagesConfirm /></div>}
                    {<div className={move === "cairkan" ? "d-block" : "d-none"}>
                        <div className='font-weight-bold text-16 mb-4'>Cairkan Petty Cash</div>
                        <Form onSubmit={handleSubmit} className="w-100" autoComplete="off" defaultValues={defaultValues}>
                            <Row className="mb-3">
                                <Col>
                                    <Label>Branch</Label>
                                    <BranchField name="branchName" />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Stockpoint</Label>
                                        <StockpointField name="stockpointName" />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label>Jumlah Dicairkan</Label>
                                        <TrigerValue price={getMoney} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Col className='d-flex justify-content-between align-items-center px-0 pt-2'>
                                <CairkanSemua money={getMoney} />
                                <div className='text-right'>
                                    <Button onClick={() => {
                                        close()
                                    }} outline size='sm' type="button" color="primary" >
                                        Batal
                                    </Button>
                                    <ConfirmButton title="Cairkan" />
                                </div>
                            </Col>
                        </Form>
                    </div>}
                </>
                }
            />
        </PettyContext.Provider>
    )
}

const CairkanSemua = ({ money }) => {
    const { setValue } = useFormContext();
    const { trigger } = useFormContext();
    const formatNumeric = (number) => new Intl.NumberFormat('id-ID').format(number);
    return (
        <div onClick={() => {
            setValue("moneyPick", formatNumeric(money));
            trigger("moneyPick")
        }} style={{ cursor: "pointer" }} className="text-primary">Cairkan Semua Saldo</div>
    )
}

const BranchField = ({ name }) => {
    const { setcode, br } = useContext(PettyContext);
    const { branchCode } = useSelector(state => state?.homesidemenu?.profile)
    const { setValue } = useFormContext();
    const { data, loading } = useFetch(
        `/api/back-office/branch`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );
    useEffect(() => {
        setValue('branchName', br ?? branchCode);
    }, [br, setValue, branchCode])
    return (
        <Select
            id={name}
            name={name}
            className="basic-single"
            classNamePrefix="select"
            options={data?.map(obj => ({ label: `${obj?.code} - ${obj?.name}`, value: obj?.code })).sort((a, b) => a.label.localeCompare(b.label))}
            placeholder={`Pilih Branch`}
            isLoading={loading}
            isClearable={true}
            isDisabled={true}
            validation={['required']}
            onChange={(ev) => {
                if (ev) {
                    setcode(ev?.value)
                }
                else {
                    setcode(null)
                }
            }}
        />
    )
}
const StockpointField = ({ name }) => {
    const { sp, br } = useContext(PettyContext);
    const { setValue } = useFormContext();
    const { stockpointId, branchCode } = useSelector(state => state?.homesidemenu?.profile)
    const { data, loading, setParams } = useFetch(
        `/api/back-office/stockpoint`,
        currentParams => ({
            unpaged: true,
        }),
        useCallback(data => ({
            data: data?.data?.content,
        }), []),
        { onMount: br || branchCode, withQueryParams: false, showAlertOnError: false }
    );

    useEffect(() => {
        setValue('stockpointName', sp ?? stockpointId);
    }, [sp, setValue, stockpointId])

    useEffect(() => {
        if (br || branchCode) {
            setParams((prev) => ({
                ...prev,
                branch: br ?? branchCode
            }));
        }
        else {
            setValue('stockpointName', null);
        }
    }, [br, setParams, setValue, branchCode]);

    return (
        <Select
            id={name}
            name={name}
            className="basic-single"
            classNamePrefix="select"
            options={data?.map(obj => ({ label: `${obj?.code} - ${obj?.name}`, value: obj?.id })).sort((a, b) => a.label.localeCompare(b.label))}
            placeholder={`Pilih Stock Point`}
            isLoading={loading}
            isClearable={true}
            isDisabled={true}
            validation={['required']}
            onChange={(ev) => {
            }}
        />
    )
}

export const ConfirmButton = ({ title }) => {
    const { watch } = useFormContext();
    const poin = watch('moneyPick')
    // color={isSubmitting || !isDirty ? "secondary" : "primary"}
    return (
        <Button type="submit" size='sm' disabled={!poin} color={!poin ? "secondary" : "primary"} >
            {title}
        </Button >
    )
}


export const TrigerValue = ({ price }) => {
    const { register } = useFormContext();
    // `moreThanCurrent[${price}]`
    return (
        <InputMoneyValidate
            count={price}
            id="uang"
            validation={[`required`, `moreThanCurrent[${price}]`]}
            name="moneyPick"
            type="text"
            placeholder="0"
            autoComplete="off"
            {...register}
        />
    )
}

const PagesConfirm = () => {
    const { setmove, state, br, close, setreload } = useContext(PettyContext);
    const { branchCode } = useSelector(state => state?.homesidemenu?.profile)
    const { hasFetch } = useAction();
    const formatNumeric = (number) => new Intl.NumberFormat('id-ID').format(number);
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const { data: sp } = useFetch(
        `/api/back-office/stockpoint`,
        currentParams => ({
            unpaged: true,
        }),
        useCallback(data => ({
            data: data?.data?.content,
        }), []),
        { onMount: br ?? branchCode, withQueryParams: false, showAlertOnError: false }
    );
    const { data: branch } = useFetch(
        `/api/back-office/branch`,
        currentParams => ({
            unpaged: true,
        }),
        useCallback(data => ({
            data: data?.data?.content,
        }), []),
        { onMount: state, withQueryParams: false, showAlertOnError: false }
    );
    const [checked, setchecked] = useState(true);
    return (
        <div style={{ gap: "12px" }} className='d-flex flex-column'>
            <div className='font-weight-bold text-16 mb-2'>Konfirmasi Pencairan Petty Cash</div>
            <div className='font-weight-bold text-14'>Informasi Pencarian</div>
            <div className='d-flex' style={{ gap: "8rem" }}>
                <div className='d-flex flex-column' style={{ gap: "6px" }}>
                    <span style={{ color: "#9C9DA6", fontWeight: 400 }} className='text-14'>Branch</span>
                    <span style={{ color: "#151823", fontWeight: 500 }}>{branch?.find(({ code }) => code === state?.branchName)?.name}</span>
                </div>
                <div className='d-flex flex-column' style={{ gap: "6px" }}>
                    <span style={{ color: "#9C9DA6", fontWeight: 400 }} className='text-14'>Stockpoint</span>
                    <span style={{ color: "#151823", fontWeight: 500 }}>{sp?.find(({ id }) => id === state?.stockpointName)?.name}</span>
                </div>
            </div>
            <div className='d-flex flex-column' style={{ gap: "4px" }}>
                <span className='text-14' style={{ fontWeight: 400, color: "#9C9DA6" }}>Jumlah Pencairan Petty Cash</span>
                <span className='text-24' style={{ fontWeight: 700, color: "#151823" }}>Rp {formatNumeric(parseFloat(state?.moneyPick?.replaceAll('.', "")))}</span>
            </div>
            <Row style={{ gap: "1rem" }}>
                <Col sm={12}>
                    <div className='d-flex pt-3 align-items-center'>
                        <input onChange={() => setchecked(!checked)} id="confirm" validation={['required']} name="confirm" type="checkbox" autoComplete="off" />
                        <span className='ml-2 text-12'>Saya setuju untuk melakukan pencatatan pencairan</span>
                    </div>
                </Col>
            </Row>
            <div className='d-flex pt-3 justify-content-end' style={{ gap: "1rem" }}>
                <div className='text-right'>
                    <Button type="button" onClick={() => {
                        setmove('cairkan')
                    }} outline size='sm' color="primary" >
                        Batal
                    </Button>
                    <Button type="button" className={`btn ${checked ? "btn-secondary disabled" : "btn-primary"} btn-sm`} disabled={checked || isLoadingSubmit}
                        onClick={() => {
                            setIsLoadingSubmit(true);
                            Services().post('/api/back-office/petty-cash/withdraw', {
                                "branchId": branch?.find(({ code }) => code === state?.branchName)?.id,
                                "stockpointId": sp?.find(({ id }) => id === state?.stockpointName)?.id,
                                "amount": parseFloat(state?.moneyPick?.replaceAll('.', ""))
                            }).then((_) => {
                                setIsLoadingSubmit(false);
                                hasFetch({
                                    type: 'ALERT_TOAST_SUCCESS',
                                    payload: { message: `Pencairan Berhasil. Lihat detail di halaman Mutasi Rekening` }
                                })
                                close();
                                setreload('reload')
                                setmove('cairkan')
                            }).catch(e => {
                                setIsLoadingSubmit(false);
                                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Pencairan Gagal` } })
                            })
                        }} outline size='sm' color="primary">
                        {isLoadingSubmit &&
                            <span className='mr-2'>
                                <Spinner size="sm" />
                            </span>}
                        Konfirmasi
                    </Button>
                </div>
            </div>
        </div>
    )
}
export default ModalSettlement