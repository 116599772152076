import React, { createContext, useCallback, useContext, useState } from 'react'
import {
    Card, CardBody, Row, Col,
    FormGroup,
    Label,
    Button,
    Badge,
} from 'reactstrap';
import {
    useParams
} from 'react-router-dom';
import { Breadcrumb } from 'components';
import Error404 from 'page/error/Error404';
import ModalPage from 'components/Modal';
import icScan from 'assets/svg/ic_scan.svg';
import IcEdit from 'assets/svg/ic_edit_blue.svg'
import { format } from 'date-fns';
import Penyelesaian from './components/component.modal.edit';
import { Services } from 'service';
import { useAction, useFetch } from 'hooks';
import ModalFormsQR from '../create.qr.warehouse/components/component.modal.qr';

const getColorStatus = (status) => {
    switch (status) {
        case 'completed':
            return <Badge style={{ color: "white" }} color="success" className='text-10 p-1'>
                Selesai
            </Badge>
        case 'canceled':
            return <Badge style={{ color: "white" }} color="danger" className='text-10 p-1'>
                Dibatalkan
            </Badge>
        case 'picking_up_process':
            return <Badge style={{ color: "white" }} color="warning" className='text-10 p-1'>
                Dalam Proses
            </Badge>
        default:
            return <Badge style={{ color: "white" }} color="black" className='text-10 p-1'>
                {status}
            </Badge>
    }
}
export const ModalDetailContext = createContext();
const App = ({
    title: documentTitle,
    access: permissionAccess,
    history
}) => {
    document.title = documentTitle;
    const [rejected, setrejected] = useState(false);
    const [resolved, setresolved] = useState(false);
    const [edited, setedited] = useState(false);
    const { id } = useParams();

    const dataBreadcrumb = [
        { to: '/pickup-warehouse', label: 'Buat QR Warehouse' },
        { to: null, label: 'Detail' }
    ]

    const { data,
        status, error, fetchData } = useFetch(
            `/api/back-office/pickup-request/warehouse/${id}`,
            {},
            useCallback(data => ({ data: data?.data }), [])
        )

    const filterDataJeriken = (data, volume, keyName) => {
        const result = data.filter(item => item.type === "jeriken").find(item => item.volume === volume)?.[keyName];
        return result
    }

    if ((status === 'rejected' && error.code === 400) ||
        (status === 'resolved' && data === null)) return <Error404 />
    return status === 'resolved' && (
        <ModalDetailContext.Provider
            value={{
                rejected,
                setrejected,
                resolved,
                setresolved,
                edited,
                setedited,
                filters: filterDataJeriken,
                data,
                fetchData
            }}
        >
            <Row className="row mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>QR Penjemputan Baru</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Card>
                <CardBody>
                    <FormGroup row>
                        <Label sm={3}>No. Tiket</Label>
                        <Col sm={9}>
                            : {data?.tripCode ?? ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Driver</Label>
                        <Col sm={9}>
                            : {data?.driverName ?? ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Tanggal Penjemputan</Label>
                        <Col sm={9}>
                            : {format(new Date(data?.date), 'dd-MM-yyyy') ?? ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Asal Minyak</Label>
                        <Col sm={9}>
                            : {data?.originName ?? ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Jumlah Minyak Jeriken 18L</Label>
                        <Col sm={9}>
                            : {filterDataJeriken(data?.oilContainers, "18", "qty") ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Jumlah Minyak Jeriken 20L</Label>
                        <Col sm={9}>
                            : {filterDataJeriken(data?.oilContainers, "20", "qty") ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Jumlah Minyak Dalam Kg</Label>
                        <Col sm={9}>
                            : {data?.totalWeight + " Kg" ?? ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Status</Label>
                        <Col sm={9}>
                            : {getColorStatus(data?.status) ?? ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Dibuat Oleh</Label>
                        <Col sm={9}>
                            : {data?.createBy ?? ""}
                        </Col>
                    </FormGroup>
                    <Row className='d-inline-block float-left mt-2 mt-md-0'>
                        <Col>
                            {data?.status === "picking_up_process" && <Button className="mr-3" size='sm' color="primary" onClick={() =>
                                setresolved(!resolved)
                            }>
                                <img src={icScan} className='mr-2' alt='icon_scan' height={24} />Tunjukan QR Code
                            </Button>}
                            {(permissionAccess?.update && data?.status === "picking_up_process") && <Button onClick={(e) => { setedited(!edited); e.target.blur(); }} size='sm' outline color="primary" className='btn-edit'>
                                <img src={IcEdit} className='mr-2' alt='icon_scan' height={24} />
                                Edit
                            </Button>}
                        </Col>
                    </Row>
                    <Row className='d-inline-block float-right mt-2 mt-md-0'>
                        <Col>
                            {(permissionAccess?.delete && data?.status === "picking_up_process") && <Button onClick={() =>
                                setrejected(!rejected)
                            } size='sm' color="danger">
                                Batalkan
                            </Button>}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Batalkan />
            <Penyelesaian />
            <ModalFormsQR />
        </ModalDetailContext.Provider>
    )
}

export const Batalkan = () => {
    const { rejected, setrejected, fetchData } = useContext(ModalDetailContext);
    const { hasFetch } = useAction();
    const { id } = useParams();
    const batalkan = () => {
        Services().post('/api/back-office/pickup-request/warehouse/cancel', { id }).then(() => {
            hasFetch({
                type: 'ALERT_TOAST_SUCCESS',
                payload: { message: `QR penjemputan baru berhasil dibatalkan.` }
            })
            fetchData();
            setrejected(!rejected);
        }).catch(e => {
            hasFetch({
                type: 'ALERT_TOAST_ERROR',
                payload: { message: e?.message ?? ` Telah dibatalkan` }
            })
        })
    }

    return (
        <ModalPage
            isOpen={rejected}
            handleClose={() => setrejected(!rejected)}
            component={<>
                <div className='moda-password-title font-weight-bold'>Batalkan?</div>
                <p style={{ fontSize: "14px" }} className='moda-password-title font-weight-normal m-0'>
                    Apakah Anda yakin ingin membatalkan pembuatan QR?
                </p>
                <div className='d-flex justify-content-end pt-4'>
                    <Button onClick={() => setrejected(!rejected)} type='button' className="mr-3" size='sm' outline color="primary">Batal</Button>
                    <Button onClick={batalkan} type='button' className="mr-3" size='sm' color="primary">Konfirmasi</Button>
                </div>
            </>
            }
        />
    )
}

export default App