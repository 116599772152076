import React, { useCallback, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import axios from "axios";

import IcDownloadFull from "assets/svg/ic_download-full.svg";
import { IdrFormat, capitalize, downloadedfiletopdf, isBlankOrNullish, parseValue } from "helper";
import { useFetch } from "hooks";
import { DetailContext } from "page/direct.purchase/detail";
import DetailImagePreview from "page/direct.purchase/components/DetailImagePreview";

import { formatDate } from "../constants";
import RowInfo from "./RowInfo";

export const useModalPurchaseDetail = () => {
    const config = useState(false);
    const [, setIsOpen] = config;

    const open = () => setIsOpen(true);

    return {
        config,
        open
    }
};

const verifikasiSelfDeclarationOptions = [
    { value: "verified", label: "Terverifikasi", color: "text-success" },
    { value: "unverified", label: "Belum Terverifikasi", color: "text-warning" },
    { value: "expired", label: "Kedaluwarsa", color: "text-purple" },
    { value: "rejected", label: "Ditolak", color: "text-danger" },
]

const ModalPurchaseDetail = ({ directPurchaseId, config }) => {
    const [isOpen, setIsOpen] = config;

    const close = () => setIsOpen(false);
    
    const loaded = useRef(false);
    const shouldFetch = isOpen && !loaded.current && directPurchaseId;

    const { data, loading } = useFetch(
        `/api/back-office/direct-purchase/${directPurchaseId}`,
        {},
        useCallback((res) => {
            loaded.current = true;
            
            return {
                data: res?.data
            }
        }, []),
        { onMount: shouldFetch }
    );

    return (
        <Modal
            centered
            size="xl"
            backdrop={loading ? "static" : undefined}
            keyboard={!loading}
            returnFocusAfterClose={false}
            isOpen={isOpen}
            toggle={close}
        >
            <ModalHeader toggle={close} className="pb-0" />
            <ModalBody className="pt-3">
                <Card className="mb-0">
                    <CardHeader className="pb-1 d-flex align-items-center">
                        <h4 className="text-16 mb-0" style={{ fontWeight: 600 }}>
                            Detail Informasi Pembelian Langsung
                        </h4>
                        {loading && <Spinner size="sm" className="ml-3" />}
                    </CardHeader>
                    <CardBody className="pt-3 pb-0"> 
                        <RowInfo label="No. Tiket" value={data?.referenceCode} />
                        <RowInfo label="Tanggal Pembelian" value={data?.purchaseDate} format={(date) => formatDate(date, "dd/MM/yyyy - HH:mm")} />
                        <RowInfo label="No HP Supplier" value={data?.phone} />
                        <RowInfo
                            label="Nama Supplier"
                            value={[data?.supplierId, data?.supplierName].some(isBlankOrNullish) ? null : true}
                            format={() => <Link to={`/supplier/${data?.supplierId}`}>{data?.supplierName}</Link>}
                        />
                        <RowInfo label="Nama Outlet" value={data?.outletName} />
                        <RowInfo
                            label="Self Declaration"
                            value={data?.selfDeclaration}
                            format={(selfDeclaration) => (
                                <span className={selfDeclaration ? "text-success" : "text-danger"}>
                                    {selfDeclaration ? "Ada" : "Tidak Ada"}
                                </span>
                            )}
                        />
                        <RowInfo
                            label="Verifikasi Self Declaration"
                            value={verifikasiSelfDeclarationOptions.find(({ value }) => value === data?.statusSD)}
                            format={({ label, color }) => (
                                <span className={color}>
                                    {label}
                                </span>
                            )}
                        />
                        <RowInfo label="Alamat" value={data?.address} />
                        <RowInfo label="Nama Salesperson" value={data?.salespersonName} format={capitalize} />
                        <RowInfo label="Branch" value={data?.branchName} />
                        <RowInfo label="Stockpoint" value={data?.stockpointName} />
                        <RowInfo label="Jumlah Minyak Jeriken 18L" value={data?.totalContainer18L} />
                        <RowInfo label="Jumlah Minyak Jeriken 20L" value={data?.totalContainer20L} />
                        <RowInfo label="Estimasi Jumlah Kg" value={data?.totalOilWeight} format={(value) => `${value} Kg`} />
                        <RowInfo label="Harga Rata-Rata Per Kg" value={data?.averageOilPrice} format={IdrFormat} />
                        <RowInfo
                            label="Total Harga Pembelian"
                            value={data?.purchasePrice}
                            format={(purchasePrice) => (
                                <div className="d-inline-flex align-items-center" style={{ gap: "12px" }}>
                                    <span>{IdrFormat(purchasePrice)}</span>
                                    {parseValue(
                                        data?.purchasePow,
                                        (src) => (
                                            <DownloadBuktiPembelian
                                                src={src}
                                                filename={`Nota-${data?.referenceCode}-${data?.supplierName}-${data?.outletName}`}
                                            />
                                        ),
                                        null)}
                                </div>
                            )}
                        />
                        <RowInfo label="Bukti Pembelian" value={data?.purchasePow} asChild>
                            {(src) => <BuktiPembelian src={src} />}
                        </RowInfo>
                        <RowInfo label="Dibuat oleh" value={data?.createdBy} format={capitalize} />
                    </CardBody>
                </Card>
            </ModalBody>
        </Modal>
    );
};

const BuktiPembelian = ({ src }) => {
    const [openImage, setopenImage] = useState(false);

    return (
        <DetailContext.Provider value={{ openImage, setopenImage, images: src }}>
            <div
                onClick={() => setopenImage(!openImage)}
                className="d-inline-flex flex-column position-relative justify-content-center align-items-center"
                style={{ borderRadius: 4, width: 102, overflow: "hidden", gap: 2, cursor: "pointer" }}
            >
                <img src={src} style={{ background: "white", padding: 3 }} width="100%" height="auto" alt="Bukti Pembelian" />
            </div>
            <DetailImagePreview />
        </DetailContext.Provider>
    );
};

const htmls = `<section style="background-color: #ffffff; margin: 4px; padding: 14px; font-size: 24px; margin: auto; text-align: center;">
<div style="display: flex; justify-content: space-between; align-items: center;">
    <div>
        <img id="content" height="500px" />
    </div>
</div>
</section>`;

const DownloadBuktiPembelian = ({ src, filename }) => {
    const handleDownload = async () => {
        const res = await axios.get(src, { responseType: "arraybuffer" });
        const imgData = `data:${res.headers["content-type"]};base64,` + Buffer.from(res.data).toString("base64");
        
        const dom = new DOMParser().parseFromString(htmls, "text/html");
        dom.querySelector("#content").src = imgData;
        
        downloadedfiletopdf(dom.firstChild.innerHTML, filename)
    };

    return (
        <Button size="sm" color="primary" className="d-flex align-items-center" onClick={handleDownload}>
            <img className="mr-2" src={IcDownloadFull} width={16} alt="ico-download" />
            <span>Download File</span>
        </Button>
    );
};

export default ModalPurchaseDetail;
