import React from 'react';
import { Button } from 'reactstrap';

const SmallButton = ({ children, ...props }) => (
    <Button
        style={{
            paddingBlock: "6px",
            pointerEvents: props?.disabled ? "none" : undefined,
            color: props?.disabled ? "white" : undefined
        }}
        {...props}
    >
        {children}
    </Button>
);

export default SmallButton;
