import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Spinner, UncontrolledTooltip } from "reactstrap";
import { format } from "date-fns";
import DownloadFilledIcon from "@elevenia/edts-icon/glyph/DownloadFilled";

import { filterObject, isBlankOrNullish } from "helper";

// ignore pagination and empty params by default
const filterParams = (params) => filterObject(
    params,
    ([key, value]) => [
        key !== "size",
        key !== "page",
        isBlankOrNullish(value) === false
    ].every(Boolean)
);

const DownloadCsv = ({ validation, onClick, onBeforeSave }) => {
    const failedConditions = validation.filter(({ condition }) => !condition);
    const isValid = failedConditions.length === 0;
    
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const disabled = !isValid || isLoading;
    
    const handleDownload = async () => {
        if (disabled) return;

        try {
            setIsLoading(true);

            const { downloadRequest } = onClick({ filterParams });
            const { data: blob } = await downloadRequest;

            const date = new Date();
            const timestamp = format(date, "yyyyMMddHHmmss");
            const { filename } = onBeforeSave({ date, timestamp });

            const link = document.createElement("a");
            link.download = filename;
            link.href = window.URL.createObjectURL(blob);
            link.click();
        } catch (e) {
            dispatch({ type: "ALERT_TOAST_ERROR", payload: { message: e?.message ?? 'Download Failed' } })
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <Button
                id="downloadCsv"
                outline={!disabled}
                color={disabled ? "secondary" : "primary"}
                className="d-flex align-items-center"
                style={{
                    gap: "8px"
                }}
                onClick={handleDownload}
                disabled={disabled}
            >
                {isLoading
                    ? (
                        <span style={{ paddingInline: "4px" }}>
                            <Spinner size="sm" color="primary" />
                        </span>
                    )
                    : <DownloadFilledIcon color={disabled ? "white" : "#1178D4"} size={24} />}
                <span className={disabled ? "text-white" : ""} style={{ marginTop: "2px" }}>Download CSV</span>
            </Button>
            {!isValid && (
                <UncontrolledTooltip
                    placement="bottom"
                    target="downloadCsv"
                >
                    {failedConditions[0].message}
                </UncontrolledTooltip>
            )}
        </>
    );
};

export default DownloadCsv;
