import React, { useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, Row, Col, CardHeader, CardBody, Label, Button, Spinner } from 'reactstrap';
import { useFetch, useAction } from 'hooks';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import Form from 'components-form/Form';
import Select from 'components-form/Select';
import Error404 from 'page/error/Error404';
import { DatePicker } from 'components-form';
import { format } from 'date-fns';
import { useFormContext } from 'react-hook-form';

const rootStyle = (variable) => {
    return getComputedStyle(document.body).getPropertyValue(variable)
}


const theme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: rootStyle('--light'),
        primary: rootStyle('--primary'),
    }
})

const styles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#e4e7ea' : provided.backgroundColor,
        border: '1px solid #DBDCDD',
        boxShadow: 'none',
        '&:hover': {
            borderColor: state.selectProps.readonly ? '#DBDCDD' : rootStyle('--primary')
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0.475rem 0.625rem',
        height: 'calc(1.5em + 1.25rem + 5px)'
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
    })
}

const App = ({
    title: documentTitle,
    history
}) => {
    document.title = documentTitle;

    const params = useParams();
    const { hasFetch } = useAction();
    const isCreate = params?.id ? false : true;
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
    const dataBreadcrumb = isCreate ?
        [
            { to: '/delivery-warehouse', label: 'Antar ke Warehouse/KBI' },
            { to: null, label: 'Buat Pengiriman Baru' }
        ]
        : [
            { to: '/delivery-warehouse', label: 'Antar ke Warehouse/KBI' },
            { to: `/delivery-warehouse/${params.id}`, label: 'Detail Pengiriman' },
            { to: null, label: 'Edit Pengantaran' }
        ]

    /* fecthing data for detail page */
    const { data, status, error } = useFetch(
        `/api/back-office/trip-plan/delivery/${params?.id}`,
        {},
        useCallback(data => ({
            data: {
                ...data?.data
            }
        }), []),
        { onMount: !isCreate }
    )
    const onSubmit = (input) => {
        setIsLoadingSubmit(true);
        const id = params?.id;
        const requestBody = {
            assignmentDate: format(new Date(input?.tanggal), 'yyyy-MM-dd'),
            destinationId: input?.warehouseId,
            driverId: input.driverId
        };
        const action = ({ requestBody, id = null }) => isCreate
            ? Services().post('/api/back-office/trip-plan/delivery', requestBody)
            : Services().post(`/api/back-office/trip-plan/delivery/update`, { ...requestBody, id: parseInt(id) })

        action({ requestBody, id: params?.id })
            .then(() => {
                setIsLoadingSubmit(false);
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: isCreate ? `Pengantaran baru berhasil ditambah` : `Penugasan berhasil diubah` }
                })
                history.push(isCreate ? '/delivery-warehouse' : `/delivery-warehouse/${id}`);
            })
            .catch(e => {
                setIsLoadingSubmit(false);
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Cannot update ${id}` } })
            })
    }
    const defaultValues = {
        tanggal: data?.assignmentDate ? new Date(data?.assignmentDate) : null,
        warehouseId: data?.warehouseId ?? "",
        driverId: data?.driverId ?? "",
    }

    if ((status === 'rejected' && error.code === 400) || (status === 'resolved' && (data === null || data?.status !== "waiting_driver"))) return <Error404 />
    return (status === 'resolved' || isCreate) && (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>{isCreate ? "Tambah" : "Edit"} Rute Perjalanan</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Form id="penggunaForm" onSubmit={onSubmit} autoComplete="off" defaultValues={defaultValues}>
                <Card>
                    <CardHeader className="pb-0">
                        Informasi Admin
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col className='d-flex flex-column' style={{ gap: "1rem" }}>
                                <div className='d-flex align-items-center'>
                                    <Label htmlFor="nama_lengkap" sm={3}>Tanggal Penugasan</Label>
                                    <Col sm={9}>
                                        <DatePicker
                                            id="date"
                                            validation={['required']}
                                            name="tanggal"
                                            minDate={new Date()}
                                            placeholderText="Pilih Tanggal"
                                        />
                                    </Col>
                                </div>
                                <div className='py-3 px-3' style={{ fontSize: 16, fontWeight: 500 }}>Rute Tujuan</div>
                                <div className='d-flex align-items-center'>
                                    <Label htmlFor="username" sm={3}>Tempat Tujuan</Label>
                                    <Col sm={9}>
                                        <WarehouseField />
                                    </Col>
                                </div>
                                <div className='d-flex align-items-center'>
                                    <Label htmlFor="email" sm={3}>Pilih Driver</Label>
                                    <Col sm={9}>
                                        <DriverField />
                                    </Col>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Row>
                    <Col className="text-right">
                        <Link to={(isCreate) ? "/delivery-warehouse" : `/delivery-warehouse/${params.id}`}>
                            <Button type="button" color="secondary" className="m-1" onClick={() => window.history.back()}>
                                Batal
                            </Button>
                        </Link>
                        {isCreate ? <Submitted isLoadingSubmit={isLoadingSubmit} /> :
                            <Button type="submit" color="primary" className="m-1" disabled={isLoadingSubmit}>
                                {isLoadingSubmit &&
                                    <span className='mr-2'>
                                        <Spinner size="sm" />
                                    </span>}
                                {'Simpan'}
                            </Button>}
                    </Col>
                </Row>
            </Form>
        </>
    )
}


export const Submitted = ({ isLoadingSubmit }) => {
    const { formState: { isValid } } = useFormContext();
    return (
        <Button disabled={!isValid || isLoadingSubmit} type="submit" color={!isValid ? "secondary" : "primary"}>
            {isLoadingSubmit &&
                <span className='mr-2'>
                    <Spinner size="sm" />
                </span>}
            Tugaskan
        </Button>
    )
}

export const WarehouseField = () => {
    const { data, loading } = useFetch(
        `/api/back-office/warehouse`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );
    return (
        <Select
            name="warehouseId"
            id="warehouseId"
            loading={loading}
            validation={['required']}
            placeholder="Pilih Tujuan"
            options={data?.map(item => ({ value: item?.id, label: item?.name, code: item?.code }))}
            className="basic-single"
            isClearable={true}
            theme={theme}
            styles={styles}
        />
    )
}

export const DriverField = () => {
    const { data, loading } = useFetch(
        `/api/back-office/driver/warehouse/assignment`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );
    return (
        <Select
            name="driverId"
            id="driverId"
            loading={loading}
            placeholder="Pilih Driver"
            validation={['required']}
            options={data?.map(item => ({ value: item?.id, label: item?.name, code: item?.code }))}
            className="basic-single"
            isClearable={true}
            theme={theme}
            styles={styles}
        />
    )
}


export default App