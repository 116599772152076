import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormFeedback } from 'reactstrap'
// import { get } from 'lodash'
import ReactSelect from 'react-select/async';
import validationRule from 'helper/ValidationRule';
const get = (obj, path, def) => (() => typeof path === 'string' ? path.replace(/\[(\d+)]/g, '.$1') : path.join('.'))()
    .split('.')
    .filter(Boolean)
    .every(step => ((obj = obj[step]) !== undefined)) ? obj : def

export const rootStyle = (variable) => {
    return getComputedStyle(document.body).getPropertyValue(variable)
}

export const theme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: rootStyle('--light'),
        primary: rootStyle('--primary'),
    }
})

export const styles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#e4e7ea' : provided.backgroundColor,
        border: '1px solid #DBDCDD',
        boxShadow: 'none',
        '&:hover': {
            borderColor: state.selectProps.readonly ? '#DBDCDD' : rootStyle('--primary')
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0.475rem 0.625rem',
        // height: 'calc(1.5em + 1.25rem + 5px)'
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
    })
}

export const isValidValue = value => Array.isArray(value)
    ? !value?.map(isValidValue)?.includes(false)
    : value?.hasOwnProperty('label') && value?.hasOwnProperty('value') && ![value?.label, value?.value].includes('')

const Select = ({
    validation = [],
    validationMessage = [],
    options = [],
    name,
    defaultValue = null,
    shouldUnregister = false,
    ...props
}) => {
    const { control, setValue, trigger, getValues, ...otherForm } = useFormContext()
    return (
        <Controller
            shouldUnregister={shouldUnregister}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validationRule({ rule: validation, validationMessage }, getValues, name)}
            render={({ field: { value, ...fieldInput }, formState: { errors } }) => {
                return (<>
                    <ReactSelect
                        {...props}
                        {...fieldInput}
                        
                        options={options}
                        value={
                            (Array.isArray(value) ?
                                options.filter((obj) => value.includes(obj.value))?.sort((obj1, obj2) => {
                                    let index1 = value.indexOf(obj1.value);
                                    let index2 = value.indexOf(obj2.value);
                                    return index1 - index2;
                                }) :
                                (value !== null && value !== undefined && value !== "") ?
                                    options.filter(obj => obj.value.toString() === value.toString())?.[0] : null)}
                        onChange={selected => {
                            /* in case for multiple select */
                            if (Array.isArray(selected)) {
                                selected = selected.length > 0 ? selected : null;
                                let value_new = selected?.map(obj => (typeof obj === 'object') ? obj.value : obj);
                                setValue(name, value_new)
                            } else {
                                setValue(name, selected?.value ?? null)
                            }
                            trigger(name);
                            props.onChange && props.onChange(selected, { setValue, trigger, ...otherForm })
                        }}
                        className={get(errors, name) && "is-invalid select__control"}
                        classNamePrefix="select"
                        theme={theme}
                        styles={styles}
                    />
                    {get(errors, name) && <FormFeedback invalid="true" style={{ display: 'block' }}>{get(errors, name)?.message}</FormFeedback>}
                </>)
            }}
        />
    )
}

export default Select