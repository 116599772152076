import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Pagination as Paginations,
    PaginationItem,
    PaginationLink,
    Input,
} from "reactstrap";
import ReactSelect from "react-select";

const propTypes = {
    onChangePage: PropTypes.func.isRequired,
    totalPage: PropTypes.number,
    currentPage: PropTypes.number,
};

const optionsPageSizeDefault = [
    { label: 10, value: 10 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 500, value: 500 },
    { label: 1000, value: 1000 }
];

const Paginator = ({
    currentPage,
    totalPage,
    limitPage,
    onChangePage,
    options = [],
    showRowsPerPage = true,
    withSelector = false,
    optionsPageSize = [...optionsPageSizeDefault]
}) => {
    const [state, setState] = useState({
        currentPage: 0,
        totalPage: 0,
        limitPage: 0,
        page: 0,
        size: 0,
    });
    if (options.length === 0) {
        options = [
            { label: 10, value: 10 }, { label: 30, value: 30 }, { label: 50, value: 50 }, { label: 100, value: 100 }, { label: 500, value: 500 }, { label: 1000, value: 1000 }
        ]
    }
    useEffect(() => {
        if (currentPage || totalPage || limitPage) {
            setState({
                currentPage,
                totalPage,
                limitPage,
                page: currentPage,
                size: limitPage,
            });
        }
    }, [currentPage, totalPage, limitPage]);

    const setPage = (e, page) => {
        e.preventDefault();
        const newState = { ...state, currentPage: page, page };
        setState({ ...newState });
        onChangePage(newState);
    };

    const handlePageSize = ({ value }) => {
        const newState = {
            ...state,
            currentPage: 1,
            limitPage: value,
            page: 1,
            size: value
        };
        setState({ ...newState });
        onChangePage(newState);
    };

    const handleChange = ({ target: { value } }) => {
        const newState = {
            ...state,
            currentPage: 1,
            limitPage: value,
            page: 1,
            size: value,
        };
        setState({ ...newState });
        onChangePage(newState);
    };
    const setTotalPaging = (currentPage, totalPage) => {
        const fillRange = (start, end) =>
            Array(end - start + 1)
                .fill()
                .map((item, index) => start + index);

        let temp = fillRange(0, totalPage - 1);

        let startPage, endPage;
        if (totalPage <= 7) {
            startPage = 0;
            endPage = totalPage;
        } else {
            if (currentPage <= 4) {
                startPage = 0;
                endPage = 7;
            } else if (currentPage + 3 >= totalPage) {
                startPage = totalPage - 7;
                endPage = totalPage;
            } else {
                startPage = currentPage - 4;
                endPage = currentPage + 3;
            }
        }
        return (temp = temp.slice(startPage, endPage));
    };

    if (!state.totalPage || state.totalPage <= 0) {
        return null;
    }
    const pagingList = setTotalPaging(state.currentPage, state.totalPage);

    return (
        <div className="d-md-flex justify-content-between w-100">
            <div className="d-flex align-items-center mb-3 mb-md-0 justify-content-end justify-content-md-start">
                {
                    showRowsPerPage &&
                    <>
                        <div className="flex-1 mr-2">
                            Tampilkan per halaman:
                        </div>
                        <Input
                            type="select"
                            name="pageLimit"
                            className="pull-left w-auto"
                            value={state.limitPage}
                            onChange={handleChange}
                        >
                            {
                                options.map((data, key) =>
                                    <option key={key} value={data.label}>{data.value}</option>
                                )
                            }
                        </Input>
                    </>
                }
            </div>
            <div>
                <Paginations className="d-flex justify-content-end justify-content-md-start">
                    <PaginationItem className={state.currentPage === 1 ? "disabled" : ""}>
                        <PaginationLink onClick={(e) => setPage(e, 1)}>First</PaginationLink>
                    </PaginationItem>
                    <PaginationItem className={state.currentPage === 1 ? "disabled" : ""}>
                        <PaginationLink
                            previous
                            onClick={(e) => setPage(e, state.currentPage - 1)}
                        >
                            <i className="fa fa-chevron-left" />
                        </PaginationLink>
                    </PaginationItem>

                    {pagingList.map((object, i) => (
                        <PaginationItem
                            key={i}
                            className={state.currentPage === object + 1 ? "active" : ""}
                        >
                            <PaginationLink
                                onClick={(e) => setPage(e, object + 1)}
                                disabled={state.currentPage === object + 1 ? true : false}
                            >
                                {object + 1}
                            </PaginationLink>
                        </PaginationItem>
                    ))}

                    <PaginationItem
                        className={state.currentPage === state.totalPage ? "disabled" : ""}
                    >
                        <PaginationLink next onClick={(e) => setPage(e, state.currentPage + 1)}>
                            <i className="fa fa-chevron-right" />
                        </PaginationLink>
                    </PaginationItem>
                    <PaginationItem
                        className={state.currentPage === state.totalPage ? "disabled" : ""}
                    >
                        <PaginationLink className="mr-0" onClick={(e) => setPage(e, state.totalPage)}>
                            Last
                        </PaginationLink>
                    </PaginationItem>
                    {withSelector && (
                        <div className="size-selector ml-2" style={{ width: state.size.toString().length <= 3 ? 80 : 90, height: 32 }}>
                            <SelectPage
                                name="rowsPerPage"
                                type="text"
                                isClearable={false}
                                isSearchable={false}
                                placeholder={state?.size}
                                onChange={(e) => handlePageSize(e)}
                                options={optionsPageSize}
                            />
                        </div>
                    )}
                </Paginations>
            </div>
        </div>
    );
};

const customStyles = {
    control: (baseStyles, state) => ({
        ...baseStyles,
        minHeight: '34px',
        border: '1px solid #eff3f6',
        color: '#0058B1',
        cursor: 'pointer',
        boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 88, 177, 0.25)' : 'none'
    }),
    placeholder: (baseStyles) => ({
        ...baseStyles,
        color: '#0058B1'
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: '#0058B1'
    })
};

const SelectPage = React.forwardRef(({ name, options, placeholder = '', disabled, bsSize = 'md', ...props }, ref) => (
    <ReactSelect
        ref={ref}
        name={name}
        options={options}
        bsSize={bsSize}
        className="basic-single input-pills"
        classNamePrefix="select-pagination"
        isDisabled={disabled}
        placeholder={placeholder}
        isClearable={props?.isClearable}
        noOptionsMessage={() => 'Tidak ada opsi'}
        components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => <span className="mr-2 px-1"><i className="fa fa-chevron-down" /></span>
        }}
        styles={customStyles}
        theme={(theme) => ({
            ...theme,
            colors: {
                ...theme.colors,
                primary25: '#DCEBFB',
                primary: '#1178D4'
            }
        })}
        menuPlacement="top"
        {...props}
    />
));

Paginator.propTypes = propTypes;
export default Paginator;
