import React, { useCallback } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Input as ReactStrapInput, FormFeedback, InputGroup } from 'reactstrap'
import validationRule from 'helper/ValidationRule';
import { debounce } from 'helper';
const get = (obj, path, def) => (() => typeof path === 'string' ? path.replace(/\[(\d+)]/g, '.$1') : path.join('.'))()
    .split('.')
    .filter(Boolean)
    .every(step => ((obj = obj[step]) !== undefined)) ? obj : def

const defaultTransform = {
    output: val => val,
    input: val => val,
}

const InputDebounceNumber = ({
    validation = [],
    validationMessage = [],
    inputGroupAddon = null,
    name,
    defaultValue = '',
    shouldUnregister = false,
    transform = defaultTransform,
    fetch = () => null,
    ...props
}) => {
    const { control, getValues, setValue } = useFormContext()

    const transformer = { ...defaultTransform, ...transform }

    const setParamsSearch = useCallback(debounce((name, value, callback) => {
        setValue(name, transformer.input(value), { shouldValidate: true });
        fetch({ value });
    }, 500), [])

    const handleInputChange = async (e, callback) => {
        let name = e.target.name;
        let value = e.target.value;
        setParamsSearch(name, value, callback);
    };

    return (
        <Controller
            shouldUnregister={shouldUnregister}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validationRule({ rule: validation, validationMessage }, getValues, name)}
            render={({
                field: { ref, onChange, value, ...fieldInput },
                formState: { errors } }) => {
                const regexNumber = value?.toString()?.replace(/[^\d,]/g, '')
                const finalNumber = (number) => regexNumber !== "" ? number : "";
                return (
                    <InputGroup>
                        <ReactStrapInput
                            invalid={!!get(errors, name)}
                            innerRef={ref}
                            {...fieldInput}
                            maxLength={250}
                            onChange={e => {
                                handleInputChange(e);
                                onChange(transformer.output(e))
                            }}
                            value={transformer.input(finalNumber(regexNumber))}
                            {...props} />
                        {inputGroupAddon}
                        {get(errors, name) && <FormFeedback invalid={"true"} style={{ display: 'block' }}>{get(errors, name)?.message}</FormFeedback>}
                    </InputGroup>
                )
            }}
        />
    )
}

export default InputDebounceNumber