import React, { createContext, useCallback, useContext, useState } from 'react'
import {
    CardBody, Row, Col,
    Label,
    Button,
    Badge,
    Spinner,
} from 'reactstrap';
import {
    useParams
} from 'react-router-dom';
import { Breadcrumb } from 'components';
import { useFetch } from 'hooks';
import Error404 from 'page/error/Error404';
import { addHours, format } from 'date-fns';
import icScan from 'assets/svg/ic_scan.svg';
import icRegular from 'assets/svg/ic_Regular.svg';
import TableRowDetail from './row-table-detail';
import ModalFormsQR from './modal-qr';
import ModalFormsQRSolve from './modal-qr-solve';
import { Services } from 'service';
import { useDispatch } from 'react-redux';
import ModalPage from 'components/Modal';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import icCancel from 'assets/svg/ic_cancel_red.svg';

const getColorStatus = (status) => {
    switch (status) {
        case "waiting_driver":
            return <Badge color="info" className='text-10 text-white p-1'>
                Menunggu Driver
            </Badge>
        case "picking_up_process":
            return <Badge color="warning" className='text-10 text-white p-1'>
                Dalam Perjalanan
            </Badge>
        case "completed":
            return <Badge color="success" className='text-10 text-white p-1'>
                Selesai
            </Badge>
        case "canceled_by_admin":
            return <Badge color="danger" className='text-10 text-white p-1'>
                Dibatalkan Admin
            </Badge>
        default:
            return status
    }
}

export const ModalConfirm = createContext()
const App = ({
    title: documentTitle,
    access: permissionAccess,
    history
}) => {
    document.title = documentTitle;
    const [open, setOpen] = useState(false)
    const [openForm, setOpenForm] = useState(false)
    const [confirm, setconfirm] = useState(false)
    const { id } = useParams();

    const dataBreadcrumb = [
        { to: '/pickup', label: 'Penjemputan' },
        { to: null, label: 'Detail Jemput' }
    ]

    const { data,
        status, error } = useFetch(
            `/api/back-office/trip-plan/pickup/${id}`,
            {},
            useCallback(data => ({ data: data?.data }), [])
        )
    const { data: outlet } = useFetch(
        `/api/back-office/trip-plan/pickup/${id}/outlets`,
        {},
        useCallback(data => ({ data: data?.data?.content }), [])
    )
    const formatNumber = (number) => new Intl.NumberFormat('id-ID').format(number)
    if ((status === 'rejected' && error.code === 400) ||
        (status === 'resolved' && data === null)) return <Error404 />
    return status === 'resolved' && (
        <>
            <Row className="mb-5">
                <Col>
                    <div className="d-inline-block">
                        <h2>Rute Perjalanan</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <div className="d-flex flex-wrap" style={{ columnGap: "6rem", rowGap: "2rem" }}>
                <div>
                    <Label>Nama Driver</Label>
                    <div>
                        <h4>{data?.driverName ?? ""}</h4>
                    </div>
                </div>
                <div>
                    <Label>Jumlah Outlet</Label>
                    <div className='d-flex' style={{ gap: "4px" }}>
                        <h4>{data?.completedOutlet ?? ""}</h4>
                        <h4>/</h4>
                        <h5 className='text-sm align-items-center d-flex'>{data?.totalOutlet}</h5>
                    </div>
                </div>
                <div>
                    <Label>Jeriken 18 L</Label>
                    <div className='d-flex' style={{ gap: "4px" }}>
                        <h4>{data?.oilPickupSummary?.containerPickedUpDetails?.filter(item => item.type === "jeriken")?.find(item => item.volume === "18")?.qty ?? ""}</h4>
                        <h4>/</h4>
                        <h5 className='text-sm align-items-center d-flex'>{data?.oilPickupSummary?.containerTotalDetails?.filter(item => item.type === "jeriken")?.find(item => item.volume === "18")?.qty ?? ""}</h5>
                    </div>
                </div>
                <div>
                    <Label>Jeriken 20 L</Label>
                    <div className='d-flex' style={{ gap: "4px" }}>
                        <h4>{data?.oilPickupSummary?.containerPickedUpDetails?.filter(item => item.type === "jeriken")?.find(item => item.volume === "20")?.qty ?? ""}</h4>
                        <h4>/</h4>
                        <h5 className='text-sm align-items-center d-flex'>{data?.oilPickupSummary?.containerTotalDetails?.filter(item => item.type === "jeriken")?.find(item => item.volume === "20")?.qty ?? ""}</h5>
                    </div>
                </div>
                <div>
                    <Label>Jumlah Minyak</Label>
                    <div className='d-flex' style={{ gap: "4px" }}>
                        <h4>{data?.oilPickupSummary?.oilPickedUpWeight ?? "-"}</h4>
                        <h4>/</h4>
                        <h5 className='text-sm align-items-center d-flex'>{data?.oilPickupSummary?.totalOilWeight}</h5>
                    </div>
                </div>
                <div>
                    <Label>Jumlah Penjualan</Label>
                    <div className='d-flex' style={{ gap: "4px" }}>
                        <h4>{formatNumber(data?.cashPickupSummary?.completeSalesPrice) ?? ""}</h4>
                        <h4>/</h4>
                        <h5 className='text-sm align-items-center d-flex'>{formatNumber(data?.cashPickupSummary?.price)}</h5>
                    </div>
                </div>
                <div>
                    <Label>Jumlah Uang Dibawa</Label>
                    <div>
                        <h4>{formatNumber(data?.cashPickupSummary?.assignmentCash) ?? ""}</h4>
                    </div>
                </div>
            </div>
            <hr className='my-3' />
            <div className='d-flex flex-wrap justify-content-between' style={{ rowGap: "2rem" }}>
                <div className='d-flex flex-wrap' style={{ columnGap: "6rem", rowGap: "2rem" }}>
                    <div>
                        <Label>Status</Label>
                        <div>
                            {getColorStatus(data?.status) ?? ""}
                        </div>
                    </div>
                    <div>
                        <Label>No Tiket</Label>
                        <div>
                            {data?.tripPlanCode ?? ""}
                        </div>
                    </div>
                    <div>
                        <Label>Tanggal Penugasan</Label>
                        <div>
                            {format(addHours(new Date(data?.assignmentDate), 7), "dd/MM/yyyy") ?? ""}
                        </div>
                    </div>
                    {data?.status === "completed" && <>
                        <div>
                            <Label>Rugi</Label>
                            <div>
                                {formatNumber(data?.cashPickupSummary?.lossCash) ?? ""}
                            </div>
                        </div>
                        <div>
                            <Label>Setor Kas</Label>
                            <div>
                                {formatNumber(data?.cashPickupSummary?.refund) ?? ""}
                            </div>
                        </div>
                    </>
                    }
                </div>
                <div>
                    {data?.status === "waiting_driver" &&
                        <div className='d-flex'>
                            {permissionAccess?.delete && (
                                <Button onClick={() => setconfirm(!confirm)} color='danger' size='sm' type="button" outline className="btn-cancel">
                                    <div className='d-flex align-items-center'>
                                        <img className='ml-1' src={icCancel} alt="driver" style={{ height: 10 }} />
                                        <span style={{ lineHeight: 2 }} className='pl-2'>Batalkan Penugasan</span>
                                    </div>
                                </Button>
                            )}
                            <Button onClick={() => setOpen(!open)} color='primary' size='sm' type="button">
                                <div className='d-flex align-items-center'>
                                    <img className='ml-1' src={icScan} alt="driver" />
                                    <span className='pl-1'>Tampilkan QR Code</span>
                                </div>
                            </Button>
                        </div>}
                    {data?.status === "picking_up_process" &&
                        <div className='d-flex'>
                            {permissionAccess?.update && (
                                <Button onClick={() => setOpenForm(!open)} color='primary' size='sm' type="button">
                                    <div className='d-flex'>
                                        <img className='ml-1' src={icRegular} alt="driver" />
                                        <span style={{ lineHeight: 2 }} className='pl-1'>Penyelesaian</span>
                                    </div>
                                </Button>
                            )}
                        </div>}
                </div>
            </div>
            <CardBody className='py-4 px-0'>
                <Label style={{ fontSize: 15 }}>Daftar Outlet</Label>
                <TableRowDetail raw={outlet ?? []} />
            </CardBody>
            {/* Modal Element */}
            <ModalFormsQR open={open} close={() => setOpen(!open)} />
            <ModalConfirm.Provider value={{
                confirm,
                setconfirm,
                open: openForm,
                close: () => setOpenForm(!openForm),
                raw: data,
            }}>
                <ModalFormsQRSolve />
                <ConfirmationModal />
            </ModalConfirm.Provider>
        </>
    )
}

export const ConfirmationModal = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const { id } = useParams()
    const { confirm: open, setconfirm: setopen } = useContext(ModalConfirm)
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const cancelDriver = () => {
        setIsLoadingSubmit(true);
        Services().post(`/api/back-office/trip-plan/pickup/cancel/${id}`)
            .then(response => {
                setIsLoadingSubmit(false);
                dispatch({ type: "ALERT_TOAST_SUCCESS", payload: { message: "Penugasan telah dibatalkan" } })
                history.push('/pickup')
            })
            .catch(err => {
                setIsLoadingSubmit(false);
                dispatch({ type: "ALERT_TOAST_ERROR", payload: { message: err?.message ?? 'Failed' } })
            })
    };

    return (
        <ModalPage
            isOpen={open}
            handleClose={() => { setopen(!open) }}
            component={<>
                <h3>Batalkan Penugasan ?</h3>
                <div className="my-4">
                    Tugas dari rute perjalanan ini akan dibatalkan
                    oleh admin
                </div>
                <div className='text-right mt-4'>
                    <Button onClick={() => { setopen(!open) }} size='sm' type="button" color="secondary" >
                        Kembali
                    </Button>
                    <Button className='font-weight-bold' onClick={cancelDriver} size='sm' type="button" color="primary" disabled={isLoadingSubmit}>
                        {isLoadingSubmit &&
                            <span className='mr-2'>
                                <Spinner size="sm" />
                            </span>}
                        Konfirmasi
                    </Button>
                </div>
            </>}
        />
    )
}

export default App