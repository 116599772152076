import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";

import SmallButton from "components/SmallButton";
import { showToast } from "hooks";
import { Services } from "service";

import { DetailContext } from "../detail";
import DetailImagePreview from "./DetailImagePreview";

const apiClient = Services();

export const useReuploadPurchaseNote = () => {
    const config = useState(null);
    const [, setState] = config;

    const show = (mode, payload) => setState({ mode, payload });

    return {
        config,
        show,
    };
};

const ALLOWED_MIME_TYPES = [
    "image/jpeg",
    "image/png",
];

const ACCEPTED_FILES = ALLOWED_MIME_TYPES.join(", ");
const EXTENSIONS = ALLOWED_MIME_TYPES
    .map((mime) => `.${mime.split("/")[1]}`)
    .join(", ");

const MAX_FILE_SIZE_MB = 3;
const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

const ModalReuploadPurchaseNote = ({ config, onSucceed }) => {
    const { id } = useParams();
    const [state, setState] = config;
    const [uploading, setUploading] = useState(false);

    const confirming = state?.mode === "confirm";
    const previewing = state?.mode === "preview";

    const close = ({ onClosed }) => {
        setState((prev) => ({ ...prev, onClosed, mode: null }));
    };

    const invalidFile = (message, info) => {
        setState({
            mode: "confirm",
            payload: {
                invalid: {
                    message,
                    info,
                }
            }
        });
    };

    const handleFileChange = (e) => {
        const [file] = e.target.files;

        if (!file) {
            return;
        } else if (file.size > MAX_FILE_SIZE_BYTES) {
            invalidFile("Ukuran file terlalu besar", `Ukuran maksimal ${MAX_FILE_SIZE_MB}MB`);
        } else if (!ALLOWED_MIME_TYPES.includes(file.type)) {
            invalidFile("Tipe file salah", `Ekstensi yang diperbolehkan: ${EXTENSIONS}`);
        } else {
            const objectUrl = URL.createObjectURL(file);

            setState({
                mode: "preview",
                payload: {
                    file,
                    objectUrl,
                },
            })
        }
    };

    const handleUpload = () => {
        if (uploading) return;
        setUploading(true);

        const formData = new FormData();
        formData.append("file", state.payload.file);

        apiClient.post("/api/image/purchase-pow", formData)
            .then((uploadRes) => {
                const payload = {
                    id,
                    purchasePow: uploadRes?.data?.data?.storageFilePath,
                }

                apiClient.post("/api/back-office/direct-purchase/edit-purchase-pow", payload)
                    .then(() => {
                        showToast({ name: "success", message: "Penjualan langsung berhasil diperbarui" });
                        close({ onClosed: onSucceed });
                    })
                    .catch((editErr) => {
                        showToast({ name: "error", message: editErr?.message ?? "Penjualan langsung gagal diperbarui" });
                    })
                    .finally(() => {
                        setUploading(false);
                    });
            })
            .catch((uploadErr) => {
                showToast({ name: "error", message: uploadErr?.message ?? "Gambar bukti pembelian gagal diunggah" });
                setUploading(false);
            })
    };

    return (
        <Modal
            centered
            size="sm"
            backdrop="static"
            keyboard={!uploading}
            returnFocusAfterClose={false}
            isOpen={Boolean(state?.mode)}
            toggle={close}
            onClosed={() => {
                if (state?.onClosed) {
                    state.onClosed();
                }

                setState(null);
            }}
        >
            <ModalBody className="pb-3">
                <h5 className="b1 mb-4" style={{ fontWeight: 600 }}>
                    {(confirming || previewing)
                        ? "Ubah Gambar Bukti Pembelian"
                        : null}
                </h5>
                <div>
                    {confirming
                        ? (
                            <>
                                <p className="mb-2">Apakah anda ingin mengubah gambar bukti pembelian ?</p>
                                {state?.payload?.invalid && (
                                    <small className="text-danger d-flex align-items-center">
                                        {state.payload.invalid.message}
                                        <span className="ml-1 text-muted text-10">({state.payload.invalid.info})</span>
                                    </small>
                                )}
                            </>
                        )
                        : previewing
                            ? (
                                <>
                                    <p className="mb-2">Detail gambar yang diubah :</p>
                                    <PreviewImage
                                        disabled={uploading}
                                        src={state.payload.objectUrl}
                                        name={state.payload.file.name}
                                    />
                                </>
                            )
                            : null}
                </div>
            </ModalBody>
            <ModalFooter className="pt-0 pb-3">
                {confirming
                    ? (
                        <>
                            <SmallButton outline color="primary" onClick={close}>
                                Kembali
                            </SmallButton>
                            <label
                                htmlFor="new-purchase-note"
                                className="m-0 btn btn-primary"
                                style={{ paddingBlock: "6px", cursor: "pointer" }}
                            >
                                Konfirmasi
                                <input
                                    type="file"
                                    className="d-none"
                                    id="new-purchase-note"
                                    accept={ACCEPTED_FILES}
                                    onChange={handleFileChange}
                                />
                            </label>
                        </>
                    )
                    : previewing
                        ? (
                            <>
                                <SmallButton
                                    onClick={close}
                                    disabled={uploading}
                                    outline={!uploading}
                                    color={uploading ? "secondary" : "primary"}
                                >
                                    Batal
                                </SmallButton>
                                <SmallButton
                                    color="primary"
                                    disabled={uploading}
                                    className={uploading ? "position-relative" : ""}
                                    onClick={handleUpload}
                                >
                                    {uploading && <Spinner size="sm" className="position-absolute" style={{ top: "25%", left: "42%" }} />}
                                    <span className={uploading ? "invisible" : ""}>Simpan</span>
                                </SmallButton>
                            </>
                        )
                        : null}
            </ModalFooter>
        </Modal>
    );
};

const PreviewImage = ({ src, name, disabled }) => {
    const [openImage, setopenImage] = useState(false);

    return (
        <DetailContext.Provider value={{
            openImage,
            setopenImage,
            images: src,
        }}>
            <DetailImagePreview />

            <figure
                className="text-center mb-0"
                style={{ opacity: disabled ? 0.5 : undefined }}
            >
                <img
                    src={src}
                    alt={name}
                    className="w-100 mb-2 border border-light rounded-lg"
                    style={{ height: "200px", objectFit: "cover", backgroundColor: "#151823", cursor: disabled ? undefined : "pointer" }}
                    onClick={() => {
                        if (disabled) return;
                        setopenImage(true);
                    }}
                />
                <figcaption className="text-primary b1">{name}</figcaption>
            </figure>
        </DetailContext.Provider>
    );
};

export default ModalReuploadPurchaseNote;
