import React, { createContext, useCallback, useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, Row, Col, CardHeader, CardBody, FormGroup, Label, Button, Spinner } from 'reactstrap';
import { useFormContext } from 'react-hook-form';
import { components } from 'react-select';
import { useFetch, useAction } from 'hooks';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import Form from 'components-form/Form';
import Input from 'components-form/Input';
import Select from 'components-form/Select';
import Error404 from 'page/error/Error404';
import IconPinPoint from 'assets/svg/ic_pin-point.svg';
import { Switches, TextArea } from 'components-form';
import { capitalize } from 'helper';
import { INDOMARCO_CODE } from './constants';
import ModalPilihLocation from './components/ModalPilihLocation';
import axios from 'axios';

const latLongValidation = (value) => {
    const regex = /^-?(\d{1,3}\.?\d*)$/;
    const isValid = regex.test(value);

    return isValid ? null : 'Format tidak benar';
};

export const AddressContext = createContext();
const App = ({
    title: documentTitle,
    history
}) => {
    document.title = documentTitle;

    const params = useParams();
    const { hasFetch } = useAction();
    const id = params?.id;
    const isCreate = id ? false : true;
    const [dataAddress, setDataAddress] = useState(null);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [loadingLocation, setLoadingLocation] = useState(false);

    const dataBreadcrumb = isCreate ?
        [
            { to: '/stockpoint', label: 'Master Data Stockpoint' },
            { to: null, label: 'Tambah Stockpoint' }
        ]
        : [
            { to: '/stockpoint', label: 'Master Data Stockpoint' },
            { to: `/stockpoint/${id}`, label: 'Detail Stockpoint' },
            { to: null, label: 'Edit Stockpoint' }
        ];

    /* fecthing data for detail page */
    const { data, status, error } = useFetch(
        `/api/back-office/stockpoint/${params?.id}`,
        {},
        useCallback(data => ({
            data: {
                ...data?.data
            }
        }), []),
        { onMount: !isCreate }
    );

    const defaultValues = {
        active: data?.active,
        branch: data?.branchId ?? '',
        branchName: data?.branchName ?? '',
        stockpointName: data?.name ?? '',
        stockpointCodePrefix: data?.code ?? '',
        province: data?.province ? capitalize(data?.province) : '',
        city: data?.city ? capitalize(data?.city) : '',
        address: data?.address ?? '',
        location: data?.location ?? '',
        latitude: data?.latitude ?? '',
        longitude: data?.longitude ?? '',
        paymentMethodCode: data?.paymentMethodCode ?? '',
        financialAccountCode: data?.financialAccountCode ?? '',
        accountCode: data?.accountCode ?? '',
        driverSp: data?.driverSP ?? ''
    };

    const onSubmit = async (input) => {
        setLoadingSubmit(true);

        const action = (requestBody) => isCreate
            ? Services().post('/api/back-office/stockpoint', requestBody)
            : Services().post(`/api/back-office/stockpoint/update`, { ...requestBody, id: parseInt(id) });

        try {
            const payload = {
                active: input.active,
                name: input.stockpointName,
                code: isCreate ? `${input.stockpointCodePrefix}${input.stockpointCode}` : input.stockpointCodePrefix,
                latitude: input.latitude,
                longitude: input.longitude,
                address: input.address,
                location: input.location,
                province: input.province,
                city: input.city
            };

            await action(payload);

            setLoadingSubmit(false);

            hasFetch({
                type: 'ALERT_TOAST_SUCCESS',
                payload: { message: isCreate ? `Data baru berhasil ditambah` : `Data berhasil diperbarui` }
            });

            history.push(isCreate ? '/stockpoint' : `/stockpoint/${id}`);
        } catch (e) {
            setLoadingSubmit(false);
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Tidak berhasil ${id}` } });
        };
    };

    if ((status === 'rejected' && error.code === 400) || (status === 'resolved' && data === null)) return <Error404 />;

    return (status === 'resolved' || isCreate) && (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>{isCreate ? "Tambah" : "Edit"} Stockpoint</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <AddressContext.Provider
                value={{
                    setDataAddress,
                    dataAddress,
                    setLoadingSearch,
                    loadingSearch
                }}
            >
                <Form id="stockpointForm" onSubmit={onSubmit} autoComplete="off" defaultValues={defaultValues}>
                    <Card>
                        <CardHeader className="pb-0">
                            Informasi Stockpoint
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col>
                                    <InformationFields isCreate={isCreate} />
                                    <LocationFields hasFetch={hasFetch} loadingLocation={loadingLocation} setLoadingLocation={setLoadingLocation} />
                                    {!isCreate && (
                                        <OnEditFields />
                                    )}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Row>
                        <Col className="text-right">
                            <Link to={(isCreate) ? "/stockpoint" : `/stockpoint/${params.id}`}>
                                <Button type="button" color="secondary" className="m-1" onClick={() => window.history.back()}>
                                    Batal
                                </Button>
                            </Link>
                            <Button
                                type="submit"
                                color="primary"
                                className="m-1"
                                disabled={loadingSubmit || loadingLocation}
                            >
                                {(loadingSubmit) && (
                                    <span className="mr-2">
                                        <Spinner size="sm" />
                                    </span>
                                )}
                                {(isCreate) ? 'Buat' : 'Simpan'}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </AddressContext.Provider>
        </>
    );
};

const InformationFields = ({ isCreate }) => {
    const { setValue, watch, trigger } = useFormContext();
    const branch = watch('branch');

    // fetch branch list
    const { data: branchOption, loading: isLoadingBranchOption } = useFetch(
        `/api/back-office/branch/list`,
        {},
        useCallback(data => ({
            data: data?.data?.content?.map((branch) => ({
                label: `${branch.code} - ${branch.name}`,
                value: branch.id
            })),
        }), []),
        { withQueryParams: false }
    );

    const handleBranchChange = (e) => {
        setValue('branchName', e?.label.split(' - ')[1]);
        setValue('stockpointCodePrefix', `SP/${INDOMARCO_CODE}/${e?.label.split(' - ')[0]}/`);
        trigger(['branchName', 'stockpointCodePrefix']);
    };

    return (
        <>
            <FormGroup row>
                <Label htmlFor="active" sm={3}>Status</Label>
                <Col sm={9}>
                    <Switches
                        className="custom-switch-success mt-1"
                        name="active"
                        label="Aktif"
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="branch" sm={3}>Branch</Label>
                <Col sm={9}>
                    <Select
                        name="branch"
                        id="branch"
                        placeholder="Pilih Branch"
                        options={branchOption ?? []}
                        validation={['required']}
                        isDisabled={!isCreate}
                        isLoading={isLoadingBranchOption}
                        onChange={handleBranchChange}
                        components={{
                            SingleValue: ({ children, ...props }) => (
                                <components.SingleValue {...props}>
                                    {children?.split(' - ')[0]}
                                </components.SingleValue>
                            )
                        }}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="branchName" sm={3}>Nama Branch</Label>
                <Col sm={9}>
                    <Input
                        id="branchName"
                        validation={['required']}
                        name="branchName"
                        type="text"
                        placeholder="Nama Branch"
                        autoComplete="off"
                        disabled
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="stockpointCode" sm={3}>Kode Stockpoint</Label>
                <Col sm={isCreate ? 4 : 9}>
                    <Input
                        id="stockpointCodePrefix"
                        validation={['required']}
                        name="stockpointCodePrefix"
                        type="text"
                        placeholder="Kode Stockpoint"
                        autoComplete="off"
                        disabled
                    />
                </Col>
                {isCreate && (
                    <Col sm={2}>
                        <Input
                            id="stockpointCode"
                            validation={['required', 'number']}
                            name="stockpointCode"
                            type="text"
                            placeholder="0000"
                            maxLength={4}
                            autoComplete="off"
                            disabled={!branch || !isCreate}
                        />
                    </Col>
                )}
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="stockpointName" sm={3}>Nama Stockpoint</Label>
                <Col sm={9}>
                    <Input maxLength="150" id="stockpointName" validation={['required']} name="stockpointName" type="text" placeholder="SP" autoComplete="off" />
                </Col>
            </FormGroup>
        </>
    );
};

const CancelToken = axios.CancelToken;
const LocationFields = ({ hasFetch, loadingLocation, setLoadingLocation }) => {
    const { setDataAddress } = useContext(AddressContext);
    const [showModal, setShowModal] = useState(false);
    const [cancel, setCancelLocation] = useState(() => () => { });
    const { watch, setValue, trigger } = useFormContext();

    const province = watch('province');
    // const latitude = watch('latitude');
    // const longitude = watch('longitude');

    // fetch province list
    const { data: provinceOption, loading: isLoadingProvinceOption } = useFetch(
        `/api/back-office/coverage-area/province`,
        {},
        useCallback(data => ({
            data: data?.data?.content?.map((province) => ({
                label: province,
                value: province
            })),
        }), []),
        { withQueryParams: false }
    );

    // fetch city list
    const { data: cityOption, loading: isLoadingCityOption } = useFetch(
        `/api/back-office/coverage-area/city/${province}`,
        {},
        useCallback(data => ({
            data: data?.data?.content?.map((city) => ({
                label: city,
                value: city
            })),
        }), []),
        { withQueryParams: false, onMount: province }
    );

    const onLatLongChange = async (e) => {
        const { name, value } = e.target;

        // update and validate lat / long
        setValue(name, value);
        trigger(name);

        // lat long validation
        const latitude = watch('latitude');
        const longitude = watch('longitude');
        if (latLongValidation(latitude) !== null || latLongValidation(longitude) !== null) {
            setValue('location', '');
            return;
        };

        setLoadingLocation(true);

        // cancel previous fetch location
        cancel('canceled');

        const latLong = {
            latitude,
            longitude
        };

        try {
            const data = await Services().get('/api/open/map/location',
                latLong,
                {
                    cancelToken: new CancelToken(function executor(c) {
                        setCancelLocation(() => c);
                    })
                });
            setValue('location', data?.data?.data?.content?.[0]?.address ?? '');
            setDataAddress({
                address: data?.data?.data?.content?.[0]?.address,
                lat: data?.data?.data?.content?.[0]?.lat,
                lon: data?.data?.data?.content?.[0]?.lon,
                name: data?.data?.data?.content?.[0]?.name
            });
            setLoadingLocation(false);
        } catch (e) {
            if (e?.message !== 'canceled') {
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? 'Lokasi tidak ditemukan' } });
                setValue('location', '')
                setLoadingLocation(false);
            };
        };
    };

    return (
        <>
            <FormGroup row>
                <Label htmlFor="province" sm={3}>Provinsi</Label>
                <Col sm={9}>
                    <Select
                        name="province"
                        id="province"
                        placeholder="Pilih Provinsi"
                        options={provinceOption ?? []}
                        isLoading={isLoadingProvinceOption}
                        isDisabled={isLoadingProvinceOption}
                        validation={['required']}
                        onChange={() => setValue('city', null)}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="city" sm={3}>Kota</Label>
                <Col sm={9}>
                    <Select
                        name="city"
                        id="city"
                        placeholder="Pilih Kota"
                        options={cityOption ?? []}
                        isLoading={isLoadingCityOption}
                        isDisabled={!province || isLoadingCityOption}
                        validation={['required']}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="address" sm={3}>Detail Alamat</Label>
                <Col sm={9}>
                    <TextArea
                        id="address"
                        rows="4"
                        name="address"
                        validation={['required']}
                        placeholder="Ketik Detail Alamat"
                        maxLength="250"
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="location" sm={3}>Lokasi Alamat (Opsional)</Label>
                <Col sm={9} className="d-flex">
                    <div className='mr-2'>
                        <Button type="button"
                            onClick={() => { setShowModal(!showModal) }}
                            color="primary" className='font-weight-medium d-inline-flex align-items-center py-2' style={{ minWidth: 132, height: 42 }}
                            disabled={loadingLocation}
                        >
                            <img src={IconPinPoint} alt="maps" className='mr-2' />
                            Pilih Lokasi
                        </Button>
                    </div>
                    <Input id="location" name="location" type="text" placeholder="Lokasi Alamat" autoComplete="off" disabled style={{ paddingRight: '32px' }} />
                    {loadingLocation && (
                        <span style={{
                            position: 'absolute',
                            right: '24px',
                            top: '50%',
                            transform: 'translateY(-50%)'
                        }}>
                            <Spinner size="sm" />
                        </span>
                    )}
                    <ModalPilihLocation
                        open={showModal}
                        setOpen={setShowModal}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="latitude" sm={3}>Latitude</Label>
                <Col sm={9}>
                    <Input
                        id="latitude"
                        name="latitude"
                        type="text"
                        placeholder="Latitude"
                        validation={['required', latLongValidation]}
                        autoComplete="off"
                        onChange={(e) => onLatLongChange(e)}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="longitude" sm={3}>Longitude</Label>
                <Col sm={9}>
                    <Input
                        id="longitude"
                        name="longitude"
                        type="text"
                        placeholder="Longitude"
                        validation={['required', latLongValidation]}
                        autoComplete="off"
                        onChange={(e) => onLatLongChange(e)}
                    />
                </Col>
            </FormGroup>
        </>
    );
};

const OnEditFields = () => (
    <>
        <FormGroup row>
            <Label htmlFor="paymentMethodCode" sm={3}>Kode Payment Method</Label>
            <Col sm={9}>
                <Input id="paymentMethodCode" name="paymentMethodCode" type="text" autoComplete="off" disabled />
            </Col>
        </FormGroup>
        <FormGroup row>
            <Label htmlFor="financialAccountCode" sm={3}>Kode Financial Payment Method</Label>
            <Col sm={9}>
                <Input id="financialAccountCode" name="financialAccountCode" type="text" autoComplete="off" disabled />
            </Col>
        </FormGroup>
        <FormGroup row>
            <Label htmlFor="accountCode" sm={3}>Kode Akun</Label>
            <Col sm={9}>
                <Input id="accountCode" name="accountCode" type="text" autoComplete="off" disabled />
            </Col>
        </FormGroup>
        <FormGroup row>
            <Label htmlFor="driverSp" sm={3}>Driver SP</Label>
            <Col sm={9}>
                <Input id="driverSp" name="driverSp" type="text" autoComplete="off" disabled />
            </Col>
        </FormGroup>
    </>
);

export default App;
