import React, { useCallback, useState } from 'react'
import {
    Card, CardBody, Row, Col,
    CardHeader,
    FormGroup,
    Label,
    Button,
} from 'reactstrap';
import {
    Link,
    useParams
} from 'react-router-dom';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import { useFetch, useAction } from 'hooks';
import Error404 from 'page/error/Error404';
import ModalConfirmDelete from 'components/Modal';

const App = ({
    title: documentTitle,
    access: permissionAccess,
    history
}) => {
    document.title = documentTitle;
    const [open, setOpen] = useState(false)
    const { id } = useParams();
    const { hasFetch } = useAction();
    const dataBreadcrumb = [
        { to: '/branch-price', label: 'Harga Branch' },
        { to: null, label: 'Detail Harga Standar Branch' }
    ]

    const { data,
        status, error } = useFetch(
            `/api/back-office/branch-price/${id}`,
            {},
            useCallback(data => ({ data: data?.data }), [])
        )

    /* handle delete */
    const deleteAction = () => {
        Services().delete('/api/back-office/branch-price/' + id)
            .then(() => {
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: `Harga standar berhasil dihapus` }
                });
                history.push('/branch-price');
            })
            .catch(err => {
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: err?.message } });
            });
    }


    const IdrFormat = (number) => new Intl.NumberFormat('id-ID', { style: "currency", currency: "IDR", minimumFractionDigits: 0 }).format(number)

    if ((status === 'rejected' && error.code === 400) ||
        (status === 'resolved' && data === null)) return <Error404 />
    return status === 'resolved' && (
        <>
            <Row className="row mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Detail Harga Branch</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Card>
                <CardHeader className="pb-0">
                    Informasi Harga Standar branch
                </CardHeader>
                <CardBody>
                    <FormGroup row>
                        <Label sm={3}>Branch</Label>
                        <Col sm={9}>
                            : {data?.branchName ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Harga Standar / Kg</Label>
                        <Col sm={9}>
                            : {data?.price ? IdrFormat(data?.price) : "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Maximum Price</Label>
                        <Col sm={9}>
                            : {data?.maximumPrice ? IdrFormat(data?.maximumPrice) : "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Maksimum Modal</Label>
                        <Col sm={9}>
                            : {data?.maximumBalance ? IdrFormat(data?.maximumBalance) : "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Rekomendasi Top Up</Label>
                        <Col sm={9}>
                            : {`${data?.topUpThreshold} %` ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Minimum Top Up</Label>
                        <Col sm={9}>
                            : {data?.minimumTopUp ? IdrFormat(data?.minimumTopUp) : "-"}
                        </Col>
                    </FormGroup>
                </CardBody>
            </Card>
            <Row className='d-inline-block float-right mt-2 mt-md-0'>
                <Col>
                    {permissionAccess?.delete && (
                        <Button outline color="danger" onClick={(e) =>{
                            setOpen(!open);
                            e.target.blur();
                        }}>
                            <i className='fa fa-trash mr-2'></i>
                            Hapus
                        </Button>
                    )}
                    {permissionAccess?.update && (
                        <Link to={{ pathname: `/branch-price/${id}/edit/${data?.branchName?.toLowerCase()?.replaceAll(" ", "-")}` }} className="ml-1">
                        <Button color="primary">
                            <i className='fa fa-pencil mr-2'></i>
                            Ubah
                        </Button>
                    </Link>
                    )}
                </Col>
            </Row>
            <ModalConfirmDelete
                isOpen={open}
                handleClose={() => setOpen(!open)}
                component={<>
                    <div className='moda-password-title font-weight-bold'>Hapus Harga Standar?</div>
                    <p style={{ fontSize: "14px" }} className='moda-password-title font-weight-normal m-0'>
                        Harga Standar akan dihapus dari daftar
                    </p>
                    <div className='d-flex justify-content-end pt-4'>
                        <Button onClick={() => deleteAction(data)} className="mr-2" size='sm' outline color="primary">Hapus</Button>
                        <Button onClick={() => setOpen(!open)} size='sm' color="primary">Batal</Button>
                    </div>
                </>
                }
            />
        </>
    )
}

export default App