import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom'
import {
    Card, Row, Col, CardBody, Button, Badge, UncontrolledDropdown, DropdownItem, DropdownMenu, DropdownToggle, FormFeedback, Form, Input
} from 'reactstrap';
import { Pagination, Table as TableGenerator } from 'components';
import Select from 'react-select';
import { useFetch } from "hooks";
import { Breadcrumb } from 'components';
import { debounce } from 'helper';

// import IcMore from 'assets/svg/ic_more.svg'
import IcPlus from 'assets/svg/ic_plus.svg'
import { useSelector } from 'react-redux';

const rootStyle = (variable) => {
    return getComputedStyle(document.body).getPropertyValue(variable)
}

const OptionActiveInActive = [{
    value: "unverified",
    label: "Belum Verifikasi"
}, {
    value: "active",
    label: "Aktif"
}, {
    value: "inactive",
    label: "Tidak Aktif"
}]
const theme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: rootStyle('--light'),
        primary: rootStyle('--primary'),
    }
})

const getColorStatus = (status) => {
    switch (status) {
        case "active":
            return <Badge color="success" className='text-10 p-1'>
                Aktif
            </Badge>
        case "inactive":
            return <Badge color="danger" className='text-10 p-1'>
                Tidak  Aktif
            </Badge>
        case "unverified":
            return <Badge color="warning" className='text-10 text-white p-1'>
                Belum Verifikasi
            </Badge>
        default:
            return <Badge color="black" className='text-10 p-1'>
                {status}
            </Badge>
    }
}
const styles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#e4e7ea' : provided.backgroundColor,
        border: '1px solid #DBDCDD',
        boxShadow: 'none',
        '&:hover': {
            borderColor: state.selectProps.readonly ? '#DBDCDD' : rootStyle('--primary')
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0.475rem 0.625rem',
        height: 'calc(1.5em + 1.25rem + 5px)'
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
    })
}

// const getColorStatus = (status) => {
//     switch (status) {
//         case true:
//             return <Badge color="success" className='text-10 p-1'>
//                 Aktif
//             </Badge>
//         case false:
//             return <Badge color="danger" className='text-10 p-1'>
//                 Tidak  Aktif
//             </Badge>
//         default:
//             return <Badge color="black" className='text-10 p-1'>
//                 {status}
//             </Badge>
//     }
// }

const MIN_SEARCH_LENGTH = 3;
const validateKeyword = (keyword) => keyword === "" || keyword.trim().length >= MIN_SEARCH_LENGTH;

const App = ({
    title: documentTitle,
    access: permissionAccess
}) => {
    document.title = documentTitle;
    const { data, loading: isLoading, totalPage, params, setParams, error } = useFetch(
        `/api/back-office/driver`,
        currentParams => ({
            size: 10,
            sort: 'name,asc',
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
        }),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        { withQueryParams: false, showAlertOnError: false }
    )

    const TableConfiguration = {
        columns: [
            {
                key: 'name', title: 'Nama',
                //  onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <Link to={`/driver/${row.id}`}>{value}</Link>
                }
            },
            {
                key: 'phone', title: 'Nomor Handphone',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value}</span>
                }
            },
            {
                key: 'branchName', title: 'Branch',
                //  onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value}</span>
                }
            },
            {
                key: 'stockpointName', title: 'Stockpoint',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value}</span>
                }
            },
            {
                key: 'warehouseName', title: 'Warehouse',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value ?? "-"}</span>
                }
            },
            {
                key: 'status', title: 'Status',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value) => getColorStatus(value)
                // render: (value) => getColorStatus(value)
            }
        ]
    }

    const dataBreadcrumb = [
        { to: '#', label: 'Master Data Driver' }
    ]

    /* set params for searching */
    const setParamsSearch = useCallback(
        debounce(
            (name, value) => {
                const hasKeyword = validateKeyword(value);
                setShouldSearch(hasKeyword);

                if (hasKeyword) {
                    setParams(prev => ({ ...prev, page: 1, [name]: typeof value === 'string' ? value?.trim() : value }));
                }
            },
            600
        ),
        []
    );

    const [shouldSearch, setShouldSearch] = useState(validateKeyword(params?.name ?? ""));

    /* handle searching for input type */
    const handleSearchChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setParamsSearch(name, value);
    }
    const { profile } = useSelector(state => state.homesidemenu);
    return (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Driver</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                    {/* {
                        profile?.stockpointName !== null || profile?.branchName !== null || profile?.warehouseName !== null ? permissionAccess?.create && <>
                            <div className="d-inline-block float-right">
                                <Link to="/driver/create-wh">
                                    <Button color="primary" className="mr-2">
                                        <img src={IcPlus} alt='create' className='mr-2' height={19} /> Tambah Driver Warehouse
                                    </Button>
                                </Link>
                                <Link to="/driver/create-sp">
                                    <Button color="primary">
                                        <img src={IcPlus} alt='create' className='mr-2' height={19} /> Tambah Driver Stockpoint
                                    </Button>
                                </Link>
                            </div>
                        </> : null
                    } */}

                    {
                        permissionAccess?.create && <>
                            <div className="d-inline-block float-right">
                                <UncontrolledDropdown direction="down">
                                    <DropdownToggle nav style={{ padding: 0 }}>
                                        <Button color="primary">
                                            <img src={IcPlus} alt='create' className='mr-2' height={19} /> Tambah Driver
                                        </Button>
                                    </DropdownToggle>
                                    <DropdownMenu right className="border-0 mt-2" style={{ borderRadius: "12px", boxShadow: "0 1px 4px rgba(112, 114, 125, 0.2)", minWidth: "220px" }}>
                                        {
                                            profile?.level !== "warehouse officer" && <Link to="/driver/create-sp" style={{ textDecoration: 'none', cursor: 'initial' }} onClick={(e) => e.preventDefault()}>
                                                <DropdownItem disabled style={{ borderRadius: "11px 11px 0 0", paddingBlock: "14px", color: "#DCDEE3" }}>
                                                    Driver Stockpoint
                                                </DropdownItem>
                                            </Link>
                                        }
                                        {
                                            profile?.level !== "stockpoint officer" && <Link to="/driver/create-wh" style={{ textDecoration: 'none' }}>
                                                <DropdownItem style={{ borderRadius: "0 0 11px 11px", paddingBlock: "14px" }}>
                                                    Driver Warehouse
                                                </DropdownItem>
                                            </Link>
                                        }
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </div>
                        </>
                    }
                </Col>
            </Row>
            <Card body>
                <Form>
                    <Row className='justify-content-between'>
                        <Col lg={2}>
                            <Select
                                name="status"
                                id="statusId"
                                placeholder="Pilih Status"
                                className="basic-single"
                                // isLoading={isLoadingBranch}
                                options={OptionActiveInActive}
                                isClearable={true}
                                theme={theme}
                                styles={styles}
                                onChange={(e) => setParams(params => ({
                                    ...params,
                                    page: 1,
                                    status: e?.value
                                }))}
                            />
                        </Col>
                        <Col lg={4} className="align-self-end">
                            <Row className='justify-content-end'>
                                <Col lg={12}>
                                    {/* <SearchInput
                                        id="name"
                                        name="search"
                                        placeholder="Cari Nama Driver / Nomor Handphone"
                                        invalid={!shouldSearch}
                                        defaultValue={params?.name}
                                        onChange={handleSearchChange}
                                    /> */}
                                    <div className="input-search">
                                        <i className="fa fa-search text-muted"></i>
                                        <Input invalid={!shouldSearch} className="pl-5" type="text" name="search" id="name" placeholder="Cari Nama Driver / Nomor Handphone" onChange={handleSearchChange} defaultValue={params?.name} />
                                    </div>
                                    {!shouldSearch && <FormFeedback className="d-block">Minimal {MIN_SEARCH_LENGTH} karakter</FormFeedback>}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Card>
                <CardBody className="p-0">
                    <TableGenerator
                        {...TableConfiguration}
                        dataList={data}
                        params={params}
                        loading={isLoading}
                        error={error} />
                </CardBody>
            </Card>
            <Row className="pagination mr-1">
                <Col>
                    <Pagination
                        showRowsPerPage={false}
                        onChangePage={({ page, size }) => setParams(prev => ({ ...prev, page, size }))}
                        totalPage={totalPage}
                        currentPage={params.page}
                        limitPage={params.size}
                    />
                </Col>
            </Row>
        </>
    )
}

export default App;
