import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom'
import {
    Card, Row, Col, Form, CardBody, Input, Button, Badge
} from 'reactstrap';
import { Pagination, Table as TableGenerator } from 'components';
import Select from 'react-select';
import { useFetch } from "hooks";
import { Breadcrumb } from 'components';
import { capitalize, debounce } from 'helper';
import cancel from 'assets/svg/ic_cancel.svg'
// import IcMore from 'assets/svg/ic_more.svg'
import IcPlus from 'assets/svg/ic_plus.svg'
import ModalPage from 'components/Modal';
import { Services } from 'service';
import { useSelector } from 'react-redux';
// import { useSelector } from 'react-redux';

const rootStyle = (variable) => {
    return getComputedStyle(document.body).getPropertyValue(variable)
}

const OptionActiveInActive = [{
    value: "active",
    label: "Aktif"
}, {
    value: "inactive",
    label: "Tidak Aktif"
}]

const OptionJabatan = [{
    value: "1",
    label: "Salesman"
}, {
    value: "2",
    label: "Sales Supervisor"
}, {
    value: "3",
    label: "Sales Manager"
}]

const theme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: rootStyle('--light'),
        primary: rootStyle('--primary'),
    }
})

const styles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#e4e7ea' : provided.backgroundColor,
        border: '1px solid #DBDCDD',
        boxShadow: 'none',
        '&:hover': {
            borderColor: state.selectProps.readonly ? '#DBDCDD' : rootStyle('--primary')
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0.475rem 0.625rem',
        height: 'calc(1.5em + 1.25rem + 5px)'
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
    })
}

const getColorStatus = (status) => {
    switch (status) {
        case "active":
            return <Badge color="success" className='text-10 p-1'>
                Aktif
            </Badge>
        case "inactive":
            return <Badge color="danger" className='text-10 p-1'>
                Tidak  Aktif
            </Badge>
        case "unverified":
            return <Badge color="warning" className='text-10 text-white p-1'>
                Belum Verifikasi
            </Badge>
        default:
            return <Badge color="black" className='text-10 p-1'>
                {status}
            </Badge>
    }
}


const ModalContext = createContext();
const App = ({
    title: documentTitle,
    access: permissionAccess
}) => {
    document.title = documentTitle;
    const {
        data: dataSalesman,
        loading: isLoadingSalesman,
        totalPage: totalPageSalesman,
        params: paramsSalesman,
        setParams: setParamsSalesman,
        error: errorSalesman
    } = useFetch(
        `/api/back-office/salesman`,
        currentParams => ({
            size: 10,
            sort: 'name,asc',
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
        }),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        {
            withQueryParams: false,
            showAlertOnError: false
        }
    )

    const {
        data: dataSupervisor,
        loading: isLoadingSupervisor,
        totalPage: totalPageSupervisor,
        params: paramsSupervisor,
        setParams: setParamsSupervisor,
        error: errorSupervisor } = useFetch(
            `/api/back-office/salesman/supervisor`,
            currentParams => ({
                size: 10,
                sort: 'name,asc',
                page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
            }),
            useCallback(data => ({
                data: data?.data?.content,
                totalPage: data?.data?.totalPages
            }), []),
            { withQueryParams: false, showAlertOnError: false }
        )
    const {
        data: dataSM,
        loading: isLoadingSM,
        totalPage: totalPageSM,
        params: paramsSM,
        setParams: setParamsSM,
        error: errorSM } = useFetch(
            `/api/back-office/salesman/manager`,
            currentParams => ({
                size: 10,
                sort: 'name,asc',
                page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
            }),
            useCallback(data => ({
                data: data?.data?.content,
                totalPage: data?.data?.totalPages
            }), []),
            { withQueryParams: false, showAlertOnError: false }
        )
    const { data: branchList, loading: isLoadingBranch } = useFetch(
        `/api/back-office/branch`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );

    const { data: stockPoint, loading: isLoadingStockPoint, setParams: setParamSp, params: paramSp } = useFetch(
        `/api/back-office/stockpoint`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );
    const [activeTab, setActiveTab] = useState("1")
    const [open, setopen] = useState(false)
    const [spId, setspId] = useState(null)

    const TableConfiguration = {
        columns: [
            {
                key: 'name', title: 'Nama Lengkap',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <Link to={`/salesman/${row.id}/${row.level.replace(" ", "-").toLowerCase()}`}>{value}</Link>
                }
            },
            {
                key: 'employeeId', title: 'Employee ID',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value}</span>
                }
            },
            {
                key: 'phone', title: 'Nomor Handphone',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value}</span>
                }
            },
            {
                key: 'employment', title: 'Jabatan',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{capitalize(value ?? "-")}</span>
                }
            },
            {
                key: 'branchName', title: 'Branch',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value}</span>
                }
            },
            {
                key: 'stockpointName', title: activeTab === "1" ? "Stockpoint" : 'Jumlah Stockpoint',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    if (activeTab === "2" || activeTab === "3") {
                        return <Link to="#" onClick={() => {
                            setopen(!open)
                            setspId(row)
                        }} >{row?.totalStockpoint}</Link>
                    }
                    else
                        return <span>{value}</span>
                }
            },
            {
                key: 'status', title: 'Status',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value) => getColorStatus(value)
            }
        ]
    }
    const dataBreadcrumb = [
        { to: '#', label: 'Master Data Salesperson' }
    ]

    /* set params for searching */
    const setParamsSearch = useCallback(debounce((name, value) => setParamsSalesman(prev => ({ ...prev, page: 1, [name]: typeof value === 'string' ? value?.trim() : value })), 400), [])
    const setParamsSearchSupervisor = useCallback(debounce((name, value) => setParamsSupervisor(prev => ({ ...prev, page: 1, [name]: typeof value === 'string' ? value?.trim() : value })), 400), [])
    const setParamsSearchSM = useCallback(debounce((name, value) => setParamsSM(prev => ({ ...prev, page: 1, [name]: typeof value === 'string' ? value?.trim() : value })), 400), [])

    /* handle searching for input type */
    const handleSearchChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        activeTab === "1" && setParamsSearch(name, value);
        activeTab === "2" && setParamsSearchSupervisor(name, value)
        activeTab === "3" && setParamsSearchSM(name, value)
    }

    /* handle searching for select type */
    const handleSelectChange = (name, e) => {
        let value = e?.value;
        activeTab === "1" && setParamsSearch(name, value);
        activeTab === "2" && setParamsSearchSupervisor(name, value)
        activeTab === "3" && setParamsSearchSM(name, value)
    }
    const { branchId, stockpointId } = useSelector(state => state.homesidemenu?.profile)
    const [value, setvalue] = useState({
        branchId: branchId,
        stockpointId: stockpointId
    })
    const [select, setselect] = useState({
        status: null
    })
    const isSelect = (e) => {
        if (e) {
            setselect(prev => ({
                ...prev,
                status: e.value
            }))
            handleSelectChange('status', e)
        } else {
            setselect(prev => ({
                ...prev,
                status: null
            }))
            handleSelectChange('status', e)
        }
    }
    const handleChangeJabatan = (name, e) => {
        let value = e?.value;
        reload()

        if (value === "1") {
            setActiveTab('1')
            setvalue(prev => ({
                branchId: 0,
                stockpointId: 0
            }))
            isSelect(null)
            setParamsSalesman(params => {
                delete params.status
                delete params.branchId
                delete params.stockpointId
                delete params.name
                return {
                    ...params,
                    sort: "name,asc",
                    page: 1
                }
            })
        } else if (value === "2") {
            setActiveTab('2')
            setvalue(prev => ({
                branchId: 0,
                stockpointId: 0
            }))
            isSelect(null)
            setParamsSupervisor(params => {
                delete params.status
                delete params.branchId
                delete params.stockpointId
                delete params.name
                return {
                    ...params,
                    sort: "name,asc",
                    page: 1
                }
            })
        } else if (value === "3") {
            setActiveTab('3')
            setvalue(prev => ({
                branchId: 0,
                stockpointId: 0
            }))
            isSelect(null)
            setParamsSM(params => {
                delete params.status
                delete params.branchId
                delete params.stockpointId
                delete params.name
                return {
                    ...params,
                    sort: "name,asc",
                    page: 1
                }
            })
        }
    }
    const element = useRef({})
    const reload = useCallback(() => {
        element.current.filter.state.value = null;
        element.current.branch.state.value = null;
        element.current.stock.state.value = null;
    }, []);
    return (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Salesperson</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                    {
                        permissionAccess?.create &&
                        <div className="d-inline-block float-right">
                            <Link to="/salesman/create">
                                <Button color="primary">
                                    <img src={IcPlus} alt='create' className='mr-2' height={19} /> Tambah Salesperson
                                </Button>
                            </Link>
                        </div>
                    }
                </Col>
            </Row>
            <Card body>
                <Form>
                    <Row>
                        <Col lg="8">
                            <Row>
                                <Col>
                                    <Select
                                        ref={el => element.current['filter'] = el}
                                        name="status"
                                        id="statusId"
                                        placeholder="Pilih Status"
                                        className="basic-single"
                                        options={activeTab !== "3" ? OptionActiveInActive : [{
                                            value: "unverified",
                                            label: "Belum Verifikasi"
                                        }, ...OptionActiveInActive]}
                                        isClearable={true}
                                        theme={theme}
                                        styles={styles}
                                        value={(activeTab !== "3" ? OptionActiveInActive : [{
                                            value: "unverified",
                                            label: "Belum Verifikasi"
                                        }, ...OptionActiveInActive]).filter(item => item.value === select.status)}
                                        onChange={(e) => {
                                            isSelect(e);
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Select
                                        ref={el => element.current['filter'] = el}
                                        name="branchId"
                                        id="branchId"
                                        placeholder="Pilih Branch"
                                        className="basic-single"
                                        options={branchList?.map(item => ({
                                            value: item?.id,
                                            code: item?.code,
                                            label: item?.name
                                        }))}
                                        value={branchList?.map(item => ({
                                            value: item?.id,
                                            code: item?.code,
                                            label: item?.name
                                        })).filter(i => i.value === value?.branchId)}
                                        isClearable={true}
                                        theme={theme}
                                        styles={styles}
                                        onChange={(e) => {
                                            handleSelectChange('branchId', e)
                                            if (e) {
                                                setvalue(prev => ({
                                                    ...prev,
                                                    branchId: e.value
                                                }))
                                                setParamSp(prev => ({
                                                    ...prev,
                                                    branch: e.code
                                                }))
                                            }
                                            else {
                                                delete paramSp.branch
                                                setvalue(prev => ({
                                                    ...prev,
                                                    branchId: null
                                                }))
                                                setParamSp(prev => ({
                                                    ...paramSp
                                                }))
                                            }
                                        }}
                                    />
                                </Col>
                                <Col>
                                    <Select
                                        ref={el => element.current['filter'] = el}
                                        name="stockpoint"
                                        id="stockpointId"
                                        placeholder="Pilih Stockpoint"
                                        className="basic-single"
                                        options={stockPoint?.map(item => ({
                                            value: item?.id,
                                            label: item?.name
                                        }))}
                                        value={stockPoint?.map(item => ({
                                            value: item?.id,
                                            label: item?.name
                                        })).filter(i => i.value === value?.stockpointId)}
                                        isClearable={true}
                                        theme={theme}
                                        styles={styles}
                                        onChange={(e) => {
                                            if (e) {
                                                handleSelectChange('stockpointId', e)
                                                setvalue(prev => ({
                                                    ...prev,
                                                    stockpointId: e.value
                                                }))
                                            }
                                            else {
                                                setvalue(prev => ({
                                                    ...prev,
                                                    stockpointId: null
                                                }))
                                                handleSelectChange('stockpointId', null)
                                            }
                                        }}
                                    />
                                </Col>
                                <Col style={{ display: "none" }} lg={3}>
                                    <Select
                                        ref={el => element.current['branch'] = el}
                                        name="branchId"
                                        id="branchId"
                                        placeholder="Pilih Branch"
                                        className="basic-single"
                                        defaultValue={paramsSalesman?.roleId}
                                        // value={OptionActiveInActive.filter(i => i.value === element?.current?.filter?.state?.value?.value)}

                                        isLoading={isLoadingBranch}
                                        options={branchList?.map(obj => ({ label: obj?.name, value: obj?.id }))}
                                        isClearable={true}
                                        theme={theme}
                                        styles={styles}
                                        onChange={(e) => handleSelectChange('branchId', e)}
                                    />
                                </Col>
                                <Col style={{ display: "none" }} lg={3}>
                                    <Select
                                        ref={el => element.current['stock'] = el}
                                        name="stockpointId"
                                        id="stockpointId"
                                        placeholder="Pilih Stockpoint"
                                        className="basic-single"
                                        // defaultValue={params?.roleId}
                                        isLoading={isLoadingStockPoint}
                                        options={stockPoint?.map(obj => ({ label: obj?.name, value: obj?.id }))}
                                        isClearable={true}
                                        theme={theme}
                                        styles={styles}
                                        onChange={(e) => handleSelectChange('stockpointId', e)}
                                    />
                                </Col>
                                <Col>
                                    <Select
                                        name="jabatan"
                                        id="jabatanId"
                                        placeholder="Pilih Jabatan"
                                        className="basic-single"
                                        options={OptionJabatan}
                                        theme={theme}
                                        styles={styles}
                                        onChange={(e) => {
                                            handleChangeJabatan('status', e)
                                        }}
                                        defaultValue={OptionJabatan[0]}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} className="align-self-end">
                            <div className="input-search">
                                <i className="fa fa-search text-muted"></i>
                                {activeTab === "1" && <Input
                                    ref={el => element.current['searching'] = el}
                                    className="pl-5" type="text" name="name" id="name" placeholder="Cari Nama Salesman" onChange={handleSearchChange} />}
                                {activeTab === "2" && <Input
                                    ref={el => element.current['searching'] = el}
                                    className="pl-5" type="text" name="name" id="name" placeholder="Cari Nama Supervisor Salesman" onChange={handleSearchChange} />}
                                {activeTab === "3" && <Input
                                    ref={el => element.current['searching'] = el}
                                    className="pl-5" type="text" name="name" id="name" placeholder="Cari Nama Manager Salesman" onChange={handleSearchChange} />}
                            </div >
                        </Col>
                    </Row >
                </Form >
            </Card >

            {
                activeTab === '1' &&
                <div>
                    <Card>
                        <CardBody className="p-0">
                            <TableGenerator
                                {...TableConfiguration}
                                dataList={dataSalesman}
                                params={paramsSalesman}
                                loading={isLoadingSalesman}
                                error={errorSalesman} />
                        </CardBody>
                    </Card>
                    <Row className="pagination mr-1">
                        <Col>
                            <Pagination
                                showRowsPerPage={false}
                                onChangePage={({ page, size }) => setParamsSalesman(prev => ({ ...prev, page, size }))}
                                totalPage={totalPageSalesman}
                                currentPage={paramsSalesman.page}
                                limitPage={paramsSalesman.size}
                            />
                        </Col>
                    </Row>
                </div>
            }
            {
                activeTab === '2' &&
                <div>
                    <Card>
                        <CardBody className="p-0">
                            <TableGenerator
                                {...TableConfiguration}
                                dataList={dataSupervisor}
                                params={paramsSupervisor}
                                loading={isLoadingSupervisor}
                                error={errorSupervisor} />
                        </CardBody>
                    </Card>
                    <Row className="pagination mr-1">
                        <Col>
                            <Pagination
                                showRowsPerPage={false}
                                onChangePage={({ page, size }) => setParamsSupervisor(prev => ({ ...prev, page, size }))}
                                totalPage={totalPageSupervisor}
                                currentPage={paramsSupervisor.page}
                                limitPage={paramsSupervisor.size}
                            />
                        </Col>
                    </Row>
                </div>
            }
            {
                activeTab === '3' &&
                <div>
                    <Card>
                        <CardBody className="p-0">
                            <TableGenerator
                                {...TableConfiguration}
                                dataList={dataSM}
                                params={paramsSM}
                                loading={isLoadingSM}
                                error={errorSM} />
                        </CardBody>
                    </Card>
                    <Row className="pagination mr-1">
                        <Col>
                            <Pagination
                                showRowsPerPage={false}
                                onChangePage={({ page, size }) => setParamsSM(prev => ({ ...prev, page, size }))}
                                totalPage={totalPageSM}
                                currentPage={paramsSM.page}
                                limitPage={paramsSM.size}
                            />
                        </Col>
                    </Row>
                </div>
            }
            <ModalContext.Provider
                value={{
                    open,
                    setopen,
                    spId
                }}
            >
                <ModalSp />
            </ModalContext.Provider>
        </>
    )
}

export const ModalSp = () => {
    const { open, setopen, spId } = useContext(ModalContext);
    const [data, setdata] = useState(null)
    useEffect(() => {
        if (spId) {
            Services().get('/api/back-office/salesman/stockpoint', {
                type: spId?.level,
                id: spId?.id
            }).then(r => {
                setdata(r?.data?.data);
            })
        }
    }, [spId])
    return <ModalPage
        isOpen={open}
        handleClose={() => setopen(!open)}
        component={<>
            <div className='d-flex justify-content-between'>
                <h5 style={{ marginBottom: "1rem" }}>Stockpoint</h5>
                <div className='text-right'>
                    <span onClick={() => setopen(!open)} style={{ cursor: "pointer" }}>
                        <img src={cancel} alt="pict-cancel" /></span>
                </div>
            </div>
            <Row className='mb-1 mt-1'>
                <Col sm={12} style={{ marginBottom: "1rem" }}>
                    <h6 style={{ color: "#70727D", fontWeight: "normal" }}>Jumlah Stockpoint</h6>
                    <h3>{data?.totalStockpoint}</h3>
                </Col>
                <Col sm={12} style={{ marginBottom: "1rem" }}>
                    <h6 style={{ color: "#70727D", fontWeight: "normal" }}>Daftar Stockpoint</h6>
                    {data?.stockpointNames}
                </Col>
            </Row>
        </>
        }
    />
}

export default App;
