import React, { useCallback, useState, useEffect, createContext, useContext } from 'react';
import {
    Card,
    Row,
    Col,
    CardBody,
    Input,
    Button
} from 'reactstrap';
import { Pagination, Table as TableGenerator } from 'components';
import { useFetch } from "hooks";
import { Breadcrumb } from 'components';
import DatePicker from 'react-datepicker';
import { format, addDays } from 'date-fns';
import Form from 'components-form/Form';
import Select from 'components-form/Select';
import { useFormContext } from 'react-hook-form';
import { debounce } from 'helper';
import IcRecon from '../../assets/svg/ic_reconcile.svg';
import IcUpload from '../../assets/svg/ic_upload.svg';
import ModalTopUp from './components/ModalTopup';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const CashContext = createContext();
const App = ({
    title: documentTitle,
    access: permissionAccess
}) => {
    document.title = documentTitle;
    const allowedRangeDay = 31;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [modalTopup, setModalTopup] = useState({
        show: false,
        branch: "",
        stockpoint: ""
    });
    const { branchId, stockpointId } = useSelector(state => state.homesidemenu?.profile);

    const { data, loading: isLoading, totalPage, params, setParams, error, fetchData } = useFetch(
        `/api/back-office/petty-cash/top-up`,
        currentParams => ({
            size: 10,
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
            branch: branchId,
            stockpoint: stockpointId
        }),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        { withQueryParams: false, showAlertOnError: false },
        { status: true, timeout: 3600 * 1000 }
    )

    const TableConfiguration = {
        columns: [
            {
                key: 'branchName',
                title: 'Cabang',
                render: (value, row, key) => {
                    return value
                },
            },
            {
                key: 'stockpointName',
                title: 'Stockpoint',
                render: (value, row, key) => {
                    return value
                },
            },
            {
                title: 'Modal',
                children: [{
                    key: 'stockpointBalance',
                    title: 'Petty Cash (Rp)',
                    render: (value, row, key) => {
                        return value?.pettyCash.toLocaleString('id-ID')
                    },
                    stylesHeader: {
                        minWidth: "150px",
                    }
                },
                {
                    key: 'stockpointBalance',
                    title: 'Uang Kas (Rp)',
                    render: (value, row, key) => {
                        return value?.cash.toLocaleString('id-ID')
                    },
                    stylesHeader: {
                        minWidth: "150px",
                    }
                }, {
                    key: 'stockpointBalance',
                    title: 'Total Modal (Rp)',
                    render: (value, row, key) => {
                        return value?.totalAmount.toLocaleString('id-ID')
                    },
                    stylesHeader: {
                        minWidth: "150px",
                    }
                }
                ]
            },
            {
                title: 'Rencana Pengeluaran',
                children: [{
                    key: 'purchasePlan',
                    title: 'Belum Pick Up (Rp)',
                    render: (value, row, key) => {
                        return value?.pickupRequestCash.toLocaleString('id-ID')
                    },
                    stylesHeader: {
                        minWidth: "150px",
                    }
                },
                {
                    key: 'purchasePlan',
                    title: 'Assigned Trip Plan (Rp)',
                    render: (value, row, key) => {
                        return value?.assignedTripCash.toLocaleString('id-ID')
                    },
                    stylesHeader: {
                        minWidth: "150px",
                    }
                }, {
                    key: 'purchasePlan',
                    title: 'Total Request Pick Up (Rp)',
                    render: (value, row, key) => {
                        return value?.totalAmount.toLocaleString('id-ID')
                    },
                    stylesHeader: {
                        minWidth: "150px",
                    }
                }
                ]
            },
            {
                key: 'remainingCash',
                title: 'Sisa Modal',
                render: (value, row, key) => {
                    return value?.toLocaleString('id-ID')
                },
                stylesHeader: {
                    minWidth: "150px",
                }
            },
            {
                title: 'Rekomendasi Top Up',
                children: [{
                    key: 'topUp',
                    title: 'Top Up',
                    render: (value, row, key) => {
                        return value ? <div style={{ color: "#FF7D1D" }}>Perlu Top Up</div> : "-"
                    },
                    stylesHeader: {
                        minWidth: "160px",
                    }
                }, {
                    key: 'topUpRecommendation',
                    title: 'Rekomendasi (Rp)',
                    render: (value, row, key) => {
                        return value?.toLocaleString('id-ID')
                    },
                    stylesHeader: {
                        minWidth: "160px",
                    }

                }]
            },
            {
                title: 'Informasi Top Up petty Cash',
                children: [{
                    key: 'lastTopUpAmount',
                    title: 'Jumlah Top Up (Rp)',
                    render: (value, row, key) => {
                        return value?.toLocaleString('id-ID')
                    },
                    stylesHeader: {
                        minWidth: "180px",
                    }
                }, {
                    key: 'lastTopUpDate',
                    title: 'Top Up Terakhir',
                    render: (value, row, key) => {
                        return format(new Date(value), 'dd/MM/yyyy - HH:mm')
                    },
                    stylesHeader: {
                        minWidth: "180px",
                    }
                }]
            },
            {
                title: 'Action',
                render: (value, row, key) => {
                    return <Button
                        color="primary"
                        size="sm"
                        onClick={() => setModalTopup({
                            show: true,
                            stockpoint: { code: row?.code, name: row?.stockpointName, id: row?.stockpointId, },
                            branch: { code: row?.branchCode, name: row?.branchName, id: row?.branchId },
                        })}
                    >
                        Top Up
                    </Button>
                },
                stylesHeader: {
                    minWidth: "120px",
                }
            },
        ]
    }

    const dataBreadcrumb = [
        { to: '#', label: 'Perencanaan Kas' }
    ]

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const maxDate = () => {
        const currentDate = new Date();
        if (addDays(startDate, allowedRangeDay - 1) > currentDate || (startDate !== null && endDate !== null)) {
            return currentDate;
        }
        return startDate ? addDays(startDate, allowedRangeDay - 1) : new Date();
    };

    useEffect(() => {
        setParams(prev => ({
            ...prev,
            page: 1,
            startDate: startDate ? format(new Date(startDate), "dd-MM-yyyy") : null,
            endDate: endDate ? format(new Date(endDate), "dd-MM-yyyy") : null
        }))
    }, [startDate, endDate, setParams])


    /* set params for searching */
    const setParamsSearch = useCallback(debounce((name, value) => setParams(prev => ({ ...prev, page: 1, [name]: typeof value === 'string' ? value?.trim() : value })), 600), [])

    /* handle searching for input type */
    const handleSearchChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setParamsSearch(name, value);
    }

    return (
        <CashContext.Provider value={{
            setParams
        }}>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Petty Cash</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                    <div className="d-inline-block float-right">
                        {permissionAccess?.create && (
                            <>
                                <Link to="/cash-planning/topup-bulk">
                                    <Button color="secondary" className='mr-4'>
                                        <img src={IcUpload} alt='create' className='mr-2' /> Bulk Top Up
                                    </Button>
                                </Link>
                                <Button color="primary" onClick={() => setModalTopup((prevState) => ({
                                    ...prevState,
                                    show: !modalTopup.show,
                                }))}>
                                    <img src={IcRecon} alt='create' className='mr-2' /> Top Up Kas
                                </Button>
                            </>
                        )}
                    </div>
                </Col>
            </Row>
            <Card body>
                <Form>
                    <Row>
                        <Col md={3}>
                            <DatePicker
                                id="filterDateRange"
                                name="filterDateRange"
                                placeholderText="Pilih Tanggal"
                                onChange={onChange}
                                selected={startDate}
                                startDate={startDate}
                                endDate={endDate}
                                className="form-control"
                                dateFormat={"dd/MM/yyyy"}
                                maxDate={maxDate() || new Date()}
                                selectsRange
                                isClearable
                            />
                        </Col>
                        <Col md={3} className="mb-1">
                            <BranchField branchId={branchId} />
                        </Col>
                        <Col md={3} className="mb-1">
                            <StockPoint stockpointId={stockpointId} />
                        </Col>
                        <Col md={3}>
                            <div className="input-search">
                                <i className="fa fa-search text-muted"></i>
                                <Input className="pl-5" type="text" name="search" id="name" placeholder="Cari Cabang / Stockpoint" onChange={handleSearchChange} />
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Card>
                <CardBody className="p-0">
                    <TableGenerator
                        {...TableConfiguration}
                        dataList={data}
                        params={params}
                        loading={isLoading}
                        error={error}
                    />
                </CardBody>
            </Card>
            <Row className="pagination mr-1">
                <Col>
                    <Pagination
                        showRowsPerPage={false}
                        onChangePage={({ page, size }) => setParams(prev => ({ ...prev, page, size }))}
                        totalPage={totalPage}
                        currentPage={params.page}
                        limitPage={params.size}
                        withSelector
                    />
                </Col>
            </Row>

            {permissionAccess?.create && (
                <ModalTopUp
                    open={modalTopup.show}
                    close={() => setModalTopup(() => ({ show: false, branch: '', stockpoint: '', branchName: '', stockpointName: '' }))}
                    branch={modalTopup?.branch}
                    stockpoint={modalTopup?.stockpoint}
                    refetch={() => fetchData()}
                />
            )}
        </CashContext.Provider>
    )
}

const BranchField = ({ branchId }) => {
    const { setParams } = useContext(CashContext);
    const { setValue } = useFormContext();

    const { data, loading } = useFetch(
        `/api/back-office/branch`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );

    useEffect(() => {
        setValue('branchId', branchId);
    }, [branchId, setValue]);

    return (
        <Select
            name="branchId"
            id="branchId"
            placeholder="Pilih Branch"
            className="basic-single"
            isLoading={loading}
            options={data?.map(item => ({ label: item?.name, value: item?.id, id: item?.id }))}
            isClearable={true}
            isDisabled={branchId}
            onChange={(branch) => {
                if (branch) {
                    setValue("branchId", branch?.value);
                    setValue("stockpointId", null)
                    setParams(prev => ({
                        ...prev,
                        branch: branch?.value
                    }))
                }
                else {
                    setValue("branch", null);
                    setValue("branchId", null);
                    setValue("stockpointId", null)
                    setParams(prev => ({
                        ...prev,
                        branch: null
                    }));
                    setParams(prev => ({
                        ...prev,
                        stockpoint: null
                    }));
                }
            }}
        />
    );
};

const StockPoint = ({ stockpointId }) => {
    const { setParams } = useContext(CashContext);
    const { watch, setValue, } = useFormContext();
    const branchId = watch('branchId');

    const { data, loading, setParams: fetchParamsSpo } = useFetch(
        `/api/back-office/stockpoint`,
        currentParams => ({
            unpaged: true
        }),
        useCallback(data => ({
            data: data?.data?.content,
        }), [])
    );

    useEffect(() => {
        if (branchId) {
            fetchParamsSpo(prev => ({
                ...prev,
                branchId: branchId
            }))
        }
    }, [branchId, fetchParamsSpo]);

    useEffect(() => {
        setValue('stockpointId', stockpointId);
    }, [watch, stockpointId, setValue, setParams]);

    return (
        <Select
            id="stockpointId"
            name="stockpointId"
            placeholder="Pilih Stock Point"
            className="basic-single"
            isLoading={loading}
            options={data?.map(item => ({ label: item?.name, value: item?.id }))}
            isClearable={true}
            isDisabled={!branchId || stockpointId}
            onChange={(stockpoint) => {
                if (stockpoint) {
                    setValue("stockpointId", stockpoint.value)
                    setParams(prev => ({
                        ...prev,
                        stockpoint: stockpoint?.value
                    }));
                }
                else {
                    setValue("stockpointId", null);
                    setParams(prev => ({
                        ...prev,
                        stockpoint: null
                    }));
                }
            }}
        />
    );
};

export default App;
