import React, { useCallback } from 'react';
import { Link } from 'react-router-dom'
import {
    Card,
    Row,
    Col,
    UncontrolledDropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from 'reactstrap';
import { useFetch, useAction } from "hooks";
import { Breadcrumb } from 'components';
import { Services } from 'service';
import IcMore from 'assets/svg/ic_more.svg'
import { format } from 'date-fns';

const App = ({
    title: documentTitle,
    access: permissionAccess
}) => {
    document.title = documentTitle;
    const { hasFetch } = useAction();

    /* fetching latest price */
    const { data: dataPrice, fetchData } = useFetch(
        `/api/back-office/default-price/latest`,
        { unpaged: false },
        useCallback(data => ({
            data: data?.data
        }), []),
        { onMount: true }
    );

    const dataBreadcrumb = [
        { to: null, label: 'Harga Standar' },
    ]
    const numerals = (number) => new Intl.NumberFormat('id-ID').format(number);

    const deleteAction = (id) => {
        const requestBody = {
            id: id
        };

        Services().delete(`/api/back-office/default-price/${id}`, requestBody)
            .then(() => {
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: `Harga standar berhasil dihapus` }
                });
                fetchData()
            })
            .catch(err => {
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: err?.message } });
            });
    }

    return (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Harga Standar</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                    {/* {(!dataPrice?.id && status === 'resolved') &&
                        <div className="d-inline-block float-right">
                            <Link to="/default-price/create">
                                <Button color="primary">
                                    <img src={IcPlus} alt='create' className='mr-2' height={19} /> Tambah Harga Standar
                                </Button>
                            </Link>
                        </div>
                    } */}

                </Col>
            </Row>

            <Card body style={{ maxWidth: "800px" }}>
                <Row>
                    <Col className='d-flex justify-content-between'>
                        <div>
                            <span className="text-black-50">
                                Harga Standar / Kg
                            </span>
                            <p className="mt-2 font-weight-bold d2">{dataPrice?.price ? dataPrice?.price.toLocaleString('id-ID') : "-"}</p>
                        </div>
                        {
                            (permissionAccess?.update && dataPrice?.price) && <UncontrolledDropdown direction="left">
                                <DropdownToggle nav className='p-0'>
                                    <img src={IcMore} alt='more' height={24} />
                                </DropdownToggle>
                                <DropdownMenu style={{ minWidth: 82 }}>
                                    <Link to={`/default-price/${dataPrice?.id}/edit`}>
                                        <DropdownItem>Edit</DropdownItem>
                                    </Link>
                                    <DropdownItem onClick={() => deleteAction(dataPrice.id)} style={{ display: "none" }}>Hapus</DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        }
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={3}>
                        <span className='text-black-50'>Minimum Top Up</span>
                        <p className="mt-2 text-16">
                            {dataPrice?.maximumBalance
                                ? numerals(dataPrice?.minimumTopUp)
                                : "-"
                            }
                        </p>
                    </Col>
                    <Col md={3}>
                        <span className='text-black-50'>Maksimum Top Up</span>
                        <p className="mt-2 text-16">
                            {dataPrice?.minimumTopUp
                                ? numerals(dataPrice?.maximumBalance)
                                : "-"
                            }
                        </p>
                    </Col>
                    <Col md={3}>
                        <span className='text-black-50'>Tanggal Dibuat</span>
                        <p className="mt-2 text-16">
                            {dataPrice?.lastModifiedDate
                                ? format(new Date(dataPrice?.lastModifiedDate), 'dd/MM/yyyy')
                                : "-"
                            }
                        </p>
                    </Col>
                    <Col md={3}>
                        <span className='text-black-50'>Dibuat Oleh</span>
                        <p className="mt-2 text-16">{dataPrice?.user ?? "-"}</p>
                    </Col>
                </Row>
            </Card>
        </>
    )
}

export default App;
