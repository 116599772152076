import React, { useCallback, useEffect, useState } from 'react';
import {
    Card, Row, Col, Form, CardBody, Input
} from 'reactstrap';
import { Pagination, Table as TableGenerator } from 'components';
import Select from 'react-select';
import { useFetch } from "hooks";
import { Breadcrumb } from 'components';
import { debounce } from 'helper';
import { format } from 'date-fns';
import ReactDatePicker from 'react-datepicker';

const App = ({
    title: documentTitle,
    access: permissionAccess
}) => {
    document.title = documentTitle;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);


    const { data, loading: isLoading, totalPage, params, setParams, error } = useFetch(
        `/api/back-office/oil-sales`,
        currentParams => ({
            size: 10,
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
        }),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        { withQueryParams: false, showAlertOnError: false }
    )

    const TableConfiguration = {
        columns: [
            {
                key: 'date', title: 'Tanggal',
                render: (value, row, key) => {
                    return <>{value ? format(new Date(value), "dd-MM-yyyy") : '-'}</>
                }
            },
            {
                key: 'buyer', title: 'Pembeli',
                render: (value, row, key) => {
                    return <>{value ?? '-'}</>
                }
            },
            {
                key: 'warehouseName', title: 'Warehouse',
                render: (value, row, key) => {
                    return <>{value ?? '-'}</>
                }
            },
            {
                key: 'oilWeight', title: 'Total Minyak (Kg)',
                render: (value, row, key) => {
                    return <>{value ?? '-'}</>
                }
            },
            {
                key: 'salesPrice', title: 'Total Penjualan (Rp)',
                render: (value, row, key) => {
                    return value ? "Rp " + value?.toLocaleString('id-ID') : '-'
                }
            },
            {
                key: 'createdBy', title: 'Ditambahkan Oleh',
                render: (value, row, key) => {
                    return <>{value ?? '-'}</>
                }
            },
        ]
    }

    const dataBreadcrumb = [
        { to: '#', label: 'Penjualan Minyak KBI' }
    ]

    /* set params for searching */
    const setParamsSearch = useCallback(debounce((name, value) => setParams(prev => ({ ...prev, page: 1, [name]: typeof value === 'string' ? value?.trim() : value })), 600), [])

    /* handle searching for input type */
    const handleSearchChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setParamsSearch(name, value);
    }

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        if (!start && !end) setParams(prev => ({
            ...prev,
            startDate: null,
            endDate: null
        }))
    };

    useEffect(() => {
        if (startDate && endDate) {
            setParams(prev => ({
                ...prev,
                startDate: format(new Date(startDate), 'dd-MM-yyyy'),
                endDate: format(new Date(endDate), 'dd-MM-yyyy')
            }))
        }
    }, [startDate, endDate, setParams]);


    return (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Rute Perjalanan</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Card body>
                <Form>
                    <Row className="justify-content-between">
                        <Col md={6}>
                            <Row>
                                <Col>
                                    <ReactDatePicker
                                        id="filterDateRange"
                                        name="filterDateRange"
                                        placeholderText="Pilih Tanggal"
                                        onChange={onChange}
                                        selected={startDate}
                                        startDate={startDate}
                                        endDate={endDate}
                                        className="form-control"
                                        dateFormat={"dd/MM/yyyy"}
                                        selectsRange
                                        isClearable
                                    />
                                </Col>
                                <Col>
                                    <WarehouseField
                                        name="warehouse"
                                        handleParams={setParams}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} >
                            <div className="input-search">
                                <i className="fa fa-search text-muted"></i>
                                <Input className="pl-5" type="text" name="search" id="search" placeholder="Cari Pembeli" onChange={handleSearchChange} defaultValue={params?.name} />
                            </div >
                        </Col>
                    </Row >
                </Form >
            </Card >
            <Card>
                <CardBody className="p-0">
                    <TableGenerator
                        {...TableConfiguration}
                        dataList={data}
                        params={params}
                        loading={isLoading}
                        error={error} />
                </CardBody>
            </Card>
            <Row className="pagination mr-1">
                <Col>
                    <Pagination
                        showRowsPerPage={false}
                        onChangePage={({ page, size }) => setParams(prev => ({ ...prev, page, size }))}
                        totalPage={totalPage}
                        currentPage={params.page}
                        limitPage={params.size}
                    />
                </Col>
            </Row>
        </>
    )
}

const WarehouseField = ({ name, handleParams }) => {

    /* fetching Warehouse list */
    const { data: warehouseList, loading: isLoadingWarehouse } = useFetch(
        `/api/back-office/warehouse`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );

    return (
        <Select
            id={name}
            name={name}
            classNamePrefix="select"
            options={warehouseList?.map(obj => ({ label: obj?.name, value: obj?.id }))}
            placeholder={`Pilih Warehouse`}
            isLoading={isLoadingWarehouse}
            validation={['required']}
            isClearable
            onChange={(val) => {
                val
                    ? handleParams(prev => ({
                        ...prev,
                        page: 1,
                        warehouse: val?.value
                    }))
                    : handleParams(prev => ({
                        ...prev,
                        page: 1,
                        warehouse: 0
                    }))
            }}
        />
    )
}

export default App;
