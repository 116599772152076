import React from 'react';
import { Card, CardBody, Container } from 'reactstrap';
import logo from 'assets/image/logo-gabp.png';

const About = props => {
    document.title = props.title;
    return (
        <div className="animated fadeIn">
            <Container>
                <Card className="p-4 card-welcome-home">
                    <CardBody className="text-center">
                        <h1 className='text-24'>Welcome to,</h1>
                        <h1>Gurih Awan Backoffice</h1>

                        <img src={logo} alt="logo" className="mt-4 w-100" style={{ maxWidth: 200 }} />
                    </CardBody>
                </Card>
            </Container>
        </div>
    );
}

export default About;
