import React from 'react'
import Input from 'components-form/Input';

const currencyFormatter = new Intl.NumberFormat('id-ID');

export const transformDecimal = (value, props) => {
    const regexNumber = String(value).replace(/[^\d,]/g, '');
    
    if (parseFloat(regexNumber) <= props?.max && regexNumber.length <= props?.maxed) {
        const finalNumber = regexNumber !== "" && currencyFormatter.format(regexNumber.replace(/[.,\s]/g, ''));
        return finalNumber || 0;
    } else {
        return ''
    }
};

const InputDecimal = ({
    ...props
}) => {
    return <Input
        {...props}
        transform={{
            output: e => transformDecimal(e.target.value, props)
        }}
    />
}

export default InputDecimal
