import React from 'react';
import { Link } from 'react-router-dom';
import { Badge, Card, CardBody } from 'reactstrap';
import { Table as TableGenerator } from 'components';

const getColorStatus = (status) => {
    switch (status) {
        case "completed":
            return <Badge color="success" className='text-10 text-white p-1'>
                Selesai
            </Badge>
        case "canceled":
            return <Badge color="danger" className='text-10 text-white p-1'>
                Dibatalkan
            </Badge>
        case "picking_up_process":
            return <Badge color="warning" className='text-10 text-white p-1'>
                Menunggu Jemput
            </Badge>
        default:
            return <Badge color="light" className='text-10 p-1'>
                {status}
            </Badge>
    }
}

const TableRowDetail = ({ raw }) => {
    const TableConfiguration = {
        columns: [
            {
                key: 'supplierName', title: 'Nama Supplier',
                render: (value, row, key) => {
                    return <Link to={`/pickup-request/${row?.pickupId}`}>{value}</Link>
                }
            },
            {
                key: 'outletName', title: 'Outlet',
                render: (value, row, key) => {
                    return <span>{value}</span>
                }
            },
            {
                key: 'oilPickupContainers', title: 'Jeriken 18 L',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    const volume18L = value?.filter(item => item.type === "jeriken")?.find(item => item.volume === "18");
                    return <span>{volume18L?.qty ?? "-"}</span>
                }
            },
            {
                key: 'oilPickupContainers', title: 'Jeriken 20 L',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    const volume20L = value?.filter(item => item.type === "jeriken")?.find(item => item.volume === "20");
                    return <span>{volume20L?.qty ?? "-"}</span>
                }
            },
            {
                key: 'oilWeight', title: 'Jumlah Minyak',
                render: (value, row, key) => {
                    const num = Number(value)
                    return <span>{num.toString().split(".").length > 1 ? num.toFixed(1) : num}</span>
                }
            },
            {
                key: 'totalPrice', title: 'Penjualan',
                render: (value, row, key) => {
                    const formatNumber = (number) => new Intl.NumberFormat('id-ID').format(number)
                    return <span>{formatNumber(value)}</span>
                }
            },
            {
                key: 'status', title: 'Status',
                render: (value, row, key) => {
                    return <span>{getColorStatus(value)}</span>
                }
            }
        ]
    }
    return (
        <div>
            <Card>
                <CardBody className="p-0">
                    <TableGenerator
                        {...TableConfiguration}
                        showNumber={false}
                        dataList={raw}
                        params={""}
                        loading={null}
                        error={null}
                    />
                </CardBody>
            </Card>
        </div>
    )
}

export default TableRowDetail