import React, { Suspense } from 'react';

// ==============================|| LOADABLE - LAZY LOADING ||============================== //

const Loadable = (Component) => (props) =>
(
    <Suspense fallback={<></>}>
        <Component {...props} />
    </Suspense>
);

export default Loadable