import React from 'react';
import { FormFeedback, CustomInput } from 'reactstrap';
import { Controller, useFormContext } from 'react-hook-form'
import validationRule from 'helper/ValidationRule';
// import { get } from 'lodash'
const get = (obj, path, def) => (() => typeof path === 'string' ? path.replace(/\[(\d+)]/g, '.$1') : path.join('.'))()
    .split('.')
    .filter(Boolean)
    .every(step => ((obj = obj[step]) !== undefined)) ? obj : def

const Switches = ({
    validation = [],
    validationMessage = [],
    name,
    defaultValue = null,
    label,
    containerClass = "",
    labelClass,
    ...props
}) => {
    const { control, getValues } = useFormContext()
    return (
        <Controller
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validationRule({ rule: validation, validationMessage }, getValues, name)}
            render={({ field: { value, ref, ...fieldInput }, formState: { errors } }) => {
                return (
                    <div className={`d-flex ${containerClass}`}>
                        <CustomInput
                            type="switch"
                            innerRef={ref}
                            {...props}
                            {...fieldInput}
                            onChange={(e) => fieldInput.onChange(e.target.checked)}
                            checked={value || false}
                            id={name}
                            label={null}
                        />
                        {label ? <span className={labelClass ?? "ml-3 mt-1"}>{label}</span> : null}
                        {get(errors, name) && <FormFeedback invalid="true" style={{ display: 'block' }}>{get(errors, name)?.message}</FormFeedback>}
                    </div>
                )
            }}
        />
    )
}

export default Switches;