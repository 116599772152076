import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Row, Col, CardHeader, CardBody, FormGroup, Label, Button, Spinner } from 'reactstrap';
import { useFetch, useAction } from 'hooks';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import Form from 'components-form/Form';
import Select from 'components-form/Select';
import DatePicker from 'components-form/DatePicker';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { format } from 'date-fns';
import InputCounter from 'components-form/InputCounter';
import { IcGlyphInformation } from '@elevenia/edts-icon';

const ContextForms = createContext();
const App = ({
    title: documentTitle,
    history
}) => {
    document.title = documentTitle;

    const { hasFetch } = useAction();
    const [jerikens, setjerikens] = useState({
        is18L: null,
        is20L: null
    })
    // const isCreate = params?.id ? false : true;
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const dataBreadcrumb = [
        { to: '/pickup-warehouse', label: 'Buat QR Warehouse' },
        { to: null, label: 'QR Penjemputan Baru' }
    ];

    /* fecthing data for detail page */
    const onSubmit = (input) => {
        setIsLoadingSubmit(true);
        const requestBody = {
            "driverId": input?.driver,
            "date": format(new Date(input?.tanggal), 'yyyy-MM-dd'),
            "originId": input?.asalMinyak,
            "totalContainer18": parseFloat(input?.jeriken18L),
            "totalContainer20": parseFloat(input?.jeriken20L)
        }
        const action = ({ requestBody, id = null }) => Services().post('/api/back-office/pickup-request/warehouse/create', requestBody)

        action({ requestBody })
            .then(() => {
                setIsLoadingSubmit(false);
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: `QR penjemputan baru berhasil dibuat.` }
                })
                history.push('/pickup-warehouse');
            })
            .catch(e => {
                setIsLoadingSubmit(false);
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? 'Terjadi Kesalahan' } })
            });
    };

    return (
        <ContextForms.Provider
            value={{
                jerikens,
                setjerikens
            }}
        >
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>QR Penjemputan Baru</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Form id="penggunaForm" onSubmit={onSubmit} autoComplete="off">
                <Card>
                    <CardHeader className="pb-0">
                        Informasi Penjemputan
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label htmlFor="nama_lengkap" sm={3}>Driver</Label>
                                    <Col sm={9}>
                                        <DriverList />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="username" sm={3}>Tanggal Penjemputan</Label>
                                    <Col sm={9}>
                                        <DatePicker
                                            name="tanggal"
                                            id="tanggal"
                                            validation={['required']}
                                            minDate={new Date()}
                                            placeholderText="Pilih Tanggal Penjemputan"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="asalMinyak" sm={3}>Asal Minyak</Label>
                                    <Col sm={9}>
                                        <WarehousePoint />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="jeriken18L" sm={3}>Jumlah Minyak Jeriken 18L</Label>
                                    <Col sm={3}>
                                        <Jeriken18L />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="jeriken20L" sm={3}>Jumlah Minyak Jeriken 20L</Label>
                                    <Col sm={3}>
                                        <Jeriken20L />
                                    </Col>
                                </FormGroup>
                                <FormGroup row className='mt-4 text-black-50 text-12'>
                                    <Col sm={{ size: 3, offset: 3 }} style={{ display: 'flex', gap: '10px' }}>
                                        <span>
                                            <IcGlyphInformation />
                                        </span>
                                        <span style={{ textWrap: 'nowrap' }}>
                                            Note :
                                        </span>
                                        <span>
                                            Jumlah Jeriken tidak boleh kosong. Minimal harus terisi di salah satu Jerikan 18 atau 20
                                        </span>
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Row>
                    <Col className="text-right">
                        <Link to="/pickup-warehouse">
                            <Button type="button" color="secondary" className="m-1" onClick={() => window.history.back()}>
                                Batal
                            </Button>
                        </Link>
                        <ConfirmButton isLoadingSubmit={isLoadingSubmit} />
                    </Col>
                </Row>
            </Form>
        </ContextForms.Provider>
    );
};

export const ConfirmButton = ({ isLoadingSubmit }) => {
    const { formState: { isValid } } = useFormContext();
    return (
        <Button disabled={!isValid || isLoadingSubmit} type="submit" color={!isValid ? "secondary" : "primary"} >
            {isLoadingSubmit &&
                <span className='mr-2'>
                    <Spinner size="sm" />
                </span>}
            Simpan
        </Button>
    );
};

export const Jeriken18L = () => {
    const { jerikens } = useContext(ContextForms);

    return (
        <InputCounter
            id="jeriken18L"
            name="jeriken18L"
            validation={['required', `maxValue[${jerikens?.is18L}]`]}
            validationMessage={['maxValue[Jumlah yang diisi tidak boleh melebihi stok jeriken di warehouce/DC]']}
            errorStyle={{ textWrap: 'nowrap' }}
            type="text"
            placeholder="Jeriken 18 L"
            autoComplete="off"
            transform={{
                output: (e) => {
                    const parsedValue = parseInt(e.target.value);
                    return isNaN(parsedValue) ? '' : parsedValue;
                }
            }}
        />
    );
};

export const Jeriken20L = () => {
    const { jerikens } = useContext(ContextForms);

    return (
        <InputCounter
            id="jeriken20L"
            name="jeriken20L"
            validation={['required', 'number', `maxValue[${jerikens?.is20L}]`]}
            validationMessage={['maxValue[Jumlah yang diisi tidak boleh melebihi stok jeriken di warehouce/DC]']}
            errorStyle={{ textWrap: 'nowrap' }}
            type="text"
            placeholder="Jeriken 20 L"
            autoComplete="off"
            transform={{
                output: (e) => {
                    const parsedValue = parseInt(e.target.value);
                    return isNaN(parsedValue) ? '' : parsedValue;
                }
            }}
        />
    );
};


export const DriverList = () => {
    /* fetching role list */
    const { data, loading: isLoadingRole } = useFetch(
        `/api/back-office/driver/delivery/on-duty`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );

    return (
        <Select
            id="driver"
            validation={['required']}
            name="driver"
            className="basic-single"
            classNamePrefix="select"
            options={data?.map(obj => ({ label: obj?.name, value: obj?.id }))}
            placeholder={`Pilih Nama Driver`}
            isLoading={isLoadingRole}
        />
    );
};

const getOilQty = (item, volume) => item?.oilContainers?.find(items => items?.volume === volume.toString())?.qty;

const WarehousePoint = () => {
    const { setValue } = useFormContext();
    const { setjerikens } = useContext(ContextForms);
    const { level, warehouseId } = useSelector(state => state?.homesidemenu?.profile);

    const { data: stockpoint, loading: isLoading } = useFetch(
        `/api/back-office/warehouse/oil-stock-list`,
        currentParams => ({
            unpaged: true
        }),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        { withQueryParams: false, showAlertOnError: false },
        { status: true, timeout: 3600 * 1000 }
    );

    // set asal minyak for WO
    useEffect(() => {
        if (warehouseId) {
            setValue('asalMinyak', warehouseId);
            const items = stockpoint?.find(item => item?.id === warehouseId);
            const jeriken18L = getOilQty(items, 18);
            const jeriken20L = getOilQty(items, 20);
            setValue('jeriken18L', 0);
            setValue('jeriken20L', 0);
            setjerikens(prev => ({
                ...prev,
                is18L: jeriken18L,
                is20L: jeriken20L
            }));
        };
    }, [setValue, warehouseId, stockpoint, setjerikens]);

    return (
        <Select
            id="driver"
            isDisabled={level === "Warehouse Officer"}
            validation={['required']}
            name="asalMinyak"
            className="basic-single"
            classNamePrefix="select"
            options={stockpoint?.map(item => ({
                ...item,
                value: item?.id,
                label: item?.name + " - " + item?.code
            }))}
            onChange={(item) => {
                const jeriken18L = getOilQty(item, 18);
                const jeriken20L = getOilQty(item, 20);
                setValue('jeriken18L', 0)
                setValue('jeriken20L', 0)
                setjerikens(prev => ({
                    ...prev,
                    is18L: jeriken18L,
                    is20L: jeriken20L
                }))
            }}
            placeholder={`Pilih Asal Minyak`}
            isLoading={isLoading}
        />
    );
};

export default App;
