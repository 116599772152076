import React, { createContext, useCallback, useContext, useState } from 'react'
import {
    Row, Col,
    Button,
    Badge,
    Spinner,
} from 'reactstrap';
import {
    useParams
} from 'react-router-dom';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import { useFetch, useAction } from 'hooks';
import Error404 from 'page/error/Error404';
import ModalPage from 'components/Modal';
import icCancel from 'assets/svg/ic_cancel_red.svg';
import icRegular from 'assets/svg/ic_Regular.svg';
import IcEdit from 'assets/svg/ic_edit_blue.svg'
import TableDetail from './component/component.table';
import Penyelesaian from './component/component.modal.solved';
import { format } from 'date-fns';

const getColorStatus = (status) => {
    switch (status) {
        case "completed":
            return <Badge color="success" className='text-10 p-1'>
                Selesai
            </Badge>
        case "canceled_by_admin":
            return <Badge color="danger" className='text-10 p-1'>
                Dibatalkan Admin
            </Badge>
        case "picking_up_process":
            return <Badge color="warning" className='text-10 p-1' style={{ color: "white" }}>
                Dalam Perjalanan
            </Badge>
        default:
            return <Badge color="info" className='text-10 p-1'>
                Menunggu Driver
            </Badge>
    }
}
export const Modalcontext = createContext();
const App = ({
    title: documentTitle,
    access: permissionAccess,
    history
}) => {
    document.title = documentTitle;
    const [rejected, setrejected] = useState(false);
    const [resolved, setresolved] = useState(false)
    const { id } = useParams();
    const { hasFetch } = useAction();
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const dataBreadcrumb = [
        { to: '/delivery-warehouse', label: 'Antar ke Warehouse' },
        { to: null, label: 'Detail Pengiriman' }
    ]

    const { data,
        status, error, fetchData } = useFetch(
            `/api/back-office/trip-plan/delivery/${id}`,
            {},
            useCallback(data => ({ data: data?.data }), [])
        )

    const deleteAction = (data) => {
        setIsLoadingSubmit(true);
        let id = data?.id;
        Services().post('/api/back-office/trip-plan/delivery/cancel/' + id)
            .then(() => {
                setIsLoadingSubmit(false);
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: `Penugasan Berhasil Dibatalkan` }
                });
                fetchData();
            })
            .catch(err => {
                setIsLoadingSubmit(false);
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: err?.message } });
            });
    }
    if ((status === 'rejected' && error.code === 400) ||
        (status === 'resolved' && data === null)) return <Error404 />
    return status === 'resolved' && (
        <Modalcontext.Provider
            value={{
                rejected,
                setrejected,
                resolved,
                setresolved,
                data
            }}
        >
            <Row className="row mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Rute Perjalanan</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <div className='d-flex justify-content-between'>
                <div className='d-flex' style={{ gap: "2rem" }}>
                    <div style={{ minWidth: "12rem" }}>
                        <div sm={3} style={{ color: "#70727D" }}>Nama Driver</div>
                        <div style={{ fontSize: "24px", fontWeight: 700 }} sm={9}>
                            {data?.driverName ?? "-"}
                        </div>
                    </div>
                    <div style={{ minWidth: "15rem" }}>
                        <div sm={3} style={{ color: "#70727D" }}>Warehouse</div>
                        <div style={{ fontSize: "24px", fontWeight: 700 }} sm={9}>
                            {data?.warehouseName ?? "-"}
                        </div>
                    </div>
                    <div style={{ minWidth: "12rem" }}>
                        <div sm={3} style={{ color: "#70727D" }}>Jumlah Titik Jemput</div>
                        <div style={{ fontSize: "24px", fontWeight: 700 }} sm={9}>
                            {data?.totalCompleteDelivery ?? "-"}
                        </div>
                    </div>
                    <div style={{ minWidth: "12rem" }}>
                        <div sm={3} style={{ color: "#70727D" }}>Jumlah Minyak Diantar (Kg)</div>
                        <div style={{ fontSize: "24px", fontWeight: 700 }} sm={9}>
                            {data?.oilDeliverySummary?.totalOilWeight ?? "-"}
                        </div>
                    </div>
                </div>
                <div className='d-flex align-items-center'>
                    <Row className='d-inline-block float-right mt-2 mt-md-0'>
                        {(permissionAccess?.update && data?.status === "waiting_driver") && <Col>
                            <Button onClick={() => history?.push('/delivery-warehouse/' + id + '/edit')} color='primary' size='sm' type="button" outline className="btn-edit">
                                <div className='d-flex align-items-center' >
                                    <img className='ml-1' src={IcEdit} alt="driver" style={{ height: 24 }} />
                                    <span style={{ lineHeight: 2 }} className='pl-2'>Edit</span>
                                </div>
                            </Button>
                        </Col>}
                    </Row>
                </div >
            </div >
            <div className='my-3' style={{ borderTop: "1px solid #DCDEE3" }} />
            <div className='d-flex justify-content-between flex-wrap'>
                <div className='d-flex' style={{ gap: "2rem" }}>
                    <div className='d-flex flex-column' style={{ gap: "0.5rem", minWidth: "12rem" }}>
                        <div sm={3} style={{ color: "#70727D" }}>Status</div>
                        <div style={{ fontSize: "10px", fontWeight: 400, display: "flex", alignItems: "center" }} sm={9}>
                            {getColorStatus(data?.status)}
                        </div>
                    </div>
                    <div className='d-flex flex-column' style={{ gap: "0.5rem", minWidth: "15rem" }}>
                        <div sm={3} style={{ color: "#70727D" }}>No Tiket</div>
                        <div style={{ fontSize: "16px", fontWeight: 400 }} sm={9}>
                            {data?.tripPlanCode ?? "-"}
                        </div>
                    </div>
                    <div className='d-flex flex-column' style={{ gap: "0.5rem", minWidth: "12rem" }}>
                        <div sm={3} style={{ color: "#70727D" }}>Tanggal penugasan</div>
                        <div style={{ fontSize: "16px", fontWeight: 400 }} sm={9}>
                            {data?.assignmentDate && format(new Date(data?.assignmentDate), 'dd/MM/yyyy')}
                        </div>
                    </div>
                    {
                        data?.status === "completed" && <div className='d-flex flex-column' style={{ gap: "0.5rem", minWidth: "12rem" }}>
                            <div sm={3} style={{ color: "#70727D" }}>Rugi</div>
                            <div style={{ fontSize: "16px", fontWeight: 400 }} sm={9}>
                                {data?.lossCash ?? "-"}
                            </div>
                        </div>
                    }
                </div>
                <div className='d-flex align-items-center'>
                    <Row className='d-inline-block float-right mt-2 mt-md-0'>
                        <Col>
                            {(permissionAccess?.delete && data?.status === "waiting_driver") && <Button onClick={(e) => { setrejected(!rejected); e.currentTarget.blur(); }} color='danger' size='sm' type="button" outline className="btn-cancel">
                                <div className='d-flex align-items-center'>
                                    <img className='ml-1' src={icCancel} alt="driver" style={{ height: 12 }} />
                                    <span style={{ lineHeight: 2 }} className='pl-2'>Batalkan Penugasan</span>
                                </div>
                            </Button>}
                            {(permissionAccess?.update && data?.status === "picking_up_process") && <Button onClick={() => setresolved(!resolved)} color='primary' size='sm' type="button" outline className="btn-primary">
                                <div className='d-flex align-items-center'>
                                    <img className='ml-1' src={icRegular} alt="driver" style={{ height: 24 }} />
                                    <span style={{ lineHeight: 2 }} className='pl-2'>Penyelesaian</span>
                                </div>
                            </Button>}
                        </Col>
                    </Row>
                </div>
            </div>
            <div className='pt-3'>
                <div className='py-3' style={{ fontSize: "16px", fontWeight: 500 }}>Daftar Dijemput</div>
                <div>
                    <TableDetail />
                </div>
            </div>
            <Batalkan deleteAction={deleteAction} isLoadingSubmit={isLoadingSubmit} />
            <Penyelesaian />
        </Modalcontext.Provider>
    )
}

export const Batalkan = ({ deleteAction, isLoadingSubmit }) => {
    const { rejected, setrejected } = useContext(Modalcontext);
    const { id } = useParams();
    return <ModalPage
        isOpen={rejected}
        handleClose={() => setrejected(!rejected)}
        component={<>
            <div className='moda-password-title font-weight-bold'>Batalkan Penugasan</div>
            <p style={{ fontSize: "14px" }} className='moda-password-title font-weight-normal m-0'>
                Tugas dari rute perjalanan ini akan dibatalkan oleh admin
            </p>
            <div className='d-flex justify-content-end pt-4'>
                <Button onClick={() => setrejected(!rejected)} type='button' className="mr-3" size='sm' outline color="primary">Kembali</Button>
                <Button
                    onClick={() => {
                        deleteAction({ id })
                        setrejected(false)
                    }}
                    disabled={isLoadingSubmit}
                    type='button'
                    className="mr-3"
                    size='sm'
                    color="primary"
                >
                    {isLoadingSubmit &&
                        <span className='mr-2'>
                            <Spinner size="sm" />
                        </span>}
                    Konfirmasi
                </Button>
            </div>
        </>
        }
    />
}

export default App