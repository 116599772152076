import ModalPage from 'components/Modal';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Label, Row } from 'reactstrap';
import DatePicker from "components-form/DatePicker";
import Form from 'components-form/Form';
import Select from 'components-form/Select';
import AsyncSelect from 'components-form/AsyncSelect';

import { useAction, useFetch } from 'hooks';
import { addHours, format } from 'date-fns';
import { Services } from 'service';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useFormContext, useWatch } from 'react-hook-form';
import { debounce } from 'helper';
import InputMoneyValidate from 'components-form/InputMoneyValidate';

const findDriver = [{
    label: "Internal Stockpoint",
    value: "internal"
}, {
    label: "External Stockpoint",
    value: "external"
}]

const ModalForms = ({ open, setopen, raw, refetch, setCalculate }) => {
    /* fetching role list */
    const { data: roleList, loading: isLoadingRole, setParams: setparamsDriver } = useFetch(
        `/api/back-office/driver/assignment`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );
    const { data: cashData, setParams: setStockPointList } = useFetch(
        `/api/back-office/petty-cash/balance`,
        {
            // stockpoint: raw[0]?.stockpointId,
            unpaged: true
        },
        useCallback(data => ({
            data: data?.data
        }), []),
        { onMount: open }
    );
    useEffect(() => {
        setStockPointList(prev => ({
            ...prev,
            stockpoint: raw[0]?.stockpointId
        }))
    }, [open, setStockPointList, raw])

    const params = useHistory()
    const defaultValues = {
        roleId: null,
        tanggalPenugasan: null,
        moneyPick: ""
    }
    const [flex, setflex] = useState("internal")
    const hasFetch = useDispatch()
    const weight = raw.reduce((acc, item) => acc + item.oilWeight, 0);
    const price = raw.reduce((acc, item) => acc + item.totalPrice, 0);
    const formatNumber = (number) => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number);
    const numerals = (number) => new Intl.NumberFormat('id-ID').format(number);
    const handleSubmit = (value) => {
        let assingmentCash = ""
        if (typeof value?.moneyPick === "string") {
            assingmentCash = value?.moneyPick?.replace(/[^\d,]/g, '')
        }
        else {
            assingmentCash = value?.moneyPick || ''
        }
        const requestBody = {
            "driverId": value?.roleId,
            "pickupRequestId": raw.map(item => item.id),
            "assignmentDate": format(addHours(value?.tanggalPenugasan, 7), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
            "assignmentCash": assingmentCash === "" ? "" : parseFloat(assingmentCash?.replace(/,/g, '.'))
        }
        const action = ({ requestBody, id = null }) => Services().post('/api/back-office/trip-plan/pickup', requestBody)

        action({ requestBody })
            .then((result) => {
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: `Penjemputan baru berhasil Ditambah` }
                })
                setopen(!open)
                params.push(`/pickup-request`);
                refetch();
                setCalculate([])
            })
            .catch(e => {
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message } })
            })
    }
    const x = raw?.map(item => {
        return item?.oilContainers.filter(item => item.volume !== "16")
    });

    const qtyByVolume = {};

    for (const array of x) {
        for (const item of array) {
            const volume = item.volume;
            const qty = item.qty;
            if (qtyByVolume["jeriken" + volume]) {
                qtyByVolume["jeriken" + volume] += qty;
            } else {
                qtyByVolume["jeriken" + volume] = qty;
            }
        }
    }

    return (
        <ModalPage
            isOpen={open}
            handleClose={setopen}
            component={<>
                <div className='moda-password-title'>Penjemputan</div>
                <Row className='mb-1'>
                    <Col className='d-flex justify-content-between' md={12}>
                        <div>
                            <Label >Jumlah Outlet</Label>
                            <h4>{raw.length}</h4>
                        </div>
                        <div>
                            <Label >Jeriken 18 L</Label>
                            <h4>{qtyByVolume?.jeriken18 ?? "-"}</h4>
                        </div>
                        <div>
                            <Label >Jeriken 20 L</Label>
                            <h4>{qtyByVolume?.jeriken20 ?? "-"}</h4>
                        </div>
                    </Col>
                    <Col md={12}>
                        <Row>
                            <Col md={6}>
                                <Label >Jumlah Minyak</Label>
                                <h4>{weight.toFixed(1)} Kg</h4>
                            </Col>
                            <Col md={6}>
                                <Label>Jumlah Penjualan</Label>
                                <h4>{formatNumber(price)}</h4>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={12} className='py-3 px-4'>
                        <Row className='border border-light justify-content-between px-4' style={{ padding: 12, borderRadius: 6 }}>
                            <Col md={3} className='p-0 d-flex' style={{ flexDirection: "column" }}>
                                <Label className='mb-1' style={{ color: '#70727D', fontSize: 14, fontWeight: 700 }}>Petty Cash</Label>
                                <span style={{ color: '#151823', fontSize: 14, fontWeight: 600 }}>{numerals(cashData?.pettyCash)}</span>
                            </Col>
                            <Col md={3} className='p-0 d-flex' style={{ flexDirection: "column" }}>
                                <Label className='mb-1' style={{ color: '#70727D', fontSize: 14, fontWeight: 700 }}>Sisa kas</Label>
                                <span style={{ color: '#151823', fontSize: 14, fontWeight: 600 }}>{numerals(cashData?.cash)}</span>
                            </Col>
                            <Col md={3} className='p-0 d-flex' style={{ flexDirection: "column" }}>
                                <Label className='mb-1' style={{ color: '#70727D', fontSize: 14, fontWeight: 700 }}>Total Modal</Label>
                                <span style={{ color: '#1178D4', fontSize: 14, fontWeight: 600 }}>{numerals(cashData?.totalAmount)}</span>
                            </Col>
                        </Row>
                    </Col>
                    <Form className="w-100" id="modalForm" onSubmit={handleSubmit} autoComplete="off" defaultValue={defaultValues}>
                        <Col md={12} className="pb-2">
                            <Label >Tanggal penugasan</Label>
                            <DatePicker
                                placeholderText="Pilih Tanggal"
                                isClearable
                                // // value={fillDate}
                                onChange={(date) => {
                                    setparamsDriver(prev => ({
                                        ...prev,
                                        date: format(date, "dd-MM-yyyy")
                                    }))
                                }}
                                validation={['required']}
                                name="tanggalPenugasan"
                                className="form-control"
                                minDate={new Date()}
                                dateFormat={"dd/MM/yyyy"}
                            />
                        </Col>
                        <Col md={12} className="pb-2">
                            <Label htmlFor="roleId">Cari Driver Berdasarkan</Label>
                            <Select
                                id="roleId"
                                onChange={(finds) => {
                                    setflex(finds?.value)
                                }}
                                // validation={['required']}
                                name="findDriver"
                                className="basic-single"
                                classNamePrefix="select"
                                options={findDriver}
                                placeholder={`Pilih Driver`}
                                // isLoading={isLoadingRole}
                                defaultValue={findDriver[0]?.value}
                            />
                        </Col>
                        <Col md={12} className="pb-2">
                            <Label htmlFor="roleId">Driver</Label>
                            {flex === "internal" && <Select
                                id="roleId"
                                validation={['required']}
                                name="roleId"
                                className="basic-single"
                                classNamePrefix="select"
                                options={roleList?.map(obj => ({ label: obj?.name, value: obj?.id }))}
                                placeholder={`Pilih Driver`}
                                isLoading={isLoadingRole}
                                defaultValue={null}
                            />}
                            {flex === "external" && <DriverExternal />}
                        </Col>
                        <Col md={12}>
                            <Label>Uang yang dibawa</Label>
                            <TrigerValue price={price} cash={cashData?.cash} />
                        </Col>
                        <Col>
                            <div className='text-right mt-4'>
                                <Button onClick={setopen} outline size='sm' type="button" color="primary" >
                                    Batal
                                </Button>
                                <Button size='sm' type="submit" color="primary" >
                                    Tugaskan
                                </Button>
                            </div>
                        </Col>
                    </Form>
                </Row>
            </>
            }
        />
    )
}
export const TrigerValue = ({ price, cash }) => {
    const { register, setValue } = useFormContext();
    const formatNumeric = (number) => new Intl.NumberFormat('id-ID').format(number)
    useEffect(() => {
        setValue("moneyPick", formatNumeric(price))
    }, [price, setValue])
    return (
        <InputMoneyValidate
            id="uang"
            validation={[`required`, `minThan[${price}]`, `moreThanCurrently[${price}]`]}
            name="moneyPick"
            type="text"
            placeholder="0"
            autoComplete="off"
            {...register}
        />
    )
}
export const DriverExternal = () => {
    const val = useWatch()
    const { hasFetch } = useAction();
    const loadOptions = debounce(async (inputValue, callback) => {
        try {
            const date = format(new Date(val?.tanggalPenugasan), "dd-MM-yyyy")
            const raw = await Services().get('/api/back-office/driver/stc-assignment/cross', {
                driverCode: inputValue,
                date
            })
            callback([{
                label: raw?.data?.data?.name,
                value: raw?.data?.data?.id,
            }])
        } catch (err) {
            hasFetch({
                type: 'ALERT_TOAST_ERROR',
                payload: { message: err.message }
            })
        }
    }, 1000);
    return (
        <AsyncSelect
            id="roleId"
            loadOptions={loadOptions}
            validation={['required']}
            name="roleId"
            className="basic-single"
            classNamePrefix="select"
            placeholder={`Pilih Driver`}
        />
    )
}

export default ModalForms