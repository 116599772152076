import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Input as ReactStrapInput, FormFeedback, InputGroup } from 'reactstrap'
// import { get } from 'lodash'
import validationRule from 'helper/ValidationRule';
const get = (obj, path, def) => (() => typeof path === 'string' ? path.replace(/\[(\d+)]/g, '.$1') : path.join('.'))()
    .split('.')
    .filter(Boolean)
    .every(step => ((obj = obj[step]) !== undefined)) ? obj : def

const defaultTransform = {
    output: val => val,
    input: val => val,
}

const InputId = ({
    inputCode,
    validation = [],
    validationMessage = [],
    inputGroupAddon = null,
    name,
    defaultValue = '',
    shouldUnregister = false,
    transform = defaultTransform,
    ...props
}) => {
    const { control, getValues } = useFormContext()

    const transformer = { ...defaultTransform, ...transform }
    return (
        <Controller
            shouldUnregister={shouldUnregister}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validationRule({ rule: validation, validationMessage }, getValues, name)}
            render={({ field: { ref, onChange, value, ...fieldInput }, formState: { errors } }) => {
                let isValue = value.replace(inputCode, "").replace(/\//g, "").match(/.{1,4}/g)
                const f = isValue?.map((_, i) => {
                    if (i === 4) {
                        return inputCode + _
                    }
                    return _
                })
                const x = f?.join('/')
                return (
                    <InputGroup>
                        <ReactStrapInput
                            invalid={!!get(errors, name)}
                            innerRef={ref}
                            {...fieldInput}
                            maxLength={24}
                            onChange={e => onChange(transformer.output(e))}
                            value={transformer.input(x ?? "")}
                            {...props} />
                        {inputGroupAddon}
                        {get(errors, name) && <FormFeedback invalid={"true"} style={{ display: 'block' }}>{get(errors, name)?.message}</FormFeedback>}
                    </InputGroup>
                )
            }}
        />
    )
}

export default InputId