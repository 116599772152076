import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormFeedback } from 'reactstrap'
// import { get } from 'lodash'
import ReactCreatableSelect from 'react-select/creatable';
import validationRule from 'helper/ValidationRule';
import { isValidValue, styles, theme } from '.';
const get = (obj, path, def) => (() => typeof path === 'string' ? path.replace(/\[(\d+)]/g, '.$1') : path.join('.'))()
  .split('.')
  .filter(Boolean)
  .every(step => ((obj = obj[step]) !== undefined)) ? obj : def

const CreatableSelect = ({
  validation = [],
  validationMessage = [],
  name,
  defaultValue = null,
  shouldUnregister = false,
  ...props
}) => {
  const { control, setValue, trigger, getValues, ...otherForm } = useFormContext()
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={validationRule({ rule: validation, validationMessage }, getValues, name)}
      render={({ field: { value, ...fieldInput }, formState: { errors } }) => (<>
        <ReactCreatableSelect
          {...props}
          {...fieldInput}
          value={isValidValue(value) ? value : null}
          onChange={selected => props.onChange
            ? props.onChange(selected, { setValue, trigger, ...otherForm })
            : fieldInput.onChange(selected)}
          className="basic-single"
          classNamePrefix="select"
          theme={theme}
          styles={styles}
        />
        {get(errors, name) && <FormFeedback invalid="true" style={{ display: 'block' }}>{get(errors, name)?.message}</FormFeedback>}
      </>)}
    />
  )
}

export default CreatableSelect