import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { MapContainer, Marker, TileLayer, useMap } from 'react-leaflet';
import { Icon } from 'leaflet';
import PinPoint from '../../../assets/svg/ic_marker.svg'
import 'leaflet/dist/leaflet.css';
import { Services } from "service";

const center = {
    // set default location in monas

    lat: -6.1791583,
    lng: 106.8279038,
}

const MapsDraggable = ({ mapsContext }) => {
    const { setDataAddress, dataAddress, setLoadingSearch } = useContext(mapsContext)

    const DraggableMarker = () => {

        const [position, setPosition] = useState({
            lat: dataAddress?.lat ?? center.lat,
            lng: dataAddress?.lon ?? center.lng
        });

        const GetNearestLoc = (lat, lon) => {
            setLoadingSearch(true);
            Services().get('/api/open/map/location', {
                latitude: lat,
                longitude: lon
            }).then(data => {
                setLoadingSearch(false);
                setDataAddress({
                    address: data?.data?.data?.content?.[0]?.address,
                    lat: data?.data?.data?.content?.[0]?.lat,
                    lon: data?.data?.data?.content?.[0]?.lon,
                    name: data?.data?.data?.content?.[0]?.name
                });
            });
        };

        const map = useMap();

        useEffect(() => {
            if (dataAddress) {
                map.setView(position, map.getZoom());
            }
        }, [position, map])

        const markerRef = useRef(null)
        const eventHandlers = useMemo(
            () => ({
                dragend() {
                    const marker = markerRef.current
                    if (marker != null) {
                        setPosition(marker.getLatLng())
                        setDataAddress(prev => ({
                            ...prev,
                            lat: marker.getLatLng()?.lat,
                            lon: marker.getLatLng()?.lng,
                        }))
                        GetNearestLoc(marker.getLatLng()?.lat, marker.getLatLng()?.lng)
                    }
                },
            }),
            [],
        )

        return (
            <Marker
                draggable
                eventHandlers={eventHandlers}
                position={position}
                ref={markerRef}
                icon={new Icon({
                    iconUrl: PinPoint,
                    iconRetinaUrl: PinPoint,
                    popupAnchor: null,
                    shadowAnchor: null,
                    iconSize: [40, 40],
                    iconAnchor: [22, 40]
                })}
            >
            </Marker>
        )
    }

    return <MapContainer
        center={center}
        zoom={15}
        scrollWheelZoom={false}
        scrollTopAtClose={true}
        style={{ width: '100%', height: window.innerHeight / 2, zIndex: 1 }}
    >
        <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <DraggableMarker />
    </MapContainer>
}

export default MapsDraggable