import { useEffect, useState, useCallback, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import qs from 'query-string'
import { Services } from 'service'
import { showToast } from 'hooks/useAlertToast';

const fromEntries = require('fromentries')

const initialState = {
  data: null,
  status: 'idle',
  error: null,
  totalPage: 1,
  loading: false
}

function reducer(state, action) {
  switch (action.type) {
    case 'GET_REQUEST':
      return {
        ...state,
        error: null,
        status: "resolve",
        loading: true
      }
    case 'GET_REQUEST_SUCCESS':
      return {
        ...state,
        data: action.payload.data,
        totalPage: action.payload.totalPage ?? 1,
        status: 'resolved',
        loading: false
      }
    case 'GET_REQUEST_FAILURE':
      return {
        ...state,
        status: 'rejected',
        error: action.payload,
        loading: false
      }
    default:
      throw new Error(`There is no action type : ${action.type}`)
  }
}

const defaultConfig = {
  onMount: true,
  withQueryParams: false,
  dependencies: undefined,
  showAlertOnError: true
}

export const useFetch = (url, paramsArg = {}, mapData, configArgs = {}, realTime = { status: false, timeout: null }) => {
  const history = useHistory();
  const config = { ...defaultConfig, ...configArgs };
  const defaultParams = typeof paramsArg === 'function'
    ? paramsArg(qs.parse(history.location.search))
    : paramsArg;
  const queryParams = qs.parse(history.location.search)
  const [params, setParams] = useState(
    (Object.keys(queryParams).length && config.withQueryParams)
      ? { ...queryParams, ...defaultParams }
      : defaultParams
  )
  const [state, dispatch] = useReducer(reducer, initialState)

  const getParams = useCallback(() => {
    const { page, size, ...query } = params
    const pagination = page ? { page: page - 1, size } : {}
    return { ...query, ...pagination }
  }, [params])

  const pushParamsToHistory = useCallback(() => {
    const { size, ...publicParams } = params
    const filteredParams = Object.entries(publicParams).filter(([, value]) => value !== null)
    history.replace({
      search: `?${qs.stringify(fromEntries(filteredParams))}`
    })
  }, [params, history])

  const fetchData = useCallback(() => {
    dispatch({ type: 'GET_REQUEST' })
    Services().get(url, getParams())
      .then(({ data }) => {
        if (realTime.status) {
          setTimeout(() => {
            setParams(prev => ({
              ...prev,
            }));
          }, realTime.timeout)
        }
        dispatch({ type: 'GET_REQUEST_SUCCESS', payload: mapData(data) })
        config.withQueryParams && pushParamsToHistory()
      })
      .catch(e => {
        dispatch({ type: 'GET_REQUEST_FAILURE', payload: e })
        // config.showAlertOnError && dispatchRedux({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ? e?.message : e?.status ?? 'Cannot get data' } })
        config.showAlertOnError && showToast({ name: 'error', message: e?.message ? e?.message : e?.status ?? 'Cannot get data' });
      })
  }, [config.withQueryParams, config.showAlertOnError, getParams, url, pushParamsToHistory, mapData, realTime.status, realTime.timeout])

  const dependenciesIsPassed = useCallback(dependencies => {
    switch (typeof dependencies) {
      case 'string':
        return dependencies === 'resolved'
      case 'object':
        return !dependencies.map(val => val === 'resolved').includes(false)
      default:
        throw new Error(`Invalid dependencies for ${dependencies}`)
    }
  }, [])

  useEffect(() => {
    if (typeof config.dependencies !== 'undefined') {
      dependenciesIsPassed(config.dependencies) && fetchData();
    } else {
      config.onMount && fetchData();
    }
  }, [fetchData, dependenciesIsPassed, config.dependencies, config.onMount])

  return { ...state, params, setParams, fetchData }
}