const InitialStateModal = {
    type: null,
    isLoading: false,
    show: false,
    message: 'Are you sure ?',
    description: null,
    confirmText: "Ya",
    dismissText: "Tidak",
    onConfirm: null
}

export const confirmationModal = (state = InitialStateModal, action) => {
    switch (action.type) {
        case 'CONFIRMATION_MODAL_SHOW':
            return {
                ...state,
                type: action?.payload?.type,
                message: action?.payload?.message ? action?.payload?.message : InitialStateModal.message,
                description: action?.payload?.description ? action?.payload?.description : InitialStateModal.description,
                onConfirm: action?.payload?.onConfirm,
                confirmText: action?.payload?.confirmText ? action?.payload?.confirmText : InitialStateModal.confirmText,
                dismissText: action?.payload?.dismissText ? action?.payload?.dismissText : InitialStateModal.dismissText,
                show: true,
                isLoading: false
            }
        case 'CONFIRMATION_MODAL_HIDE':
            return {
                ...state,
                show: false
            }
        case 'CONFIRMATION_MODAL_ACTION_START':
            return {
                ...state,
                isLoading: true
            }
        default:
            return state
    }
}
