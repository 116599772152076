import React, { useCallback, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, Row, Col, CardHeader, CardBody, FormGroup, Label, Button, InputGroup, Spinner } from 'reactstrap';
import { useFetch, useAction } from 'hooks';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import Form from 'components-form/Form';
import Input from 'components-form/Input';
import Switches from 'components-form/Switches';
import { hideConfirmationModal } from 'store/actions/confirmationModal';
import Error404 from 'page/error/Error404';
import { useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import Select from 'components-form/Select';

const App = ({
    title: documentTitle,
    history
}) => {
    document.title = documentTitle;

    const [showPassword, setShowPassword] = useState(false);
    const params = useParams();
    const { hasFetch } = useAction();
    const isCreate = params?.id ? false : true;
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const dataBreadcrumb = isCreate ?
        [
            { to: '/driver', label: 'Master Data Driver' },
            { to: null, label: 'Tambah Driver Stockpoint' }
        ]
        : [
            { to: '/driver', label: 'Master Data Driver' },
            { to: `/driver/${params.id}`, label: 'Detail Driver' },
            { to: null, label: 'Edit Driver Stockpoint' }
        ]

    /* fecthing data for detail page */
    const { data, status, error } = useFetch(
        `/api/back-office/driver/${params?.id}`,
        {},
        useCallback(data => ({
            data: {
                ...data?.data
            }
        }), []),
        { onMount: !isCreate }
    )

    const onSubmit = (input) => {
        setIsLoadingSubmit(true);
        const id = params?.id;
        delete input.userLevel;
        const requestBody = {
            ...input,
            type: "stockpoint",
            branchId: input.branchId !== null ? input.branchId : [],
            stockpointId: input.stockpointId !== null ? input.stockpointId : []
        };
        if (requestBody.password === "") {
            delete requestBody.password
        }
        const action = ({ requestBody, id = null }) => isCreate
            ? Services().post('/api/back-office/driver/registration', requestBody)
            : Services().post(`/api/back-office/driver/update`, { ...requestBody, id: parseInt(id) })

        action({ requestBody, id: params?.id })
            .then(() => {
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: isCreate ? `Driver baru berhasil ditambah` : `Driver baru berhasil diubah` }
                })
                setIsLoadingSubmit(false);
                history.push(isCreate ? '/driver' : `/driver/${id}`);
                hasFetch(hideConfirmationModal());
            })
            .catch(e => {
                setIsLoadingSubmit(false);
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Cannot update ${id}` } })
                hasFetch(hideConfirmationModal());
            })
    }
    const defaultValues = {
        name: data?.name ?? '',
        phone: data?.phone ?? '',
        password: data?.password ?? "",
        stockpointId: data?.stockpointId ?? [],
        branchId: data?.branchId ?? [],
        active: data?.active ?? false
    }

    const { profile } = useSelector(state => state.homesidemenu);
    const gm = profile?.stockpointName === null || profile?.branchName === null;
    const who = profile?.branchName === null || profile?.warehouseName === null
    if ((status === 'rejected' && error.code === 400) || (status === 'resolved' && data === null)) return <Error404 />
    else if (gm && who) return <Error404 />
    return (status === 'resolved' || isCreate) && (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>{isCreate ? "Tambah" : "Edit"} Driver Stockpoint</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Form id="driverForm" onSubmit={onSubmit} autoComplete="off" defaultValues={defaultValues}>
                <Card>
                    <CardHeader className="pb-0">
                        Informasi Driver
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label htmlFor="nama_lengkap" sm={3}>Nama Lengkap</Label>
                                    <Col sm={9}>
                                        <Input id="nama_lengkap" validation={['required']} name="name" type="text" placeholder="Nama Lengkap" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="phone" sm={3}>Nomor Handphone</Label>
                                    <Col sm={9}>
                                        <Input id="phone" disabled={!isCreate} maxLength={15} validation={['required', 'phone', 'minLength[10]', 'maxLength[15]']} name="phone" type="text" placeholder="Nomor Handphone" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                                {isCreate && <FormGroup htmlFor="secret" row>
                                    <Label sm={3}>Password</Label>
                                    <Col sm={9}>
                                        <InputGroup>
                                            <Input
                                                id="secret"
                                                type={`${showPassword ? "text" : "password"}`}
                                                name="password"
                                                placeholder="Password"
                                                validation={isCreate ? ['required', 'minLength[8]', 'passwordWithCharacter'] : ['minLength[8]', 'passwordWithCharacter']}
                                                autoComplete="new-password"
                                                maxLength={50} />
                                            <i className={`text-14 icon-password fa ${(showPassword) ? "fa-eye" : "fa-eye-slash"}`} aria-hidden="true" onClick={() => setShowPassword(!showPassword)} />
                                        </InputGroup>
                                    </Col>
                                </FormGroup>}
                                <FormGroup row>
                                    <Label htmlFor="branch" sm={3}>Branch</Label>
                                    <Col sm={9}>
                                        <BranchField name="branchId" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="stockPoint" sm={3}>StockPoint</Label>
                                    <Col sm={9}>
                                        <StockPoint name="stockpointId" />
                                    </Col>
                                </FormGroup>
                                {(!isCreate) ? data?.status !== "unverified" && <FormGroup row>
                                    <Label htmlFor="active" sm={3}>Status</Label>
                                    <Col sm={9}>
                                        <Switches
                                            className="custom-switch-success mt-1"
                                            name="active"
                                            label={defaultValues?.active ? "Aktif" : "Tidak Aktif"}
                                        />
                                    </Col>
                                </FormGroup> : null}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Row>
                    <Col className="text-right">
                        <Link to={(isCreate) ? "/driver" : `/driver/${params.id}`}>
                            <Button type="button" color="secondary" className="m-1" onClick={() => window.history.back()}>
                                Batal
                            </Button>
                        </Link>
                        <Button type="submit" color="primary" className="m-1" disabled={isLoadingSubmit}>
                            {isLoadingSubmit &&
                                <span className='mr-2'>
                                    <Spinner size="sm" />
                                </span>}
                            {(isCreate) ? 'Buat' : 'Simpan'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

const BranchField = ({ name }) => {
    const { trigger } = useFormContext();

    /* fetching branch list */
    const { data, loading } = useFetch(
        `/api/back-office/branch`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );

    return (
        <Select
            id={name}
            name={name}
            className="basic-single"
            classNamePrefix="select"
            options={data?.map(obj => ({ label: `${obj?.code} - ${obj?.name}`, value: obj?.id }))}
            placeholder={`Pilih Branch`}
            isLoading={loading}
            // isMulti={true}
            // isDisabled={isDisabled}
            validation={['required']}
            onChange={() => trigger()}
        />
    )
}

const StockPoint = ({ name }) => {
    const { trigger } = useFormContext();

    /* fetching stock point list */
    const { data: stockPointList, loading: isLoadingStockPoint } = useFetch(
        `/api/back-office/stockpoint`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );

    return (
        <Select
            id={name}
            name={name}
            className="basic-single"
            classNamePrefix="select"
            options={stockPointList?.map(obj => ({ label: `${obj?.code} - ${obj?.name}`, value: obj?.id }))}
            placeholder={`Pilih Stock Point`}
            isLoading={isLoadingStockPoint}
            validation={['required']}
            onChange={() => trigger()}
        />
    )
}

export default App