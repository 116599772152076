import React, { useCallback } from 'react';
import { Table as TableGenerator } from 'components';
import { useFetch } from "hooks";
import { Badge, Card } from 'reactstrap';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { format } from 'date-fns';

const getColorStatus = (status) => {
    switch (status) {
        case "completed":
            return <Badge color="success" className='text-10 p-1'>
                Selesai
            </Badge>
        case "canceled_by_admin":
            return <Badge color="danger" className='text-10 p-1'>
                Dibatalkan Admin
            </Badge>
        case "picking_up_process":
            return <Badge color="warning" className='text-10 p-1' style={{ color: "white" }}>
                Dalam Perjalanan
            </Badge>
        case "canceled":
            return <Badge color="danger" className='text-10 p-1' style={{ color: "white" }}>
                Dibatalkan
            </Badge>
        default:
            return <Badge color="info" className='text-10 p-1'>
                Menunggu Driver
            </Badge>
    }
}

const TableDetail = () => {
    // document.title = documentTitle;
    const { id } = useParams();
    const { data, loading: isLoading, params, error } = useFetch(
        `/api/back-office/trip-plan/delivery/${id}/origins`,
        currentParams => ({
            size: 10,
            sort: '',
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
        }),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        { withQueryParams: false, showAlertOnError: false }
    )

    const TableConfiguration = {
        columns: [
            {
                key: 'date', title: 'Tanggal Jemput',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{format(new Date(value), 'dd/MM/yyyy')}</span>
                }
            },

            {
                key: 'originName', title: 'Titik Jemput',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <span>{value}</span>
                }
            },
            {
                key: 'oilPickupDetails', title: 'Jeriken 18 L',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value) => {
                    return (
                        <span>{value?.filter(item => item.volume === "18")?.find(item => item.type === "jeriken")?.qty ?? 0}</span>
                    )
                }
            },
            {
                key: 'oilPickupDetails', title: 'Jeriken 20 L',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value) => {
                    return (
                        <span>{value?.filter(item => item.volume === "20")?.find(item => item.type === "jeriken")?.qty ?? 0}</span>
                    )
                }
            },
            {
                key: 'oilWeight', title: 'Jumlah Minyak (Kg)',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value) => value
            },
            {
                key: 'status', title: 'Status',
                render: (value) => {
                    return getColorStatus(value)
                }
            }
            
            // {
            //     key: 'active', title: 'Penjualan (kg)',
            //     // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
            //     render: (value) => 0
            // }
        ]
    }

    return (
        <Card>
            <TableGenerator
                {...TableConfiguration}
                dataList={data}
                params={params}
                loading={isLoading}
                error={error} />
        </Card>
    )
}

export default TableDetail;
