import React, { useCallback } from 'react';
import {
    Card, Row, Col, CardBody
} from 'reactstrap';
import { Pagination, Table as TableGenerator } from 'components';
import { useFetch } from "hooks";
import { Breadcrumb } from 'components';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

// import IcInfo from 'assets/svg/ic_information.svg';

const App = ({
    title: documentTitle,
    access: permissionAccess
}) => {
    document.title = documentTitle;
    const { date, id } = useParams()
    const { getMenus } = useSelector(state => state.homesidemenu)
    const { data, loading: isLoading, totalPage, params, setParams, error } = useFetch(
        `/api/back-office/stockpoint/cash-mutation/detail`,
        useCallback((currentParams) => {
            return {
                size: 10,
                sort: "",
                date: date,
                stockpoint: id,
                page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
            }
        }, [date, id]),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        { withQueryParams: false, showAlertOnError: true },
        { status: true, timeout: 3600 * 1000 }
    )

    const Money = (uang) => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(uang)
    const getAccessStatusTrip = (subMenuCode) => getMenus?.find((val) => val.code === "trip-plan")?.menus.find((val) => val.code === subMenuCode)?.enabled;

    const handleLinkTrip = (value, row) => {
        if (getAccessStatusTrip("pickup")) {
            return <Link to={`/pickup/${row?.tripId}`}>{value}</Link>
        } else {
            return <>{value}</>
        }
    }

    const TableConfiguration = {
        columns: [
            {
                key: 'date',
                title: 'Tanggal',
                render: (value, row, key) => {
                    return format(new Date(value), "dd/MM/yyyy")
                }
            },
            {
                key: 'tripCode',
                title: 'No Tiket',
                render: (value, row, key) => {

                    return handleLinkTrip(value, row)
                }
            },
            {
                key: 'driverName',
                title: 'Driver',
                render: (value, row, key) => {
                    return <>{value}</>
                }
            },
            {
                key: 'cashDisburse',
                title: 'Cash Disburse',
                render: (value, row, key) => {
                    return <>{Money(value)}</>
                }
            },
            {
                title: 'STOKPOIN',
                children: [{
                    key: 'stockpointOil',
                    title: 'Minyak',
                    render: (value, row, key) => {
                        return <>{Money(value)}</>
                    }
                }, {
                    key: 'stockpointRefund',
                    title: 'Refund',
                    render: (value, row, key) => {
                        return <>{Money(value)}</>
                    }
                }]
            }, {
                title: 'DRIVER',
                children: [{
                    key: 'driverOil',
                    title: 'Minyak',
                    render: (value, row, key) => {
                        return <>{Money(value)}</>
                    }
                }, {
                    key: 'driverCash',
                    title: 'Kas',
                    render: (value, row, key) => {
                        return <>{Money(value)}</>
                    }
                }]
            }, {
                title: 'ADJUSTMENT',
                children: [{
                    key: 'adjustmentOil',
                    title: 'Minyak',
                    render: (value, row, key) => {
                        return <>{Money(value)}</>
                    }
                }, {
                    key: 'adjustmentCash',
                    title: 'Kas',
                    render: (value, row, key) => {
                        return <>{Money(value)}</>
                    }
                }]
            }
        ]
    }

    const dataBreadcrumb = [
        { to: '/cash-flow', label: 'Rekonsiliasi Kas' },
        { to: null, label: 'Detail Rekonsiliasi Kas' }
    ]

    return (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Detail Rekonsiliasi Kas</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>


                    {/* <div className="d-inline-block float-right rounded border border-primary p-3">
                        <div className='text-12 text-muted font-weight-normal'>
                            Penyegaran Terakhir : 14 Feb 2023 - 13:12
                        </div>
                        <div className="d-flex align-items-center mt-2">
                            <img src={IcInfo} alt='create' className='mr-2' height={14} />
                            <span className="text-primary text-12">
                                Data disegarkan setiap 1 jam
                            </span>
                        </div>
                    </div> */}
                </Col>
            </Row>
            <Card>
                <CardBody className="p-0">
                    <TableGenerator
                        {...TableConfiguration}
                        dataList={data}
                        params={params}
                        loading={isLoading}
                        error={error} />
                </CardBody>
            </Card>
            <Row className="pagination mr-1">
                <Col>
                    <Pagination
                        showRowsPerPage={false}
                        onChangePage={({ page, size }) => setParams(prev => ({ ...prev, page, size }))}
                        totalPage={totalPage}
                        currentPage={params.page}
                        limitPage={params.size}
                    />
                </Col>
            </Row>
        </>
    )
}

export default App;
