import ModalPage from 'components/Modal';
import React, { useRef, useState } from 'react';
import { Button, Col, Label, Row, FormGroup } from 'reactstrap';
import Form from 'components-form/Form';
// import Input from 'components-form/Input';
import { useFormContext } from 'react-hook-form';
import { Services } from 'service';
import { useAction } from 'hooks';
import { useReactToPrint } from 'react-to-print';
import cancel from 'assets/svg/ic_cancel.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import InputNumberDecimal from 'components-form/InputNumberDecimal';


const ModalFormsQRSolve = ({ open, close, raw }) => {

    const { hasFetch } = useAction()
    const [currPage, setCurrPage] = useState("form")
    const [openQr, setOpenQR] = useState(null)
    const defaultValues = {
        totalWeight: "",
    }
    const history = useHistory()
    const handleSubmit = (values) => {
        setOpenQR(true)
        Services().post('/api/back-office/trip-plan/delivery-settlement', {
            "tripCode": raw?.tripPlanCode,
            "totalWeight": values.totalWeight,
            "notes": ""

        }, { responseType: "arraybuffer" }).then((result) => {
            const blob = new Blob([result.data], { type: "image/jpg" });
            const uris = URL.createObjectURL(blob);
            hasFetch({
                type: 'ALERT_TOAST_SUCCESS',
                payload: { message: `QR Berhasil Dibuat` }
            })
            setOpenQR(uris)
            uris && setCurrPage("QR")
        }).catch(e => {
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `QR Gagal Dibuat` } })
        })
    }

    const refs = useRef(null)
    const handlePrint = useReactToPrint({
        content: () => refs.current
    });

    return (
        <ModalPage
            isOpen={open}
            handleClose={close}
            component={<>
                {currPage === "QR" && <div className='text-right'>
                    <span onClick={() => {
                        history.go(0)
                        close()
                    }} style={{ cursor: "pointer" }}><img src={cancel} alt="pict-cancel" /></span>
                </div>}
                {currPage === "form" && <>
                    <div className='font-weight-bold text-16 mb-4'>Konfirmasi Penyelesaian</div>
                    <Form onSubmit={handleSubmit} className="w-100" id="modalForm" autoComplete="off" defaultValue={defaultValues}>
                        <Row className="mb-4">
                            <Col>
                                <Label>No Tiket</Label>
                                <div className="font-weight-bold">
                                    {raw?.tripPlanCode ?? "-"}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Jumlah Minyak Diantar</Label>
                                    <InputNumberDecimal
                                        id="totalWeight"
                                        // defaulWeight={oilStockValue}
                                        name="totalWeight"
                                        type="text"
                                        placeholder="0"
                                        autoComplete="off"
                                        validation={[`required`]}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Col>
                            <div className='text-right mt-4'>
                                <Button onClick={() => {
                                    close()
                                }} outline size='sm' type="button" color="primary" >
                                    Batal
                                </Button>
                                <ConfirmButton />
                            </div>
                        </Col>
                    </Form>
                </>
                }
                {
                    currPage === "QR" && (
                        <>
                            <Row className='justify-content-center align-items-center flex-column'>
                                <div className='moda-password-title align-items-center m-0'>Qr Code Penyelesaian</div>
                                <img id='print-out' ref={refs} style={{ maxWidth: "100%", width: 250 }} src={openQr} alt="pict-qr" />
                                <Button
                                    onClick={handlePrint}
                                    type="button" outline color='primary'>
                                    <i className='fa fa-print mr-2'></i>
                                    Print
                                </Button>
                            </Row>
                        </>
                    )
                }
            </>
            }
        />
    )
}

export const ConfirmButton = () => {
    const { formState: { isValid } } = useFormContext();
    return (
        <Button disabled={!isValid} size='sm' type="submit" color={!isValid ? "secondary" : "primary"} >
            Konfirmasi
        </Button>
    )
}

export default ModalFormsQRSolve