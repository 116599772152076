import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

export const formatValue = value => {
  if (typeof value === 'object' && value !== null) {
    Object.keys(value).forEach(key => {
      value[key] = formatValue(value[key])
      return value
    })
  }
  if (typeof value === 'string') return value.trim()
  return value
}

const Form = ({ onError = () => null, onSubmit, children, defaultValues = {}, form: formProps = null, ...props }) => {
  const defaultForm = useForm({ defaultValues })
  const form = formProps || defaultForm

  const handleSubmit = data => {
    Object.keys(data).forEach(key => {
      data[key] = formatValue(data[key])
    })
    if (onSubmit) {
      return onSubmit(data, form)
    }
  }

  return (
    <FormProvider {...form}>
      <form {...props} onSubmit={form.handleSubmit(handleSubmit, onError)} autoComplete="false">
        {children}
      </form>
    </FormProvider>
  )
}

export default Form