import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom'
import {
    Badge, Card, Row, Col, Form, CardBody, Input, Button, Tooltip
} from 'reactstrap';
import IcPlus from 'assets/svg/ic_plus.svg';
import IcInformation from 'assets/svg/ic_information_orange.svg';
import { Pagination, Table as TableGenerator } from 'components';
import { useFetch } from "hooks";
import { Breadcrumb } from 'components';
import { debounce } from 'helper';
import Select from 'react-select';

const App = ({
    title: documentTitle,
    access: permissionAccess
}) => {
    document.title = documentTitle;

    const { data, loading: isLoading, totalPage, params, setParams, error } = useFetch(
        `/api/back-office/supplier`,
        currentParams => ({
            size: 10,
            sort: '',
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
        }),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        { withQueryParams: false, showAlertOnError: false }
    )

    const rootStyle = (variable) => {
        return getComputedStyle(document.body).getPropertyValue(variable)
    }

    const styles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: state.isDisabled ? '#e4e7ea' : provided.backgroundColor,
            border: '1px solid #DBDCDD',
            boxShadow: 'none',
            '&:hover': {
                borderColor: state.selectProps.readonly ? '#DBDCDD' : rootStyle('--primary')
            }
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0.475rem 0.625rem',
            height: 'calc(1.5em + 1.25rem + 5px)'
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none'
        })
    }

    const dataBreadcrumb = [
        { to: '#', label: 'Master Data Supplier' }
    ]

    /* set params for searching */
    const setParamsSearch = useCallback(debounce((name, value) => setParams(prev => ({ ...prev, page: 1, search: typeof value === 'string' ? value?.trim() : value })), 600), [])

    /* handle searching for input type */
    const handleSearchChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setParamsSearch(name, value);
    }

    const OptionSelfDeclare = [{
        value: "checked",
        label: "Ada"
    }, {
        value: "unchecked",
        label: "Tidak Aktif"
    }]

    const OptionVerifSelfDeclare = [{
        value: "verified",
        label: "Terverfikasi"
    }, {
        value: "unverified",
        label: "Belum Terverifikasi"
    }, {
        value: "rejected",
        label: "Ditolak"
    }, {
        value: "expired",
        label: "Kedaluwarsa"
    }]

    const TooltipInfo = ({ id }) => {
        const [tooltipOpen, setTooltipOpen] = useState(false);

        return <>
            <span className="ml-1" color="secondary" id={'tooltip-' + id}>
                <img src={IcInformation} alt='Info Terupdate' />
            </span>
            <Tooltip
                isOpen={tooltipOpen}
                target={'tooltip-' + id}
                toggle={() => setTooltipOpen(!tooltipOpen)}
                className='tooltip-sd'
            >
                Document telah diupdate
            </Tooltip>
        </>
    }

    const getColorSD = (value, row) => {
        switch (value) {
            case true:
                return <div>
                    Ada

                    {row?.updatedSD && <>
                        <TooltipInfo id={row?.id} />
                    </>}
                </div>

            case false:
                return <span style={{ color: "#EE2B2E" }}>
                    Tidak Ada
                </span>

            default:
                return value
        }
    }

    const getColorVerifiedSD = (value) => {
        switch (value) {
            case 'verified':
                return <Badge color="success">
                    Terverifikasi
                </Badge>
            case 'unverified':
                return <Badge color="warning">
                    Belum Terverifikasi
                </Badge>
            case 'rejected':
                return <Badge color="danger">
                    Ditolak
                </Badge>
            case 'expired':
                return <Badge color="purple">
                    Kedaluwarsa
                </Badge>

            default:
                return value
        }
    }

    const TableConfiguration = {
        columns: [
            {
                key: 'name', title: 'Nama Supplier',
                render: (value, row, key) => {
                    return <Link to={`/supplier/${row.id}`}>{value ?? "-"}</Link>
                }
            },
            {
                key: 'outletName', title: 'Nama Outlet',
                render: (value, row, key) => {
                    return <span>{value ?? "-"}</span>
                }
            },
            {
                key: 'phone', title: 'Nomor Handphone',
                render: (value, row, key) => {
                    return <span>{value ?? "-"}</span>
                }
            }, {
                key: 'supplierCategoryName', title: 'Kategori',
                render: (value, row, key) => {
                    return <span>{value ?? "-"}</span>
                }
            },
            {
                key: 'employeeId', title: 'Employee ID',
                render: (value, row, key) => {
                    return <span>{value ?? "-"}</span>
                }
            },
            {
                key: 'employeeName', title: 'Nama Employee',
                render: (value, row, key) => {
                    return <span>{value ?? "-"}</span>
                }
            },
            {
                key: 'selfDeclaration', title: 'Self Declaration',
                render: (value, row, key) => {
                    return value !== null ? getColorSD(value, row) : "-"
                },
                stylesHeader: {
                    minWidth: "150px",
                }
            },
            {
                key: 'statusSD', title: 'Verifikasi Self Declaration',
                render: (value, row, key) => {
                    return value !== null ? getColorVerifiedSD(value) : "-";
                },
            },
            {
                key: 'createdBy', title: 'Dibuat Oleh',
                render: (value, row, key) => {
                    return <span>{value ?? "-"}</span>
                }
            },
        ]
    }


    return (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Supplier</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                    <div className="d-inline-block float-right">
                        {permissionAccess?.create && (
                            <Link to="/supplier/create">
                                <Button color="primary">
                                    <img src={IcPlus} alt='create' className='mr-2' height={19} /> Tambah Supplier
                                </Button>
                            </Link>
                        )}
                    </div>
                </Col>
            </Row>
            <Card body>
                <Form>
                    <Row>
                        <Col sm={3}>
                            <Select
                                name="selfDeclare"
                                id="selfDeclare"
                                placeholder="Self Declaration"
                                className="basic-single"
                                options={OptionSelfDeclare}
                                isClearable
                                styles={styles}
                                onChange={(e) => setParams(params => ({
                                    ...params,
                                    page: 1,
                                    selfDeclaration: e?.value
                                }))}
                            />
                        </Col>
                        <Col sm={3}>
                            <Select
                                name="selfDeclareVerif"
                                id="selfDeclareVerif"
                                placeholder="Verifikasi Self Declaration"
                                className="basic-single"
                                options={OptionVerifSelfDeclare}
                                isClearable
                                styles={styles}
                                onChange={(e) => setParams(params => ({
                                    ...params,
                                    page: 1,
                                    statusSD: e?.value
                                }))}
                            />
                        </Col>
                        <Col className='justify-content-end d-flex'>
                            <Col sm={6}>
                                <div className="input-search">
                                    <i className="fa fa-search text-muted"></i>
                                    <Input className="pl-5" type="text" name="supplier" id="name" placeholder="Cari Nama Supplier / Outlet" onChange={handleSearchChange} defaultValue={params?.name} />
                                </div >
                            </Col>
                        </Col>
                    </Row >
                </Form >
            </Card >
            <Card>
                <CardBody className="p-0">
                    <TableGenerator
                        {...TableConfiguration}
                        dataList={data}
                        params={params}
                        loading={isLoading}
                        error={error} />
                </CardBody>
            </Card>
            <Row className="pagination mr-1">
                <Col>
                    <Pagination
                        showRowsPerPage={false}
                        onChangePage={({ page, size }) => setParams(prev => ({ ...prev, page, size }))}
                        totalPage={totalPage}
                        currentPage={params.page}
                        limitPage={params.size}
                        withSelector
                    />
                </Col>
            </Row>
        </>
    )
}

export default App;
