import React, { useCallback, useState, useEffect } from 'react';
import {
    Card,
    Row,
    Col,
    CardBody
} from 'reactstrap';
import { Pagination, Table as TableGenerator } from 'components';
import { useFetch } from "hooks";
import { Breadcrumb } from 'components';
// import IcInfo from 'assets/svg/ic_information.svg';
import { format } from 'date-fns';
import Form from 'components-form/Form';
import Select from 'components-form/Select';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const App = ({
    title: documentTitle,
    access: permissionAccess
}) => {
    document.title = documentTitle;
    const { data, loading: isLoading, totalPage, params, setParams, error } = useFetch(
        `/api/back-office/stockpoint/cash-mutation`,
        currentParams => ({
            size: 10,
            sort: '',
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
            stockpoint: 0
        }),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        { withQueryParams: false, showAlertOnError: false, onMount: true },
        { status: true, timeout: 3600 * 1000 }
    )
    const Money = (uang) => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(uang)
    const TableConfiguration = {
        columns: [
            {
                key: 'date',
                title: 'Tanggal',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <Link to={`/cash-flow/${params.stockpoint}/${format(new Date(value), "yyyy-MM-dd")}`}>
                        {format(new Date(value), "dd/MM/yyyy")}
                    </Link>
                }
            },
            {
                key: 'cashDisburse',
                title: 'Cash Disburse',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <>{Money(value)}</>
                }
            },
            {
                title: 'STOKPOIN',
                children: [{
                    key: 'stockpointOil',
                    title: 'Minyak',
                    // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                    render: (value, row, key) => {
                        return <>{Money(value)}</>
                    }
                }, {
                    key: 'stockpointRefund',
                    title: 'Refund',
                    // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                    render: (value, row, key) => {
                        return <>{Money(value)}</>
                    }
                }]
            }, {
                title: 'DRIVER',
                children: [{
                    key: 'driverOil',
                    title: 'Minyak',
                    // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                    render: (value, row, key) => {
                        return <>{Money(value)}</>
                    }
                }, {
                    key: 'driverCash',
                    title: 'Kas',
                    // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                    render: (value, row, key) => {
                        return <>{Money(value)}</>
                    }
                }]
            }, {
                title: 'ADJUSTMENT',
                children: [{
                    key: 'adjustmentOil',
                    title: 'Minyak',
                    // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                    render: (value, row, key) => {
                        return <>{Money(value)}</>
                    }
                }, {
                    key: 'adjustmentCash',
                    title: 'Kas',
                    // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                    render: (value, row, key) => {
                        return <>{Money(value)}</>
                    }
                }]
            }
        ]
    }
    const trigger = (id) => {
        setintegratedStockpoint(id)
    }


    const dataBreadcrumb = [
        { to: '#', label: 'Rekonsiliasi Kas' }
    ]

    const [integratedStocpoint, setintegratedStockpoint] = useState(null)


    return (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Rekonsiliasi Kas</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                    {/* <div className="d-inline-block float-right rounded border border-primary p-3">
                        <div className='text-12 text-muted font-weight-normal'>
                            Penyegaran Terakhir : 14 Feb 2023 - 13:12
                        </div>
                        <div className="d-flex align-items-center mt-2">
                            <img src={IcInfo} alt='create' className='mr-2' height={14} />
                            <span className="text-primary text-12">
                                Data disegarkan setiap 1 jam
                            </span>
                        </div>
                    </div> */}
                </Col>
            </Row>
            <Card body>
                <Form>
                    <Row className='justify-content-between'>
                        <Col md={9}>
                            <Row>
                                <Col md={4} className="mb-1">
                                    <BranchField
                                        name="branchId"
                                        triggerID={trigger}
                                    />
                                </Col>
                                <Col md={4} className="mb-1">
                                    <StockPoint
                                        name="stockpointId"
                                        code={integratedStocpoint}
                                        setParamsOil={setParams}
                                        isLoading={isLoading}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Card>
                <CardBody className="p-0">
                    <TableGenerator
                        {...TableConfiguration}
                        dataList={data}
                        params={params}
                        loading={isLoading}
                        error={error}
                    />
                </CardBody>
            </Card>
            <Row className="pagination mr-1">
                <Col>
                    <Pagination
                        showRowsPerPage={false}
                        onChangePage={({ page, size }) => setParams(prev => ({ ...prev, page, size }))}
                        totalPage={totalPage}
                        currentPage={params.page}
                        limitPage={params.size}
                    />
                </Col>
            </Row>
        </>
    )
}

const BranchField = ({ name, defaultValues, triggerID }) => {
    const { trigger, setValue } = useFormContext();
    const { branch, stockpoint: stockpointId } = useSelector(state => state.homesidemenu)
    const { data, loading } = useFetch(
        `/api/back-office/branch`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );
    useEffect(() => {
        setValue("branchId", branch[0]?.id)
    }, [branch, setValue])
    return (
        <>
            <Select
                id={name}
                name={name}
                className="basic-single"
                classNamePrefix="select"
                options={data?.map(obj => ({ label: `${obj?.name}`, value: obj?.id }))}
                placeholder={`Pilih Branch`}
                isLoading={loading}
                isClearable={true}
                isDisabled={stockpointId.length > 0}
                onChange={(ev) => {
                    if (ev) {
                        const { code } = data.filter(item => item.id === ev.value)[0]
                        trigger()
                        triggerID(code)
                    }
                    else {
                        triggerID(null)
                        setValue("stockpointId", "")
                    }
                }}
            />
        </>
    )

}

export const CreateProvider = React.createContext()
const StockPoint = ({ name, code, setParamsOil }) => {
    const { watch, setValue, getValues } = useFormContext();
    const { branch, stockpoint: stockpointId } = useSelector(state => state.homesidemenu)
    /* fetching stock point list */
    const { data: stockPointList, loading: isLoadingStockPoint, setParams } = useFetch(
        `/api/back-office/stockpoint`,
        currentParams => ({
            unpaged: true,
            branch: branch[0]?.code
        }),
        useCallback(data => ({
            data: data?.data?.content,
        }), []),
        { withQueryParams: false, showAlertOnError: false }
    )
    useEffect(() => {
        code && setParams(prev => ({
            ...prev,
            branch: code
        }))
        if (getValues('branchId')) {
            setValue("stockpointId", null)
        }
        else {
            setValue("stockpointId", watch('stockpointId', stockpointId[0]?.id))
        }
    }, [watch, setValue, code, setParams, stockpointId, getValues, setParamsOil])
    useEffect(() => {
        if (stockPointList?.length !== undefined) {
            setParamsOil(prev => ({
                ...prev,
                page: 1,
                stockpoint: !stockPointList?.length ? 0 : getValues('stockpointId') && getValues('branchId') ? stockpointId[0]?.id : 0
            }))
        }
    }, [stockPointList, setParamsOil, stockpointId, getValues])
    const isValue = getValues('stockpointId')
    useEffect(() => {
        isValue === "" && setParamsOil(prev => ({
            ...prev,
            page: 1,
            stockpoint: 0
        }))
    }, [isValue, setParamsOil])
    const isDisabled = !code || stockPointList?.length === 0;
    return (
        <>
            <Select
                id={name}
                name={name}
                className="basic-single"
                classNamePrefix="select"
                options={stockPointList?.map(obj => ({ label: `${obj?.name}`, value: obj?.id }))}
                placeholder={isDisabled ? `Stock Point Tidak Ada` : `Pilih Stock Point`}
                isLoading={isLoadingStockPoint}
                isDisabled={stockPointList?.length === 0 || !getValues('branchId') || stockpointId?.length > 0}
                // defaultValue={x}
                isClearable={true}
                onChange={(e) => {
                    e && setParamsOil(prev => ({
                        ...prev,
                        page: 1,
                        stockpoint: e?.value
                    }))
                    !e && setParamsOil(prev => ({
                        ...prev,
                        page: 1,
                        stockpoint: 0
                    }))
                }}
            />
        </>
    )
}

export default App;
