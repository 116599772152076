import React from "react"
import { Col, FormGroup, Label } from "reactstrap";

import { parseValue } from "helper";

const RowInfo = ({
    label,
    value,
    format = (value) => value,
    asChild = false,
    children
}) => (
    <FormGroup row>
        <Label sm={4}>{label}</Label>
        <Col sm={8}>
            {asChild
                ? parseValue(value, (props) => children(format(props)), ": -")
                : <>: {parseValue(value, format)}</>}
        </Col>
    </FormGroup>
);

export default RowInfo;
