import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Input as ReactStrapInput, FormFeedback, InputGroup } from 'reactstrap'
// import { get } from 'lodash'
import validationRule from 'helper/ValidationRule';
const get = (obj, path, def) => (() => typeof path === 'string' ? path.replace(/\[(\d+)]/g, '.$1') : path.join('.'))()
    .split('.')
    .filter(Boolean)
    .every(step => ((obj = obj[step]) !== undefined)) ? obj : def

const defaultTransform = {
    output: val => val,
    input: val => val,
}

const formatRupiah = (angka, prefix) => {
    if (angka !== undefined && angka !== null) {
        let number = angka.toString(),
            number_string = number.replace(/[^,\d]/g, "").toString(),
            split = number_string.split(","),
            sisa = split[0].length % 3,
            rupiah = split[0].substr(0, sisa),
            ribuan = split[0].substr(sisa).match(/\d{3}/gi);
        if (ribuan) {
            let separator = sisa ? "." : "";
            rupiah += separator + ribuan.join(".");
        }
        rupiah = split[1] !== undefined ? rupiah + "," + split[1] : rupiah;
        return prefix === undefined ? rupiah : rupiah ? "Rp" + rupiah : "";
    } else {
        return "";
    }
};

const InputMoneyValidateRequest = ({
    inputCode,
    validation = [],
    mandatoryMoney = 0,
    count = 0,
    validationMessage = [],
    inputGroupAddon = null,
    name,
    defaultValue = '',
    shouldUnregister = false,
    transform = defaultTransform,
    maxLength,
    ...props
}) => {
    const { control, getValues } = useFormContext()
    const transformer = { ...defaultTransform, ...transform }
    return (
        <Controller
            shouldUnregister={shouldUnregister}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validationRule({ rule: validation, validationMessage }, getValues, name)}
            render={({ field: { ref, onChange, value, ...fieldInput }, formState: { errors } }) => {
                // const regexNumber = value?.replace(/[^\d,]/g, '')

                return (
                    <InputGroup>
                        <ReactStrapInput
                            invalid={!!get(errors, name)}
                            innerRef={ref}
                            {...fieldInput}
                            maxLength={maxLength || 24}
                            onChange={e => onChange(transformer.output(e))}
                            value={transformer.input(formatRupiah(value))}
                            {...props} />
                        {inputGroupAddon}
                        {get(errors, name) && <FormFeedback invalid={"true"} style={{ display: 'block' }}>{get(errors, name)?.message}</FormFeedback>}
                    </InputGroup>
                )
            }}
        />
    )
}

export default InputMoneyValidateRequest