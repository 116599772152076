import React from 'react'
import { Modal, ModalBody } from 'reactstrap';

const ModalPage = ({ isOpen = false, component }) => {
    return (
        <Modal isOpen={isOpen} className="modal-dialog-centered modal-dialog-confirmation">
            <ModalBody className="confirmation-modal">
                {component}
            </ModalBody>
        </Modal>
    )
}

export default ModalPage