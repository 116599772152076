import React, { useCallback, useContext, useEffect } from 'react';
import {
    Card,
    Row,
    Col,
    CardBody,
} from 'reactstrap';
import { Pagination, Table as TableGenerator } from 'components';
import { useFetch } from "hooks";
import { FileUploadContext } from '../form-topup';;

const TableUploadSuccess = () => {

    const { dataResultUpload, setTotalSuccessUpload } = useContext(FileUploadContext)

    const { data, loading: isLoading, totalPage, params, setParams, error } = useFetch(
        `/api/back-office/petty-cash/upload/${dataResultUpload?.id}/review/read-list`,
        currentParams => ({
            size: 10,
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
        }),
        useCallback(data => ({
            data: data?.data,
            totalPage: data?.data?.totalPages
        }), []),
        { withQueryParams: false, showAlertOnError: false }
    )

    useEffect(() => {
        if (data) setTotalSuccessUpload(data?.totalElements)

        return () => {
            setTotalSuccessUpload('')
        }
    }, [data, isLoading, setTotalSuccessUpload])


    const TableConfiguration = {
        columns: [
            {
                key: 'branchName', title: 'Branch',
                //  onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return value ?? "-"
                }
            },
            {
                key: 'stockPointCode', title: 'Kode Stockpoint',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return value ?? "-"
                }
            },
            {
                key: 'stockPointName', title: 'Stockpoint',
                //  onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return value ?? "-"
                }
            },
            {
                key: 'currentAmount', title: 'Saldo Saat ini (Rp)',
                render: (value, row, key) => {
                    return value ?? "-"
                }
            },
            {
                key: 'totalAmount', title: 'Jumlah Top Up (Rp)',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return value ?? "-"
                }
            },
            {
                key: 'finalAmount', title: 'Saldo Akhir (Rp)',
                render: (value, row, key) => {
                    return value ?? "-"
                }
            },
        ]
    }

    return (
        <>
            <Card>
                <CardBody className="p-0">
                    <TableGenerator
                        {...TableConfiguration}
                        dataList={data?.content}
                        params={params}
                        loading={isLoading}
                        error={error} />
                </CardBody>
            </Card>
            <Row className="pagination mr-1">
                <Col>
                    <Pagination
                        showRowsPerPage={false}
                        onChangePage={({ page, size }) => setParams(prev => ({ ...prev, page, size }))}
                        totalPage={totalPage}
                        currentPage={params.page}
                        limitPage={params.size}
                        withSelector
                    />
                </Col>
            </Row>
        </>
    )
}

export default TableUploadSuccess;
