import React, { useCallback } from 'react'
import { Row, Col, Badge, } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Breadcrumb } from 'components';
import { useFetch } from 'hooks';
import Error404 from 'page/error/Error404';
import TableDetail from './component/component.table';
import { format } from 'date-fns';

const dataBreadcrumb = [
    { to: '/delivery-report', label: 'Mutasi Pengantaran' },
    { to: null, label: 'Detail' }
];

const getColorStatus = (status) => {
    switch (status) {
        case "completed":
            return <Badge color="success" className='text-10 p-1'>
                Selesai
            </Badge>
        case "canceled_by_admin":
            return <Badge color="danger" className='text-10 p-1'>
                Dibatalkan Admin
            </Badge>
        case "picking_up_process":
            return <Badge color="warning" className='text-10 p-1' style={{ color: "white" }}>
                Dalam Perjalanan
            </Badge>
        default:
            return <Badge color="info" className='text-10 p-1'>
                Menunggu Driver
            </Badge>
    }
};

const App = ({
    title: documentTitle,
    access: permissionAccess
}) => {
    document.title = documentTitle;
    const { id } = useParams();

    const { data,
        status, error } = useFetch(
            `/api/back-office/delivery-report/${id}`,
            {},
            useCallback(data => ({ data: data?.data }), [])
        );

    if ((status === 'rejected' && error.code === 400) ||
        (status === 'resolved' && data === null)) return <Error404 />;

    return status === 'resolved' && (
        <>
            <Row className="row mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Laporan</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <div className='d-flex justify-content-start' style={{ gap: "2rem" }}>
                <div style={{ width: "15rem" }}>
                    <div sm={3} style={{ color: "#70727D" }}>Nama Driver</div>
                    <div style={{ fontSize: "24px", fontWeight: 700 }} sm={9}>
                        {data?.driverName ?? "-"}
                    </div>
                </div>
                <div style={{ width: "15rem" }}>
                    <div sm={3} style={{ color: "#70727D" }}>Warehouse</div>
                    <div style={{ fontSize: "24px", fontWeight: 700 }} sm={9}>
                        {data?.warehouseName ?? "-"}
                    </div>
                </div>
                <div style={{ width: "12rem" }}>
                    <div sm={3} style={{ color: "#70727D" }}>Jumlah Titik Jemput</div>
                    <div style={{ fontSize: "24px", fontWeight: 700 }} sm={9}>
                        {data?.totalCompleteDelivery ?? "-"}
                    </div>
                </div>
                <div style={{ width: "12rem" }}>
                    <div sm={3} style={{ color: "#70727D" }}>Jumlah Minyak Diantar (Kg)</div>
                    <div style={{ fontSize: "24px", fontWeight: 700 }} sm={9}>
                        {data?.oilDeliverySummary?.totalOilWeight ?? "-"}
                    </div>
                </div>
            </div>
            <div className='my-3' style={{ borderTop: "1px solid #DCDEE3" }} />
                <div className='d-flex justify-content-start' style={{ gap: "2rem" }}>
                    <div className='d-flex flex-column' style={{ gap: "0.5rem", width: "15rem" }}>
                        <div sm={3} style={{ color: "#70727D" }}>Status</div>
                        <div style={{ fontSize: "10px", fontWeight: 400, display: "flex", alignItems: "center" }} sm={9}>
                            {getColorStatus(data?.status)}
                        </div>
                    </div>
                    <div className='d-flex flex-column' style={{ gap: "0.5rem", width: "15rem" }}>
                        <div sm={3} style={{ color: "#70727D" }}>No Tiket</div>
                        <div style={{ fontSize: "16px", fontWeight: 400 }} sm={9}>
                            {data?.tripPlanCode ?? "-"}
                        </div>
                    </div>
                    <div className='d-flex flex-column' style={{ gap: "0.5rem", width: "12rem" }}>
                        <div sm={3} style={{ color: "#70727D" }}>Tanggal penugasan</div>
                        <div style={{ fontSize: "16px", fontWeight: 400 }} sm={9}>
                            {data?.assignmentDate && format(new Date(data?.assignmentDate), 'dd/MM/yyyy')}
                        </div>
                    </div>
                </div>
            <div className='pt-3'>
                <div className='py-3' style={{ fontSize: "16px", fontWeight: 500 }}>Daftar Dijemput</div>
                <div>
                    <TableDetail />
                </div>
            </div>
        </>
    );
};

export default App;
