import ModalPage from 'components/Modal';
import React, { useState } from 'react'
import { Button, Col, Row } from 'reactstrap';
import icCancel from 'assets/svg/ic_cancel.svg'
import { Form } from 'components-form';
import InputCounter from 'components-form/InputCounter';
import { useAction } from 'hooks';
import { Services } from 'service';

const ModalForms = ({ open, setopen, jerikenType, fetchData }) => {
    const defaultValues = {
        bruto: '',
        netto: ''
    };
    const { hasFetch } = useAction();
    const [confirmation, setConfirmation] = useState(false);
    const handleSubmited = (input) => {
        setConfirmation(true);
        const requestBody = {
            "id": jerikenType?.id,
            "weight": input?.netto,
            "grossWeight": input?.bruto
        }
        confirmation && Services().post(`/api/back-office/config-jeriken/edit`, { ...requestBody }).then(() => {
            hasFetch({
                type: 'ALERT_TOAST_SUCCESS',
                payload: { message: `Berat jeriken berhasil diperbarui` }
            });
            fetchData();
            setConfirmation(false);
            setopen(!open);
        }).catch(e => {
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Berat jeriken gagal diperbarui` } });
            setConfirmation(false);
        })
    };
    return (
        <ModalPage
            isOpen={open}
            handleClose={() => setopen(!open)}
            component={<div>
                <Row className='justify-content-between d-flex px-3'>
                    <div className='moda-password-title m-0'>{confirmation ? 'Konfirmasi Perubahan' : `Ubah Berat Jeriken ${jerikenType?.volume} L`}</div>
                    {!confirmation && <span onClick={() => setopen(!open)} style={{ cursor: "pointer" }}>
                        <img src={icCancel} alt="pict-cancel" />
                    </span>}
                </Row>
                <Form onSubmit={handleSubmited} defaultValues={defaultValues}>
                    {confirmation ? <Row className='d-flex py-3 px-3 flex-column' style={{ gap: "1rem" }}>Data berat baru akan disimpan ?</Row> : <Row className='d-flex py-3 flex-column' style={{ gap: "1rem" }}>
                        <Col>
                            <InputCounter
                                nocounter
                                id='bruto'
                                name='bruto'
                                placeholder="Berat Bruto (Kg)"
                                maxLength={4}
                                validation={['required']}
                            />
                        </Col>
                        <Col>
                            <InputCounter
                                nocounter
                                id='netto'
                                name='netto'
                                placeholder="Berat Netto (Kg)"
                                maxLength={4}
                                validation={['required']}
                            />
                        </Col>
                    </Row>}
                    <div className='text-right'>
                        <Button onClick={() => setopen(!open)} size='sm' type="button" color="secondary" >
                            Batal
                        </Button>
                        <Button size='sm' type="submit" color="primary" >
                            Simpan
                        </Button>
                    </div>
                </Form>
            </div >
            }
        />
    )
}


export default ModalForms