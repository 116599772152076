import React, { useCallback } from 'react';
import { Link } from 'react-router-dom'
import { Card, Row, Col, Form, CardBody, Input, Button, Badge } from 'reactstrap';
import { Table as TableGenerator } from 'components';
import Select from 'react-select';
import { useFetch } from "hooks";
import { Breadcrumb } from 'components';
import { debounce } from 'helper';

import IcPlus from 'assets/svg/ic_plus.svg'

const rootStyle = (variable) => {
    return getComputedStyle(document.body).getPropertyValue(variable)
}

const theme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: rootStyle('--light'),
        primary: rootStyle('--primary'),
    }
})

const styles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#e4e7ea' : provided.backgroundColor,
        border: '1px solid #DBDCDD',
        boxShadow: 'none',
        '&:hover': {
            borderColor: state.selectProps.readonly ? '#DBDCDD' : rootStyle('--primary')
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0.475rem 0.625rem',
        height: 'calc(1.5em + 1.25rem + 5px)'
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
    })
}

const getColorStatus = (status) => {
    switch (status) {
        case "active":
            return <Badge color="success" className='text-10 p-1'>
                Aktif
            </Badge>
        case "inactive":
            return <Badge color="danger" className='text-10 p-1'>
                Tidak  Aktif
            </Badge>
        default:
            return <Badge color="light" className='text-10 p-1'>
                {status}
            </Badge>
    }
}

const RoleList = ({
    title: documentTitle,
    access: permissionAccess
}) => {
    document.title = documentTitle;

    const { data, loading: isLoading, params, setParams, error } = useFetch(
        `/api/back-office/roles`,
        currentParams => ({
            size: 10,
            sort: 'name,asc',
            // page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
            unpaged: true
        }),
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { withQueryParams: true, showAlertOnError: false }
    )

    const TableConfiguration = {
        columns: [
            {
                key: 'name', title: 'Role', 
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value, row, key) => {
                    return <Link to={`/roles/${row.id}`}>{value}</Link>
                },
                stylesHeader: {
                    width: "70%"
                }
            },
            {
                key: 'userInRoleCount', title: 'Jumlah Pengguna',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value) => value,
                stylesHeader: {
                    width: "20%",
                }
            },
            {
                key: 'status', title: 'Status',
                // onSort: sort => setParams(prev => ({ ...prev, sort, page: 1 })),
                render: (value) => value ? getColorStatus(value) : "-",
            }
        ]
    }

    const dataBreadcrumb = [
        { to: null, label: 'Otorisasi Pengguna' }
    ]

    /* set params for searching */
    const setParamsSearch = useCallback(debounce((name, value) => setParams(prev => ({ ...prev, page: 1, [name]: typeof value === 'string' ? value?.trim() : value })), 600), [])

    /* handle searching for input type */
    const handleSearchChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setParamsSearch(name, value);
    }

    /* handle searching for select type */
    const handleSelectChange = (name, e) => {
        let value = e?.value;

        setParamsSearch(name, value);
    }

    return (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Admin</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>

                    <div className="d-inline-block float-right">
                        {permissionAccess?.create && (
                            <Link to="/roles/create">
                                <Button color="primary">
                                    <img src={IcPlus} alt='create' className='mr-2' height={19} />
                                    Tambah Otorisasi
                                </Button>
                            </Link>
                        )}
                    </div>
                </Col>
            </Row>
            <Card body>
                <Form>
                    <Row className='justify-content-between'>
                        <Col lg={2}>
                            <Select
                                name="status"
                                id="status"
                                placeholder="Pilih Status"
                                className="basic-single"
                                defaultValue={params?.status}
                                options={[
                                    { value: "active", label: "Aktif" },
                                    { value: "inactive", label: "Tidak Aktif" }
                                ]}
                                isClearable={true}
                                theme={theme}
                                styles={styles}
                                onChange={(e) => handleSelectChange('status', e)}
                            />
                        </Col>
                        <Col lg={4} className="align-self-end">
                            <div className="input-search">
                                <i className="fa fa-search text-muted"></i>
                                <Input className="pl-5" type="text" name="name" id="name" placeholder="Cari Role" onChange={handleSearchChange} defaultValue={params?.name} />
                            </div >
                        </Col>
                    </Row >
                </Form >
            </Card >
            <Card>
                <CardBody className="p-0">
                    <TableGenerator
                        {...TableConfiguration}
                        dataList={data}
                        params={params}
                        loading={isLoading}
                        error={error} />
                </CardBody>
            </Card>
        </>
    )
}

export default RoleList;
