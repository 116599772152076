import React, { useCallback, useState } from 'react'
import {
    Row,
    Col,
    Label,
    Button,
    Badge,
} from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Breadcrumb } from 'components';
import { useAction, useFetch } from 'hooks';
import Error404 from 'page/error/Error404';
import { addHours, format } from 'date-fns';
import { Services } from 'service';

import icScan from 'assets/svg/ic_scan.svg';
import icRegular from 'assets/svg/ic_Regular.svg';
import icCancel from 'assets/svg/ic_cancel_red.svg';
import ModalFormsQRSolve from './components/ModalQRSolve';
import ModalFormsQR from './components/ModalQr';
import ModalConfirm from './components/ModalConfirm';

const getColorStatus = (status) => {
    switch (status) {
        case "waiting_driver":
            return <Badge color="info" className='text-10 text-white p-1'>
                Menunggu Driver
            </Badge>
        case "picking_up_process":
            return <Badge color="warning" className='text-10 text-white p-1'>
                Dalam Perjalanan
            </Badge>
        case "completed":
            return <Badge color="success" className='text-10 text-white p-1'>
                Selesai
            </Badge>
        case "canceled_by_admin":
            return <Badge color="danger" className='text-10 text-white p-1'>
                Dibatalkan Admin
            </Badge>
        default:
            return status 
    }
}

const App = ({
    title: documentTitle,
    access: permissionAccess,
    history
}) => {
    document.title = documentTitle;
    const [open, setOpen] = useState(false)
    const [openForm, setOpenForm] = useState(false)
    const { id } = useParams();
    const { hasFetch } = useAction();
    const [openConfirm, setOpenConfirm] = useState(false)
    const dataBreadcrumb = [
        { to: '/delivery', label: 'Pengantaran' },
        { to: null, label: 'Detail Pengantaran' }
    ]

    const { data, status, error } = useFetch(
        `/api/back-office/trip-plan/delivery/${id}`,
        {},
        useCallback(data => ({ data: data?.data }), [])
    )

    /* handle delete user */
    const cancelTrip = () => {
        Services().post('/api/back-office/trip-plan/delivery/cancel/' + id)
            .then(() => {
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: `Penugasan berhasil dibatalkan` }
                });
                history.push('/delivery');
            })
            .catch(err => {
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: err?.message } });
            });
    }

    // const formatNumber = (number) => new Intl.NumberFormat('id-ID').format(number)
    if ((status === 'rejected' && error.code === 400) ||
        (status === 'resolved' && data === null)) return <Error404 />
    return status === 'resolved' && (
        <>
            <Row className="mb-5">
                <Col>
                    <div className="d-inline-block">
                        <h2>Rute Perjalanan</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <div className="d-flex flex-wrap" style={{ columnGap: "6rem", rowGap: "2rem" }}>
                <div>
                    <Label>Nama Driver</Label>
                    <div>
                        <h4>{data?.driverName ?? ""}</h4>
                    </div>
                </div>
                <div>
                    <Label>Stockpoint</Label>
                    <div>
                        <h4>{data?.stockpointName ?? "-"}</h4>
                    </div>
                </div>
                <div>
                    <Label>Warehouse</Label>
                    <div>
                        <h4>{data?.warehouseName ?? "-"}</h4>
                    </div>
                </div>
                <div>
                    <Label>Jumlah Minyak (kg)</Label>
                    <div>
                        <h4>{data?.oilWeight ?? "-"}</h4>
                    </div>
                </div>
            </div>
            <hr className='my-3' />
            <div className='d-flex flex-wrap justify-content-between' style={{ rowGap: "2rem" }}>
                <div className='d-flex flex-wrap' style={{ columnGap: "6rem", rowGap: "2rem" }}>
                    <div>
                        <Label>Status</Label>
                        <div>
                            {getColorStatus(data?.status) ?? ""}
                        </div>
                    </div>
                    <div>
                        <Label>No Tiket</Label>
                        <div>
                            {data?.tripPlanCode ?? ""}
                        </div>
                    </div>
                    <div>
                        <Label>Tanggal Penugasan</Label>
                        <div>
                            {format(addHours(new Date(data?.assignmentDate), 7), "dd/MM/yyyy") ?? ""}
                        </div>
                    </div>
                </div>
                <div>
                    {data?.status === "waiting_driver" && <>
                        <Button onClick={() => setOpenConfirm(true)} color='danger' size='sm' type="button" outline className="btn-cancel">
                            <div className='d-flex align-items-center'>
                                <img className='ml-1' src={icCancel} alt="driver" style={{ height: 10 }} />
                                <span style={{ lineHeight: 2 }} className='pl-2'>Batalkan Penugasan</span>
                            </div>
                        </Button>
                        <Button onClick={() => setOpen(!open)} color='primary' size='sm' type="button">
                            <div className='d-flex'>
                                <img className='ml-1' src={icScan} alt="driver" />
                                <span style={{ lineHeight: 2 }} className='pl-1'>Tampilkan QR Code</span>
                            </div>
                        </Button>
                    </>
                    }
                    {data?.status === "picking_up_process" && <Col md={12}>
                        <Button onClick={() => setOpenForm(!openForm)} color='primary' size='sm' type="button">
                            <div className='d-flex'>
                                <img className='ml-1' src={icRegular} alt="driver" />
                                <span style={{ lineHeight: 2 }} className='pl-1'>Penyelesaian</span>
                            </div>
                        </Button>
                    </Col>}
                </div>
            </div>
            <ModalFormsQR open={open} close={() => setOpen(!open)} />
            <ModalFormsQRSolve open={openForm} close={() => setOpenForm(!openForm)} raw={data} />
            <ModalConfirm open={openConfirm} close={() => setOpenConfirm(!openConfirm)} yesAction={() => cancelTrip()} />
        </>
    )
}

export default App