import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, Row, Col, CardHeader, CardBody, FormGroup, Label, Button, InputGroup, Spinner } from 'reactstrap';
import { useFormContext, useWatch } from 'react-hook-form';
import { useFetch, useAction } from 'hooks';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import Form from 'components-form/Form';
import Input from 'components-form/Input';
import Select from 'components-form/Select';
import Switches from 'components-form/Switches';
import Error404 from 'page/error/Error404';

const App = ({
    title: documentTitle,
    history
}) => {
    document.title = documentTitle;

    const [showPassword, setShowPassword] = useState(false);
    const params = useParams();
    const { hasFetch } = useAction();
    const isCreate = params?.id ? false : true;
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const dataBreadcrumb = isCreate ?
        [
            { to: '/warehouse-employee', label: 'Admin Warehouse' },
            { to: null, label: 'Tambah Admin' }
        ]
        : [
            { to: '/warehouse-employee', label: 'Admin Warehouse' },
            { to: `/warehouse-employee/${params.id}`, label: 'Detail Admin' },
            { to: null, label: 'Edit Admin' }
        ]

    /* fetching role list */
    const { data: roleList, loading: isLoadingRole } = useFetch(
        `/api/back-office/roles`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );

    /* fecthing data for detail page */
    const { data, status, error } = useFetch(
        `/api/back-office/warehouse-employee/${params?.id}`,
        {},
        useCallback(data => ({
            data: {
                ...data?.data
            }
        }), []),
        { onMount: !isCreate }
    )

    const [integratedStocpoint, setintegratedStockpoint] = useState(null)

    const onSubmit = (input) => {
        setIsLoadingSubmit(true);
        const id = params?.id;
        delete input.userLevel;
        const requestBody = {
            ...input,
            branchId: input.branchId !== null ? input.branchId : [],
            warehouseId: input.warehouseId !== null ? input.warehouseId : []
        };
        if (!input.branchId) {
            delete requestBody.branchId
            delete requestBody.warehouseId
        }
        if (requestBody.password === "") {
            delete requestBody.password
        }
        const action = ({ requestBody, id = null }) => isCreate
            ? Services().post('/api/back-office/warehouse-employee', requestBody)
            : Services().post(`/api/back-office/warehouse-employee/update`, { ...requestBody, id: parseInt(id) })

        action({ requestBody, id: params?.id })
            .then(() => {
                setIsLoadingSubmit(false);
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: isCreate ? `Admin warehouse baru berhasil ditambah` : `Admin warehouse berhasil diubah` }
                })
                history.push(isCreate ? '/warehouse-employee' : `/warehouse-employee/${id}`);
            })
            .catch(e => {
                setIsLoadingSubmit(false);
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Cannot update ${id}` } })
            })
    }
    const trigger = (id) => {
        setintegratedStockpoint(id)
    }
    const defaultValues = {
        name: data?.name ?? '',
        username: data?.username ?? '',
        email: data?.email ?? '',
        roleId: data?.roleId ?? null,
        warehouseId: data?.warehouseId ?? null,
        branchId: data?.branchId ?? null,
        level: data?.level ?? null,
        allowEditConfigKg: data?.allowEditConfigKg ?? null,
        allowEditSupplier: data?.allowEditSupplier ?? null,
        allowWithdraw: !!data?.allowWithdraw,
        active: data?.active ?? isCreate ? true : false,
    }

    if ((status === 'rejected' && error.code === 400) || (status === 'resolved' && data === null)) return <Error404 />
    return (status === 'resolved' || isCreate) && (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>{isCreate ? "Tambah" : "Edit"} Admin</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Form id="penggunaForm" onSubmit={onSubmit} autoComplete="off" defaultValues={defaultValues}>
                <Card>
                    <CardHeader className="pb-0">
                        Informasi Admin
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label htmlFor="nama_lengkap" sm={3}>Nama Lengkap</Label>
                                    <Col sm={9}>
                                        <Input id="nama_lengkap" validation={['required']} name="name" type="text" placeholder="Nama Lengkap" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="username" sm={3}>Username</Label>
                                    <Col sm={9}>
                                        <Input id="username" validation={['required', 'minLength[6]']} name="username" type="text" placeholder="Username" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="email" sm={3}>Email</Label>
                                    <Col sm={9}>
                                        <Input id="email" validation={['required', 'email']} name="email" type="text" placeholder="Email" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                                <FormGroup htmlFor="secret" row>
                                    <Label sm={3}>Password {!isCreate && "(Optional)"}</Label>
                                    <Col sm={9}>
                                        <InputGroup>
                                            <Input id="secret" type={`${showPassword ? "text" : "password"}`}
                                                name="password"
                                                placeholder="Password Awal"
                                                validation={isCreate ? ['required', 'minLength[8]', 'passwordWithCharacter'] : ['minLength[8]', 'passwordWithCharacter']}
                                                autoComplete="new-password"
                                                maxLength={50} />
                                            <i className={`text-14 icon-password fa ${(showPassword) ? "fa-eye" : "fa-eye-slash"}`} aria-hidden="true" onClick={() => setShowPassword(!showPassword)} />
                                        </InputGroup>
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="roleId" sm={3}>Role</Label>
                                    <Col sm={9}>
                                        <Select
                                            id="roleId"
                                            validation={['required']}
                                            name="roleId"
                                            className="basic-single"
                                            classNamePrefix="select"
                                            options={roleList?.filter(item => item.status === "active")?.map(obj => ({ label: obj?.name, value: obj?.id }))}
                                            placeholder={`Pilih Role`}
                                            isLoading={isLoadingRole}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="roleId" sm={3}>User Level</Label>
                                    <Col sm={9}>
                                        <UserLevel name="level" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="branch" sm={3}>Branch</Label>
                                    <Col sm={9}>
                                        <BranchField
                                            name="branchId"
                                            triggerID={trigger}
                                            defaultValues={defaultValues}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="stockPoint" sm={3}>Warehouse</Label>
                                    <Col sm={9}>
                                        <StockPoint name="warehouseId" code={
                                            !isCreate ? data?.branchId : integratedStocpoint
                                        } />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Cairkan Petty Cash</Label>
                                    <Col sm={9}>
                                        <Switches
                                            className="custom-switch-success mt-1"
                                            name="allowWithdraw"
                                            label="Aktif"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Konfigurasi Jeriken</Label>
                                    <Col sm={9}>
                                        <Switches
                                            className="custom-switch-success mt-1"
                                            name="allowEditConfigKg"
                                            label="Aktif"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label sm={3}>Edit Supplier</Label>
                                    <Col sm={9}>
                                        <Switches
                                            className="custom-switch-success mt-1"
                                            name="allowEditSupplier"
                                            label="Aktif"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="active" sm={3}>Status</Label>
                                    <Col sm={9}>
                                        <Switches
                                            className="custom-switch-success mt-1"
                                            name="active"
                                            label="Aktif"
                                        />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Row>
                    <Col className="text-right">
                        <Link to={(isCreate) ? "/user" : `/user/${params.id}`}>
                            <Button type="button" color="secondary" className="m-1" onClick={() => window.history.back()}>
                                Batal
                            </Button>
                        </Link>
                        <Button type="submit" color="primary" className="m-1" disabled={isLoadingSubmit}>
                            {isLoadingSubmit &&
                                <span className='mr-2'>
                                    <Spinner size="sm" />
                                </span>}
                            {(isCreate) ? 'Buat' : 'Simpan'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

const BranchField = ({ name, defaultValues, triggerID }) => {
    const { watch, trigger } = useFormContext();
    const isDisabled = watch("userLevel")?.length > 0;

    /* fetching stock point list */
    const { data, loading } = useFetch(
        `/api/back-office/branch`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );
    return (
        <Select
            id={name}
            name={name}
            className="basic-single"
            classNamePrefix="select"
            options={data?.map(obj => ({ label: `${obj?.code} - ${obj?.name}`, value: obj?.id }))}
            placeholder={`Pilih Branch`}
            isLoading={loading}
            validation={['required']}
            isClearable={true}
            isDisabled={isDisabled}
            onChange={(ev) => {
                if (ev) {
                    const { code } = data.filter(item => item.id === ev.value)[0]
                    trigger()
                    triggerID(code)
                }
                else {
                    triggerID(null)
                }
            }}
        />
    )

}

const StockPoint = ({ name, code }) => {
    const { trigger, control, setValue } = useFormContext();
    const second = useWatch({
        control,
        name
    });

    useEffect(() => {
        !code && setValue(name, code)
    }, [second, name, code, setValue])

    /* fetching stock point list */
    const { data: stockPointList, loading: isLoadingStockPoint, setParams } = useFetch(
        `/api/back-office/warehouse`,
        currentParams => ({
            unpaged: true,
        }),
        useCallback(data => ({
            data: data?.data?.content,
        }), []),
        { withQueryParams: false, showAlertOnError: false }
    )
    useEffect(() => {
        code && setParams(prev => ({
            ...prev,
            branch: code
        }))
    }, [code, setParams])

    const isDisabled = !code || stockPointList?.length === 0;
    return (
        <Select
            id={name}
            name={name}
            className="basic-single"
            classNamePrefix="select"
            options={stockPointList?.map(obj => ({ label: `${obj?.code} - ${obj?.name}`, value: obj?.id }))}
            placeholder={isDisabled ? `Warehouse Tidak Ada` : `Pilih Warehouse`}
            isLoading={isLoadingStockPoint}
            isDisabled={!code}
            validation={['required']}
            isClearable={true}
            defaultValue={[]}
            onChange={() => {
                trigger()
            }}
        />
    )
}

const UserLevel = ({ name }) => {
    const { trigger } = useFormContext();

    /* fetching stock point list */
    const { data, loading: isLoadingStockPoint } = useFetch(
        `/api/back-office/user/level`,
        currentParams => ({
            unpaged: true,
        }),
        useCallback(data => ({
            data: data?.data?.content,
        }), []),
        { withQueryParams: false, showAlertOnError: false }
    )
    const options = data?.map(item => ({
        label: item,
        value: item
    }))
    return (
        <Select
            id={name}
            name={name}
            className="basic-single"
            classNamePrefix="select"
            options={options}
            placeholder={"User Level"}
            isLoading={isLoadingStockPoint}
            isClearable={true}
            defaultValue={{ label: '5200/5212/5212/0099 - Stockpoint Daan Mogot', value: 1 }}
            onChange={(ev) => {
                trigger()
            }}
        />
    )
}

export default App