import React from "react";
import { format } from "date-fns";

import { isBlankOrNullish } from "helper";

export const indexPath = "/change-approval";

export const editStatusOptions = [
    {
        value: "pending",
        label: "Menunggu Disetujui",
        badgeColor: "warning",
        textClass: "text-warning",
    },
    {
        value: "accepted",
        label: "Disetujui",
        badgeColor: "success",
        textClass: "text-success",
    },
    {
        value: "rejected",
        label: "Ditolak",
        badgeColor: "danger",
        textClass: "text-danger",
    },
    {
        value: "expired",
        label: "Kedaluwarsa",
        badgeColor: "purple",
        format: (label) => (
            <>
                <span className="text-purple font-weight-bold">{label}</span>
                <span className="text-12 text-black-50 ml-1">(Melewati batas waktu perubahan)</span>
            </>
        )
    },
];

export const formatDate = (date, dateFormat = "dd/MM/yyyy") => format(new Date(date), dateFormat);

// Check if both `a` and `b` is nullish or have values
export const xor = (a, b) => {
    const isA = isBlankOrNullish(a);
    const isB = isBlankOrNullish(b);

    return isA === isB;
};
