import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Label, Spinner } from 'reactstrap';
import axios from 'axios';
import { format } from 'date-fns';

import IcDownload from 'assets/svg/ic_download.svg'
import IcDownloadFull from 'assets/svg/ic_download-full.svg';
import IcEdit from 'assets/svg/ic_edit.svg';
import { Downloadit, IdrFormat, capitalize, downloadedfiletopdf, parseValue } from 'helper';
import { Services } from 'service';

import { isDirectPurchaseEditable } from '../constants';
import { DetailContext } from '../detail';
import ChangePurchaseNote from './ChangePurchaseNote';
import DetailImagePreview from './DetailImagePreview';

const htmls = `<section style="background-color: #ffffff; margin: 4px; padding: 14px; font-size: 24px; margin: auto; text-align: center;">
<div style="display: flex; justify-content: space-between; align-items: center;">
    <div>
        <img id="content" height="500px" />
    </div>
</div>
</section>`;

const selfDeclarationStatus = (callback) => {
    const danger = () => <span className='text-danger'>Tidak Ada</span>
    const normal = () => <span className='text-success'>Ada</span>
    return !callback() ? danger() : normal();
};

const verifiedSelfDeclarationStatus = (callback) => {
    const warning = () => <span className='text-14 text-warning'>Belum Terverifikasi</span>
    const danger = () => <span className='text-14 text-danger'>Ditolak</span>
    const success = () => <span className='text-14 text-success'>Terverifikasi</span>
    const expired = () => <span className='text-14 text-purple'>Kedaluwarsa</span>
    switch (callback()) {
        case 'verified':
            return success();
        case 'unverified':
            return warning();
        case 'expired':
            return expired();
        case 'rejected':
            return danger();
        default:
            return '-'
    }
};

const TabInformation = ({ data, refetch, permissionAccess }) => {
    const history = useHistory();
    const { id } = useParams();
    
    const [openImage, setopenImage] = useState(false);
    
    const editable = isDirectPurchaseEditable(data?.chgStatus);
  
    return (
        <>
            <Card>
                <CardHeader className="pb-0 d-flex">
                    <h4 className="text-16 flex-grow-1" style={{ fontWeight: 600 }}>
                        Detail Informasi Pembelian Langsung
                    </h4>
                    {permissionAccess?.update && (
                        <Button
                            color={editable ? "primary" : "secondary"}
                            className="d-flex align-items-center"
                            style={{ height: "40px", pointerEvents: editable ? undefined : "none" }}
                            disabled={!editable}
                            onClick={() => {
                                if (!editable) return;
                                history.push(`/direct-purchase/${id}/edit`);
                            }}
                        >
                            <img src={IcEdit} alt="Edit icon" />
                            <span className="text-white ml-2">Edit</span>
                        </Button>
                    )}
                </CardHeader>
                <CardBody className="pt-2">
                    <FormGroup row>
                        <Label sm={3}>No. Tiket</Label>
                        <Col sm={9}>
                            : {data?.referenceCode ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Tanggal Pembelian</Label>
                        <Col sm={9}>
                            : {data?.purchaseDate ? format(new Date(data?.purchaseDate), 'dd/MM/yyyy - HH:mm') : "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>No HP Supplier</Label>
                        <Col sm={9}>
                            : {data?.phone ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Nama Supplier</Label>
                        <Col sm={9}>
                            : <Link to={`/supplier/${data?.supplierId}`} >{data?.supplierName ?? '-'}</Link>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Nama Outlet</Label>
                        <Col sm={9}>
                            : {data?.outletName ?? '-'}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Self Declaration</Label>
                        <Col sm={9}>
                            : {selfDeclarationStatus(() => data?.selfDeclaration) ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Verifikasi Self Declaration</Label>
                        <Col sm={9}>
                            : {verifiedSelfDeclarationStatus(() => data?.statusSD) ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Alamat</Label>
                        <Col sm={9}>
                            : {data?.address ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Nama Salesperson</Label>
                        <Col sm={9}>
                            : {parseValue(data?.salespersonName, capitalize)}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Branch</Label>
                        <Col sm={9}>
                            : {data?.branchName ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Stockpoint</Label>
                        <Col sm={9}>
                            : {data?.stockpointName ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Jumlah Jeriken 18L</Label>
                        <Col sm={9}>
                            : {data?.totalContainer18L ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Jumlah Jeriken 20L</Label>
                        <Col sm={9}>
                            : {data?.totalContainer20L ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Estimasi Jumlah Kg</Label>
                        <Col sm={9}>
                            : {data?.totalOilWeight + " Kg" ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Harga Rata-Rata Per Kg</Label>
                        <Col sm={9}>
                            : {IdrFormat(data?.averageOilPrice) ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Total Pembelian (Rp)</Label>
                        <Col sm={9} className='d-flex align-items-center' style={{ gap: '1rem' }}>
                            : {IdrFormat(data?.purchasePrice) ?? "-"}
                        </Col>
                    </FormGroup>
                    <DetailContext.Provider value={{
                        openImage,
                        setopenImage,
                        images: data?.purchasePow
                    }}>
                        <FormGroup row>
                            <Label sm={3}>Bukti Pembelian</Label>
                            <Col sm={9} className='d-flex' style={{ gap: '12px' }}>
                                {parseValue(data?.purchasePow, (src) => (
                                    <>
                                        <div onClick={() => setopenImage(!openImage)} className='d-flex flex-column position-relative justify-content-center align-items-center' style={{ backgroundColor: "#151823", borderRadius: 4, width: 102, height: 108, overflow: "hidden", gap: 2, cursor: 'pointer' }}>
                                            <span>
                                                <img src={src} width={'100%'} height={'auto'} alt="Bukti Pembelian" />
                                            </span>
                                        </div>
                                        <div className="d-flex flex-column" style={{ gap: "6px" }}>
                                            <Button size='sm' onClick={async () => {
                                                const responseimg = await axios.get(src, { responseType: 'arraybuffer' });
                                                let img = `data:${responseimg.headers['content-type']};base64,` + Buffer.from(responseimg.data).toString('base64');
                                                const dom = new DOMParser().parseFromString(htmls, "text/html");
                                                dom.querySelector('#content').src = img;
                                                downloadedfiletopdf(dom.firstChild.innerHTML, `Nota-${data?.referenceCode}-${data?.supplierName}-${data?.outletName} `)
                                            }} color="primary" className="m-0">
                                                <img className="mr-2" width={16} src={IcDownloadFull} alt="ico-download" />
                                                Download File
                                            </Button>

                                            {permissionAccess?.update && <ChangePurchaseNote onSucceed={refetch} />}
                                        </div>

                                        <DetailImagePreview />
                                    </>
                                ))}
                            </Col>
                        </FormGroup>
                    </DetailContext.Provider>
                    <FormGroup row>
                        <Label sm={3}>Dibuat oleh</Label>
                        <Col sm={9}>
                            : {parseValue(data?.createdBy, capitalize)}
                        </Col>
                    </FormGroup>
                </CardBody>
            </Card>
            <div style={{ display: 'flex', justifyContent: "end" }}>
                {parseValue(data?.referenceCode, (invoiceId) => <DownloadInvoice invoiceId={invoiceId} />)}
            </div>
        </>
  );
};

const DownloadInvoice = ({ invoiceId }) => {
    const dispatch = useDispatch();
    
    const [downloading, setDownloading] = useState(false);
    const [iconSrc, setIconSrc] = useState(IcDownload);

    const handleDownload = async () => {
        if (downloading) return; // prevent duplicate request

        try {
            setDownloading(true);

            const invoiceItem = await Services().get(`/api/back-office/pickup-report/invoice/${invoiceId}`);
            const { data: htmlRaw } = await Services(process.env.REACT_APP_URL_INVOICE).get("/api/invoice", {
                ...invoiceItem?.data?.data
            });

            await Downloadit({ htmlRaw, invoiceItem });
        } catch (error) {
            dispatch({
                type: "ALERT_TOAST_ERROR",
                payload: { message: error?.message ?? "Gagal Download Invoice" }
            });
        } finally {
            setDownloading(false);
        }
    };

    return (
        <Button
            outline={!downloading}
            disabled={downloading}
            color={downloading ? "secondary" : "primary"}
            className="d-flex align-items-center"
            onMouseEnter={() => setIconSrc(IcDownloadFull)}
            onMouseLeave={() => setIconSrc(IcDownload)}
            style={{ pointerEvents: downloading ? "none" : undefined }}
            onClick={handleDownload}
        >
            {downloading
                ? (
                    <span style={{ paddingBlock: "1.5px", paddingInline: "4px" }}>
                        <Spinner size="sm" color="white" />
                    </span>
                )
                : <img src={iconSrc} alt="ico-download" style={{ height: "24px" }} />}
            <span className={`ml-2 ${downloading ? "text-white" : ""}`}>
                Download Invoice
            </span>
        </Button>
    );
};

export default TabInformation;
