import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, Row, Col, CardHeader, CardBody, FormGroup, Label, Button, Input as BoostrapInput, Spinner } from 'reactstrap';
import { useFetch, useAction } from 'hooks';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import Form from 'components-form/Form';
import Select from 'components-form/Select';
import { hideConfirmationModal } from 'store/actions/confirmationModal';
import Error404 from 'page/error/Error404';
import { capitalize } from 'helper';
import Modal from 'components/Modal';
import InputDecimal from './components/InputDecimal';
import IcInfo from 'assets/svg/ic_information.svg';
import { useFormContext } from 'react-hook-form';

const App = ({
    title: documentTitle,
    history
}) => {
    document.title = documentTitle;
    const params = useParams();
    const { hasFetch } = useAction();
    const isCreate = params?.id ? false : true;
    const [visible, setvisible] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const getParamStockPoint = !isCreate && capitalize(params?.query.replaceAll("-", " "));
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const dataBreadcrumb = isCreate ?
        [
            { to: '/stockpoint-price', label: 'Harga Stock Point' },
            { to: null, label: 'Tambah Harga Standar Stock Point' }
        ]
        : [
            { to: '/stockpoint-price', label: 'Harga Stock Point' },
            { to: `/stockpoint-price/${params.id}`, label: 'Detail Harga Standar Stock Point' },
            { to: null, label: 'Edit Harga Standar Stock Point' }
        ]

    /* fecthing data for detail page */
    const { data, status, error } = useFetch(
        `/api/back-office/stockpoint-price/${params?.id}`,
        {},
        useCallback(data => ({
            data: {
                ...data?.data
            }
        }), []),
        { onMount: !isCreate }
    )

    const { data: defaultBranch } = useFetch(
        `/api/back-office/branch-price/template`,
        {},
        useCallback(data => ({
            data: {
                ...data?.data
            }
        }), []),
        { onMount: isCreate }
    )

    /* fetching stockpoint list */
    const { data: dataSp, loading: loadingSp } = useFetch(
        `/api/back-office/stockpoint/available-add-price`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );

    useEffect(() => {

        isCreate && Services().post('/api/back-office/stockpoint-price/create-check').then(response => {
            const stockpointAllSet = response?.data.data;
            setShowModal(stockpointAllSet)
        }
        ).catch(error => {
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: error?.message ?? "Something Wrong" } })
        })

    }, [isCreate, hasFetch])


    const onSubmit = (input) => {
        setIsLoadingSubmit(true);
        const id = params?.id;
        delete input.userLevel;
        const removeDeciFormat = (number) => parseFloat(number.replace(/[.,\s]/g, ''))
        const requestBody = {
            ...input,
            stockpointId: input.stockpointId !== null ? input.stockpointId : null,
            price: input.price !== null ? removeDeciFormat(input.price) : null,
            maximumPrice: input.maxPrice !== null ? removeDeciFormat(input.maxPrice) : null,
            topUpThreshold: removeDeciFormat(input?.recomendedtopup),
            minimumTopUp: removeDeciFormat(input?.minPrice),
            maximumBalance: removeDeciFormat(input?.maxModal)
        };
        if (requestBody.password === "") {
            delete requestBody.password
        }
        const action = ({ requestBody, id = null }) => isCreate
            ? Services().post('/api/back-office/stockpoint-price/add', requestBody)
            : Services().post(`/api/back-office/stockpoint-price/edit`, { ...requestBody, id: parseInt(id) })

        action({ requestBody, id: params?.id })
            .then(() => {
                setIsLoadingSubmit(false);
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: isCreate ? `Harga standar baru berhasil ditambah` : `Harga standar baru berhasil diubah` }
                })
                history.push(isCreate ? '/stockpoint-price' : `/stockpoint-price/${id}`);
                hasFetch(hideConfirmationModal());
            })
            .catch(e => {
                setIsLoadingSubmit(false);
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Cannot update ${id}` } })
                hasFetch(hideConfirmationModal());
            })
    }
    const defaultValues = {
        stockpointId: data?.stockpointId ?? '',
        price: data?.price ? Intl.NumberFormat('id-ID').format(data?.price) : '',
        maxPrice: data?.maximumPrice ? Intl.NumberFormat('id-ID').format(data?.maximumPrice) : '',
        recomendedtopup: data?.topUpThreshold ? Intl.NumberFormat('id-ID').format(data?.topUpThreshold) : '',
        minPrice: data?.minimumTopUp ? Intl.NumberFormat('id-ID').format(data?.minimumTopUp) : "",
        maxModal: data?.maximumBalance ? Intl.NumberFormat('id-ID').format(data?.maximumBalance) : ""
    }
    if ((status === 'rejected' && error.code === 400) || (status === 'resolved' && data === null)) return <Error404 />
    return (status === 'resolved' || isCreate) && (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>{isCreate ? "Tambah" : "Edit"} Harga Standar Stock Point</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Form id="driverForm" onSubmit={onSubmit} autoComplete="off" defaultValues={defaultValues}>
                <Card>
                    <CardHeader className="pb-0">
                        Informasi Harga Standar Stock Point
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label htmlFor="stockpointName" sm={3}>Stock Point</Label>
                                    <Col sm={9}>
                                        {isCreate
                                            ? <Select
                                                id="stockpointId"
                                                name="stockpointId"
                                                className="basic-single"
                                                classNamePrefix="select"
                                                options={dataSp?.map(obj => ({ label: `${obj?.code} - ${obj?.name}`, value: obj?.id }))}
                                                placeholder={`Pilih Stockpoint`}
                                                isLoading={loadingSp}
                                                validation={['required']}
                                            />
                                            : <BoostrapInput type="text" placeholder="Stockpoint Name" autoComplete="off" defaultValue={getParamStockPoint} disabled />
                                        }

                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="price" sm={3}>Harga Standar / Kg</Label>
                                    <Col sm={9}>
                                        <InputDecimal id="price" maxLength={6} max={999999999} maxed={5} validation={['required', `minLength[4]`, 'valueOfNoteKg', 'valueOfnull']} name="price" type="text" placeholder="Harga Standar" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="maxPrice" sm={3}>Batas Maksimum</Label>
                                    <Col sm={9}>
                                        <InputMax defaultBranch={defaultBranch} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="maxModal" sm={3}>Maksimum Modal</Label>
                                    <Col sm={9}>
                                        <InputDecimal id="maxModal" maxLength={10} max={999999999} maxed={8} validation={['required', 'valueOfNumber', 'valueOfnull']} name="maxModal" type="text" placeholder="Maksimum Modal" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label className='relative d-flex' style={{ gap: 2 }} sm={3}>
                                        <span className='pr-1'>Rekomendasi Top Up</span>
                                        <div
                                            style={{ cursor: "pointer", position: "relative" }}
                                        >
                                            <img
                                                onMouseOver={() => setvisible(true)}
                                                onMouseLeave={() => setvisible(false)}
                                                style={{ cursor: "pointer" }}
                                                src={IcInfo} alt='create' className='mr-2' height={14}
                                            />
                                            {visible && <div className='text-12 mt-2 tooltip-text' style={{
                                                position: 'absolute',
                                                color: "#151823",
                                                zIndex: 50,
                                                right: 0,
                                                borderRadius: "4px",
                                                padding: "8px",
                                                width: "155px",
                                                background: "#ffff",
                                                boxShadow: "0px 1px 4px 0px rgba(112, 114, 125, 0.40)"
                                            }}>
                                                <span>Pengingat rekomendasi top up akan aktif sesuai dengan angka % yang diinput</span>
                                            </div>}
                                        </div>
                                    </Label>
                                    <Col sm={9}>
                                        <InputTreshold defaultBranch={defaultBranch} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="minPrice" sm={3}>Minimum Top Up</Label>
                                    <Col sm={9}>
                                        <InputDecimal id="minPrice" maxLength={10} max={999999999} maxed={8} validation={['required', 'valueOfNumber', 'valueOfnull']} name="minPrice" type="text" placeholder="Minimum Top Up" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Row>
                    <Col className="text-right">
                        <Link to={(isCreate) ? "/stockpoint" : `/stockpoint/${params.id}`}>
                            <Button type="button" color="secondary" className="m-1" onClick={() => window.history.back()}>
                                Batal
                            </Button>
                        </Link>
                        <Button type="submit" color="primary" className="m-1" disabled={isLoadingSubmit}>
                            {isLoadingSubmit &&
                                <span className='mr-2'>
                                    <Spinner size="sm" />
                                </span>}
                            {(isCreate) ? 'Buat' : 'Simpan'}
                        </Button>
                    </Col>
                </Row>
            </Form>

            {/* Modal info stockpoint all set */}
            <Modal
                isOpen={showModal}
                handleClose={() => setShowModal(!showModal)}
                component={<>
                    <h5>Harga Standar Stockpoint Penuh</h5>
                    <div className="my-4">
                        Semua Stockpoint telah memiliki harga standar.
                        Anda hanya bisa ubah atau hapus harga
                    </div>
                    <div className='text-right mt-4'>
                        <Link to="/stockpoint-price">
                            <Button onClick={() => setShowModal(false)} size='sm' type="button" color="primary" >
                                Mengerti
                            </Button>
                        </Link>
                    </div>
                </>
                }
            />
        </>
    )
}

export const InputMax = ({ defaultBranch }) => {
    const { id } = useParams()
    const { setValue } = useFormContext();
    useEffect(() => {
        !id && setValue('maxPrice', Intl.NumberFormat('id-ID').format(defaultBranch?.maximumPrice))
    }, [id, setValue, defaultBranch])
    return (
        <InputDecimal id="maxPrice" maxLength={6} max={999999} maxed={999999} validation={['required', `minLength[4]`, `moreThanStandar[${defaultBranch?.maximumPrice}]`, 'valueOfnull']} name="maxPrice" type="text" placeholder="Batas Maksimum" autoComplete="off" />
    )
}

export const InputTreshold = ({ defaultBranch }) => {
    const { id } = useParams()
    const { setValue } = useFormContext();
    useEffect(() => {
        !id && setValue('recomendedtopup', defaultBranch?.topUpThreshold.toString());
    }, [id, setValue, defaultBranch])
    return (
        <InputDecimal id="recomendedtopup" maxLength={3} max={100} maxed={100} validation={['required', 'valueOfnull']} name="recomendedtopup" type="text" placeholder="0%" autoComplete="off" />

    )
}

export default App