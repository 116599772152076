import React, { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormFeedback, InputGroup } from 'reactstrap'
// import { get } from 'lodash'
import validationRule from 'helper/ValidationRule';
import { FileUploader } from 'react-drag-drop-files';
const get = (obj, path, def) => (() => typeof path === 'string' ? path.replace(/\[(\d+)]/g, '.$1') : path.join('.'))()
    .split('.')
    .filter(Boolean)
    .every(step => ((obj = obj[step]) !== undefined)) ? obj : def

const defaultTransform = {
    output: val => val,
    input: val => val,
}

const FilUploaderContext = ({
    validation = [],
    validationMessage = [],
    inputGroupAddon = null,
    name,
    defaultValue = '',
    shouldUnregister = false,
    transform = defaultTransform,
    ...props
}) => {
    const { control, getFieldState, getValues, setValue, setError, trigger, watch } = useFormContext();
    const { isDirty } = getFieldState(name);
    const currentValue = watch(name);
    
    const handleInputChange = (file) => {
        setValue(name, file, { shouldValidate: true, shouldDirty: true });
    };

    /**
     * To force field revalidation each time currentValue changed, because
     * handleChange is not always called even when currentValue had changed
     */
    useEffect(() => {
        if (isDirty) {
            trigger(name);
        }
    }, [isDirty, name, trigger, currentValue]);

    return (
        <Controller
            shouldUnregister={shouldUnregister}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validationRule({ rule: validation, validationMessage }, getValues, name)}
            render={({
                field: { ref, onChange, value, ...fieldInput },
                formState: { errors } }) => {
                return (
                    <InputGroup>
                        <div className='d-flex flex-column'>
                            <FileUploader
                                name={name}
                                fileOrFiles={value}
                                onTypeError={(err) => setError(name, {
                                    type: "manual",
                                    message: err,
                                })}
                                onSizeError={(err) => setError(name, {
                                    type: "manual",
                                    message: err,
                                })}
                                handleChange={(e) => {
                                    handleInputChange(e)
                                }}
                                {...fieldInput}
                                {...props}
                            />
                            
                        </div>
                        {get(errors, name) && <FormFeedback invalid={"true"} style={{ display: 'block' }}>{get(errors, name)?.message}</FormFeedback>}
                    </InputGroup>
                )
            }}
        />
    )
}

export default FilUploaderContext;