import { combineReducers } from 'redux';
import { setAlertsToast } from './alertToast';
import { confirmationModal } from './confirmationModal';
import { homesidemenu } from './homesidemenu';

const rootReducers = combineReducers({
    setAlertsToast,
    confirmationModal,
    homesidemenu
})

export default rootReducers
