import axios from 'axios';
import {
    getToken,
    setToken,
    logout,
    isTimeUp,
} from 'helper';

const baseURL = process?.env?.REACT_APP_API_URL;

const Axios = (url = null) => {
    const { accessToken } = getToken()
    let instance = axios.create();
    if (url) {
        instance.defaults.baseURL = url
    } else {
        if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "staging" || process.env.NODE_ENV === "development") {
            instance.defaults.baseURL = process.env.REACT_APP_API_URL
        }
    }
    instance.defaults.timeout = 1000 * process.env.REACT_APP_API_TIMEOUT;
    instance.defaults.headers.common['Content-Type'] = 'application/json';
    if (accessToken) {
        instance.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
    }

    instance.interceptors.request.use(config => {
        isTimeUp();
        return config
    });

    instance.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        const { refreshToken } = getToken();
        const originialRequest = error.config;

        // if (error.response?.status === 400) logout()

        if (refreshToken && error?.response?.status === 401 && !originialRequest?.retried) {
            originialRequest.retried = true
            return axios.post('/api/authentication/refresh-token', {
                "refreshToken": refreshToken
            }, { baseURL })
                .then(res => {
                    let newAccessToken = res.data?.data?.accessToken ?? null;
                    let newRefreshToken = res.data?.data?.refreshToken ?? null;

                    setToken({
                        accessToken: newAccessToken,
                        refreshToken: newRefreshToken,
                    });

                    return instance({
                        ...originialRequest,
                        headers: {
                            ...originialRequest.headers,
                            'Authorization': `Bearer ${newAccessToken}`
                        }
                    })
                }).catch(e =>
                    e.response?.status === 400 && axios.post('/api/authentication/logout', {
                        "refreshToken": refreshToken
                    }, { baseURL })
                        .then(res => {
                            logout()
                        }).catch(e =>
                            e.response?.status === 400 && logout()
                        )
                )
        }

        return Promise.reject({
            code: error.response?.status ?? '',
            status: error.response?.statusText ?? '',
            message: (error.response?.data.detail)
                ? error.response?.data.detail
                : (error.response?.data.message)
                    ? error.response?.data.message
                    : error.response?.statusText
                        ? error.response?.statusText
                        : error?.message
                        ?? 'Something went wrong',
            rawData: error.response?.data ?? ''
        })
    });

    return instance;
}

export const Services = (url) => ({
    get: (endpointName, params = null, config = null) => {
        let data = {};
        if (params) { data['params'] = params; }
        if (config) { data = { ...data, ...config }; }
        return Axios(url).get(endpointName, data);
    },
    post: (endpointName, params = null, config = null) => {
        return Axios(url).post(endpointName, params, config);
    },
    put: (endpointName, params = null, config = null) => {
        return Axios(url).put(endpointName, params, config);
    },
    delete: (endpointName, params = null, config = null) => {
        let data = {};
        if (params) { data['params'] = params; }
        if (config) { data = { ...data, ...config }; }
        return Axios(url).delete(endpointName, data);
    }
})
