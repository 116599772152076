import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
// import { get } from 'lodash'
import validationRule from 'helper/ValidationRule';
// const get = (obj, path, def) => (() => typeof path === 'string' ? path.replace(/\[(\d+)]/g, '.$1') : path.join('.'))()
//     .split('.')
//     .filter(Boolean)
//     .every(step => ((obj = obj[step]) !== undefined)) ? obj : def

const defaultTransform = {
    output: val => val,
    input: val => val,
}

const TextInputHolder = ({
    validation = [],
    validationMessage = [],
    inputGroupAddon = null,
    name,
    defaultValue = '',
    shouldUnregister = false,
    transform = defaultTransform,
    ...props
}) => {
    const { control, getValues, } = useFormContext()

    const transformer = { ...defaultTransform, ...transform }
    return (
        <Controller
            shouldUnregister={shouldUnregister}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validationRule({ rule: validation, validationMessage }, getValues, name)}
            render={({
                field: { ref, onChange, value, ...fieldInput },
                formState: { errors } }) => {
                return (
                    <span>{transformer.input(value)}</span>
                )
            }}
        />
    )
}

export default TextInputHolder