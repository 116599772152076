import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormFeedback, InputGroup } from 'reactstrap'
import validationRule from 'helper/ValidationRule';
// import { get } from 'lodash'
import "react-datepicker/dist/react-datepicker.css";
// import Date from "react-datepicker";
import ReactDatePicker from "react-datepicker";

const get = (obj, path, def) => (() => typeof path === 'string' ? path.replace(/\[(\d+)]/g, '.$1') : path.join('.'))()
  .split('.')
  .filter(Boolean)
  .every(step => ((obj = obj[step]) !== undefined)) ? obj : def

const DatePicker = ({
  validation = [],
  validationMessage = [],
  name,
  defaultValue = null,
  ...props
}) => {
  const { control, setValue, trigger, watch, getValues } = useFormContext()
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={validationRule({ rule: validation, validationMessage }, getValues, name)}
      render={({ field: { ref, onChange, ...fieldInput }, formState: { errors } }) => {
        return (
          <InputGroup className="input-icon-right">
            <ReactDatePicker
              {...props}
              inneraRef={ref}
              className={`form-control ${get(errors, name) && "is-invalid"}`}
              selected={watch(name)}
              {...fieldInput}
              onChange={date => {
                setValue(name, date)
                trigger(name)
                props?.onChange && props.onChange(date)
              }}
              autoComplete="off"
            />
            {get(errors, name) && <FormFeedback invalid="true" style={{ display: 'block' }}>{get(errors, name)?.message}</FormFeedback>}
          </InputGroup>
        )
      }}
    />
  )
}

export default DatePicker