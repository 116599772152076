import React from 'react'
import Input from 'components-form/Input';

const InputDecimal = ({
    ...props
}) => {
    return <Input
        {...props}
        transform={{
            output: e => {
                // regex only number                
                const regexNumber = e.target.value.replace(/[^\d,]/g, '')
                if (parseFloat(regexNumber) <= props?.max && regexNumber.length <= props?.maxed) {
                    const formatCurrency = new Intl.NumberFormat('id-ID');
                    const finalNumber = (number) => regexNumber !== "" && formatCurrency.format(number.replace(/[.,\s]/g, ''));
                    return finalNumber(regexNumber) || 0;
                }
                else {
                    return ''
                }
            }
        }}
    />
}

export default InputDecimal
