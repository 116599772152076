import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { useCallback, useEffect } from 'react';
import { useAction } from './useAction';

const timeout = 3000;
export const showToast = ({ name, message }) => {
    switch (name) {
        case 'info':
            NotificationManager.info(message, 'Information', timeout);
            break;
        case 'success':
            NotificationManager.success(message, 'Success', timeout);
            break;
        case 'warning':
            NotificationManager.warning(message, 'Warning', timeout);
            break;
        case 'error':
            NotificationManager.error(message, 'Error', timeout);
            break;
        default:
            break;
    }
}

export const useAlertToast = (options = null) => {
    const { hasFetch } = useAction();
    const toastAlerts = useSelector(state => {
        return {
            status: state.setAlertsToast.notify.status,
            statName: state.setAlertsToast.notify.statName,
            message: state.setAlertsToast.notify.message,
        }
    });

    const showToast = useCallback(() => {
        switch (toastAlerts.statName) {
            case 'info':
                NotificationManager.info(toastAlerts.message, 'Information', timeout);
                break;
            case 'success':
                NotificationManager.success(toastAlerts.message, 'Success', timeout);
                break;
            case 'warning':
                NotificationManager.warning(toastAlerts.message, 'Warning', timeout);
                break;
            case 'error':
                NotificationManager.error(toastAlerts.message, 'Error', timeout);
                break;
            default:
                break;
        }
    }, [toastAlerts.message, toastAlerts.statName])

    useEffect(() => {
        toastAlerts.status && showToast();
    }, [toastAlerts.status, hasFetch, showToast]);

    useEffect(() => {
        toastAlerts.status && hasFetch({ type: 'ALERT_TOAST_CLEARS' });
    }, [toastAlerts.status, hasFetch])

    return {
        NotificationContainer
    }
}
