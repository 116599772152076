import React, { Fragment } from 'react';
import { Table, Spinner } from 'reactstrap';

const App = ({
    columns,
    dataList,
    params,
    showNumber = true,
    loading,
    error,
    customDataNotFoundMessage = 'Data tidak ditemukan'
}) => {
    /* define value sorting */
    const { sort } = params;
    /* generate header */
    const generateHeader = () => {
        const [sortKey, sortValue] = sort?.split?.(',') ?? []
        return columns.map((obj, key) => {
            if (obj.renderHeader) {
                return (
                    <th rowSpan={!obj?.children ? 2 : 1} key={key} style={obj.stylesHeader}>
                        {obj.renderHeader}
                    </th>
                )
            }
            if (obj.onSort) {
                return (
                    <th rowSpan={!obj?.children ? 2 : 1} key={key} style={obj.stylesHeader}>
                        {<a href="#/" onClick={() => obj.onSort(sort === `${obj.key},asc` ? `${obj.key},desc` : `${obj.key},asc`)} className={'text-nowrap'}>
                            {obj.title} &nbsp;
                            {(sortKey === obj.key) && <i className={`fa fa-sort-${sortValue}`} />}
                        </a>}
                    </th>
                )
            }
            return <th
                style={obj.stylesHeader}
                rowSpan={!obj?.children ? 2 : 1}
                colSpan={obj?.children?.length}
                key={key}
                className={obj?.children ? "hasChildren" : "text-left"}
            >
                {obj.title}
            </th>
        });
    }

    const generateChildren = () => {
        const [sortKey, sortValue] = sort?.split?.(',') ?? []
        return columns.map((objK, key) => {
            if (objK.children) {
                return objK.children.map((obj, index) => {
                    if (obj.renderHeader) {
                        return (
                            <th colSpan={1} key={index} style={obj.stylesHeader}>
                                {obj.renderHeader}
                            </th>
                        )
                    }
                    if (obj.onSort) {
                        return (
                            <th colSpan={1} key={index} style={obj.stylesHeader}>
                                <a href="#/" onClick={() => obj.onSort(sort === `${obj.key},asc` ? `${obj.key},desc` : `${obj.key},asc`)} className={'text-nowrap'}>
                                    {obj.title} &nbsp;
                                    {(sortKey === obj.key) && <i className={`fa fa-sort-${sortValue}`} />}
                                </a>
                            </th>
                        )
                    }
                    return <th colSpan={1} key={index} style={obj.stylesHeader}>{obj.title}</th>
                })
            }
            return [null]
        });
    }

    const renderColumn = (columnData, rowData, keyColumn) => {
        const { key, render, children, cellStyle } = columnData;
        const value = getValueColumn(rowData, key);
        if (render) {
            return <td key={keyColumn} style={cellStyle}>{render(value, rowData, keyColumn)}</td>;
        }
        else if (children) {
            return children?.map((item, index) => {
                const { key, render, } = item;
                const valuex = getValueColumn(rowData, key);
                return (
                    <td key={index} style={cellStyle}>{render(valuex, rowData, keyColumn)}</td>
                )
            });
        }
        else {
            return <td key={keyColumn} style={cellStyle}>{(value) ? value : '-'}</td>;
        }
    }
    const getValueColumn = (value, dataIndex) => {
        switch (typeof dataIndex) {
            case 'function':
                return dataIndex(value);
            case 'string':
                return value[dataIndex];
            default:
                return null;
        }
    }

    /* generate column */

    const generateColumn = () => {
        if (error !== null) {
            return (
                <tr>
                    <td colSpan="99" className="text-center">
                        {error?.message}
                    </td>
                </tr>
            )
        }
        if (dataList && dataList.length > 0) {
            let number = params.page > 1 ? ((params.page - 1) * params.size) + 1 : 1;
            return dataList.map((row, key) => {
                return (
                    <tr key={`row-${key}`}>
                        {showNumber ? <td key={`number-${number}`}>{number++}.</td> : null}
                        {columns.map((objColumn, keyColumn) => {
                            return renderColumn(objColumn, row, keyColumn);
                        })}
                    </tr>
                )
            });
        } else {
            return (
                <tr>
                    <td colSpan="99" className="text-center">
                        {customDataNotFoundMessage}
                    </td>
                </tr>
            )
        }
    }
    return (
        <>
            <Table responsive>
                <thead>
                    <tr>
                        {showNumber ? <th rowSpan="2">No.</th> : null}
                        {generateHeader()}
                    </tr>
                    <tr>
                        {generateChildren().map((item, index) => {
                            return <Fragment key={index + 1}>{item}</Fragment>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {
                        (loading) ? <TableLoader /> : (generateColumn())
                    }
                </tbody>
            </Table>
        </>
    )
}

const TableLoader = () => {
    return (
        <tr>
            <td colSpan="99" className="text-center">
                <Spinner size="lg" color="primary" />
            </td>
        </tr>
    )
}

export default App;