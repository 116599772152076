import React, { useState, useCallback, useEffect, createContext } from 'react';
import { Button, Col, Label, Row, Modal, ModalBody, FormGroup } from 'reactstrap';
import Form from 'components-form/Form';
import { useFormContext, useWatch } from 'react-hook-form';
import { Services } from 'service';
import { useAction, useFetch } from 'hooks';
import Select from 'components-form/Select';
import DatePicker from "components-form/DatePicker";
import { addHours, format } from 'date-fns';
// import { useSelector } from 'react-redux';
import InputNumberDecimal from 'components-form/InputNumberDecimal';
import AsyncSelect from 'components-form/AsyncSelect';
import { debounce } from 'helper';

const findDriver = [{
    label: "Internal Stockpoint",
    value: "internal"
}, {
    label: "External Stockpoint",
    value: "external"
}]

const rootStyle = (variable) => {
    return getComputedStyle(document.body).getPropertyValue(variable)
}

const styles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#e4e7ea' : provided.backgroundColor,
        border: '1px solid #DBDCDD',
        boxShadow: 'none',
        '&:hover': {
            borderColor: state.selectProps.readonly ? '#DBDCDD' : rootStyle('--primary')
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0.475rem 0.625rem',
        height: 'calc(1.5em + 1.25rem + 5px)'
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
    })
}

const theme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: rootStyle('--light'),
        primary: rootStyle('--primary'),
    }
})

const ContextMinyak = createContext()
const ModalCreateDelivery = ({ open, close, refetch }) => {
    // const { profile } = useSelector(state => state.homesidemenu)
    const currentDate = new Date();
    const [flex, setflex] = useState("internal")
    const [oilStockSp, setOilStockSp] = useState("")
    const { hasFetch } = useAction()

    const handleSubmit = (values) => {
        Services().post('/api/back-office/trip-plan/delivery', {
            "assignmentDate": format(addHours(values?.assignmentDate, 7), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
            "branchId": values.branchId,
            "stockpointId": values.stockpointId,
            // "warehouseId": profile?.warehouseId,
            "warehouseId": values.warehouseId,
            "driverId": values.driver,
            "oilWeight": parseFloat(values.oilWeight)
        }).then((_) => {
            hasFetch({
                type: 'ALERT_TOAST_SUCCESS',
                payload: { message: `Pengantaran baru berhasil ditambah` }
            })
            refetch()
            close()
        }).catch(e => {
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Pengantaran baru gagal ditambah` } })
        })
    }

    /* fetching driver list */
    const { data: driverList, loading: isLoadingDriver, setParams: setParamsDriver } = useFetch(
        `/api/back-office/driver/warehouse/assignment`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );

    /* fetching warehouse list */
    const { data: warehouseList, loading: isLoadingWarehouse } = useFetch(
        `/api/back-office/warehouse`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: open }
    );

    const handleDateChange = (date) => {
        const FormatingDate = format(new Date(date), "dd-MM-yyyy")
        setParamsDriver(prev => ({
            ...prev,
            date: FormatingDate
        }))
    }

    useEffect(() => {
        return () => {
            setOilStockSp("")
        }
    }, [oilStockSp])

    const defaultValue = {
        branchId: "",
        stockpointId: "",
        oilWeight: ""
    }

    return (
        <Modal
            isOpen={open}
            toggle={close}
            size="lg"
            className="modal-dialog-centered modal-dialog-confirmation"
        >
            <ModalBody>
                <div className='font-weight-bold text-16 mb-4'>Rute Pengantaran</div>
                <Form onSubmit={handleSubmit} className="w-100" id="modalForm" autoComplete="off" defaultValues={defaultValue}>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Tanggal Penugasan</Label>
                                <DatePicker
                                    id="assignmentDate"
                                    name="assignmentDate"
                                    placeholderText="Tanggal Penugasan"
                                    onChange={handleDateChange}
                                    validation={['required']}
                                    className="form-control"
                                    minDate={currentDate}
                                    dateFormat={"dd/MM/yyyy"}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Warehouse</Label>
                                <Select
                                    id="warehouseId"
                                    name="warehouseId"
                                    classNamePrefix="select"
                                    options={warehouseList?.map(obj => ({ label: `${obj?.code} - ${obj?.name}`, value: obj?.id }))}
                                    placeholder={`Pilih Warehouse`}
                                    isLoading={isLoadingWarehouse}
                                    validation={['required']}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Branch</Label>
                                <BranchField name={'branchId'} />
                                {/* <Select
                                    id="branchId"
                                    name="branchId"
                                    classNamePrefix="select"
                                    options={branchList?.map(obj => ({ label: `${obj?.code} - ${obj?.name}`, value: obj?.id }))}
                                    placeholder={`Pilih Branch`}
                                    isLoading={isLoadingBranch}
                                    validation={['required']}
                                /> */}
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Stockpoint</Label>
                                <StockPoint name={'stockpointId'} />
                                {/* <Select
                                    id={"stockpointId"}
                                    name={"stockpointId"}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    options={spList?.content.map(obj => ({
                                        label: `${obj?.name} (Tersedia ${handleDecimal(obj?.oilWeightStock)} Kg)`,
                                        value: obj?.id,
                                        oilWeightStock: handleDecimal(obj?.oilWeightStock)
                                    }))}
                                    onChange={(val) => {
                                        setOilStockSp(val.oilWeightStock)
                                    }}
                                    placeholder={`Pilih Stockpoint`}
                                    isLoading={isLoadingSp}
                                    validation={['required']}
                                    theme={theme}
                                    styles={styles}
                                /> */}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label htmlFor="roleId">Cari Driver Berdasarkan</Label>
                                <Select
                                    id="roleId"
                                    onChange={(finds) => {
                                        setflex(finds?.value)
                                    }}
                                    // validation={['required']}
                                    name="findDriver"
                                    className="basic-single"
                                    classNamePrefix="select"
                                    options={findDriver}
                                    placeholder={`Pilih Driver`}
                                    // isLoading={isLoadingRole}
                                    defaultValue={findDriver[0]?.value}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label>Driver</Label>
                                {flex === "internal" && <Select
                                    id={"driver"}
                                    name={"driver"}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    options={driverList?.map(obj => ({ label: obj?.name, value: obj?.id }))}
                                    placeholder={`Pilih Driver`}
                                    isLoading={isLoadingDriver}
                                    validation={['required']}
                                    theme={theme}
                                    styles={styles}
                                />}
                                {flex === "external" && <DriverExternal />}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <FormGroup>
                                <Label>Jumlah Minyak (Kg)</Label>
                                <ContextMinyak.Provider value={oilStockSp}>
                                    <JumlahMinyak />
                                </ContextMinyak.Provider>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className='text-right mt-4'>
                        <Button onClick={() => {
                            close()

                        }} outline size='sm' type="button" color="primary" >
                            Batal
                        </Button>
                        <TugaskanDriver />
                    </div>
                </Form>
            </ModalBody>
        </Modal>

    )
}


export const JumlahMinyak = () => {
    return (
        <InputNumberDecimal
            id="oilWeight"
            name="oilWeight"
            type="text"
            disabled={true}
            placeholder="0"
            autoComplete="off"
            validation={[`required`]}
        />
    )
}

export const TugaskanDriver = () => {
    const { formState: { isValid } } = useFormContext();

    return (
        <Button disabled={!isValid} size='sm' type="submit" color={!isValid ? "secondary" : "primary"} >
            Tugaskan
        </Button>
    )
}

export const DriverExternal = ({ price, control }) => {
    const val = useWatch()
    const { hasFetch } = useAction();
    const loadOptions = debounce(async (inputValue, callback) => {
        try {
            const date = format(new Date(val?.assignmentDate), "dd-MM-yyyy")
            const raw = await Services().get('/api/back-office/driver/wrh-assignment/cross', {
                driverCode: inputValue,
                date
            })
            callback([{
                label: raw?.data?.data?.name,
                value: raw?.data?.data?.id,
            }])
        } catch (err) {
            hasFetch({
                type: 'ALERT_TOAST_ERROR',
                payload: { message: err.message }
            })
        }
    }, 1000);
    return (
        <AsyncSelect
            id="roleId"
            loadOptions={loadOptions}
            validation={['required']}
            name="roleId"
            className="basic-single"
            classNamePrefix="select"
            placeholder={`Pilih Driver`}
        />
    )
}

const BranchField = ({ name }) => {
    const { setValue } = useFormContext();
    const { data, loading } = useFetch(
        `/api/back-office/branch`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );
    return (
        <Select
            id={name}
            name={name}
            className="basic-single"
            classNamePrefix="select"
            options={data?.map(obj => ({ label: `${obj?.name}`, value: obj?.id, id: obj?.id })).sort((a, b) => a.label.localeCompare(b.label))}
            placeholder={`Pilih Branch`}
            isLoading={loading}
            isClearable={true}
            onChange={(ev) => {
                setValue('stockpointId', null)
            }}
        />
    )
}

const StockPoint = ({ name }) => {
    const { watch, setValue } = useFormContext();
    const branchValue = watch('branchId');
    const { data: stockPointList, loading: isLoadingStockPoint, setParams } = useFetch(
        `/api/back-office/warehouse/stockpoint`,
        currentParams => ({
            unpaged: true
        }),
        useCallback(data => ({
            data: data?.data?.content,
        }), []),
        { onMount: true, withQueryParams: false, showAlertOnError: false }
    )
    useEffect(() => {
        if (branchValue) {
            setParams(prev => ({
                ...prev,
                branch: branchValue
            }))
        }
    }, [branchValue, setParams]);
    return (
        <>
            <Select
                id={name}
                name={name}
                className="basic-single"
                classNamePrefix="select"
                options={stockPointList?.map(obj => ({
                    label: `${obj?.name}`, value: obj?.id,
                    oilVolumeStock: obj?.oilVolumeStock,
                    oilWeightStock: obj?.oilWeightStock
                }))}
                placeholder={`Pilih Stockpoint`}
                isLoading={isLoadingStockPoint}
                isDisabled={!branchValue}
                isClearable={true}
                onChange={(ev) => {
                    setValue('oilWeight', ev?.oilWeightStock)
                }}
            />
        </>
    )
}


export default ModalCreateDelivery