import React, { useCallback } from 'react'
import {
    Card, CardBody, Row, Col,
    CardHeader,
    FormGroup,
    Label,
    Button,
} from 'reactstrap';
import {
    Link,
    useParams
} from 'react-router-dom';
import { Breadcrumb } from 'components';
import { useFetch } from 'hooks';
import Error404 from 'page/error/Error404';
import { format } from 'date-fns';
import localeId from 'date-fns/locale/id';

const dataBreadcrumb = [
    { to: '/stockpoint', label: 'Master Data Stockpoint' },
    { to: null, label: 'Detail Stockpoint' }
];

const formatDate = (date) => date ? format(new Date(date), 'EEEE, dd/MM/yyyy - HH:mm', { locale: localeId }) : '-';

const getColorStatus = (status) => {
    switch (status) {
        case "active":
            return <span color="success" className='text-14 text-success p-1'>
                Aktif
            </span>
        case "inactive":
            return <span color="danger" className='text-14 text-danger p-1'>
                Tidak  Aktif
            </span>
        default:
            return <span color="black" className='text-14 p-1'>
                {status}
            </span>
    }
}

const App = ({
    title: documentTitle,
    access: permissionAccess,
    history
}) => {
    document.title = documentTitle;
    const { id } = useParams();

    const { data, status, error } = useFetch(
        `/api/back-office/stockpoint/${id}`,
        {},
        useCallback(data => ({ data: data?.data }), [])
    );

    if ((status === 'rejected' && error.code === 400) || (status === 'resolved' && data === null)) return <Error404 />

    return status === 'resolved' && (
        <>
            <Row className="row mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Detail Stockpoint</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Card>
                <CardHeader className="pb-0">
                    Informasi Stockpoint
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col>
                            <DetailField
                                label='Status'
                                value={getColorStatus(data?.status)}
                            />
                            <DetailField
                                label='Branch'
                                value={data?.branchCode}
                            />
                            <DetailField
                                label='Nama Branch'
                                value={data?.branchName}
                            />
                            <DetailField
                                label='Kode Stockpoint'
                                value={data?.code}
                            />
                            <DetailField
                                label='Nama Stockpoint'
                                value={data?.name}
                            />
                            <DetailField
                                label='Provinsi'
                                value={data?.province}
                            />
                            <DetailField
                                label='Kota'
                                value={data?.city}
                            />
                            <DetailField
                                label='Detail Alamat'
                                value={data?.address}
                            />
                            <DetailField
                                label='Lokasi Alamat'
                                value={data?.location}
                            />
                            <DetailField
                                label='Latitude'
                                value={data?.latitude}
                            />
                            <DetailField
                                label='Longitude'
                                value={data?.longitude}
                            />
                            <DetailField
                                label='Kode Payment Method'
                                value={data?.paymentMethodCode}
                            />
                            <DetailField
                                label='Kode Financial Payment Method'
                                value={data?.financialAccountCode}
                            />
                            <DetailField
                                label='Kode Akun'
                                value={data?.accountCode}
                            />
                            <DetailField
                                label='Driver SP'
                                value={data?.driverSP}
                            />
                            <DetailField
                                label='Dibuat Oleh'
                                value={`${data?.createdBy ?? '-'} | ${formatDate(data?.createdDate)}`}
                            />
                            <DetailField
                                label='Diperbarui Oleh'
                                value={`${data?.modifiedBy ?? '-'} | ${formatDate(data?.modifiedDate)}`}
                            />
                        </Col>
                    </Row>

                </CardBody>
            </Card>
            <div className='d-flex justify-content-end mt-2 mt-md-0'>
                {permissionAccess?.update && (
                    <Link to={`/stockpoint/${id}/edit`}>
                        <Button type="button" color="primary">
                            <i className='fa fa-pencil mr-3'></i>
                            Ubah
                        </Button>
                    </Link>
                )}
            </div>
        </>
    );
};

const DetailField = ({ label, value }) => (
    <FormGroup row>
        <Label md={3}>{label}</Label>
        <Col md={9}>
            : {value ?? "-"}
        </Col>
    </FormGroup>
);

export default App;
