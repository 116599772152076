import React from "react";
import { Card, Spinner } from "reactstrap";

const CardSpinner = () => (
    <Card
        body
        className="d-flex align-items-center justify-content-center"
        style={{ height: "310px" }}
    >
        <Spinner />
    </Card>
);

export default CardSpinner;
