import { NotificationManager } from "react-notifications"

const InitialStateAlerts = {
    notify: {
        status: false,
        componentMessage: {
            ribbon: "",
            block: ""
        },
        statName: '',
        message: "",
    },
    status: null,
    toastShow: null
}

export const setAlertsToast = (state = InitialStateAlerts, action) => {
    switch (action.type) {
        case 'ALERT_TOAST_ERROR':
            return {
                ...state,
                status: action.payload.status,
                toastShow: NotificationManager.error(action.payload.message, "Error", 3000),
            }
        case 'ALERT_TOAST_SUCCESS':
            return {
                ...state,
                status: action.payload.status,
                toastShow: NotificationManager.success(action.payload.message, 'Success', 3000)
            }
        case 'ALERT_TOAST_WARNING':
            return {
                ...state,
                status: action.payload.status,
                toastShow: NotificationManager.warning(action.payload.message, 'Warning', 3000)
            }
        case 'ALERT_TOAST_INFORMATION':
            return {
                ...state,
                status: action.payload.status,
                toastShow: NotificationManager.info(action.payload.message, 'Information', 3000)
            }
        default:
            return state
    }
}
