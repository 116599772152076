import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { FormFeedback, InputGroup } from 'reactstrap'
// import { get } from 'lodash'
import validationRule from 'helper/ValidationRule';
const get = (obj, path, def) => (() => typeof path === 'string' ? path.replace(/\[(\d+)]/g, '.$1') : path.join('.'))()
  .split('.')
  .filter(Boolean)
  .every(step => ((obj = obj[step]) !== undefined)) ? obj : def

const TextArea = ({
  validation = [],
  validationMessage = [],
  inputGroupAddon = null,
  name,
  defaultValue = '',
  shouldUnregister = false,
  ...props
}) => {
  const { control, getValues } = useFormContext()
  return (
    <Controller
      shouldUnregister={shouldUnregister}
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={validationRule({ rule: validation, validationMessage }, getValues, name)}
      render={({ field: { ...fieldInput }, formState: { errors } }) => (<InputGroup>
        <textarea  {...props} className={`form-control ${props.className ?? ''}`.trim()} {...fieldInput} />
        {inputGroupAddon}
        {get(errors, name) && <FormFeedback invalid="true" style={{ display: 'block' }}>{get(errors, name)?.message}</FormFeedback>}
      </InputGroup>)}
    />
  )
}

export default TextArea