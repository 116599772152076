import React, { useContext, useState } from 'react';
import { Button, Row, Col, Label, Spinner } from 'reactstrap';
import cancel from 'assets/svg/ic_cancel.svg';
import { useAction } from 'hooks';
import { Services } from 'service';
import { useHistory } from 'react-router-dom';
import { balanceContext } from './ModalTopup';

const ContentConfirmTopup = ({ data, step, close }) => {
    const { branchName, stockpointName } = useContext(balanceContext);
    const [checkedAgree, setCheckedAgree] = useState(false);
    const { hasFetch } = useAction()
    // const removeDeciFormat = (number) => parseFloat(number.replace(/[.,\s]/g, ''))
    const history = useHistory()
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const handleSubmit = () => {
        setIsLoadingSubmit(true);
        Services().post('/api/back-office/petty-cash/top-up', {
            "cashSource": data?.cashSource,
            "branchId": branchName?.id,
            "stockpointId": data?.stockpointId,
            "amount": data?.amount
        }).then((_) => {
            hasFetch({
                type: 'ALERT_TOAST_SUCCESS',
                payload: { message: `Top Up Berhasil. Lihat detail di halaman Riwayat Top Up` }
            })
            // refetch()
            setIsLoadingSubmit(false);
            close()
            history.go(0)
        }).catch(e => {
            setIsLoadingSubmit(false);
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Top Up Gagal` } })
        })
    }

    return (
        <>
            <div className="d-flex justify-content-between">
                <div className='font-weight-bold text-16 mb-4'>Konfirmasi Top Up Kas</div>
                <div>
                    <span onClick={() => {
                        step("confirm-close")
                    }} style={{ cursor: "pointer" }}><img src={cancel} alt="pict-cancel" /></span>
                </div>
            </div>
            <div className='text-14'>
                <div className='font-weight-bold mb-2'>Informasi Asal Top Up</div>
                <Row className='mb-4'>
                    <Col md={12}>
                        <Label className='text-muted'>Top Up dari</Label>
                        <div>{data?.cashSource ?? "-"}</div>
                    </Col>
                </Row>
                <div className='font-weight-bold text-14 mb-2'>Informasi Tujuan Top Up</div>
                <Row>
                    <Col md={6}>
                        <Label className='text-muted'>Branch Tujuan</Label>
                        <div>{branchName?.label ?? "-"}</div>
                    </Col>
                    <Col md={6}>
                        <Label className='text-muted'>Stockpoint Tujuan</Label>
                        <div>{stockpointName ?? "-"}</div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col md={12}>
                        <Label className='text-muted'>Jumlah Top Up (Rp)</Label>
                        <div className='text-24 font-weight-bold'><span className='text-16 font-weight-bold'>Rp</span>{" "}{Intl.NumberFormat('id-ID').format(data?.amount) ?? "-"}</div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col md={12}>
                        <label htmlFor="agreement" className="d-flex align-items-center">
                            <input id="agreement" name="agreenent" type="checkbox" checked={checkedAgree} onChange={() => setCheckedAgree(!checkedAgree)} />
                            <div className='ml-2'>
                                Saya setuju untuk melakukan pencatatan Top Up
                            </div>
                        </label>
                    </Col>
                </Row>
            </div>
            <div className='text-right mt-4'>
                <Button type="button" size='sm' color="secondary" onClick={() => step("form")} >
                    Kembali
                </Button>
                <Button disabled={!checkedAgree || isLoadingSubmit} type="button" size='sm' color={!checkedAgree ? "secondary" : "primary"} onClick={() => handleSubmit()}>
                    {isLoadingSubmit &&
                        <span className='mr-2'>
                            <Spinner size="sm" />
                        </span>}
                    Konfirmasi
                </Button>
            </div>
        </>
    )
}

export default ContentConfirmTopup