import ModalPage from 'components/Modal';
import React, { useContext } from 'react';
import { Row } from 'reactstrap';
import icCancel from 'assets/svg/ic_cancel.svg'
import 'assets/css/printable.css';
import { DetailContext } from '../detail';
const DetailImagePreview = () => {
    const { openImage, setopenImage, images } = useContext(DetailContext);

    return (
        <ModalPage
            isOpen={openImage}
            handleClose={() => setopenImage(!openImage)}
            component={<div className='d-flex flex-column' style={{ gap: '2rem' }}>
                <div className='d-flex justify-content-between align-items-center'>
                    <span style={{ fontSize: 16, fontWeight: 500 }}>Lihat Gambar</span>
                    <div className='text-right'>
                        <span onClick={() => setopenImage(!openImage)} style={{ cursor: "pointer" }}>
                            <img src={icCancel} alt="pict-cancel" />
                        </span>
                    </div>
                </div>
                <Row className='justify-content-center align-items-center flex-column'>
                    <img width={360} src={images} alt='pict-lost' />
                </Row>
            </div>
            }
        />
    )
}

export default DetailImagePreview