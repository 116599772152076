import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { Col, Row } from "reactstrap";

import { Breadcrumb } from "components";
import { useFetch } from "hooks";
import Error404 from "page/error/Error404";

import { indexPath } from "./constants";
import ButtonApproval from "./components/ButtonApproval";
import CardSpinner from "./components/CardSpinner";
import { CurrentValue, IncomingChange } from "./components/InfoCards";

const dataBreadcrumb = [
    { to: indexPath, label: "Persetujuan Perubahan" },
    { to: null, label: "Detail" }
]

const App = ({ title: documentTitle, access: permissionAccess }) => {
    document.title = documentTitle;

    const { id } = useParams();

    const fetchDetail = useFetch(
        `/api/back-office/direct-purchase/history/detail/${id}`,
        {},
        useCallback(({ data }) => {
            const {
                currentChgNum,
                currentTotalContainer18,
                currentTotalContainer20,
                currentTotalOilWeight,
                currentPurchasePrice,
                ...incoming
            } = data;
            
            return {
                data: {
                    incoming,
                    current: {
                        chgNum: currentChgNum,
                        totalContainer18: currentTotalContainer18,
                        totalContainer20: currentTotalContainer20,
                        totalOilWeight: currentTotalOilWeight,
                        purchasePrice: currentPurchasePrice
                    }
                }
            }
        }, [])
    );

    const allFetches = [
        fetchDetail
    ];

    const hasError = allFetches.some(({ data, error, status }) => ([
        status === "rejected" && error.code === 400,
        status === "resolved" && data === null
    ].some(Boolean)));

    if (hasError) return <Error404 />;
    
    return (
        <>
            <Row className="row mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Pembelian</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            {fetchDetail.loading
                ? <CardSpinner />
                : (
                    <>
                        <IncomingChange data={fetchDetail.data?.incoming} />
                        <CurrentValue data={fetchDetail.data?.current} />
                        {(permissionAccess?.update && fetchDetail.data?.incoming?.status === "pending") && (
                            <ButtonApproval
                                historyId={fetchDetail.data?.incoming?.id}
                                onSucceed={fetchDetail.fetchData}
                                purchaseDate= {fetchDetail.data?.incoming?.purchaseDate}
                            />
                        )}
                    </>
                )}
        </>
    );
};

export default App;
