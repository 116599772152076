import React, { useContext } from 'react';
import { AddressContext } from '../form';
import { useFormContext } from 'react-hook-form';
import { Button, Modal, ModalBody } from 'reactstrap';
import MapsDraggable from './Maps';
import AsyncSelect from 'react-select/async';
import cancel from 'assets/svg/ic_cancel.svg';
import { useAction } from 'hooks';
import { debounce } from 'helper';
import { Services } from 'service';
import { components } from 'react-select';

const ModalPilihLocation = ({ open, setOpen }) => {
    const { dataAddress, loadingSearch } = useContext(AddressContext);
    const { setValue, trigger } = useFormContext();

    const pilihLocation = () => {
        setValue('location', dataAddress?.address);
        setValue('latitude', dataAddress?.lat);
        setValue('longitude', dataAddress?.lon);
        trigger(['latitude', 'longitude']);
        setOpen(!open);
    };

    return (
        <Modal size='lg' isOpen={open} className="modal-dialog-centered">
            <ModalBody className="confirmation-modal">
                <div className='d-flex justify-content-between'>
                    <div className='pb-3' style={{ fontWeight: 600, fontSize: 16 }}>Pilih Lokasi</div>
                    <div className='text-right'>
                        <span onClick={() => setOpen(!open)} style={{ cursor: "pointer" }}><img src={cancel} alt="pict-cancel" /></span>
                    </div>
                </div>
                <div className='d-flex flex-column' style={{ gap: "1rem" }}>
                    <div className='d-flex flex-column' style={{ gap: 2 }}>
                        <SearchLocation />
                    </div>
                    <div>
                        <MapsDraggable mapsContext={AddressContext} />
                    </div>
                    <div className='d-flex justify-content-end' style={{ gap: "6px" }}>
                        <Button size='md' type="submit" color="primary" onClick={pilihLocation} disabled={loadingSearch || !dataAddress?.address}>
                            Pilih Lokasi
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

const SearchLocation = () => {
    const { dataAddress, setDataAddress, loadingSearch, setLoadingSearch } = useContext(AddressContext);
    const { hasFetch } = useAction();

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <div className='bg-primary justify-content-center align-items-center d-flex rounded' style={{ height: 32, width: 32 }}>
                    <i className="fa fa-search text-white"></i>
                </div>
            </components.DropdownIndicator>
        );
    };

    const loadOptions = debounce(async (inputValue, callback) => {
        setLoadingSearch(true)
        try {
            const raw = await Services().post('/api/open/map/place-autocomplete', {
                location: inputValue,
            })
            const getData = raw?.data?.data?.content;

            if (getData) setLoadingSearch(false)

            callback(
                getData.map(data => ({ label: data?.address, value: { lat: data?.lat, lon: data?.lon }, data: data }))
            )
        } catch (err) {
            setLoadingSearch(false)
            hasFetch({
                type: 'ALERT_TOAST_ERROR',
                payload: { message: err.message }
            })
        }
    }, 1500);

    return (
        <AsyncSelect
            id="location"
            name="location"
            loadOptions={loadOptions}
            validation={['required']}
            placeholder={`Ketik alamat`}
            cacheOptions
            classNamePrefix="select"
            components={{ DropdownIndicator }}
            onChange={(e) => {
                if (e) setDataAddress(e.data)
                else setDataAddress(null)
            }}
            value={dataAddress ? {
                label: dataAddress?.address,
                value: ""
            } : null}
            isLoading={loadingSearch}
        />
    )
};

export default ModalPilihLocation;
