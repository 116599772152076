import React from 'react';
import { useContext } from "react";
import { ModalDetailContext } from "../detail";
import ModalPage from "components/Modal";
import { Button, Col, Label, Row } from "reactstrap";
import Form from 'components-form/Form';
import InputCounter from 'components-form/InputCounter';
import TextInput from 'components-form/textInput';
import { useAction } from 'hooks';
import { Services } from 'service';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const ModalEdit = () => {
    const { data, filters, edited, setedited, fetchData } = useContext(ModalDetailContext);
    const { hasFetch } = useAction()
    const defaultValue = {
        noTiket: data?.tripCode ?? "-",
        driver: data?.driverName ?? "-",
        asalMinyak: data?.originName ?? "-",
        totalMinyak: data?.totalWeight ?? "-",
        jeriken18L: filters(data?.oilContainers, "18", "qty") ?? 0,
        jeriken20L: filters(data?.oilContainers, "20", "qty") ?? 0
    }

    const labelStyle = {
        fontSize: 14, fontWeight: 500, color: "#9C9DA6"
    }

    const { id: paramsId } = useParams()
    const handleSubmited = (input) => {
        const requestBody = {
            "driverId": data?.driverId,
            "totalContainer18": parseFloat(input?.jeriken18L),
            "totalContainer20": parseFloat(input?.jeriken20L)
        }
        const action = ({ requestBody, id = null }) => Services().post(`/api/back-office/pickup-request/stockpoint/edit`, { ...requestBody, id: parseInt(id) })
        action({ requestBody, id: paramsId })
            .then(() => {
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: `QR penjemputan baru berhasil diubah.` }
                })
                setedited(!edited);
                fetchData();
            })
            .catch(e => {
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Cannot update ${paramsId}` } })
            })
    }
    return <ModalPage
        isOpen={edited}
        handleClose={() => setedited(!edited)}
        component={<>
            <div className='moda-password-title font-weight-bold'>Edit</div>
            <Form id="penggunaForm" onSubmit={handleSubmited} autoComplete="off" defaultValues={defaultValue}>
                <div className='font-weight-bold mb-3'>Informasi Umum</div>
                <Row>
                    <Col md={6}>
                        <Label style={labelStyle}>Nomor Tiket</Label>
                        <TextInput name="noTiket" />
                    </Col>
                    <Col md={6}>
                        <Label style={labelStyle}>Driver</Label>
                        <TextInput name="driver" />
                    </Col>
                </Row>
                <Row>
                    <Col className='py-3' md={6}>
                        <Label style={labelStyle}>Asal Minyak</Label>
                        <TextInput name="asalMinyak" />
                    </Col>
                    <Col className='py-3' md={6}>
                        <Label style={labelStyle}>Total Minyak</Label>
                        <div className='font-weight-bold d-flex align-items-center'>
                            <TextInput
                                name="totalMinyak"
                                className="mr-3"
                                style={{ width: "auto", fontSize: 24 }}
                            /> Kg
                        </div>
                    </Col>
                </Row>
                <div className='font-weight-bold mb-3'>Jumlah Minyak</div>
                <Row>
                    <Col md={6}>
                        <Label>Jeriken 18 L</Label>
                        <InputCounter
                            name="jeriken18L"
                            max={filters(data?.oilContainers, "18", "qty")}
                            disabled={data?.oilContainers?.length === 0}
                        />
                    </Col>
                    <Col md={6}>
                        <Label>Jeriken 20 L</Label>
                        <InputCounter
                            name="jeriken20L"
                            max={filters(data?.oilContainers, "20", "qty")}
                            disabled={data?.oilContainers?.length === 0}
                        />
                    </Col>
                </Row>
                <div className='d-flex justify-content-end pt-4'>
                    <Button onClick={() => setedited(!edited)} className="mr-2" size='sm' outline color="primary">Batal</Button>
                    <Button size='sm' color="primary">Simpan</Button>
                </div>
            </Form>
        </>
        }
    />
}


export default ModalEdit;