import Modal from 'components/Modal';
import React from 'react'
import { Button } from 'reactstrap';

const ModalConfirm = ({ open, close, yesAction }) => {
    return (
        <Modal
            isOpen={open}
            handleClose={close}
            component={<>
                <h3>Batalkan Penugasan ?</h3>
                <div className="my-4">
                    Tugas dari rute perjalanan ini akan dibatalkan
                    oleh admin
                </div>
                <div className='text-right mt-4'>
                    <Button onClick={close} size='sm' type="button" color="secondary" >
                        Kembali
                    </Button>
                    <Button type="button" size='sm' color="primary" onClick={yesAction}>
                        Konfirmasi
                    </Button>
                </div>
            </>
            }
        />
    )
}

export default ModalConfirm