import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Input as ReactStrapInput, FormFeedback } from 'reactstrap';
// import { get } from 'lodash';
import validationRule from 'helper/ValidationRule';
const get = (obj, path, def) => (() => typeof path === 'string' ? path.replace(/\[(\d+)]/g, '.$1') : path.join('.'))()
    .split('.')
    .filter(Boolean)
    .every(step => ((obj = obj[step]) !== undefined)) ? obj : def;

const defaultTransform = {
    output: val => val,
    input: val => val,
};

const InputCounter = ({
    validation = [],
    validationMessage = [],
    inputGroupAddon = null,
    name,
    nocounter = false,
    defaultValue = '',
    shouldUnregister = false,
    transform = defaultTransform,
    max,
    errorStyle,
    ...props
}) => {
    const { control, getValues, setValue, trigger } = useFormContext();

    const transformer = { ...defaultTransform, ...transform };
    const handleIncrement = async (name, value) => {
        if (max === undefined) {
            await setValue(name, parseFloat(value) + 1);
            await trigger(name);
        }
        else if (parseFloat(value) < max) {
            await setValue(name, parseFloat(value) + 1);
            await trigger(name);
        };
    };
    const handleDecrement = async (name, value) => {
        if (value !== 0) {
            await setValue(name, parseFloat(value) - 1);
            await trigger(name);
        };
    };

    const handleInputChange = async (e) => {
        const fieldName = e.target.name;
        await trigger(fieldName);
    };

    return (
        <Controller
            shouldUnregister={shouldUnregister}
            control={control}
            name={name}
            defaultValue={defaultValue}
            rules={validationRule({ rule: validation, validationMessage }, getValues, name)}
            render={({
                field: { ref, onChange, value, ...fieldInput },
                formState: { errors } }) => {
                const filterDecimal = (str) => {
                    let filteredStr = str?.toString()?.replace(/[^\d.]/g, ""); // hapus karakter selain angka dan titik
                    let decimalIndex = filteredStr.indexOf("."); // dapatkan indeks tanda desimal pertama

                    if (decimalIndex === 0) { // jika tanda desimal ditemukan di awal
                        filteredStr = filteredStr.slice(1); // hapus tanda desimal di awal
                        decimalIndex = filteredStr.indexOf("."); // dapatkan indeks tanda desimal pertama setelah penghapusan
                    };

                    if (decimalIndex !== -1) { // jika ada tanda desimal
                        // hapus semua tanda desimal setelah indeks pertama
                        let str = "";
                        const regex = /^\d*\.?\d{0,1}$/; // Menggunakan regular expression untuk membatasi input
                        if (regex.test(value)) {
                            str = filteredStr.slice(decimalIndex + 1).replace(/\./g, "").slice(0, 1);
                        };
                        filteredStr = filteredStr.slice(0, decimalIndex + 1) + str;
                    };

                    return filteredStr;
                };

                return (
                    <div>
                        <div className="wrapper-input-counter">
                            {!nocounter && <div className="group-counter">
                                <button
                                    className='button-counter-up'
                                    type='button'
                                    onClick={() => handleIncrement(name, transformer.input(value))}
                                    disabled={props?.disabled || value === ''}
                                >
                                    <i className="fa fa-chevron-up" />
                                </button>
                                <button
                                    className='button-counter-down'
                                    type='button'
                                    onClick={() => handleDecrement(name, transformer.input(value))}
                                    disabled={props?.disabled || value === ''}
                                >
                                    <i className="fa fa-chevron-down" />
                                </button>
                            </div>}
                            <ReactStrapInput
                                {...fieldInput}
                                {...props}
                                invalid={!!get(errors, name)}
                                type="text"
                                autoComplete='off'
                                value={transformer.input(filterDecimal(value))}
                                onChange={e => {
                                    if (max === undefined) {
                                        onChange(transformer.output(e));
                                        handleInputChange(e);
                                    }
                                    else if (e.target.value === '' || parseFloat(e.target.value) <= max) {
                                        onChange(transformer.output(e));
                                        handleInputChange(e);
                                    }
                                }}
                            />
                        </div >
                        {inputGroupAddon}
                        {get(errors, name) && <FormFeedback invalid={"true"} style={{ display: 'block', ...errorStyle }}>{get(errors, name)?.message}</FormFeedback>}
                    </div>
                )
            }}
        />
    );
};

export default InputCounter;
