import React, { useCallback, useEffect, useState } from 'react'
import {
    Card, CardBody, Row, Col,
    CardHeader,
    FormGroup,
    Label,
    Button,
} from 'reactstrap';
import {
    Link,
    useParams
} from 'react-router-dom';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import { useFetch, useAction } from 'hooks';
import Error404 from 'page/error/Error404';
import ModalPage from 'components/Modal';
import { capitalize } from 'helper';

const getColorStatus = (status) => {
    switch (status) {
        case "active":
            return <span color="success" className='text-14 text-success p-1'>
                Aktif
            </span>
        case "inactive":
            return <span color="danger" className='text-14 text-danger p-1'>
                Tidak  Aktif
            </span>
        case "unverified":
            return <span color="warning" className='text-14 text-warning p-1'>
                Belum Verifikasi
            </span>
        default:
            return <span color="black" className='text-14 p-1'>
                {status}
            </span>
    }
}
const App = ({
    title: documentTitle,
    access: permissionAccess,
    history
}) => {
    document.title = documentTitle;
    const [open, setOpen] = useState(false)
    const { id, level } = useParams();
    const { hasFetch } = useAction();
    const [
        // stockPointName, 
        setStockPointName] = useState([]);
    const [
        // branchName,
        setBranchName] = useState([]);
    const dataBreadcrumb = [
        { to: '/salesman', label: 'Master Data Salesperson' },
        { to: null, label: 'Detail Salesperson' }
    ]
    let isLevel;
    if (level === 'salesman') {
        isLevel = 'salesman'
    }
    else if (level === 'sales-supervisor') {
        isLevel = 'salesman/supervisor'
    }
    else if (level === 'sales-manager') {
        isLevel = 'salesman/manager'
    }
    
    const { data,
        status, error } = useFetch(
            `/api/back-office/${isLevel}/${id}`,
            {},
            useCallback(data => ({ data: data?.data }), [])
        )

    useEffect(() => {
        if (data?.unitCodes) {
            const requestBody = { codes: data?.unitCodes }

            Services().post('/api/back-office/stock-points/info', requestBody)
                .then(({ data }) => {
                    setStockPointName(data?.data?.content);
                });
        }
        if (data?.branchCodes) {
            const requestBody = { codes: data?.branchCodes }

            Services().post('/api/back-office/branch/info', requestBody)
                .then(({ data }) => {
                    setBranchName(data?.data?.content);
                });

        }
    });

    /* handle delete user */
    const deleteAction = (data) => {
        let id = data?.id;
        Services().post('/api/back-office/salesman/delete/' + id)
            .then(() => {
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: `Salesman Baru berhasil dihapus` }
                });
                history.push('/salesman');
            })
            .catch(err => {
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: err?.message } });
            });
    }
    if ((status === 'rejected' && error.code === 400) ||
        (status === 'resolved' && data === null)) return <Error404 />
    return status === 'resolved' && (
        <>
            <Row className="row mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Detail Salesperson</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Card>
                <CardHeader className="pb-0">
                    Informasi Pengguna
                </CardHeader>
                <CardBody>
                    <FormGroup row>
                        <Label sm={3}>Status</Label>
                        <Col sm={9}>
                            : {getColorStatus(data?.status) ?? ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>ID Pengguna</Label>
                        <Col sm={9}>
                            : {data?.userCode ?? ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Nama Lengkap</Label>
                        <Col sm={9}>
                            : {data?.name ?? ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Username</Label>
                        <Col sm={9}>
                            : {data?.username ?? ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Jabatan</Label>
                        <Col sm={9}>
                            : {capitalize(data?.level) ?? ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Employee ID</Label>
                        <Col sm={9}>
                            : {data?.employeeId ?? ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>email</Label>
                        <Col sm={9}>
                            : {data?.email ?? ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>phone</Label>
                        <Col sm={9}>
                            : {data?.phone ?? ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Branch</Label>
                        <Col sm={9}>
                            : {data?.branchName ?? ""}
                        </Col>
                    </FormGroup>
                    {data?.level !== "salesman" && <FormGroup row>
                        <Label sm={3}>Jumlah Stockpoint</Label>
                        <Col sm={9}>
                            : {data?.totalStockpoint ?? ""}
                        </Col>
                    </FormGroup>}
                    <FormGroup row>
                        <Label sm={3}>Daftar Stockpoint</Label>
                        <Col sm={9}>
                            : {data?.stockpointName ?? ""}
                        </Col>
                    </FormGroup>
                    {/* <FormGroup row>
                        <Label sm={3}>Password</Label>
                        <Col sm={9}>
                            : {data?.fullname ?? ""}
                        </Col>
                    </FormGroup> */}
                </CardBody>
            </Card>
            <Row className='d-inline-block float-right mt-2 mt-md-0'>
                <Col>
                    <Button style={{ display: "none" }} className="mr-3" outline color="danger" onClick={() =>
                        setOpen(!open)
                    }>
                        {/* <i className='fa fa-trash mr-2'></i>
                        Hapus */}
                        Non-Aktifkan
                    </Button>
                    {permissionAccess?.update && (
                        <Link to={`/salesman/${id}/${data?.level?.replace(" ", "-").toLowerCase()}/edit`}>
                            <Button color="primary">
                                <i className='fa fa-pencil mr-2'></i>
                                Ubah
                            </Button>
                        </Link>
                    )}
                </Col>
            </Row>
            <ModalPage
                isOpen={open}
                handleClose={() => setOpen(!open)}
                component={<>
                    <div className='moda-password-title font-weight-bold'>Hapus Salesman</div>
                    <p style={{ fontSize: "14px" }} className='moda-password-title font-weight-normal m-0'>
                        Salesman akan dihapus dari daftar
                    </p>
                    <div className='d-flex justify-content-end pt-4'>
                        <Button onClick={() => setOpen(!open)} className="mr-3" size='sm' outline color="primary">Batal</Button>
                        <Button onClick={() => deleteAction(data)} className="mr-3" size='sm' color="primary">Lanjutkan</Button>
                    </div>
                </>
                }
            />
        </>
    )
}

export default App