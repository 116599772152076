import React, { useCallback, useState, useEffect } from 'react';
import {
    Card,
    Row,
    Col,
    CardBody,
} from 'reactstrap';
import { Pagination, Table as TableGenerator } from 'components';
import { useFetch } from "hooks";
import { Breadcrumb } from 'components';
import DatePicker from 'react-datepicker';
import { format, addDays } from 'date-fns';
import Form from 'components-form/Form';
import Select from 'components-form/Select';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
// import { debounce } from 'helper';
import { Link } from 'react-router-dom';
import DownloadCsv from 'components/DownloadCsv';
import { Services } from 'service';

const apiClient = Services();
const baseEndpoint = "/api/back-office/petty-cash/mutation";

const App = ({
    title: documentTitle,
    access: permissionAccess
}) => {
    document.title = documentTitle;
    const allowedRangeDay = 31;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const { branchId, branchCode, stockpointId, level } = useSelector(state => state.homesidemenu?.profile);

    const { data, loading: isLoading, totalPage, params, setParams, error } = useFetch(
        baseEndpoint,
        currentParams => ({
            size: 10,
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
            branch: branchId,
            stockpoint: stockpointId,
        }),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        { withQueryParams: false, showAlertOnError: false },
        { status: true, timeout: 3600 * 1000 }
    )

    const TableConfiguration = {
        columns: [
            {
                key: 'referenceNumber',
                title: 'No Referensi Top Up',
                render: (value, row, key) => {
                    return <Link to={`/petty-cash-mutation/${row.id}`}>{value}</Link> ?? '-'
                },
            },
            {
                key: 'transactionDate',
                title: 'Tanggal',
                render: (value, row, key) => {
                    return format(new Date(value), 'dd/MM/yyyy - HH:mm') ?? "-"
                },
            },
            {
                key: 'branchName',
                title: 'Branch',
                render: (value, row, key) => {
                    return value ?? "-"
                },
            },
            {
                key: 'stockpointName',
                title: 'Stockpoint',
                render: (value, row, key) => {
                    return value ?? "-"
                },
            },
            {
                key: 'transactionType',
                title: 'Tipe Mutasi',
                render: (value, row, key) => {
                    return getColorMutationType(value) ?? "-"
                },
            },
            {
                key: 'amount',
                title: 'Jumlah (Rp)',
                render: (value, row, key) => {
                    return getColorAmount(row?.transactionType, value) ?? "-"
                },
            },
            {
                key: 'totalAfter',
                title: 'Saldo Akhir Petty Cash (Rp)',
                render: (value, row, key) => {
                    return value?.toLocaleString('id-ID') ?? "-"
                },
            },
            {
                key: 'transactionBy',
                title: 'Oleh',
                render: (value, row, key) => {
                    return value ?? "-"
                },
            },
        ].filter((column) => {
            if (level === "Stockpoint Officer") {
                return column.key !== "stockpointName" && column.key !== "branchName";
            } else {
                return column;
            }
        })
    }
    const trigger = (id) => {
        setintegratedStockpoint(id)
    }

    const dataBreadcrumb = [
        { to: null, label: 'Mutasi Petty Cash' }
    ]

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const maxDate = () => {
        const currentDate = new Date();
        if (addDays(startDate, allowedRangeDay - 1) > currentDate || (startDate !== null && endDate !== null)) {
            return currentDate;
        }
        return startDate ? addDays(startDate, allowedRangeDay - 1) : new Date();
    };

    const [integratedStocpoint, setintegratedStockpoint] = useState(branchCode ?? null)

    useEffect(() => {
        if (startDate || endDate) {
            setParams(prev => ({
                ...prev,
                page: 1,
                startDate: startDate ? format(new Date(startDate), "dd-MM-yyyy") : null,
                endDate: endDate ? format(new Date(endDate), "dd-MM-yyyy") : null,
            }))
        }
        if (!startDate && !endDate) {
            setParams(prev => ({
                ...prev,
                page: 1,
                startDate: null,
                endDate: null,
            }))
        }
    }, [startDate, endDate, setParams])

    /* set params for searching */
    // const setParamsSearch = useCallback(debounce((name, value) => setParams(prev => ({ ...prev, page: 1, [name]: typeof value === 'string' ? value?.trim() : value })), 600), [])

    /* handle searching for input type */
    // const handleSearchChange = (e) => {
    //     let name = e.target.name;
    //     let value = e.target.value;

    //     setParamsSearch(name, value);
    // }

    const rootStyle = (variable) => {
        return getComputedStyle(document.body).getPropertyValue(variable)
    }

    const theme = (theme) => ({
        ...theme,
        colors: {
            ...theme.colors,
            primary25: rootStyle('--light'),
            primary: rootStyle('--primary'),
        }
    })

    const styles = {
        control: (provided, state) => ({
            ...provided,
            backgroundColor: state.isDisabled ? '#e4e7ea' : provided.backgroundColor,
            border: '1px solid #DBDCDD',
            boxShadow: 'none',
            '&:hover': {
                borderColor: state.selectProps.readonly ? '#DBDCDD' : rootStyle('--primary')
            }
        }),
        valueContainer: (provided) => ({
            ...provided,
            padding: '0.475rem 0.625rem',
            height: 'calc(1.5em + 1.25rem + 5px)'
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: 'none'
        })
    }

    const getColorMutationType = (type) => {
        switch (type) {
            case "top-up":
                return <div className="text-success">Top Up</div>
            case "withdraw":
                return <div className="text-warning">Pencairan</div>
            case "compensation":
                return <div className="text-danger">Ganti Rugi & Setor Kas</div>
            default:
                return type
        }
    }

    const getColorAmount = (type, value) => {

        const isPositive = (num) => {
            if (num < 0) {
                return false
            } else {
                return true
            }
        }

        let number = isPositive(value) ? `+${value.toLocaleString('id-ID')}` : value.toLocaleString('id-ID');

        switch (type) {
            case "top-up":
                return <div className="text-success">{number}</div>
            case "withdraw":
                return <div className="text-warning">{number}</div>
            case "compensation":
                return <div className="text-danger">{number}</div>
            default:
                return number
        }
    }

    const OptionMutationType = [{
        value: "top-up",
        label: "Top Up"
    }, {
        value: "withdraw",
        label: "Pencairan"
    }]

    return (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Petty Cash</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                    <div className="d-inline-block float-right">
                        <DownloadCsv
                            validation={[
                                {
                                    condition: (data?.length > 0) && !isLoading,
                                    message: "Data tidak ditemukan"
                                },
                                {
                                    condition: (params?.startDate?.length > 1) && (params?.endDate?.length > 1),
                                    message: "Kedua filter tanggal wajib diisi"
                                }
                            ]}
                            onClick={({ filterParams }) => ({
                                downloadRequest: apiClient.get(`${baseEndpoint}/export`, filterParams(params), {
                                    responseType: "blob"
                                })
                            })}
                            onBeforeSave={({ timestamp }) => ({
                                filename: `MUTASIPETTYCASH${timestamp}.csv`
                            })}
                        />
                    </div>
                </Col>
            </Row>
            <Card body>
                <Form>
                    <Row>
                        <Col sm={12} lg={level === "Stockpoint Officer" ? 4 : ""} className="mb-1">
                            <DatePicker
                                id="filterDateRange"
                                name="filterDateRange"
                                placeholderText="Pilih Tanggal"
                                onChange={onChange}
                                onChangeRaw={(e) => e.preventDefault()}
                                selected={startDate}
                                startDate={startDate}
                                endDate={endDate}
                                className="form-control"
                                dateFormat={"dd/MM/yyyy"}
                                maxDate={maxDate() || new Date()}
                                selectsRange
                                isClearable
                            />
                        </Col>
                        <Col sm={12} lg={level === "Stockpoint Officer" ? 3 : ""} className="mb-1">
                            <Select
                                name="status"
                                id="statusId"
                                placeholder="Pilih Tipe Mutasi"
                                className="basic-single"
                                options={OptionMutationType}
                                isClearable
                                theme={theme}
                                styles={styles}
                                onChange={(e) => setParams(params => ({
                                    ...params,
                                    page: 1,
                                    type: e?.value
                                }))}
                            />
                        </Col>
                        {level !== "Stockpoint Officer" &&
                            <>
                                <Col sm={12} lg="" className="mb-1">
                                    <BranchField
                                        name="branchId"
                                        setParams={setParams}
                                        triggerID={trigger}
                                    />
                                </Col>
                                <Col sm={12} lg="" className="mb-1">
                                    <StockPoint
                                        name="stockpointId"
                                        code={integratedStocpoint}
                                        setParamsOil={setParams}
                                        stockpointId={stockpointId}
                                    />
                                </Col>
                            </>
                        }
                        {/* <Col sm={12} lg="" className="mb-1">
                            <div className="input-search">
                                <i className="fa fa-search text-muted"></i>
                                <Input className="pl-5" type="text" name="search" id="name" placeholder="Cari Cabang / Stockpoint" onChange={handleSearchChange} />
                            </div>
                        </Col> */}
                    </Row>
                </Form>
            </Card>
            <Card>
                <CardBody className="p-0">
                    <TableGenerator
                        {...TableConfiguration}
                        dataList={data}
                        params={params}
                        loading={isLoading}
                        error={error}
                    />
                </CardBody>
            </Card>
            <Row className="pagination mr-1">
                <Col>
                    <Pagination
                        showRowsPerPage={false}
                        onChangePage={({ page, size }) => setParams(prev => ({ ...prev, page, size }))}
                        totalPage={totalPage}
                        currentPage={params.page}
                        limitPage={params.size}
                        withSelector
                    />
                </Col>
            </Row>
        </>
    )
}

const BranchField = ({ name, triggerID, setParams }) => {
    const { trigger, setValue } = useFormContext();
    const { branchId } = useSelector(state => state.homesidemenu.profile);

    const { data, loading } = useFetch(
        `/api/back-office/branch`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );

    useEffect(() => {
        setValue("branchId", branchId);
    }, [branchId, setValue]);

    return (
        <>
            <Select
                id={name}
                name={name}
                className="basic-single"
                classNamePrefix="select"
                options={data?.map(obj => ({ label: obj?.name, value: obj?.id }))}
                placeholder={`Pilih Branch`}
                isLoading={loading}
                isClearable={true}
                isDisabled={branchId}
                onChange={(ev) => {
                    if (ev) {
                        const { code } = data.filter(item => item.id === ev.value)[0]
                        trigger()
                        triggerID(code)
                        setParams(params => ({
                            ...params,
                            page: 1,
                            branch: ev?.value
                        }))
                    }
                    else {
                        triggerID(null)
                        setValue("stockpointId", "")
                        setParams(params => ({
                            ...params,
                            page: 1,
                            branch: 0
                        }))
                    }
                }}
            />
        </>
    );
};

const StockPoint = ({ name, code, setParamsOil, stockpointId }) => {
    const { watch, setValue } = useFormContext();

    /* fetching stock point list */
    const { data: stockPointList, loading: isLoadingStockPoint, setParams } = useFetch(
        `/api/back-office/stockpoint`,
        currentParams => ({
            unpaged: true,
            branch: code
        }),
        useCallback(data => ({
            data: data?.data?.content,
        }), []),
        { withQueryParams: false, showAlertOnError: false }
    );

    useEffect(() => {
        code && setParams(prev => ({
            ...prev,
            branch: code
        }));
        setValue("stockpointId", watch('stockpointId', stockpointId));
    }, [watch, stockpointId, setValue, code, setParams]);

    return (
        <>
            <Select
                id={name}
                name={name}
                isDisabled={stockpointId || !code}
                className="basic-single"
                classNamePrefix="select"
                options={stockPointList?.map(obj => ({ label: obj?.name, value: obj?.id }))}
                placeholder="Pilih Stock Point"
                isLoading={isLoadingStockPoint}
                isClearable={true}
                onChange={(e) => {
                    e && setParamsOil(prev => ({
                        ...prev,
                        page: 1,
                        stockpoint: e?.value
                    }))
                    !e && setParamsOil(prev => ({
                        ...prev,
                        page: 1,
                        stockpoint: 0
                    }))
                }}
            />
        </>
    );
};

export default App;
