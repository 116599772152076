import React, { useCallback, useEffect, useState } from 'react'
import {
    Card, CardBody, Row, Col,
    CardHeader,
    FormGroup,
    Label,
    Button,
} from 'reactstrap';
import {
    Link,
    useParams
} from 'react-router-dom';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import { useFetch, useAction } from 'hooks';
import Error404 from 'page/error/Error404';
import ModalPage from 'components/Modal';

const getColorStatus = (status) => {
    switch (status) {
        case "active":
            return <span className="text-success">Aktif</span >
        case "inactive":
            return <span className='text-danger'>Tidak Aktif</span>
        case "unverified":
            return <span className='text-warning'>Belum Verifikasi</span>
        default:
            return <span>{status}</span>
    }
}

const getColorStatusAssignment = (status) => {
    switch (status) {
        case "waiting_assignment":
            return <span className="text-info">Tidak Bertugas</span >
        case "on_duty":
            return <span className='text-warning'>Sedang Bertugas</span>
        default:
            return <span>{status}</span>
    }
}

const App = ({
    title: documentTitle,
    access: permissionAccess,
    history
}) => {
    document.title = documentTitle;
    const [open, setOpen] = useState(false)
    const { id } = useParams();
    const { hasFetch } = useAction();
    const [
        // stockPointName, 
        setStockPointName] = useState([]);
    const [
        // branchName,
        setBranchName] = useState([]);
    const dataBreadcrumb = [
        { to: '/driver', label: 'Master Data Driver' },
        { to: null, label: 'Detail Driver' }
    ]

    const { data,
        status, error } = useFetch(
            `/api/back-office/driver/${id}`,
            {},
            useCallback(data => ({ data: data?.data }), [])
        )

    useEffect(() => {
        if (data?.unitCodes) {
            const requestBody = { codes: data?.unitCodes }

            Services().post('/api/back-office/stock-points/info', requestBody)
                .then(({ data }) => {
                    setStockPointName(data?.data?.content);
                });
        }
        if (data?.branchCodes) {
            const requestBody = { codes: data?.branchCodes }

            Services().post('/api/back-office/branch/info', requestBody)
                .then(({ data }) => {
                    setBranchName(data?.data?.content);
                });

        }
    });

    /* handle delete user */
    const deleteAction = (data) => {
        let id = data?.id;
        Services().post('/api/back-office/user/delete/' + id)
            .then(() => {
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: `Pengguna berhasil dihapus` }
                });
                history.push('/user');
            })
            .catch(err => {
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: err?.message } });
            });
    }

    const driverType = data?.type;

    if ((status === 'rejected' && error.code === 400) ||
        (status === 'resolved' && data === null)) return <Error404 />
    return status === 'resolved' && (
        <>
            <Row className="row mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Detail Driver</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Card>
                <CardHeader className="pb-0">
                    Informasi Driver
                </CardHeader>
                <CardBody>
                    <FormGroup row>
                        <Label sm={3}>ID Driver</Label>
                        <Col sm={9}>
                            : {data?.driverCode ?? ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Nama Lengkap</Label>
                        <Col sm={9}>
                            : {data?.name ?? ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Nomor Handphone</Label>
                        <Col sm={9}>
                            : {data?.phone ?? ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Branch</Label>
                        <Col sm={9}>
                            : {data?.branchName ?? "-"}
                        </Col>
                    </FormGroup>
                    {data?.type === "stockpoint" &&
                        <FormGroup row>
                            <Label sm={3}>Stockpoint</Label>
                            <Col sm={9}>
                                : {data?.stockpointName ?? "-"}
                            </Col>
                        </FormGroup>
                    }
                    {data?.type === "warehouse" &&
                        <FormGroup row>
                            <Label sm={3}>Warehouse</Label>
                            <Col sm={9}>
                                : {data?.warehouseName ?? "-"}
                            </Col>
                        </FormGroup>
                    }
                    <FormGroup row>
                        <Label sm={3}>Status</Label>
                        <Col sm={9}>
                            : {getColorStatus(data?.status) ?? ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Status Penugasan</Label>
                        <Col sm={9}>
                            : {getColorStatusAssignment(data?.dutyStatus) ?? ""}
                        </Col>
                    </FormGroup>
                </CardBody>
            </Card>
            <Row className='d-inline-block float-right mt-2 mt-md-0'>
                <Col>
                    <Button className="mr-3 d-none" outline color="danger" onClick={() =>
                        setOpen(!open)
                    }>
                        <i className='fa fa-trash mr-2'></i>
                        Hapus
                    </Button>
                    {permissionAccess?.update && (
                        <Link to={driverType === "stockpoint" ? `/driver/${id}/edit-sp` : `/driver/${id}/edit-wh`}>
                            <Button color="primary">
                                <i className='fa fa-pencil mr-2'></i>
                                Ubah
                            </Button>
                        </Link>
                    )}
                </Col>
            </Row>
            <ModalPage
                isOpen={open}
                handleClose={() => setOpen(!open)}
                component={<>
                    <div className='moda-password-title font-weight-bold'>Profil</div>
                    <p style={{ fontSize: "14px" }} className='moda-password-title font-weight-normal m-0'>
                        Link reset akan dikirim ke email pengguna
                    </p>
                    <div className='d-flex justify-content-end pt-4'>
                        <Button onClick={() => () => setOpen(!open)} className="mr-3" size='sm' outline color="primary">Batal</Button>
                        <Button onClick={() => deleteAction(data)} className="mr-3" size='sm' color="primary">Lanjutkan</Button>
                    </div>
                </>
                }
            />
        </>
    )
}

export default App