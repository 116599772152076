import React, { useEffect } from 'react'
import { useController, useFormContext } from 'react-hook-form'
import { FormFeedback } from 'reactstrap'
// import { get } from 'lodash'
import classnames from 'classnames'
// import { humanFileSize } from 'helper'
const get = (obj, path, def) => (() => typeof path === 'string' ? path.replace(/\[(\d+)]/g, '.$1') : path.join('.'))()
  .split('.')
  .filter(Boolean)
  .every(step => ((obj = obj[step]) !== undefined)) ? obj : def

// const DEFAULT_MAX_FILE_SIZE_IN_BYTES = 3000000;
// const KILO_BYTES_PER_BYTE = 1000;
const DEFAULT_LABEL = "Drag & drop file or Browse";

const FileUploader = ({
  name,
  maxFileSize,
  required = false,
  disabled,
  defaultValue = '',
  label = DEFAULT_LABEL,
  linear = false,
  smallSize = false,
  shouldUnregister = false,
  ...props
}) => {
  const fileState = `${name}.file`
  const inputName = `${name}.value`
  const { formState: { errors }, watch, getValues, setValue, trigger, control, register, unregister } = useFormContext()
  const { field: { onChange, value, ...inputField } } = useController({
    control, name: inputName, shouldUnregister, defaultValue, rules: {
      validate: {
        required: val => {
          if (required && (val === null || val === '' || !Boolean(val))) {
            return 'Perlu diisi'
          }
        },
        maxFileSize: () => {
          const file = getValues(fileState)
          if (Boolean(maxFileSize) && file instanceof File && file?.size > maxFileSize) {
            return `Ukuran file terlalu besar (max 2})`
          }
        }
      }
    }
  })

  useEffect(() => {
    register(fileState)
    return () => { if (shouldUnregister) unregister(fileState) }
  }, [fileState, shouldUnregister, setValue, unregister, register])

  const handleChange = e => {
    onChange(e)
    const file = e.target.files[0]
    setValue(fileState, file)
  }

  const resetFile = () => {
    setValue(inputName, '')
    setValue(fileState, null)
    trigger(inputName)
  }
  const file = watch(fileState)

  return (<>
    <div className="uploader-wrapper">
      <div className={classnames(['uploader-container', linear && "linear", disabled && 'disabled', smallSize && 'small'])}>
        {Boolean(file) ? (
          <div className="uploader-preview">
            {(file && file instanceof File)
              ? file?.type?.split("/")[0] === "image" && (
                <div className="uploader-thumbnail">
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`${file.name} preview`}
                  />
                </div>
              ) : file?.fileContentType !== 'application/pdf' && (
                <div className="uploader-thumbnail">
                  <img
                    src={file.cdnUrl}
                    alt={`${file.fileName} preview`}
                  />
                </div>
              )}
            <div className="d-flex justify-content-between align-items-center mt-2">
              {!smallSize && <span>{file.name ?? file.fileName}</span>}
              {!disabled && <button type="button" style={smallSize ? { position: 'absolute', top: 5, right: 8 } : {}} className="uploader-delete" onClick={resetFile}>
                <i className="fa fa-trash" />
              </button>}
            </div>
          </div>
        ) : (<>
          <div className="uploader-dropzone">
            <div className="uploader-icon" />
            {!smallSize && <div className="uploader-label">{label}</div>}
            <label
              className="uploader-input"
              htmlFor={inputName}
            />
          </div>
          <input
            disabled={disabled}
            type="file"
            {...props}
            {...inputField}
            onChange={handleChange}
            id={inputName}
            style={{ height: 0, width: 0 }}
          />
        </>)}
      </div>
    </div>
    {get(errors, inputName) && <FormFeedback invalid="true" style={{ display: 'block' }}>{get(errors, inputName)?.message}</FormFeedback>}
  </>)
}

export default FileUploader