import React, { useCallback, useEffect, useState } from 'react'
import {
    Card, CardBody, Row, Col,
    CardHeader,
    FormGroup,
    Label,
    Button,
} from 'reactstrap';
import {
    Link,
    useParams
} from 'react-router-dom';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import { useFetch, useAction } from 'hooks';
import Error404 from 'page/error/Error404';
import ModalPage from 'components/Modal';

const getColorStatus = (status) => {
    switch (status) {
        case true:
            return <span className="text-success">Aktif</span >
        case false:
            return <span className='text-danger'>Tidak Aktif</span>
        default:
            return <span>-</span>
    }
}

const App = ({
    title: documentTitle,
    access: permissionAccess,
    history
}) => {
    document.title = documentTitle;
    const [open, setOpen] = useState(false)
    const { id } = useParams();
    const { hasFetch } = useAction();
    const [
        // stockPointName, 
        setStockPointName] = useState([]);
    const [
        // branchName,
        setBranchName] = useState([]);
    const dataBreadcrumb = [
        { to: '/user', label: 'Manajemen Pengguna' },
        { to: null, label: 'Detail Admin' }
    ]

    const { data,
        status, error } = useFetch(
            `/api/back-office/user/${id}`,
            {},
            useCallback(data => ({ data: data?.data }), [])
        )

    useEffect(() => {
        if (data?.unitCodes) {
            const requestBody = { codes: data?.unitCodes }

            Services().post('/api/back-office/stock-points/info', requestBody)
                .then(({ data }) => {
                    setStockPointName(data?.data?.content);
                });
        }
        if (data?.branchCodes) {
            const requestBody = { codes: data?.branchCodes }

            Services().post('/api/back-office/branch/info', requestBody)
                .then(({ data }) => {
                    setBranchName(data?.data?.content);
                });

        }
    });

    /* handle delete user */
    const deleteAction = (data) => {
        let id = data?.id;
        Services().post('/api/back-office/user/delete/' + id)
            .then(() => {
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: `Pengguna berhasil dihapus` }
                });
                history.push('/user');
            })
            .catch(err => {
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: err?.message } });
            });
    }

    if ((status === 'rejected' && error.code === 400) ||
        (status === 'resolved' && data === null)) return <Error404 />
    return status === 'resolved' && (
        <>
            <Row className="row mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Detail Admin</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Card>
                <CardHeader className="pb-0">
                    Informasi Pengguna
                </CardHeader>
                <CardBody>
                    <FormGroup row>
                        <Label sm={3}>ID Pengguna</Label>
                        <Col sm={9}>
                            : {data?.userCode ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Nama Lengkap</Label>
                        <Col sm={9}>
                            : {data?.name ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Username</Label>
                        <Col sm={9}>
                            : {data?.username ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Email</Label>
                        <Col sm={9}>
                            : {data?.email ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Role</Label>
                        <Col sm={9}>
                            : {data?.roleName ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Branch</Label>
                        <Col sm={9}>
                            : {data?.branchName ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>User Level</Label>
                        <Col sm={9}>
                            : {data?.level ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Stockpoint</Label>
                        <Col sm={9}>
                            : {data?.stockpointName ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Cairkan Petty Cash</Label>
                        <Col sm={9}>
                            : {getColorStatus(data?.allowWithdraw) ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Konfigurasi Jeriken</Label>
                        <Col sm={9}>
                            : {getColorStatus(data?.allowEditConfigKg) ?? "-"}
                        </Col>
                    </FormGroup>
                    {/* <FormGroup row>
                        <Label sm={3}>Edit Supplier</Label>
                        <Col sm={9}>
                            : {getColorStatus(data?.allowEditSupplier) ?? "-"}
                        </Col>
                    </FormGroup> */}
                    <FormGroup row>
                        <Label sm={3}>Verifikasi Supplier</Label>
                        <Col sm={9}>
                            : {getColorStatus(data?.allowReviewSD) ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Status</Label>
                        <Col sm={9}>
                            : {getColorStatus(data?.active) ?? "-"}
                        </Col>
                    </FormGroup>
                </CardBody>
            </Card>
            <Row className='d-inline-block float-right mt-2 mt-md-0'>
                <Col>
                    {permissionAccess?.delete && (
                        <Button outline color="danger" onClick={() =>
                            setOpen(!open)
                        }>
                            <i className='fa fa-trash mr-2'></i>
                            Hapus
                        </Button>
                    )}
                    {permissionAccess?.update && (
                        <Link to={`/user/${id}/edit`} className="ml-1">
                            <Button color="primary">
                                <i className='fa fa-pencil mr-2'></i>
                                Ubah
                            </Button>
                        </Link>
                    )}
                </Col>
            </Row>
            <ModalPage
                isOpen={open}
                handleClose={() => setOpen(!open)}
                component={<>
                    <div className='moda-password-title font-weight-bold'>Hapus Admin</div>
                    <p style={{ fontSize: "14px" }} className='moda-password-title font-weight-normal m-0'>
                        Admin akan dihapus permanen dari daftar
                    </p>
                    <div className='d-flex justify-content-end pt-4'>
                        <Button onClick={() => deleteAction(data)} className="mr-3" size='sm' outline color="primary">Hapus</Button>
                        <Button onClick={() => setOpen(!open)} className="mr-3" size='sm' color="primary">Batal</Button>
                    </div>
                </>
                }
            />
        </>
    )
}

export default App