import React, { useCallback, useEffect, useRef, useState } from 'react'
import {
    Card, CardBody, Row, Col,
    CardHeader,
    FormGroup,
    Label,
    Button,
} from 'reactstrap';
import {
    Link,
    useParams
} from 'react-router-dom';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import { useFetch, useAction } from 'hooks';
import Error404 from 'page/error/Error404';
import ModalPage from 'components/Modal';
import { format } from 'date-fns';
import icFill from 'assets/svg/ic_filled.svg'
import { Downloadit } from 'helper';

const time = {
    1: "09.00 - 12.00",
    2: "13.00 - 16.00"
}

const hari = {
    1: "Senin",
    2: "Selasa",
    3: "Rabu",
    4: "Kamis",
    5: "jum'at"
};

const verifiedSelfDeclarationStatus = (callback) => {
    const warning = () => <span className='text-14 text-warning'>Belum Terverifikasi</span>
    const danger = () => <span className='text-14 text-danger'>Ditolak</span>
    const success = () => <span className='text-14 text-success'>Terverifikasi</span>
    const expired = () => <span className='text-14 text-purple'>Kedaluwarsa</span>
    switch (callback()) {
        case 'verified':
            return success();
        case 'unverified':
            return warning();
        case 'expired':
            return expired();
        case 'rejected':
            return danger();
        default:
            return '-'
    }
}

const getColorStatus = (status) => {
    switch (status) {
        case "waiting_pick_up":
            return <span className='text-14 text-warning p-1'>
                Menunggu Jemput
            </span>
        case "canceled":
            return <span className='text-14 text-danger p-1'>
                Dibatalkan
            </span>
        case "completed":
            return <span className='text-14 text-success p-1'>
                Selesai
            </span>
        default:
            return <span className='text-14 text-warning p-1'>
                Dalam Perjalanan
            </span>
    }
}

const App = ({
    title: documentTitle,
    access: permissionAccess,
    history
}) => {
    document.title = documentTitle;
    const [isDownload, setdownload] = useState(false);
    const [open, setOpen] = useState(false)
    const { id } = useParams();
    const { hasFetch } = useAction();
    const refs = useRef(null);
    const [
        // stockPointName, 
        setStockPointName] = useState([]);
    const [
        // branchName,
        setBranchName] = useState([]);
    const dataBreadcrumb = [
        { to: '/pickup-request', label: 'Rute Jemput' },
        { to: null, label: 'Detail Rute Jemput' }
    ]

    const { data,
        status, error } = useFetch(
            `/api/back-office/pickup-request/${id}`,
            {},
            useCallback(data => ({ data: data?.data }), [])
        )
    useEffect(() => {
        if (data?.unitCodes) {
            const requestBody = { codes: data?.unitCodes }

            Services().post('/api/back-office/stock-points/info', requestBody)
                .then(({ data }) => {
                    setStockPointName(data?.data?.content);
                });
        }
        if (data?.branchCodes) {
            const requestBody = { codes: data?.branchCodes }

            Services().post('/api/back-office/branch/info', requestBody)
                .then(({ data }) => {
                    setBranchName(data?.data?.content);
                });

        }
    });

    /* handle delete user */
    const deleteAction = (data) => {
        let id = data?.id;
        Services().post('/api/back-office/user/delete/' + id)
            .then(() => {
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: `Pengguna berhasil dihapus` }
                });
                history.push('/user');
            })
            .catch(err => {
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: err?.message } });
            });
    }
    const [detailPict, setImagePict] = useState(null)
    const getDataKtp = useCallback(() => {
        data?.pict &&
            Services().get(data?.pict, {}, { responseType: "arraybuffer" })
                .then((response) => {
                    const blob = new Blob([response?.data], { type: "image/jpg" });
                    const uris = URL.createObjectURL(blob);
                    setImagePict(uris)
                });
    }, [data])

    useEffect(() => {
        getDataKtp()
    }, [getDataKtp])
    const formatNumber = (number) => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number);

    if ((status === 'rejected' && error.code === 400) ||
        (status === 'resolved' && data === null)) return <Error404 />
    return status === 'resolved' && (
        <>
            <Row className="row mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Rute Perjalanan</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Card>
                <CardHeader className="pb-0">
                    Informasi Permintaan
                </CardHeader>
                <Row>
                    <Col lg={6}>
                        <CardBody>
                            <FormGroup row>
                                <Label sm={5}>Status</Label>
                                <Col sm={7}>
                                    : {getColorStatus(data?.status) ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={5}>Verifikasi Self Declaration</Label>
                                <Col sm={7}>
                                    : {verifiedSelfDeclarationStatus(() => data?.statusSD) ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={5}>No Invoice</Label>
                                <Col sm={7}>
                                    : {data?.invoiceRef ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={5}>Tanggal Permintaan</Label>
                                <Col sm={7}>
                                    : {format(new Date(data?.pickupDate), "dd/MM/yyyy") ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={5}>Nama Supplier</Label>
                                <Col sm={7}>
                                    : {data?.supplierName ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={5}>Outlet</Label>
                                <Col sm={7}>
                                    : {data?.outletName ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={5}>Alamat Outlet</Label>
                                <Col sm={7}>
                                    : {data?.supplierAddress ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={5}>Hari Penjemputan</Label>
                                <Col sm={7}>
                                    : {hari[data?.pickupDay] ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={5}>Jam Penjemputan</Label>
                                <Col sm={7}>
                                    : {time[data?.pickupTime] ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={5}>Harga Jual /Kg</Label>
                                <Col sm={7}>
                                    : {formatNumber(data?.price) ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={5}>Alamat Penjemputan</Label>
                                <Col sm={7}>
                                    : {data?.pickupAddress ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={5}>Jumlah Jeriken 18 L</Label>
                                <Col sm={7}>
                                    : {data?.oilContainers?.[0]?.qty ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={5}>Jumlah Jeriken 20 L</Label>
                                <Col sm={7}>
                                    : {data?.oilContainers?.[1]?.qty ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={5}>Penyesuaian Jeriken 18 L</Label>
                                <Col sm={5} >
                                    : <span style={{ color: "red" }}>{data?.oilContainers?.[2]?.qty ?? "-"}</span>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={5}>Penyesuaian Jeriken 20 L</Label>
                                <Col sm={7}>
                                    : <span style={{ color: "red" }}>{data?.oilContainers?.[3]?.qty ?? "-"}</span>
                                </Col>
                            </FormGroup>
                        </CardBody>
                    </Col>
                    <Col lg="4">
                        <CardBody className='d-flex justify-content-between pb-0'>
                            <FormGroup>
                                <Label style={{ color: "#70727D", fontWeight: 400 }}>Jumlah Minyak</Label>
                                <Col className='p-0'>
                                    <h4>{data?.oilWeight ?? ""} Kg</h4>
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Label style={{ color: "#70727D", fontWeight: 400 }}>Jumlah Penjualan</Label>
                                <Col className='p-0'>
                                    <h4>{formatNumber(data?.totalPrice) ?? ""}</h4>
                                </Col>
                            </FormGroup>
                        </CardBody>
                        <CardBody className='d-flex justify-content-between pt-0'>
                            <FormGroup>
                                <Label style={{ color: "#70727D", fontWeight: 400 }}>Foto</Label>
                                <Col className='p-0' style={{ width: 200, height: 200 }}>
                                    {detailPict ? <img style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: "8px" }} src={detailPict} alt="oil-pic" /> : <span>Loading..</span>}
                                </Col>
                            </FormGroup>
                        </CardBody>
                    </Col>
                </Row>
            </Card>
            {data?.status === 'completed' && <div style={{ display: 'flex', justifyContent: "end" }}>
                <Button disabled={isDownload} onClick={() => {
                    setdownload(true);
                    Services().get(`/api/back-office/pickup-report/invoice/${data?.invoiceRef}`).then((invoiceItem) => {
                        setdownload(true);
                        Services('https://invi-gurihawan-dev.edts.id').get(`/api/invoice`, { ...invoiceItem?.data?.data }).then(async (result) => {
                            setdownload(true);
                            Downloadit({ htmlRaw: result?.data, invoiceItem: invoiceItem }).then(status => {
                                // console.log(status);
                                setdownload(false);
                            }).catch(err => {
                                // console.log(err);
                                setdownload(false);
                            })
                        });
                    })
                }} outline color="info" >
                    {!isDownload && <img className="mr-2" src={icFill} alt="ico-download" />}
                    {isDownload ? 'Waiting Download...' : ' Download Invoice'}
                </Button>
            </div >}
            <div ref={refs}></div>
            <Row className='d-inline-block float-right mt-2 mt-md-0'>
                <Col style={{ display: "none" }} >
                    <Button className="mr-3" outline color="danger" onClick={() =>
                        setOpen(!open)
                    }>
                        <i className='fa fa-trash mr-2'></i>
                        Hapus
                    </Button>
                    <Link to={`/user/${id}/edit`}>
                        <Button color="primary">
                            <i className='fa fa-pencil mr-2'></i>
                            Ubah
                        </Button>
                    </Link>
                </Col>
            </Row>
            <ModalPage
                isOpen={open}
                handleClose={() => setOpen(!open)}
                component={<>
                    <div className='moda-password-title font-weight-bold'>Profil</div>
                    <p style={{ fontSize: "14px" }} className='moda-password-title font-weight-normal m-0'>
                        Link reset akan dikirim ke email pengguna
                    </p>
                    <div className='d-flex justify-content-end pt-4'>
                        <Button onClick={() => () => setOpen(!open)} className="mr-3" size='sm' outline color="primary">Batal</Button>
                        <Button onClick={() => deleteAction(data)} className="mr-3" size='sm' color="primary">Lanjutkan</Button>
                    </div>
                </>
                }
            />
        </>
    )
}

export default App