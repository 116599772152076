import { Breadcrumb } from 'components';
import { showToast } from 'hooks';
import React, { useEffect, useState } from 'react'
import { Col, Row, Spinner } from 'reactstrap';
import { Services } from 'service';

const dataBreadcrumb = [
    { to: null, label: 'Dashboard Petty Cash' }
];

const App = ({ title: documentTitle }) => {
    document.title = documentTitle;
    
    const [dashboardUrl, setDashboardUrl] = useState('');

    useEffect(() => {
        Services()
            .get('/api/back-office/petty-cash/dashboard')
            .then(({ data }) => {
                setDashboardUrl(data?.data?.src);
            })
            .catch((e) => {
                showToast({ name: 'error', message: e?.message ?? 'Error' })
            })
    }, []);

    return (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Dashboard</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className='position-absolute'
                        style={{
                            zIndex: '-10',
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '20px'
                        }}
                    >
                        <Spinner size="lg" />
                    </div>
                    <iframe
                        style={{ border: 'none', width: '100%', height: 'calc(100vh - 150px)' }}
                        allowFullScreen
                        title='Dashboard Petty Cash'
                        src={dashboardUrl}
                    />
                </Col>
            </Row >
        </>
    );
};

export default App;
