import React, { createContext, useCallback, useContext, useState } from 'react'
import {
    Card,
    CardBody,
    Row,
    Col,
    FormGroup,
    Label,
    Button,
} from 'reactstrap';
import {
    useParams
} from 'react-router-dom';
import { Breadcrumb } from 'components';
import { useAction, useFetch } from 'hooks';
import Error404 from 'page/error/Error404';
import ModalPage from 'components/Modal';
import icScan from 'assets/svg/ic_scan.svg';
import IcEdit from 'assets/svg/ic_edit_blue.svg'
import { format } from 'date-fns';
import ModalEdit from './components/modal.edit';
import ModalQR from './components/modal.qr';
import { Services } from 'service';

const getColorStatus = (status) => {
    switch (status) {
        case "waiting_driver":
            return <span className='text-info'>
                Menunggu Driver
            </span>
        case "picking_up_process":
            return <span className='text-warning'>
                Dalam Perjalanan
            </span>
        case "completed":
            return <span className='text-success'>
                Selesai
            </span>
        case "canceled":
            return <span className='text-danger'>
                Dibatalkan
            </span>
        default:
            return status
    }
}

export const ModalDetailContext = createContext();

const App = ({
    title: documentTitle,
    access: permissionAccess,
    history
}) => {
    document.title = documentTitle;
    const [rejected, setrejected] = useState(false);
    const [resolved, setresolved] = useState(false);
    const [showQR, setShowQR] = useState(false)
    const [edited, setedited] = useState(false);
    const { id } = useParams();
    // const { hasFetch } = useAction();
    const dataBreadcrumb = [
        { to: '/pickup-stockpoint', label: 'Buat QR Stockpoint' },
        { to: null, label: 'Detail' }
    ]

    const { data, status, error, fetchData } = useFetch(
        `/api/back-office/pickup-request/stockpoint/${id}`,
        {},
        useCallback(data => ({ data: data?.data }), [])
    )

    const filterDataJeriken = (data, volume, keyName) => {
        const result = data.filter(item => item.type === "jeriken").find(item => item.volume === volume)?.[keyName];
        return result
    }

    if ((status === 'rejected' && error.code === 400) ||
        (status === 'resolved' && data === null)) return <Error404 />
    return status === 'resolved' && (
        <ModalDetailContext.Provider
            value={{
                rejected,
                setrejected,
                resolved,
                setresolved,
                edited,
                setedited,
                filters: filterDataJeriken,
                data,
                fetchData
            }}
        >
            <Row className="row mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>QR Penjemputan Baru</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Card>
                <CardBody>
                    <FormGroup row>
                        <Label sm={3}>No. Tiket</Label>
                        <Col sm={9}>
                            : {data?.tripCode ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Driver</Label>
                        <Col sm={9}>
                            : {data?.driverName ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Tanggal Penjemputan</Label>
                        <Col sm={9}>
                            : {format(new Date(data?.date), "dd/MM/yyyy") ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Asal Minyak</Label>
                        <Col sm={9}>
                            : {data?.originName ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Jumlah Minyak Jeriken 18L</Label>
                        <Col sm={9}>
                            : {filterDataJeriken(data?.oilContainers, "18", "qty") ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Jumlah Minyak Jeriken 20L</Label>
                        <Col sm={9}>
                            : {filterDataJeriken(data?.oilContainers, "20", "qty") ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Jumlah Minyak Dalam Kg</Label>
                        <Col sm={9}>
                            : {data?.totalWeight ? data?.totalWeight + " Kg" : "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Status</Label>
                        <Col sm={9}>
                            : {getColorStatus(data?.status) ?? ""}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Dibuat Oleh</Label>
                        <Col sm={9}>
                            : {data?.createBy ?? "-"}
                        </Col>
                    </FormGroup>

                    {data?.status === "picking_up_process" && (<>
                        <Row className='d-inline-block float-left mt-2 mt-md-0'>
                            <Col>
                                <Button className="mr-3 d-inline-flex align-items-center" size='sm' color="primary" onClick={() => setShowQR(!showQR)}>
                                    <img src={icScan} className='mr-2' alt='icon_scan' height={24} />Tunjukan QR Code
                                </Button>
                                {permissionAccess?.update && (
                                    <Button onClick={(e) => { setedited(!edited); e.target.blur(); }} size='sm' outline color="primary" className='btn-edit desabled-hover d-inline-flex align-items-center'>
                                        <img src={IcEdit} className='mr-2' alt='icon_scan' height={24} />Edit
                                    </Button>
                                )}
                            </Col>
                        </Row>
                        <Row className='d-inline-block float-right mt-2 mt-md-0'>
                            {permissionAccess?.delete && (
                                <Col>
                                    <Button onClick={() => setrejected(!rejected)} size='sm' color="danger">
                                        Batalkan
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </>)}

                </CardBody>
            </Card>
            <Batalkan />
            <ModalEdit />
            <ModalQR open={showQR} close={() => setShowQR(!showQR)} />
        </ModalDetailContext.Provider>
    )
}

export const Batalkan = () => {
    const { rejected, setrejected, fetchData } = useContext(ModalDetailContext);
    const { hasFetch } = useAction();
    const { id } = useParams();
    const batalkan = () => {
        Services().post('/api/back-office/pickup-request/stockpoint/cancel', { id }).then(() => {
            hasFetch({
                type: 'ALERT_TOAST_SUCCESS',
                payload: { message: `QR penjemputan baru berhasil dibatalkan.` }
            })
            fetchData();
            setrejected(!rejected);
        }).catch(e => {
            hasFetch({
                type: 'ALERT_TOAST_ERROR',
                payload: { message: e?.message ?? ` Telah dibatalkan` }
            })
        })
    }

    return (
        <ModalPage
            isOpen={rejected}
            handleClose={() => setrejected(!rejected)}
            component={<>
                <div className='moda-password-title font-weight-bold'>Batalkan?</div>
                <p style={{ fontSize: "14px" }} className='moda-password-title font-weight-normal m-0'>
                    Apakah Anda yakin ingin membatalkan pembuatan QR?
                </p>
                <div className='d-flex justify-content-end pt-4'>
                    <Button onClick={() => setrejected(!rejected)} className="mr-3" size='sm' outline color="primary">Batal</Button>
                    <Button onClick={batalkan} className="mr-3" size='sm' color="primary">Konfirmasi</Button>
                </div>
            </>
            }
        />
    )
}

export default App