import React, { useCallback, useState, useEffect, createContext, useContext } from 'react';
import {
    Card,
    Row,
    Col,
    CardBody,
    ModalBody,
    Modal,
    Label,
    Button,
    Spinner
} from 'reactstrap';
import { Pagination, Table as TableGenerator } from 'components';
import { useAction, useFetch } from "hooks";
import { Breadcrumb } from 'components';
import DatePicker from 'react-datepicker';
import IcOilDrop from 'assets/svg/ic_oil_drop.svg';
import IcInfo from 'assets/svg/ic_twist.svg';
// import IcJerikenBlue from 'assets/svg/ic_jeriken_blue.svg';
import IcJerikenRed from 'assets/svg/ic_jeriken_red.svg';
import IcJerikenGreen from 'assets/svg/ic_jeriken_green.svg';
import { format, addDays } from 'date-fns';
import Form from 'components-form/Form';
import Select from 'components-form/Select';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { TextArea } from 'components-form';
import { Services } from 'service';
import InputCounter from 'components-form/InputCounter';

const typeStatus = (type) => {
    switch (type) {
        case "pickup":
            return <span className='text-success'>Penjemputan</span>
        case "delivery":
            return <span className='text-danger'>Pengantaran</span>
        case "adjustment":
            return <span className='text-warning'>Penyesuaian</span>
        case "sales":
            return <span style={{ color: '#7B61FF' }}>Penjualan</span>
        default:
            return <>-</>
    }
}
const CardOilAmount = ({ primary, icon, title, amount, bgColor }) => {
    return <div
        className={`card-oil-amount ${primary ? 'primary bg-primary' : ""}`}
        style={{ backgroundColor: bgColor }}
    >
        {primary
            && <div className='mr-4'>
                <img src={IcOilDrop} alt='oil' />
            </div>
        }
        <div className='d-flex flex-column align-items-between'>
            <div className='d-flex mb-1 align-items-center'>
                {!primary
                    && <div className='mr-2'>
                        <img src={icon} alt='oil' />
                    </div>
                }
                <div className="font-weight-normal">
                    {title}
                </div>
            </div>
            <div className='amount'>
                {primary
                    ? <h1>{amount ?? "0"}</h1>
                    : <span className='d2 font-weight-bold'>{amount ?? "0"}</span>
                }
            </div>
        </div>
    </div>
}
const ContextTracking = createContext()
const App = ({
    title: documentTitle,
    access: permissionAccess
}) => {
    document.title = documentTitle;
    const allowedRangeDay = 31;
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    // const { getMenus } = useSelector(state => state.homesidemenu)
    const { branchId: isbranch, warehouseId: iswarehouse } = useSelector(state => state.homesidemenu.profile);

    /* fetching total oil */
    const { data: dataOil, setParams: setParamsInventory } = useFetch(
        `/api/back-office/warehouse/inventory`,
        {
            warehouse: iswarehouse
        },
        useCallback(data => ({
            data: data?.data
        }), []),
        { onMount: true },
        { status: true, timeout: 3600 * 1000 }
    );

    const { data, loading: isLoading, totalPage, params, setParams, error } = useFetch(
        `/api/back-office/warehouse/oil-stock-mutation`,
        currentParams => ({
            size: 10,
            sort: '',
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
            warehouse: iswarehouse,
            branch: isbranch
        }),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        { withQueryParams: false, showAlertOnError: false },
        { status: true, timeout: 3600 * 1000 }
    )

    // const getAccessStatusTrip = (subMenuCode) => getMenus?.find((val) => val.code === "trip-plan")?.menus.find((val) => val.code === subMenuCode)?.enabled;

    // const handleLinkTrip = (value, row) => {
    //     if (getAccessStatusTrip("pickup") && row?.type === "pickup") return <Link to={`/pickup/${row?.id}`}>{value}</Link>
    //     if (getAccessStatusTrip("delivery") && row?.type === "delivery") return <Link to={`/delivery-warehouse/${row?.id}`}>{value}</Link>
    //     return <>{value}</>
    // }

    const TableConfiguration = {
        columns: [
            {
                key: 'date',
                title: 'Tanggal',
                render: (value, row, key) => {
                    return <>{format(new Date(value), "dd-MM-yyyy")}</>
                }
            },
            {
                key: 'tripCode',
                title: 'No Tiket',
                render: (value, row, key) => {
                    // return handleLinkTrip(value, row)
                    return <Link to={`${row?.url}/${row?.id}`}>{value}</Link>
                }
            },
            {
                key: 'type',
                title: 'Tipe Transaksi',
                render: (value, row, key) => {
                    return typeStatus(value)
                }
            },
            {
                title: 'Jeriken 18 L',
                children: [{
                    key: 'oilContainerBeans',
                    title: 'Masuk',
                    render: (value, row, key) => {
                        return <>{row?.oilContainerBeans?.find(item => item.volume === "18")?.qty > 0
                            ? getColorAmount(findOilValue(value, "18"))
                            : 0
                        }</>
                    }
                }, {
                    key: 'oilContainerBeans',
                    title: 'Keluar',
                    render: (value, row, key) => {
                        return <>{row?.oilContainerBeans?.find(item => item.volume === "18")?.qty < 0
                            ? getColorAmount(findOilValue(value, "18"))
                            : 0
                        }</>
                    }
                }]
            },
            {
                title: 'Jeriken 20 L',
                children: [{
                    key: 'oilContainerBeans',
                    title: 'Masuk',
                    render: (value, row, key) => {
                        return <>{row?.oilContainerBeans?.find(item => item.volume === "20")?.qty > 0
                            ? getColorAmount(findOilValue(value, "20"))
                            : 0
                        }</>
                    }
                }, {
                    key: 'oilContainerBeans',
                    title: 'Keluar',
                    render: (value, row, key) => {
                        return <>{row?.oilContainerBeans?.find(item => item.volume === "20")?.qty < 0
                            ? getColorAmount(findOilValue(value, "20"))
                            : 0
                        }</>
                    }
                }]
            },
            {
                title: 'Total Kg',
                children: [{
                    key: 'totalWeight',
                    title: 'Masuk',
                    render: (value, row, key) => {
                        return <>{row?.totalWeight > 0
                            ? getColorAmount(value)
                            : 0
                        }</>
                    }
                }, {
                    key: 'totalWeight',
                    title: 'Keluar',
                    render: (value, row, key) => {
                        return <>{row?.totalWeight < 0
                            ? getColorAmount(value)
                            : 0
                        }</>
                    }
                }]
            },
            {
                title: 'Total Stok Jeriken 18 L',
                children: [{
                    key: 'oilContainerBeans',
                    title: 'Awal',
                    render: (value, row, key) => {
                        return <>{getColorAmount(row?.totalPrevContainer18)
                        }</>
                    }
                }, {
                    key: 'oilContainerBeans',
                    title: 'Akhir',
                    render: (value, row, key) => {
                        return <>{getColorAmount(row?.totalAfterContainer18)
                        }</>
                    }
                }]
            },
            {
                title: 'Total Stok Jeriken 20 L',
                children: [{
                    key: 'oilContainerBeans',
                    title: 'Awal',
                    render: (value, row, key) => {
                        return <>{getColorAmount(row?.totalPrevContainer20)
                        }</>
                    }
                }, {
                    key: 'oilContainerBeans',
                    title: 'Akhir',
                    render: (value, row, key) => {
                        return <>{getColorAmount(row?.totalAfterContainer20)

                        }</>
                    }
                }]
            },
            {
                title: 'Total (Kg)',
                children: [{
                    key: 'oilContainerBeans',
                    title: 'Awal',
                    render: (value, row, key) => {
                        return <>{getColorAmount(row?.totalPrevStockWeight)

                        }</>
                    }
                }, {
                    key: 'oilContainerBeans',
                    title: 'Akhir',
                    render: (value, row, key) => {
                        return <>{getColorAmount(row?.totalAfterStockWeight)
                        }</>
                    }
                }]
            },
        ]
    }

    const getColorAmount = (amount) => {
        let getClass = "";
        let isPlus = "";

        if (amount > 0) {
            getClass = "text-success"
            isPlus = "+"
        } else if (amount < 0) {
            getClass = "text-danger"
        } else {
            getClass = ""
            isPlus = ""
        }

        return <div className={getClass}>
            {isPlus}{amount ?? "-"}
        </div>
    }

    const dataBreadcrumb = [
        { to: '#', label: 'Stok Minyak Warehouse' }
    ]

    const findOilValue = (value, volume) => {
        return value?.find((i) => i.volume === volume)?.qty
    }

    const onChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const maxDate = () => {
        const currentDate = new Date();
        if (addDays(startDate, allowedRangeDay - 1) > currentDate || (startDate !== null && endDate !== null)) {
            return currentDate;
        }
        return startDate ? addDays(startDate, allowedRangeDay - 1) : new Date();
    };

    useEffect(() => {
        setParams(prev => ({
            ...prev,
            page: 1,
            startDate: startDate ? format(new Date(startDate), "dd-MM-yyyy") : null,
            endDate: endDate ? format(new Date(endDate), "dd-MM-yyyy") : null
        }))
        setParamsInventory(prev => ({
            ...prev,
            page: 1,
            startDate: startDate ? format(new Date(startDate), "dd-MM-yyyy") : null,
            endDate: endDate ? format(new Date(endDate), "dd-MM-yyyy") : null
        }))
    }, [startDate, endDate, setParams, setParamsInventory])

    const [triggers, settriggers] = useState(null)
    const [open, setopen] = useState(false);
    const [warehouse, setwarehouse] = useState(0);
    return (
        <ContextTracking.Provider
            value={{
                open,
                setopen,
                warehouse,
                setwarehouse,
                setParams,
                triggers,
                settriggers,
                setParamsInventory,
                oil18L: findOilValue(dataOil?.oilContainerBeans, "18"),
                oil20L: findOilValue(dataOil?.oilContainerBeans, "20")
            }}
        >
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Stok Minyak Warehouse</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                    <ButtonOpen />
                    {/* <button onClick={() => setopen(!open)} className="hover-none d-inline-block btn bg-white float-right rounded border border-primary p-2">
                        <img src={IcInfo} alt='create' className='mr-2' height={24} />
                        <span className="text-primary text-12">
                            Penyesuaian Stok Minyak Warehouse
                        </span>
                    </button> */}
                </Col>
            </Row>
            <Card body>
                <Form>
                    <Row className='justify-content-between'>
                        <Col md={9}>
                            <Row>
                                <Col md={4} style={{ display: "none" }} className="mb-1">
                                    <BranchField
                                        name="branchId"
                                    />
                                </Col>
                                <Col md={4} className="mb-1">
                                    <WarehouseField
                                        name="warehouseId"
                                        setParamsOil={setParams}
                                        setParamsInventory={setParamsInventory}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={3}>
                            <DatePicker
                                id="filterDateRange"
                                name="filterDateRange"
                                placeholderText="Pilih Tanggal"
                                onChange={onChange}
                                selected={startDate}
                                startDate={startDate}
                                endDate={endDate}
                                className="form-control"
                                dateFormat={"dd/MM/yyyy"}
                                maxDate={maxDate() || new Date()}
                                selectsRange
                                isClearable
                            />
                        </Col>
                    </Row>
                </Form>

            </Card>
            <Row className='mb-4'>
                <Col md={4} className='mb-3'>
                    <CardOilAmount
                        primary
                        title="Total Minyak"
                        amount={dataOil?.totalWeight ? `${dataOil?.totalWeight?.toLocaleString("de-DE")} Kg` : "0"}
                    />
                </Col>
                {/* <Col md="" className='mb-3'>
                    <CardOilAmount
                        icon={IcJerikenBlue}
                        bgColor={"#EDF6FF"}
                        title="Jeriken 16 L"
                        amount={findOilValue(dataOil?.oilContainerBeans, "16")}
                    />
                </Col> */}
                <Col md="" className='mb-3'>
                    <CardOilAmount
                        icon={IcJerikenRed}
                        bgColor={"#FBEBEA"}
                        title="Jeriken 18 L"
                        amount={dataOil?.oilContainerBeans?.find(item => item.volume === "18")?.qty}
                    />
                </Col>
                <Col md="" className='mb-3'>
                    <CardOilAmount
                        icon={IcJerikenGreen}
                        bgColor={"#F5FFDF"}
                        title="Jeriken 20 L"
                        amount={findOilValue(dataOil?.oilContainerBeans, "20")}
                    />
                </Col>
            </Row>
            <Form>
                <Row className='mb-3'>
                    <Col>
                        <h6>Mutasi Stok Minyak</h6>
                    </Col>
                </Row>
            </Form>
            <Card>
                <CardBody className="p-0">
                    <TableGenerator
                        {...TableConfiguration}
                        dataList={data}
                        params={params}
                        loading={isLoading}
                        error={error}
                    />
                </CardBody>
            </Card>
            <Row className="pagination mr-1">
                <Col>
                    <Pagination
                        showRowsPerPage={false}
                        onChangePage={({ page, size }) => setParams(prev => ({ ...prev, page, size }))}
                        totalPage={totalPage}
                        currentPage={params.page}
                        limitPage={params.size}
                    />
                </Col>
            </Row>
            <ModalTopUp />
        </ContextTracking.Provider>
    )
}

export const ButtonOpen = () => {
    const { setopen, open, warehouse } = useContext(ContextTracking);
    const { warehouseId, branchId } = useSelector(state => state.homesidemenu.profile)
    if (!warehouse && warehouseId === 0 && branchId === 0) return <></>
    return (
        <button onClick={() => setopen(!open)} className="hover-none d-inline-block btn bg-white float-right rounded border border-primary p-2">
            <img src={IcInfo} alt='create' className='mr-2' height={24} />
            <span className="text-primary text-12">
                Penyesuaian Stok Minyak
            </span>
        </button>
    )
}

const BranchField = ({ name }) => {
    const { settriggers } = useContext(ContextTracking);
    const { setValue } = useFormContext();
    const { branch } = useSelector(state => state.homesidemenu)
    const { branchId } = useSelector(state => state.homesidemenu.profile)
    const { data, loading } = useFetch(
        `/api/back-office/branch`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );
    useEffect(() => {
        if (branchId > 0) {
            setValue("branchId", branch[0]?.id)
            settriggers(data?.find(item => item.id === branch[0]?.id)?.code)
        }
    }, [branch, setValue, data, settriggers, branchId])

    return (
        <>
            <Select
                id={name}
                name={name}
                className="basic-single"
                classNamePrefix="select"
                options={data?.map(obj => ({ label: `${obj?.name}`, value: obj?.id, code: obj?.code }))}
                placeholder={`Pilih Branch`}
                isLoading={loading}
                isDisabled={branchId > 0}
                isClearable={true}
                onChange={(ev) => {
                    if (!ev) {
                        setValue("warehouseId", "")
                    }
                }}
            />
        </>
    )
}

const WarehouseField = ({
    name,
    setParamsOil,
    setParamsInventory
}) => {
    const { setwarehouse, triggers } = useContext(ContextTracking);
    const { setValue, watch } = useFormContext();
    const { warehouseId } = useSelector(state => state.homesidemenu.profile)
    const branch = watch('branchId');
    const { data, loading, setParams } = useFetch(
        `/api/back-office/warehouse`,
        currentParams => ({
            unpaged: true,
        }),
        useCallback(data => ({
            data: data?.data?.content,
        }), []),
        { onMount: true }
    )
    useEffect(() => {
        if (branch && triggers) {
            setParams(prev => ({
                ...prev,
                branch: triggers
            }))
            setValue('warehouseId', warehouseId)
            setParamsOil(prev => ({
                ...prev,
                page: 1,
                warehouse: warehouseId
            }))
            setParamsInventory(prev => ({
                ...prev,
                warehouse: warehouseId
            }))
            setwarehouse(warehouseId);
            // etstockpoint(stockpointId[0]?.id)
        }
    }, [branch, setValue, setParams, triggers, warehouseId, setParamsOil, setParamsInventory, setwarehouse])

    return (
        <>
            <Select
                id={name}
                name={name}
                className="basic-single"
                classNamePrefix="select"
                options={data?.map(obj => ({ label: `${obj?.name}`, value: obj?.id }))}
                placeholder={`Pilih Warehouse`}
                isLoading={loading}
                isClearable={true}
                isDisabled={warehouseId > 0}
                onChange={(ev) => {
                    setParamsOil(prev => ({
                        ...prev,
                        warehouse: ev?.value
                    }))
                    setParamsInventory(prev => ({
                        ...prev,
                        warehouse: ev?.value
                    }))
                    setwarehouse(ev?.value)
                }}
            />
        </>
    )
}

const ModalTopUp = () => {
    const { open, setopen, warehouse, setParams, setParamsInventory, oil18L, oil20L } = useContext(ContextTracking);
    const { hasFetch } = useAction();
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const handleSubmit = (values) => {
        setIsLoadingSubmit(true);
        const requestBody = {
            "warehouseId": warehouse,
            "notes": values?.catatan,
            "oilContainers": [
                {
                    "id": 1,
                    "name": "20 liter (18,2 kg)",
                    "volume": "20",
                    "weight": "18.2",
                    "qty": values?.stok20L,
                    "type": "jeriken"
                },
                {
                    "id": 2,
                    "name": "18 liter (16,4 kg)",
                    "volume": "18",
                    "weight": "16.4",
                    "qty": values?.stok18L,
                    "type": "jeriken"
                }
            ]
        }
        Services().post('/api/back-office/warehouse/inventory/adjustment', requestBody)
            .then((result) => {
                setIsLoadingSubmit(false);
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: `Stok Berhasil Disesuaikan` }
                })
                setParams(prev => ({
                    ...prev,
                    warehouse: warehouse
                }))
                setParamsInventory(prev => ({
                    ...prev,
                    warehouse: warehouse
                }))
                setopen(!open)
            }).catch(e => {
                setIsLoadingSubmit(false);
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Stok Gagal Disesuaikan` } })
            });
    };

    const defaultValue = {
        stok18L: oil18L,
        stok20L: oil20L
    }
    return (
        <Modal
            isOpen={open}
            toggle={() => setopen(!open)}
            backdrop="static"
            size={'lg'}
            className="modal-dialog-centered modal-dialog-confirmation"
        >
            <ModalBody>
                <div className='d-flex flex-column' style={{ width: "100%", gap: "0.5rem" }}>
                    <div style={{ fontSize: 16, fontWeight: 500 }}>Penyesuaian Stok Minyak Warehouse</div>
                    <Form onSubmit={handleSubmit} id="form-submited" defaultValues={defaultValue}>
                        <Row className='py-3'>
                            <Col md={6}>
                                <div className='d-flex flex-column'>
                                    <Label style={{ fontSize: 16, fontWeight: "bold" }}>Jeriken 18 L</Label>
                                    <Label>Jumlah Stok</Label>
                                </div>
                                <InputCounter
                                    name="stok18L"
                                    // max={oil18L}
                                    // maxLength={oil18L?.toString()?.length}
                                    validation={['required']}
                                />
                                {/* <InputNumberDeciml
                                    id="stok18L"
                                    name="stok18L"
                                    validation={['required']}
                                    placeholder="stok"
                                /> */}
                            </Col>
                            <Col md={6}>
                                <div className='d-flex flex-column'>
                                    <Label style={{ fontSize: 16, fontWeight: "bold" }}>Jeriken 20 L</Label>
                                    <Label>Jumlah Stok</Label>
                                </div>
                                <InputCounter
                                    name="stok20L"
                                    // max={oil20L}
                                    // maxLength={oil20L?.toString()?.length}
                                    validation={['required']}
                                />
                                {/* <InputNumberDecimal
                                    id="stok20L"
                                    name="stok20L"
                                    validation={['required']}
                                    placeholder="stok"
                                /> */}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div className='d-flex flex-column'>
                                    <Label>Catatan</Label>
                                </div>
                                <TextArea
                                    id="catatan"
                                    columns="5"
                                    name="catatan"
                                    validation={['required']}
                                    placeholder="Masukan Catatan"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }} className='pt-3'>
                                    <Button onClick={() => setopen(!open)} size='sm' type="button" color="secondary" >
                                        Batal
                                    </Button>
                                    <Button size='sm' type="submit" color="primary" disabled={isLoadingSubmit}>
                                        {isLoadingSubmit &&
                                            <span className='mr-2'>
                                                <Spinner size="sm" />
                                            </span>}
                                        Simpan
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </ModalBody>
        </Modal>
    )
}

export default App;
