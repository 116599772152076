import React, { useCallback, useEffect, useState } from 'react'
import {
    Card, CardBody, Row, Col,
    CardHeader,
    FormGroup,
    Label,
    Button
} from 'reactstrap';
import {
    useParams
} from 'react-router-dom';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import { useFetch } from 'hooks';
import Error404 from 'page/error/Error404';
import { format } from 'date-fns';
import icFill from 'assets/svg/ic_filled.svg'
import { Downloadit } from 'helper';

const time = {
    1: "09.00 - 12.00",
    2: "13.00 - 16.00"
}

const hari = {
    1: "Senin",
    2: "Selasa",
    3: "Rabu",
    4: "Kamis",
    5: "jum'at"
};

// const selfDeclarationStatus = (callback) => {
//     const danger = () => <span className='text-danger'>Tidak Ada</span>
//     const normal = () => <span className='text-success'>Ada</span>
//     return !callback() ? danger() : normal();
// }

// const verifiedSelfDeclarationStatus = (callback) => {
//     const danger = () => <span className='text-14 text-danger'>Belum Terverifikasi</span>
//     const success = () => <span className='text-14 text-success'>Terverifikasi</span>
//     return !callback() ? danger() : success();
// }

const App = ({
    title: documentTitle,
    access: permissionAccess,
    history
}) => {
    document.title = documentTitle;
    const [isDownload, setdownload] = useState(false);
    const { id } = useParams();
    const [
        // stockPointName, 
        setStockPointName] = useState([]);
    const [
        // branchName,
        setBranchName] = useState([]);
    const dataBreadcrumb = [
        { to: '/pickup-report', label: 'Laporan Jemput' },
        { to: null, label: 'Detail Laporan' }
    ]

    const { data,
        status, error } = useFetch(
            `/api/back-office/pickup-report/${id}`,
            {},
            useCallback(data => ({ data: data?.data }), [])
        )

    useEffect(() => {
        if (data?.unitCodes) {
            const requestBody = { codes: data?.unitCodes }

            Services().post('/api/back-office/stock-points/info', requestBody)
                .then(({ data }) => {
                    setStockPointName(data?.data?.content);
                });
        }
        if (data?.branchCodes) {
            const requestBody = { codes: data?.branchCodes }

            Services().post('/api/back-office/branch/info', requestBody)
                .then(({ data }) => {
                    setBranchName(data?.data?.content);
                });

        }
    });

    const [detailPict, setImagePict] = useState(null)
    const getImageReport = useCallback(() => {
        data?.pict &&
            Services().get(data?.pict, {}, { responseType: "arraybuffer" })
                .then((response) => {
                    const blob = new Blob([response?.data], { type: "image/jpg" });
                    const uris = URL.createObjectURL(blob);
                    setImagePict(uris)
                });
    }, [data])

    useEffect(() => {
        getImageReport()
    }, [getImageReport])

    const money = (number) => new Intl.NumberFormat('id-ID', { style: "currency", currency: "IDR", minimumFractionDigits: 0 }).format(number);

    if ((status === 'rejected' && error.code === 400) ||
        (status === 'resolved' && data === null)) return <Error404 />
    return status === 'resolved' && (
        <>
            <Row className="row mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Laporan</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Card>
                <CardHeader className="pb-0">
                    Detail Informasi {data?.type === "direct-purchase" ? "Pembelian Langsung" : "Penjemputan"}
                </CardHeader>
                <CardBody>
                    {data?.type !== "direct-purchase" && <Row>
                        <Col md={6}>
                            <FormGroup row>
                                <Label sm={3}>Status</Label>
                                <Col sm={9}>
                                    : <span className='text-success'>{data?.status === "completed" ? "Selesai" : "-"}</span>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>No. Invoice</Label>
                                <Col sm={9}>
                                    : {data?.invoiceId ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Tanggal Permintaan</Label>
                                <Col sm={9}>
                                    : {data?.date ? format(new Date(data?.date), 'dd/MM/yyyy') : "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Nama Supplier</Label>
                                <Col sm={9}>
                                    : {data?.supplierName ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Branch</Label>
                                <Col sm={9}>
                                    : {data?.branchName ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Stockpoint</Label>
                                <Col sm={9}>
                                    : {data?.stockpointName ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Outlet</Label>
                                <Col sm={9}>
                                    : {data?.outletName ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Alamat Outlet</Label>
                                <Col sm={9}>
                                    : {data?.supplierAddress ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Hari Penjemputan</Label>
                                <Col sm={9}>
                                    : {hari[data?.pickupDay] ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Jam Penjemputan</Label>
                                <Col sm={9}>
                                    :  {time[data?.pickupTime] ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Harga Jual/Kg</Label>
                                <Col sm={9}>
                                    : {money(data?.averageOilPrice) ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Alamat Penjemputan</Label>
                                <Col sm={9}>
                                    : {data?.pickupAddress ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Jumlah Jeriken 18 L</Label>
                                <Col sm={9}>
                                    : {data?.totalContainer18 ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Jumlah Jeriken 20 L</Label>
                                <Col sm={9}>
                                    : {data?.totalContainer20 ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Penyesuaian Jeriken 18 L</Label>
                                <Col sm={9}>
                                    : {data?.adjustmentContainer18 ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Penyesuaian Jeriken 20 L</Label>
                                <Col sm={9}>
                                    : {data?.adjustmentContainer20 ?? "-"}
                                </Col>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup row>
                                <Col md={4} className='d-flex' style={{ flexDirection: "column", gap: "2px" }}>
                                    <span>Jumlah Minyak</span>
                                    <span style={{ fontSize: "24px", fontWeight: "500" }}>{money(data?.purchasePrice) ?? "-"}</span>
                                </Col>
                                <Col className='d-flex' style={{ flexDirection: "column", gap: "2px" }}>
                                    <span>Jumlah Penjualan</span>
                                    <span style={{ fontSize: "24px", fontWeight: "500" }}>{data?.totalOilWeight ?? "-"} Kg</span>
                                </Col>
                            </FormGroup>
                            <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
                                <Label>Foto</Label>
                                <Col className='p-0' style={{ width: 200, height: 200 }}>
                                    {detailPict ? <img style={{ maxWidth: "100%", maxHeight: "100%", borderRadius: "8px" }} src={detailPict} alt="oil-pic" /> : <span>Loading..</span>}
                                </Col>
                            </div>
                        </Col>
                    </Row>}

                    {data?.type === "direct-purchase" && <Row>
                        <Col md={6}>
                            <FormGroup row>
                                <Label sm={3}>No. Invoice</Label>
                                <Col sm={9}>
                                    : {data?.invoiceId ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Nama Salesperson</Label>
                                <Col sm={9}>
                                    : {data?.salespersonName ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Tanggal Pembelian</Label>
                                <Col sm={9}>
                                    : {data?.date ? format(new Date(data?.date), 'dd/MM/yyyy') : "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Stockpoint</Label>
                                <Col sm={9}>
                                    : {data?.stockpointName ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Jumlah Jeriken 18 L</Label>
                                <Col sm={9}>
                                    : {data?.totalContainer18 ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Jumlah Jeriken 20 L</Label>
                                <Col sm={9}>
                                    : {data?.totalContainer20 ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Estimasi Jumlah Kg</Label>
                                <Col sm={9}>
                                    : {data?.totalOilWeight ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Harga Rata-rata per Kg</Label>
                                <Col sm={9}>
                                    : {money(data?.averageOilPrice) ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Total Harga pembelian</Label>
                                <Col sm={9}>
                                    : {money(data?.purchasePrice) ?? "-"}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={3}>Dibuat oleh</Label>
                                <Col sm={9}>
                                    : {data?.createdBy ?? "-"}
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>}
                </CardBody>
            </Card>
            <div style={{ display: 'flex', justifyContent: "end" }}>
                <Button onClick={() => {
                    setdownload(true);
                    Services().get(`/api/back-office/pickup-report/invoice/${id}`).then((invoiceItem) => {
                        setdownload(true);
                        Services(process.env.REACT_APP_URL_INVOICE).get(`/api/invoice`, { ...invoiceItem?.data?.data }).then(async (result) => {
                            setdownload(true);
                            Downloadit({ htmlRaw: result?.data, invoiceItem: invoiceItem }).then(status => {
                                setdownload(false);
                            }).catch(err => {
                                console.log(err);
                            })
                        });
                    })
                }} outline color="info" >
                    {!isDownload && <img className="mr-2" src={icFill} alt="ico-download" />}
                    {isDownload ? 'Waiting Download...' : ' Download Invoice'}
                </Button>
            </div>
        </>
    )
}

export default App