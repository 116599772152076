import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { Button, Col, Label, Row, Modal, ModalBody, FormGroup } from 'reactstrap';
import Form from 'components-form/Form';
import { useFormContext } from 'react-hook-form';
import { useFetch } from 'hooks';
import Select from 'components-form/Select';
import Input from 'components-form/Input';
import ContentConfirmTopup from './ContentConfirmTopup';
import ContentConfirmClose from './ContentConfirmClose';
import InputMoneyValidateRequest from 'components-form/InputMoneyValidateRequest';

const InputTopUpSource = () => {
    const { setValue } = useFormContext();

    /* fetching top tup source */
    const { data: dataSource } = useFetch(
        `/api/back-office/petty-cash/top-up/source`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );

    useEffect(() => {
        setValue("cashSource", dataSource?.[0])
        return () => {
            setValue("cashSource", "")
        }
    }, [setValue, dataSource])

    return (
        <Input
            disabled
            name="cashSource"
            type="text"
            placeholder="-"
            autoComplete="off"
            validation={[`required`]}
        />
    )
}

const ButtonTopUp = () => {
    const { watch } = useFormContext();
    const amount = watch('amount')?.toString()?.trim();

    const regex = /^[0.]*([^\d]*[0.]+[^\d]*)*$/;
    const isZero = regex.test(amount);
    const firstCharIsNumber = !isNaN(amount.toString()[0]);

    const isValid = Boolean(amount && !isZero && firstCharIsNumber);

    return (
        <Button disabled={!isValid} size='sm' type="submit" color={!isValid ? "secondary" : "primary"} >
            Top Up
        </Button>
    );
};

const BranchField = ({ name, disabled, branch }) => {
    const { setValue } = useFormContext();
    const { setbranch } = useContext(balanceContext);
    const { data, loading } = useFetch(
        `/api/back-office/branch`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );

    useEffect(() => {
        if (branch) {
            setbranch({ label: `${branch?.name}`, id: branch?.id })
        }
    }, [branch, setbranch])

    if (branch) {
        return <Input
            id={name}
            name={name}
            disabled
            value={`${branch?.name}`}
        />
    }

    return (
        <Select
            id={name}
            name={name}
            isDisabled={disabled}
            className="basic-single"
            classNamePrefix="select"
            options={data?.map(obj => ({ label: `${obj?.name}`, id: obj?.id, value: obj?.code })).sort((a, b) => a.label.localeCompare(b.label))}
            placeholder={`Pilih Branch`}
            isLoading={loading}
            isClearable={true}
            validation={['required']}
            onChange={(ev) => {
                if (ev) {
                    setbranch({ label: ev.label, id: ev.id })
                }
                setValue('stockpointId', '');
            }}
        />
    )
}

const StockpointField = ({ name, disabled, stockpoint }) => {
    const { setamount } = useContext(balanceContext)
    const { setstockpoint } = useContext(balanceContext);
    const { watch } = useFormContext();
    const code = watch("branchId")
    const [fetch, setFetch] = useState(false)
    const { data, loading, setParams } = useFetch(
        `/api/back-office/stockpoint`,
        currentParams => ({
            unpaged: true,
        }),
        useCallback(data => ({
            data: data?.data?.content,
        }), []),
        { onMount: true, withQueryParams: false, showAlertOnError: false }
    );

    const { data: balance, setParams: setParamsBalance } = useFetch(
        `/api/back-office/petty-cash/balance`,
        currentParams => ({
            // unpaged: true,
        }),
        useCallback(data => ({
            data: data?.data,
        }), []),
        { onMount: fetch, withQueryParams: false, showAlertOnError: false }
    );

    useEffect(() => {
        if (code !== undefined && code !== '') {
            setParams((prev) => ({
                ...prev,
                branch: code
            }));
        }
    }, [code, setParams]);

    useEffect(() => {
        if (balance) {
            setamount(balance?.maximumTopUp)
        }
    }, [balance, setamount])

    useEffect(() => {
        if (stockpoint) {
            setstockpoint(`${stockpoint?.name}`)
        }
    }, [stockpoint, setstockpoint])


    if (stockpoint) {
        return <Input
            id={name}
            name={name}
            value={`${stockpoint?.name}`}
            disabled
        />
    }

    return (
        <Select
            id={name}
            name={name}
            className="basic-single"
            classNamePrefix="select"
            options={data?.map(obj => ({ label: `${obj?.name}`, value: obj?.id })).sort((a, b) => a.label.localeCompare(b.label))}
            placeholder={`Pilih Stockpoint`}
            isLoading={loading}
            isClearable={true}
            isDisabled={code === null || code === ""}
            validation={['required']}
            onChange={(ev) => {
                if (ev) {
                    setParamsBalance(prev => ({
                        ...prev,
                        stockpoint: ev?.value
                    }))
                    setstockpoint(ev?.label)
                    setFetch(true)
                }
            }}

        />
    )
}

const InputContext = ({ name }) => {
    const { watch } = useFormContext();
    const { amount } = useContext(balanceContext)
    const maxAmount = amount;
    const getAmount = watch(name)
    const isreplace = getAmount?.toString()?.replaceAll(".", "");
    const number = isreplace === "" ? null : parseFloat(isreplace);

    return (
        <>
            <InputMoneyValidateRequest
                name={name}
                type="text"
                maxLength={10}
                placeholder="0"
                autoComplete="off"
                validation={[`required`]}
            />


            <div style={{
                marginTop: '0.25rem',
                fontSize: "80%",
                color: '#EE2B2E'
            }}>
                {maxAmount > 0 && (number > maxAmount && `Maksimal Top Up Rp ${Intl.NumberFormat('id-ID').format(maxAmount)}`)}
            </div >

        </>
    )
}

const FormTopUp = ({ close, data, setDataSubmit, step, open, branch, stockpoint }) => {

    const defaultValues = {
        branchId: branch?.code ? branch?.code : (data?.branchId || ''),
        stockpointId: stockpoint?.id ? stockpoint?.id : (data?.stockpointId || ''),
        cashSource: data?.cashSource || '',
        amount: data?.amount || ''
    }

    const handleSubmit = (values) => {
        const transformNumber = (str) => {
            if (typeof str === 'string') {
                const removeDot = str.replaceAll(".", "");
                const strToNumber = parseFloat(removeDot);

                return strToNumber
            }
            else return str
        }

        const requestBody = {
            ...values,
            amount: transformNumber(values?.amount)
        }

        setDataSubmit(requestBody)
        step("confirm")
    }

    const closeAction = () => {
        close()
        setDataSubmit(null)
    }

    return (
        <>
            <div className='font-weight-bold text-16 mb-4'>Top Up Kas & Branch </div>
            <Form onSubmit={handleSubmit} defaultValues={defaultValues} className="w-100" id="modalForm" autoComplete="off">
                <div className='font-weight-bold text-14 mb-2'>Informasi Asal Top Up</div>
                <Row className='mb-4'>
                    <Col>
                        <FormGroup>
                            <Label>Top Up Dari</Label>
                            <InputTopUpSource />
                        </FormGroup>
                    </Col>
                </Row>
                <div className='font-weight-bold text-14 mb-2'>Informasi Tujuan Top Up</div>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Branch Tujuan</Label>
                            <BranchField name="branchId" disabled={branch?.code ? true : false} branch={branch} />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Stockpoint Tujuan</Label>
                            <StockpointField name="stockpointId" disabled={branch?.code ? true : false} open={open} stockpoint={stockpoint} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <FormGroup>
                            <Label>Jumlah Top Up (Rp)</Label>
                            <InputContext name="amount" />
                        </FormGroup>
                    </Col>
                </Row>
                <div className='text-right mt-4'>
                    <Button onClick={closeAction} outline size='sm' type="button" color="primary" >
                        Batal
                    </Button>
                    <ButtonTopUp />
                </div>
            </Form>
        </>
    );
}

export const balanceContext = createContext();
const ModalTopUp = ({ open, close, branch, stockpoint, refetch }) => {
    const [showContent, setShowContent] = useState("form")
    const [dataSubmit, setDataSubmit] = useState(null)
    const [branchName, setbranch] = useState(null);
    const [stockpointName, setstockpoint] = useState(null);
    const [amount, setamount] = useState(null);

    return (
        <balanceContext.Provider
            value={{
                amount,
                setamount,
                branchName,
                setbranch,
                stockpointName,
                setstockpoint
            }}
        >
            <Modal
                isOpen={open}
                toggle={close}
                backdrop="static"
                size={showContent !== "form" ? "md" : "lg"}
                className="modal-dialog-centered modal-dialog-confirmation"
            >
                <ModalBody>
                    {showContent === "form" && <FormTopUp
                        close={close}
                        data={dataSubmit}
                        branch={branch}
                        stockpoint={stockpoint}
                        setDataSubmit={(data) => setDataSubmit(data)}
                        step={(e) => setShowContent(e)}
                        open={open}
                    />}
                    {showContent === "confirm" && <ContentConfirmTopup data={dataSubmit} step={(e) => setShowContent(e)} refetch={refetch} close={close} />}
                    {showContent === "confirm-close" && <ContentConfirmClose data={dataSubmit} step={(e) => setShowContent(e)} close={close} />}
                </ModalBody>
            </Modal>
        </balanceContext.Provider>
    )
}


export default ModalTopUp;