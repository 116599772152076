import Loadable from 'helper/Loadable';
import React from 'react';

/*public components*/
const Login = Loadable(React.lazy(() => import('page/login')));

/*private components*/
const Error404 = Loadable(React.lazy(() => import('page/error/Error404')));
const Home = Loadable(React.lazy(() => import('page/home')));

const DashboardPettyCash = Loadable(React.lazy(() => import('page/dashboard-petty-cash')));

const UserList = Loadable(React.lazy(() => import('page/user')));
const UserForm = Loadable(React.lazy(() => import('page/user/form')));
const UserDetail = Loadable(React.lazy(() => import('page/user/detail')));

const RoleList = Loadable(React.lazy(() => import('page/role')));
const RoleForm = Loadable(React.lazy(() => import('page/role/form')));
const RoleDetail = Loadable(React.lazy(() => import('page/role/detail')));

const RequestPickupList = Loadable(React.lazy(() => import('page/list-request-pickup')));
const RequestPickupDetail = Loadable(React.lazy(() => import('page/list-request-pickup/detail')));

const PickupList = Loadable(React.lazy(() => import('page/pickup')));
// const PickupForm = Loadable(React.lazy(() => import('page/pickup/form')));
const PickupDetail = Loadable(React.lazy(() => import('page/pickup/detail')));

const SuplierList = Loadable(React.lazy(() => import('page/suplier')));
const SuplierForm = Loadable(React.lazy(() => import('page/suplier/form')));
const SuplierDetail = Loadable(React.lazy(() => import('page/suplier/detail')));

const SalesmanList = Loadable(React.lazy(() => import('page/salesman')));
const SalesmanForm = Loadable(React.lazy(() => import('page/salesman/form')));
const SalesmanDetail = Loadable(React.lazy(() => import('page/salesman/detail')));

const DriverList = Loadable(React.lazy(() => import('page/driver')));
const DriverFormSp = Loadable(React.lazy(() => import('page/driver/form-sp')));
const DriverFormWh = Loadable(React.lazy(() => import('page/driver/form-wh')));
const DriverDetail = Loadable(React.lazy(() => import('page/driver/detail')));

const StockPointList = Loadable(React.lazy(() => import('page/stock-point')));
const StockPointForm = Loadable(React.lazy(() => import('page/stock-point/form')));
const StockPointDetail = Loadable(React.lazy(() => import('page/stock-point/detail')));

const OilStockList = Loadable(React.lazy(() => import('page/oil-stock')));
const OilStockListDetail = Loadable(React.lazy(() => import('page/oil-stock/detail')));

const OilWarehouseStockList = Loadable(React.lazy(() => import('page/oil-stock-warehouse')));
const OilWarehouseStockListDetail = Loadable(React.lazy(() => import('page/oil-stock-warehouse/detail')));

const Reconciliated = Loadable(React.lazy(() => import('page/rekonsiliasi-kas')));
const DetailReconciliated = Loadable(React.lazy(() => import('page/rekonsiliasi-kas/detail')));

const HargaStandar = Loadable(React.lazy(() => import('page/harga-standar')));
const HargaStandarForm = Loadable(React.lazy(() => import('page/harga-standar/form')));

const DeliveryList = Loadable(React.lazy(() => import('page/delivery')));
const DeliveryDetail = Loadable(React.lazy(() => import('page/delivery/detail')));
const ForgotPassword = Loadable(React.lazy(() => import('page/forgot-password')));

const WarehouseList = Loadable(React.lazy(() => import('page/user.warehouse')));
const WarehouseForm = Loadable(React.lazy(() => import('page/user.warehouse/form')));
const WarehouseDetail = Loadable(React.lazy(() => import('page/user.warehouse/detail')));

const HargaStandarSp = Loadable(React.lazy(() => import('page/harga-standar-stockpoint')));
const HargaStandarSpForm = Loadable(React.lazy(() => import('page/harga-standar-stockpoint/form')));
const HargaStandarSpDetail = Loadable(React.lazy(() => import('page/harga-standar-stockpoint/detail')));

const DeliveryToWarehouse = Loadable(React.lazy(() => import('page/delivery.to.warehouse')));
const DeliveryToWarehouseForm = Loadable(React.lazy(() => import('page/delivery.to.warehouse/form')));
const DeliveryToWarehouseDetail = Loadable(React.lazy(() => import('page/delivery.to.warehouse/detail')));

const HargaStandarBr = Loadable(React.lazy(() => import('page/harga-standar-branch')));
const HargaStandarBrForm = Loadable(React.lazy(() => import('page/harga-standar-branch/form')));
const HargaStandarBrDetail = Loadable(React.lazy(() => import('page/harga-standar-branch/detail')));

const CreateQrWarehouse = Loadable(React.lazy(() => import('page/create.qr.warehouse')));
const CreateQrWarehouseForm = Loadable(React.lazy(() => import('page/create.qr.warehouse/form')));
const CreateQrWarehouseDetail = Loadable(React.lazy(() => import('page/create.qr.warehouse/detail')));

const CreateQrStockpoint = Loadable(React.lazy(() => import('page/create.qr.stockpoint')));
const CreateQrStockpointForm = Loadable(React.lazy(() => import('page/create.qr.stockpoint/form')));
const CreateQrStockpointDetail = Loadable(React.lazy(() => import('page/create.qr.stockpoint/detail')));
const PettyCashIap = Loadable(React.lazy(() => import('page/petty-cash-iap')));
const CashPlanning = Loadable(React.lazy(() => import('page/perencanaan-kas')));
const UploadBulkTopUp = Loadable(React.lazy(() => import('page/perencanaan-kas/form-topup')));

const PettyCashMutation = Loadable(React.lazy(() => import('page/mutasi-petty-cash')));
const PettyCashMutationDetail = Loadable(React.lazy(() => import('page/mutasi-petty-cash/detail')));

const CashMutation = Loadable(React.lazy(() => import('page/mutasi-kas')));
const CashMutationDetail = Loadable(React.lazy(() => import('page/mutasi-kas/detail')));
const KbiOilSales = Loadable(React.lazy(() => import('page/penjualan-minyak-kbi')));
const ReportSummaryStockpoint = Loadable(React.lazy(() => import('page/report-summary-stockpoint')));

const DirectPurchaseList = Loadable(React.lazy(() => import('page/direct.purchase')));
const DirectPurchaseForm = Loadable(React.lazy(() => import('page/direct.purchase/form')));
const DirectPurchaseDetail = Loadable(React.lazy(() => import('page/direct.purchase/detail')));

const ChangeApprovalList = Loadable(React.lazy(() => import('page/change.approval')));
const ChangeApprovalDetail = Loadable(React.lazy(() => import('page/change.approval/detail')));

const InvoiceReporting = Loadable(React.lazy(() => import('page/invoice.reporting')));
const InvoiceDetailReporting = Loadable(React.lazy(() => import('page/invoice.reporting/detail')));

const DeliveryReport = Loadable(React.lazy(() => import('page/mutasi-pengantaran')));
const DeliveryReportDetail = Loadable(React.lazy(() => import('page/mutasi-pengantaran/detail')));

const JerikenConfig = Loadable(React.lazy(() => import('page/jeriken.config')));

const MasterWhList = Loadable(React.lazy(() => import('page/warehouse')));
const MasterWhForm = Loadable(React.lazy(() => import('page/warehouse/form')));
const MasterWhDetail = Loadable(React.lazy(() => import('page/warehouse/detail')));

const GenerateTitle = (name) => `${name} | ${process.env.REACT_APP_NAME}`;

const publicRoute = [
  { exact: true, path: '/login', name: GenerateTitle('Login'), component: Login },
  { exact: true, path: '/set-password', name: GenerateTitle('Forgot Password'), component: ForgotPassword },
]
const privateRoute = [
  { exact: true, path: '/', name: GenerateTitle('Home'), component: Home, access: "home", action: 'read' },

  { exact: true, path: '/dashboard-petty-cash', name: GenerateTitle('Dashboard Petty Cash'), component: DashboardPettyCash, access: "dashboard-petty-cash", action: 'read' },

  { exact: true, path: '/user', name: GenerateTitle('Pengguna'), component: UserList, access: 'user', action: 'read' },
  { exact: true, path: '/user/create', name: GenerateTitle('Pengguna'), component: UserForm, access: 'user', action: 'create' },
  { exact: true, path: '/user/:id', name: GenerateTitle('Pengguna'), component: UserDetail, access: 'user', action: 'read' },
  { exact: true, path: '/user/:id/edit', name: GenerateTitle('Pengguna'), component: UserForm, access: 'user', action: 'update' },

  { exact: true, path: '/roles', name: GenerateTitle('Otorisasi'), component: RoleList, access: 'roles', action: 'read' },
  { exact: true, path: '/roles/create', name: GenerateTitle('Otorisasi'), component: RoleForm, access: 'roles', action: 'create' },
  { exact: true, path: '/roles/:id', name: GenerateTitle('Otorisasi'), component: RoleDetail, access: 'roles', action: 'read' },
  { exact: true, path: '/roles/:id/edit', name: GenerateTitle('Otorisasi'), component: RoleForm, access: 'roles', action: 'update' },

  { exact: true, path: '/pickup-request', name: GenerateTitle('Pickup Request'), component: RequestPickupList, access: "pickup-request", action: 'read' },
  { exact: true, path: '/pickup-request/:id', name: GenerateTitle('Pickup Request'), component: RequestPickupDetail, access: "pickup-request", action: 'read' },

  { exact: true, path: '/pickup', name: GenerateTitle('Pickup'), component: PickupList, access: "pickup", action: 'read' },
  // { exact: true, path: '/pickup/create', name: GenerateTitle('pickup'), component: PickupForm, access: "pickup", action: 'create' },
  { exact: true, path: '/pickup/:id', name: GenerateTitle('Pickup'), component: PickupDetail, access: "pickup", action: 'read' },
  // { exact: true, path: '/pickup/:id/edit', name: GenerateTitle('pickup'), component: PickupForm, access: "pickup", action: 'update' },

  { exact: true, path: '/supplier', name: GenerateTitle('Supplier'), component: SuplierList, access: 'supplier', action: 'read' },
  { exact: true, path: '/supplier/create', name: GenerateTitle('Supplier'), component: SuplierForm, access: 'supplier', action: 'create' },
  { exact: true, path: '/supplier/:id', name: GenerateTitle('Supplier'), component: SuplierDetail, access: 'supplier', action: 'read' },
  { exact: true, path: '/supplier/:id/edit', name: GenerateTitle('Supplier'), component: SuplierForm, access: 'supplier', action: 'update' },

  { exact: true, path: '/stockpoint', name: GenerateTitle('Stockpoint'), component: StockPointList, access: 'stockpoint', action: 'read' },
  { exact: true, path: '/stockpoint/create', name: GenerateTitle('Stockpoint'), component: StockPointForm, access: 'stockpoint', action: 'create' },
  { exact: true, path: '/stockpoint/:id', name: GenerateTitle('Stockpoint'), component: StockPointDetail, access: 'stockpoint', action: 'read' },
  { exact: true, path: '/stockpoint/:id/edit', name: GenerateTitle('Stockpoint'), component: StockPointForm, access: 'stockpoint', action: 'update' },

  { exact: true, path: '/salesman', name: GenerateTitle('Salesperson'), component: SalesmanList, access: 'salesman', action: 'read' },
  { exact: true, path: '/salesman/create', name: GenerateTitle('Salesperson'), component: SalesmanForm, access: 'salesman', action: 'create' },
  { exact: true, path: '/salesman/:id/:level', name: GenerateTitle('Salesperson'), component: SalesmanDetail, access: 'salesman', action: 'read' },
  { exact: true, path: '/salesman/:id/:level/edit', name: GenerateTitle('Salesperson'), component: SalesmanForm, access: 'salesman', action: 'update' },

  { exact: true, path: '/driver', name: GenerateTitle('Driver'), component: DriverList, access: 'driver', action: 'read' },
  { exact: true, path: '/driver/create-sp', name: GenerateTitle('Driver'), component: DriverFormSp, access: 'driver', action: 'create' },
  { exact: true, path: '/driver/create-wh', name: GenerateTitle('Driver'), component: DriverFormWh, access: 'driver', action: 'create' },
  { exact: true, path: '/driver/:id', name: GenerateTitle('Driver'), component: DriverDetail, access: 'driver', action: 'read' },
  { exact: true, path: '/driver/:id/edit-sp', name: GenerateTitle('Driver'), component: DriverFormSp, access: 'driver', action: 'update' },
  { exact: true, path: '/driver/:id/edit-wh', name: GenerateTitle('Driver'), component: DriverFormWh, access: 'driver', action: 'update' },

  { exact: true, path: '/default-price', name: GenerateTitle('Harga Standar'), component: HargaStandar, access: 'default-price', action: 'read' },
  // { exact: true, path: '/default-price/create', name: GenerateTitle('Harga Standar'), component: HargaStandarForm, access: 'default-price', action: 'create' },
  { exact: true, path: '/default-price/:id/edit', name: GenerateTitle('Harga Standar'), component: HargaStandarForm, access: 'default-price', action: 'update' },

  { exact: true, path: '/delivery', name: GenerateTitle('Delivery'), component: DeliveryList, access: 'delivery', action: 'read' },
  { exact: true, path: '/delivery/:id', name: GenerateTitle('Delivery'), component: DeliveryDetail, access: 'delivery', action: 'read' },

  { exact: true, path: '/oil', name: GenerateTitle('Oil Stock'), component: OilStockList, access: 'oil-stock', action: 'read' },
  { exact: true, path: '/oil/:id', name: GenerateTitle('Oil Stock Detail'), component: OilStockListDetail, access: 'oil-stock', action: 'read' },

  { exact: true, path: '/cash-flow', name: GenerateTitle('Cash Flow'), component: Reconciliated, access: "cash-flow", action: 'read' },
  { exact: true, path: '/cash-flow/:id/:date', name: GenerateTitle('Detail Cash Flow'), component: DetailReconciliated, access: "cash-flow", action: 'read' },

  { exact: true, path: '/warehouse-employee', name: GenerateTitle('Warehouse'), component: WarehouseList, access: 'warehouse-employee', action: 'read' },
  { exact: true, path: '/warehouse-employee/create', name: GenerateTitle('Warehouse'), component: WarehouseForm, access: 'warehouse-employee', action: 'create' },
  { exact: true, path: '/warehouse-employee/:id', name: GenerateTitle('Warehouse'), component: WarehouseDetail, access: 'warehouse-employee', action: 'read' },
  { exact: true, path: '/warehouse-employee/:id/edit', name: GenerateTitle('Warehouse'), component: WarehouseForm, access: 'warehouse-employee', action: 'update' },

  { exact: true, path: '/stockpoint-price', name: GenerateTitle('Harga Standar Stockpoint'), component: HargaStandarSp, access: 'stockpoint-price', action: 'read' },
  { exact: true, path: '/stockpoint-price/create', name: GenerateTitle('Harga Standar Stockpoint'), component: HargaStandarSpForm, access: 'stockpoint-price', action: 'create' },
  { exact: true, path: '/stockpoint-price/:id', name: GenerateTitle('Harga Standar Stockpoint'), component: HargaStandarSpDetail, access: 'stockpoint-price', action: 'read' },
  { exact: true, path: '/stockpoint-price/:id/edit/:query', name: GenerateTitle('Harga Standar Stockpoint'), component: HargaStandarSpForm, access: 'stockpoint-price', action: 'update' },

  { exact: true, path: '/branch-price', name: GenerateTitle('Harga Standar Branch'), component: HargaStandarBr, access: 'branch-price', action: 'read' },
  { exact: true, path: '/branch-price/create', name: GenerateTitle('Harga Standar Branch'), component: HargaStandarBrForm, access: 'branch-price', action: 'create' },
  { exact: true, path: '/branch-price/:id', name: GenerateTitle('Harga Standar Branch'), component: HargaStandarBrDetail, access: 'branch-price', action: 'read' },
  { exact: true, path: '/branch-price/:id/edit/:query', name: GenerateTitle('Harga Standar Branch'), component: HargaStandarBrForm, access: 'branch-price', action: 'update' },

  { exact: true, path: '/pickup-warehouse', name: GenerateTitle('Create QR Warehouse'), component: CreateQrWarehouse, access: 'pickup-warehouse', action: 'read' },
  { exact: true, path: '/pickup-warehouse/create', name: GenerateTitle('Create QR Warehouse'), component: CreateQrWarehouseForm, access: 'pickup-warehouse', action: 'create' },
  { exact: true, path: '/pickup-warehouse/:id', name: GenerateTitle('Create QR Warehouse'), component: CreateQrWarehouseDetail, access: 'pickup-warehouse', action: 'read' },

  { exact: true, path: '/pickup-stockpoint', name: GenerateTitle('Create QR Stockpoint'), component: CreateQrStockpoint, access: 'pickup-stockpoint', action: 'read' },
  { exact: true, path: '/pickup-stockpoint/create', name: GenerateTitle('Create QR Stockpoint'), component: CreateQrStockpointForm, access: 'pickup-stockpoint', action: 'create' },
  { exact: true, path: '/pickup-stockpoint/:id', name: GenerateTitle('Create QR Stockpoint'), component: CreateQrStockpointDetail, access: 'pickup-stockpoint', action: 'read' },

  { exact: true, path: '/cash-disburse', name: GenerateTitle('Petty Cash IAP'), component: PettyCashIap, access: 'cash-disburse', action: 'read' },
  { exact: true, path: '/cash-planning', name: GenerateTitle('Cash Planning'), component: CashPlanning, access: 'cash-planning', action: 'read' },
  { exact: true, path: '/cash-planning/topup-bulk', name: GenerateTitle('Cash Planning Create'), component: UploadBulkTopUp, access: 'cash-planning', action: 'create' },

  { exact: true, path: '/petty-cash-mutation', name: GenerateTitle('Petty Cash Mutation'), component: PettyCashMutation, access: 'cash-mutation', action: 'read' },
  { exact: true, path: '/petty-cash-mutation/:id', name: GenerateTitle('Petty Cash Mutation Detail'), component: PettyCashMutationDetail, access: 'cash-mutation', action: 'read' },

  { exact: true, path: '/cash-mutation', name: GenerateTitle('Cash Mutation'), component: CashMutation, access: 'cash-mutation-cash', action: 'read' },
  { exact: true, path: '/cash-mutation/:id', name: GenerateTitle('Cash Mutation Detail'), component: CashMutationDetail, access: 'cash-mutation-cash', action: 'read' },

  { exact: true, path: '/delivery-warehouse', name: GenerateTitle('Antar Ke Warehouse'), component: DeliveryToWarehouse, access: 'delivery', action: 'read' },
  { exact: true, path: '/delivery-warehouse/create', name: GenerateTitle('Antar Ke Warehouse'), component: DeliveryToWarehouseForm, access: 'delivery', action: 'create' },
  { exact: true, path: '/delivery-warehouse/:id', name: GenerateTitle('Antar Ke Warehouse'), component: DeliveryToWarehouseDetail, access: 'delivery', action: 'read' },
  { exact: true, path: '/delivery-warehouse/:id/edit', name: GenerateTitle('Antar Ke Warehouse'), component: DeliveryToWarehouseForm, access: 'delivery', action: 'update' },

  { exact: true, path: '/oil-warehouse', name: GenerateTitle('Oil Stock Warehouse'), component: OilWarehouseStockList, access: 'oil-stock-warehouse', action: 'read' },
  { exact: true, path: '/oil-warehouse/:id', name: GenerateTitle('Oil Stock Warehouse'), component: OilWarehouseStockListDetail, access: 'oil-stock-warehouse', action: 'read' },
  { exact: true, path: '/kbi-oil-sales', name: GenerateTitle('Penjualan Minyak KBI'), component: KbiOilSales, access: 'kbi-oil-sales', action: 'read' },
  { exact: true, path: '/summary-oil', name: GenerateTitle('Laporan Summary Aset Stock Point'), component: ReportSummaryStockpoint, access: 'summary-oil', action: 'read' },

  { exact: true, path: '/direct-purchase', name: GenerateTitle('Pembelian Langsung'), component: DirectPurchaseList, access: 'direct-purchase', action: 'read' },
  { exact: true, path: '/direct-purchase/create', name: GenerateTitle('Pembelian Langsung'), component: DirectPurchaseForm, access: 'direct-purchase', action: 'create' },
  { exact: true, path: '/direct-purchase/:id', name: GenerateTitle('Pembelian Langsung'), component: DirectPurchaseDetail, access: 'direct-purchase', action: 'read' },
  { exact: true, path: '/direct-purchase/:id/edit', name: GenerateTitle('Pembelian Langsung'), component: DirectPurchaseForm, access: 'direct-purchase', action: 'update' },

  { exact: true, path: '/change-approval', name: GenerateTitle('Persetujuan Perubahan'), component: ChangeApprovalList, access: 'change-approval', action: 'read' },
  { exact: true, path: '/change-approval/:id', name: GenerateTitle('Persetujuan Perubahan'), component: ChangeApprovalDetail, access: 'change-approval', action: 'read' },

  { exact: true, path: '/pickup-report', name: GenerateTitle('Laporan Invoice'), component: InvoiceReporting, access: 'pickup-report', action: 'read' },
  { exact: true, path: '/pickup-report/:id', name: GenerateTitle('Laporan Invoice '), component: InvoiceDetailReporting, access: 'pickup-report', action: 'read' },

  { exact: true, path: '/delivery-report', name: GenerateTitle('Mutasi Pengantaran'), component: DeliveryReport, access: 'delivery-report', action: 'read' },
  { exact: true, path: '/delivery-report/:id', name: GenerateTitle('Mutasi Pengantaran '), component: DeliveryReportDetail, access: 'delivery-report', action: 'read' },

  { exact: true, path: '/config-jeriken', name: GenerateTitle('Jeriken Konfigurasi'), component: JerikenConfig, access: 'config-jeriken', action: 'read' },

  { exact: true, path: '/warehouse', name: GenerateTitle('Warehouse'), component: MasterWhList, access: 'warehouse', action: 'read' },
  { exact: true, path: '/warehouse/create', name: GenerateTitle('Warehouse'), component: MasterWhForm, access: 'warehouse', action: 'create' },
  { exact: true, path: '/warehouse/:id', name: GenerateTitle('Warehouse'), component: MasterWhDetail, access: 'warehouse', action: 'read' },
  { exact: true, path: '/warehouse/:id/edit', name: GenerateTitle('Warehouse'), component: MasterWhForm, access: 'warehouse', action: 'update' },

  // { exact: true, path: '/logout', name: GenerateTitle('Logout'), component: Logout },
  { exact: true, path: '*', name: GenerateTitle('Page Not Found'), component: Error404 },
]

const routes = { 'public': publicRoute, 'private': privateRoute }

export default routes
