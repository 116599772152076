import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";

import IcCancelRed from "assets/svg/ic_cancel_red.svg";
import IcCancel from "assets/svg/ic_cancel_white.svg";
import IcCheck from "assets/svg/ic_check.svg";

import ModalConfirmApproval, { useConfirmApproval } from "./ModalConfirmApproval";

const ButtonApproval = ({ historyId, onSucceed, purchaseDate }) => {
    const modal = useConfirmApproval();

    return (
        <>
            <ModalConfirmApproval config={modal.config} onSucceed={onSucceed} />

            <Row className="pb-1">
                <Col className="d-flex flex-row justify-content-end">
                    <IconButton
                        outline
                        color="danger"
                        label="Tolak"
                        iconSrc={IcCancelRed}
                        iconOnHoverSrc={IcCancel}
                        iconSize="12px"
                        onClick={() => modal.confirm("reject", { historyId })}
                    />
                    <IconButton
                        color="primary"
                        label="Setujui"
                        iconSrc={IcCheck}
                        iconSize="20px"
                        style={{ paddingLeft: "10px", paddingRight: "14px", gap: "4px" }}
                        onClick={() => modal.confirm("accept", { historyId, purchaseDate })}
                    />
                </Col>
            </Row>
        </>
    );
};

const IconButton = ({
    iconSrc,
    iconOnHoverSrc,
    iconSize: height,
    label,
    style = {},
    ...props
}) => {
    const [src, setSrc] = useState(iconSrc);

    return (
        <Button
            color="primary"
            className="py-2 d-inline-flex align-items-center"
            onMouseEnter={() => iconOnHoverSrc ? setSrc(iconOnHoverSrc) : undefined}
            onMouseLeave={() => iconOnHoverSrc ? setSrc(iconSrc) : undefined}
            style={{ gap: "8px", ...style }}
            {...props}
        >
            <img src={src} alt={label} style={{ height }} />
            <span>{label}</span>
        </Button>
    );
}

export default ButtonApproval