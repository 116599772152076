import React, { useState } from 'react'
import { Button, Col, FormGroup, Label, Row, Spinner } from 'reactstrap';
import Form from 'components-form/Form';
import Input from 'components-form/Input';
import ModalPage from 'components/Modal';
import { Services } from 'service';
import { showToast, useAction } from 'hooks';
const ModalForgotPassword = ({ open, setopen }) => {
    const [loading, setloading] = useState(false)
    const { hasFetch } = useAction()

    return (
        <ModalPage
            isOpen={open}
            // handleClose={() => setopen(!open)}
            component={<>
                <div className='moda-password-title font-weight-bold'>Lupa Password</div>
                <p className='font-weight-normal'>
                    Masukan email terdaftar untuk mendapatkan link reset password
                </p>
                <Form onSubmit={async (data) => {

                    const { email } = data
                    setloading(true)
                    const requestBody = {
                        "email": email
                    }
                    try {
                        const action = ({ requestBody }) => Services().post('/api/back-office/user/forgot-password', requestBody)
                        await action({ requestBody })
                        setloading(false)
                        hasFetch({
                            type: 'ALERT_TOAST_SUCCESS',
                            payload: `Reset password telah berhasil dikirim ke ${email}`,
                        })
                    }
                    catch (err) {
                        setloading(false)
                        showToast({
                            name: "error",
                            message: err?.message
                        })
                    }
                }}>
                    <Row>
                        <Col lg={12}>
                            <FormGroup >
                                <Label className='p-0 mb-2' htmlFor="Email" sm={12}>Email</Label>
                                <Row>
                                    <Col sm={12}>
                                        <Input id="email" validation={['required', 'email']} name="email" type="text" placeholder="Masukan Email" autoComplete="off" />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className='text-right mt-4'>
                        <Button disabled={loading} className='font-weight-bold' outline onClick={() => setopen(!open)} size='sm' type="button" color="primary" >
                            Batal
                        </Button>
                        <Button disabled={loading} size='sm' type="submit" color="primary" >
                            {loading ? <span className='px-2'><Spinner size="sm" color="secondary" /> </span> : 'Kirim'}
                        </Button>
                    </div>
                </Form>
            </>}
        />
    )
}

export default ModalForgotPassword