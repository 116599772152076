import React, { useCallback, useState } from 'react';
import { Card, Row, Col, CardBody } from 'reactstrap';
import { Pagination, Table as TableGenerator } from 'components';
import { useFetch } from "hooks";
import { Breadcrumb } from 'components';
import DatePicker from 'react-datepicker';
import IcOilDrop from 'assets/svg/ic_oil_drop.svg';
import IcJerikenRed from 'assets/svg/ic_jeriken_red.svg';
import IcJerikenGreen from 'assets/svg/ic_jeriken_green.svg';
import { format, addDays } from 'date-fns';
import Form from 'components-form/Form';
import Select from 'components-form/Select';
import { useSelector } from 'react-redux';
import { parseValue } from 'helper';

const CardOilAmount = ({ primary, icon, title, amount, bgColor }) => {
    return <div
        className={`card-oil-amount ${primary ? 'primary bg-primary' : ""}`}
        style={{ backgroundColor: bgColor }}
    >
        {primary
            && <div className='mr-4'>
                <img src={IcOilDrop} alt='oil' />
            </div>
        }
        <div className='d-flex flex-column align-items-between'>
            <div className='d-flex mb-1 align-items-center'>
                {!primary
                    && <div className='mr-2'>
                        <img src={icon} alt='oil' />
                    </div>
                }
                <div className="font-weight-normal">
                    {title}
                </div>
            </div>
            <div className='amount'>
                {primary
                    ? <h1>{amount ?? "0"}</h1>
                    : <span className='d2 font-weight-bold'>{amount ?? "0"}</span>
                }
            </div>
        </div>
    </div>
};

const getColorAmount = (amount) => {
    let getClass = "";
    let isPlus = "";

    if (amount > 0) {
        getClass = "text-success"
        isPlus = "+"
    } else if (amount < 0) {
        getClass = "text-danger"
    } else {
        getClass = ""
        isPlus = ""
    }

    return <div className={getClass}>
        {isPlus}{amount ?? "-"}
    </div>;
};

const findOilValue = (value, volume) => {
    return value?.find((i) => i.volume === volume)?.qty;
};

const dataBreadcrumb = [
    { to: '#', label: 'Laporan Summary Aset Stock Point' }
];


const App = ({
    title: documentTitle
}) => {
    document.title = documentTitle;
    const today = new Date().setHours(0, 0, 0, 0);
    const allowedRangeDay = 31;
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const { branchId, stockpointId } = useSelector(state => state.homesidemenu.profile);


    /* fetch total oil */
    const { data: dataOil, setParams: setParamsInventory } = useFetch(
        `/api/back-office/stockpoint/inventory/summary`,
        {
            stockpoint: stockpointId,
            branch: branchId,
            startDate: format(new Date(startDate), "dd-MM-yyyy"),
            endDate: format(new Date(endDate), "dd-MM-yyyy")
        },
        useCallback(data => ({
            data: data?.data
        }), []),
        { onMount: true },
        { status: true, timeout: 3600 * 1000 }
    );

    /* fetch mutasi oil*/
    const { data, loading: isLoading, totalPage, params, setParams: setParamsMutation, error } = useFetch(
        `/api/back-office/stockpoint/oil-stock-mutation/summary`,
        currentParams => ({
            sort: 'totalAfterStockWeight,desc',
            size: 10,
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
            stockpoint: stockpointId,
            branch: branchId,
            startDate: format(new Date(startDate), "dd-MM-yyyy"),
            endDate: format(new Date(endDate), "dd-MM-yyyy")
        }),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), []),
        { onMount: true, withQueryParams: false, showAlertOnError: false },
        { status: true, timeout: 3600 * 1000 }
    );

    const handleChangeDate = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        if (start && end) {
            setParamsInventory(prev => ({
                ...prev,
                startDate: start ? format(new Date(start), "dd-MM-yyyy") : null,
                endDate: end ? format(new Date(end), "dd-MM-yyyy") : null
            }));

            setParamsMutation(prev => ({
                ...prev,
                page: 1,
                startDate: start ? format(new Date(start), "dd-MM-yyyy") : null,
                endDate: end ? format(new Date(end), "dd-MM-yyyy") : null
            }));
        }
    };

    const handleChangeBranch = (branch) => {
        setParamsInventory(prev => ({
            ...prev,
            branch: branch?.value
        }));

        setParamsMutation(prev => ({
            ...prev,
            page: 1,
            branch: branch?.value
        }));
    };

    const maxDate = () => {
        const currentDate = new Date();
        if (addDays(startDate, allowedRangeDay - 1) > currentDate || (startDate !== null && endDate !== null)) {
            return currentDate;
        };
        return startDate ? addDays(startDate, allowedRangeDay - 1) : new Date();
    };

    const TableConfiguration = {
        columns: [
            {
                key: 'stockpointName',
                title: 'Stockpoint',
                stylesHeader: { minWidth: data?.length > 0 ? '250px' : '' },
                render: (value) => {
                    return parseValue(value)
                }
            },
            {
                title: 'Jeriken 18 L',
                children: [{
                    key: 'totalContainer18lIn',
                    title: 'Masuk',
                    render: (value) => value > 0
                        ? getColorAmount(value)
                        : 0
                }, {
                    key: 'totalContainer18lOut',
                    title: 'Keluar',
                    render: (value) => value < 0
                        ? getColorAmount(value)
                        : 0
                }]
            },
            {
                title: 'Jeriken 20 L',
                children: [{
                    key: 'totalContainer20lIn',
                    title: 'Masuk',
                    render: (value) => value > 0
                        ? getColorAmount(value)
                        : 0
                }, {
                    key: 'totalContainer20lOut',
                    title: 'Keluar',
                    render: (value) => value < 0
                        ? getColorAmount(value)
                        : 0
                }]
            },
            {
                title: 'Total Kg',
                children: [{
                    key: 'totalWeightIn',
                    title: 'Masuk',
                    render: (value) => {
                        return value > 0 ? getColorAmount(value) : 0
                    }
                }, {
                    key: 'totalWeightOut',
                    title: 'Keluar',
                    render: (value) => {
                        return value < 0 ? getColorAmount(value) : 0
                    }
                }]
            },
            {
                title: 'Total Stok Jeriken 18 L',
                children: [{
                    key: 'totalPrevContainer18',
                    title: 'Awal',
                    render: (value) => getColorAmount(value)
                }, {
                    key: 'totalAfterContainer18',
                    title: 'Akhir',
                    render: (value) => getColorAmount(value)
                }]
            },
            {
                title: 'Total Stok Jeriken 20 L',
                children: [{
                    key: 'totalPrevContainer20',
                    title: 'Awal',
                    render: (value) => getColorAmount(value)
                }, {
                    key: 'totalAfterContainer20',
                    title: 'Akhir',
                    render: (value) => getColorAmount(value)
                }]
            },
            {
                title: 'Total (Kg)',
                children: [{
                    key: 'totalPrevStockWeight',
                    title: 'Awal',
                    render: (value) => getColorAmount(value)
                }, {
                    key: 'totalAfterStockWeight',
                    title: 'Akhir',
                    onSort: (sort) => setParamsMutation((prev) => ({ ...prev, sort })),
                    render: (value) => getColorAmount(value)
                }]
            }
        ]
    };

    return (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Manajemen Aset</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Card body>
                <Form>
                    <Row className='justify-content-between'>
                        <Col md={9}>
                            <Row>
                                <Col md={4} className="mb-1">
                                    <BranchField
                                        name="branchId"
                                        handleChangeBranch={handleChangeBranch}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col md={3}>
                            <DatePicker
                                id="filterDateRange"
                                name="filterDateRange"
                                placeholderText="Pilih Tanggal"
                                onChange={handleChangeDate}
                                selected={startDate}
                                startDate={startDate}
                                endDate={endDate}
                                className="form-control"
                                dateFormat={"dd/MM/yyyy"}
                                maxDate={maxDate() || new Date()}
                                selectsRange
                            />
                        </Col>
                    </Row>
                </Form>

            </Card>
            <Row className='mb-4'>
                <Col md={4} className='mb-3'>
                    <CardOilAmount
                        primary
                        title="Total Minyak"
                        amount={dataOil?.totalWeight ? `${dataOil?.totalWeight?.toLocaleString("de-DE")} Kg` : "0"}
                    />
                </Col>
                <Col md="" className='mb-3'>
                    <CardOilAmount
                        icon={IcJerikenRed}
                        bgColor={"#FBEBEA"}
                        title="Jeriken 18 L"
                        amount={findOilValue(dataOil?.oilContainerBeans, "18")}
                    />
                </Col>
                <Col md="" className='mb-3'>
                    <CardOilAmount
                        icon={IcJerikenGreen}
                        bgColor={"#F5FFDF"}
                        title="Jeriken 20 L"
                        amount={findOilValue(dataOil?.oilContainerBeans, "20")}
                    />
                </Col>
            </Row>
            <Card>
                <CardBody className="p-0">
                    <TableGenerator
                        {...TableConfiguration}
                        dataList={data}
                        params={params}
                        loading={isLoading}
                        error={error}
                    />
                </CardBody>
            </Card>
            <Row className="pagination mr-1">
                <Col>
                    <Pagination
                        showRowsPerPage={false}
                        onChangePage={({ page, size }) => setParamsMutation(prev => ({ ...prev, page, size }))}
                        totalPage={totalPage}
                        currentPage={params.page}
                        limitPage={params.size}
                    />
                </Col>
            </Row>
        </>
    );
};

const BranchField = ({ name, handleChangeBranch }) => {
    const { branchId } = useSelector(state => state.homesidemenu.profile);

    const { data, loading } = useFetch(
        `/api/back-office/branch`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );

    return (
        <>
            <Select
                id={name}
                name={name}
                className="basic-single"
                classNamePrefix="select"
                options={data?.map(obj => ({ label: `${obj?.name}`, value: obj?.id }))}
                placeholder={`Pilih Branch`}
                defaultValue={branchId ?? null}
                isLoading={loading}
                isClearable={true}
                isDisabled={Boolean(branchId)}
                onChange={handleChangeBranch}
            />
        </>
    );
};

export default App;
