import React from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import driver from 'assets/svg/ic_Express.svg'

const OrderPickup = ({ raw = [], setopen }) => {
    const weight = raw.reduce((acc, item) => acc + item.oilWeight, 0);
    const price = raw.reduce((acc, item) => acc + item.totalPrice, 0);
    const formatNumber = (number) => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number)
    return (
        <div>
            <Card>
                <CardBody>
                    <div style={{ gap: "3rem", alignItems: "center" }} className='d-flex flex-row flex-wrap'>
                        <div>
                            <span>Total Minyak</span>
                            <h3>{weight.toFixed(1)} Kg</h3>
                        </div>
                        <div>
                            <span>Jumlah Penjualan</span>
                            <h3>{formatNumber(price)}</h3>
                        </div>
                        <div>
                            <Button onClick={setopen} color='primary' size='sm' type="button">
                                <div className='d-flex'>
                                    <img className='ml-1' src={driver} alt="driver" />
                                    <span style={{ lineHeight: 2 }} className='pl-1'>Tugaskan</span>
                                </div>
                            </Button>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}

export default OrderPickup