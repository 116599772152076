import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom'
import { Card, Row, Col, CardBody, UncontrolledTooltip, Input, Badge } from 'reactstrap';
import { Pagination, Table as TableGenerator } from 'components';
import Form from 'components-form/Form';
import Select from 'components-form/Select';
import MultiSelectAlternative from 'components-form/Select/MultiSelectAlternative';
import { useFetch } from "hooks";
import { Breadcrumb } from 'components';
import { debounce, parseValue } from 'helper';
import DatePicker from 'react-datepicker';
import { addDays, format, subDays } from 'date-fns';
import DownloadCsv from 'components/DownloadCsv';
import { Services } from 'service';
import { IcGlyphInformation } from '@elevenia/edts-icon';

const apiClient = Services();
const baseEndpoint = "/api/back-office/delivery-report";

const MAX_DATE_RANGE = 31;

const rootStyle = (variable) => {
    return getComputedStyle(document.body).getPropertyValue(variable)
}

const statusOptions = [
    { label: "Selesai Setor", value: true },
    { label: "Dalam Transit", value: false },
];

const defaultStatusOption = statusOptions[0].value;

const formatStatusAset = (isCompleted) => {
    switch (isCompleted) {
        case true:
            return <Badge color="success">Selesai Setor</Badge>;
        case false:
            return <Badge color="warning">Dalam Transit</Badge>;
        default:
            return "-";
    }
};

const theme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary25: rootStyle('--light'),
        primary: rootStyle('--primary'),
    }
});

const styles = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: state.isDisabled ? '#e4e7ea' : provided.backgroundColor,
        border: '1px solid #DBDCDD',
        boxShadow: 'none',
        '&:hover': {
            borderColor: state.selectProps.readonly ? '#DBDCDD' : rootStyle('--primary')
        }
    }),
    valueContainer: (provided) => ({
        ...provided,
        padding: '0.475rem 0.625rem',
        height: 'calc(1.5em + 1.25rem + 5px)'
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
    })
};

const dataBreadcrumb = [
    { to: '#', label: 'Mutasi Pengantaran' }
];

const App = ({
    title: documentTitle,
    access: permissionAccess
}) => {
    document.title = documentTitle;
    const today = new Date();
    const [startDate, setStartDate] = useState(subDays(today, MAX_DATE_RANGE - 1).setHours(0, 0, 0, 0));
    const [endDate, setEndDate] = useState(today.setHours(0, 0, 0, 0));
    const maxAllowedDate = Math.min(addDays(startDate, MAX_DATE_RANGE - 1), today);
    const maxDate = (startDate && endDate) ? today : maxAllowedDate;

    const { branchId } = useSelector(state => state.homesidemenu.profile);
    const hasBranchId = branchId !== 0;

    const { data, loading: isLoading, totalPage, params, setParams, error } = useFetch(
        `/api/back-office/delivery-report`,
        currentParams => ({
            size: 10,
            completed: defaultStatusOption,
            branchIds: hasBranchId ? branchId : null,
            page: isNaN(parseInt(currentParams.page)) ? 1 : parseInt(currentParams.page),
            startDate: format(new Date(startDate), 'dd-MM-yyyy'),
            endDate: format(new Date(endDate), 'dd-MM-yyyy')
        }),
        useCallback(data => ({
            data: data?.data?.content,
            totalPage: data?.data?.totalPages
        }), [])
    );

    const fetchBranchOptions = useFetch(
        "/api/back-office/branch",
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content?.map(({ id, name }) => ({
                label: `${name}`,
                value: id,
            }))
        }), []),
        { showAlertOnError: true }
    );

    /* set params for searching */
    const setParamsSearch = useCallback(debounce((name, value) => setParams(prev => ({ ...prev, page: 1, [name]: typeof value === 'string' ? value?.trim() : value })), 600), []);

    /* handle searching for input type */
    const handleSearchChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;

        setParamsSearch(name, value);
    };

    const handleFilter = (name, value) => {
        setParams((prev) => ({
            ...prev,
            [name]: value,
            page: 1,
        }));
    };

    const handleDatePicker = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);

        if (start && end) {
            setParams(prev => ({
                ...prev,
                page: 1,
                startDate: format(new Date(start), 'dd-MM-yyyy'),
                endDate: format(new Date(end), 'dd-MM-yyyy')
            }));
        };
    };

    const TableConfiguration = {
        columns: [
            {
                title: 'No Tiket',
                key: 'tripCode',
                render: (value, row) => <Link to={`/delivery-report/${row.id}`}>{value}</Link>
            }, {
                title: 'Tanggal Penugasan',
                key: 'assignmentDate',
                render: (value) => parseValue(value, (val) => format(new Date(val), 'dd/MM/yyyy'))
            }, {
                title: 'Tanggal Penjemputan',
                key: 'pickupDate',
                render: (value) => parseValue(value, (val) => format(new Date(val), 'dd/MM/yyyy'))
            }, {
                title: 'Tanggal Selesai',
                key: 'settlementDate',
                render: (value) => parseValue(value, (val) => format(new Date(val), 'dd/MM/yyyy'))
            }, {
                title: 'Jumlah Hari Dalam Transit',
                key: 'duration',
                stylesHeader: { minWidth: '150px' },
                render: (value) => parseValue(value)
            }, {
                title: 'Driver',
                key: 'driverName',
                stylesHeader: { minWidth: '150px' },
                render: (value) => parseValue(value)
            }, {
                title: 'Tipe Pengantaran',
                key: 'pickupDirection',
                render: (value) => parseValue(value)
            }, {
                title: 'Branch Asal',
                key: 'originBranch',
                stylesHeader: { minWidth: '200px' },
                render: (value) => parseValue(value)
            }, {
                title: 'Asal (SP / DC)',
                key: 'originName',
                stylesHeader: { minWidth: '200px' },
                render: (value) => parseValue(value)
            }, {
                title: 'Branch Tujuan',
                key: 'destinationBranch',
                stylesHeader: { minWidth: '200px' },
                render: (value) => parseValue(value)
            }, {
                title: 'Tujuan (DC / KBI)',
                key: 'destinationName',
                stylesHeader: { minWidth: '200px' },
                render: (value) => parseValue(value)
            }, {
                title: 'Jeriken 18 L',
                key: 'totalContainer18L',
                stylesHeader: { minWidth: '130px' },
                render: (value) => parseValue(value)

            }, {
                title: 'Jeriken 20 L',
                key: 'totalContainer20L',
                stylesHeader: { minWidth: '130px' },
                render: (value) => parseValue(value)
            }, {
                title: 'Jumlah Minyak (Kg)',
                key: 'totalWeight',
                stylesHeader: { minWidth: '130px' },
                render: (value) => parseValue(value)
            }, {
                title: 'Status Aset',
                key: 'completed',
                render: (value) => parseValue(value, formatStatusAset)
            }
        ]
    };

    return (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Laporan</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                    <div className="d-inline-block float-right">
                        <DownloadCsv
                            validation={[
                                {
                                    condition: (data?.length > 0) && !isLoading,
                                    message: "Data tidak ditemukan"
                                },
                                {
                                    condition: (params?.startDate?.length > 1) && (params?.endDate?.length > 1),
                                    message: "Kedua filter tanggal wajib diisi"
                                }
                            ]}
                            onClick={({ filterParams }) => ({
                                downloadRequest: apiClient.get(`${baseEndpoint}/export`, filterParams(params), {
                                    responseType: "blob"
                                })
                            })}
                            onBeforeSave={({ timestamp }) => ({
                                filename: `MUTASIPENGANTARAN${timestamp}.csv`
                            })}
                        />
                    </div>
                </Col>
            </Row>
            <Card body>
                <Form>
                    <Row className="justify-content-between">
                        <Col lg={3}>
                            <div className='mb-1'>
                                {params.completed ? "Tanggal Selesai" : "Tanggal Penjemputan"}
                                <span id='dateInformation' className='ml-1'>
                                    <IcGlyphInformation />
                                </span>
                                <UncontrolledTooltip
                                    placement='right'
                                    target="dateInformation"
                                    className='tooltip-sd'
                                    style={{ textAlign: 'left' }}
                                >
                                    {params.completed
                                        ? "Tanggal selesai adalah tanggal asset/minyak uco selesai disetor"
                                        : "Tanggal Penjemputan adalah tanggal asset/minyak uco berhasil dijemput driver"}
                                </UncontrolledTooltip>
                            </div>
                            <DatePicker
                                name="filterDateRange"
                                id="filterDateRange"
                                selected={startDate}
                                startDate={startDate}
                                endDate={endDate}
                                className="form-control"
                                dateFormat={"dd/MM/yyyy"}
                                maxDate={maxDate}
                                selectsRange
                                onChange={handleDatePicker}
                            />
                        </Col>
                        <Col lg={3}>
                            <div className='mb-1'>Branch Tujuan</div>
                            <MultiSelectAlternative
                                id="branchIds"
                                name="branchIds"
                                placeholder="Pilih Branch"
                                theme={theme}
                                styles={styles}
                                options={fetchBranchOptions.data ?? []}
                                isLoading={fetchBranchOptions.loading}
                                isClearable={!hasBranchId}
                                isDisabled={hasBranchId}
                                defaultValue={branchId}
                                closeMenuOnSelect={false}
                                onChange={(options) => {
                                    const value = options?.length
                                        ? options
                                            .map(({ value }) => value)
                                            .join(",")
                                        : null;

                                    handleFilter("branchIds", value);
                                }}
                            />
                        </Col>
                        <Col lg={3}>
                            <div className='mb-1'>Status Aset</div>
                            <Select
                                id="completed"
                                name="completed"
                                theme={theme}
                                styles={styles}
                                options={statusOptions}
                                defaultValue={defaultStatusOption}
                                onChange={(option) => handleFilter("completed", option?.value)}
                            />
                        </Col>
                        <Col lg={3} className="align-self-end">
                            <div className="input-search">
                                <i className="fa fa-search text-muted"></i>
                                <Input className="pl-5" type="text" name="search" id="name" placeholder="Cari No Tiket" onChange={handleSearchChange} defaultValue={params?.name} />
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <Card>
                <CardBody className="p-0">
                    <TableGenerator
                        {...TableConfiguration}
                        dataList={data}
                        params={params}
                        loading={isLoading}
                        error={error}
                    />
                </CardBody>
            </Card>
            <Row className="pagination mr-1 mb-2">
                <Col>
                    <Card
                        className='d-flex flex-row p-2 align-items-start m-0'
                        style={{ width: '250px', gap: '8px' }}
                    >
                        <span>
                            <IcGlyphInformation />
                        </span>
                        <span style={{ fontSize: '12px' }}>Data diatas hanya menampilkan pengantaran dengan status 'Selesai'</span>
                    </Card>
                </Col>
                <Col>
                    <Pagination
                        showRowsPerPage={false}
                        onChangePage={({ page, size }) => setParams(prev => ({ ...prev, page, size }))}
                        totalPage={totalPage}
                        currentPage={params.page}
                        limitPage={params.size}
                        withSelector
                    />
                </Col>
            </Row>
        </>
    );
};

export default App;
