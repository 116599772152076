const initialState = {
    profile: {
        email: "",
        name: ""
    },
    menus: [],
    getMenus: [],
    loading: false,
    pola: {}
}
export const homesidemenu = (state = initialState, action) => {
    switch (action.type) {
        case 'REQUEST_HOME_SIDE_MENU':
            return {
                ...state,
                loading: true
            }
        case 'GET_HOME_SIDE_MENU':
            const { profile, menus, actionGroup } = action.payload
            let listMenu = [];
            let linkOnly = [];
            let declareMenus = menus.map((item, index) => {
                let enabledStatus = [];
                return {
                    ...item,
                    index: index + 1,
                    code: item.code,
                    icon: `fa fa-${item.icon}`,
                    name: item.name,
                    url: item.url === undefined ? '' : item.url,
                    children: item.menus.map(valueOfChildren => {
                        linkOnly.push(valueOfChildren.url)
                        return {
                            name: valueOfChildren.label,
                            url: valueOfChildren.url,
                            code: valueOfChildren.code
                        }
                    }),
                    enabled: enabledStatus.length > 0
                }
            });
            /* if object link not empty, then reformat menu level 1 */
            declareMenus = declareMenus?.filter(obj => obj?.menus?.length > 0)?.map(obj => {
                if (obj?.url) {
                    delete obj.children;
                    return { ...obj, menus: [] }
                }
                return { ...obj }
            });

            listMenu.push(...declareMenus);
            return {
                ...state,
                profile,
                menus: listMenu,
                getMenus: menus,
                actionGroup,
                linkOnly,
                pola: action.payload.pola,
                loading: false,
                branch: action.payload.branch,
                stockpoint: action.payload.stockpoint,
            }
        case 'REQUEST_RESTARTED':
            return {
                ...state,
                loading: false
            }
        default:
            return state
    }
}