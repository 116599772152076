import ModalPage from 'components/Modal';
import React, { useContext, useState } from 'react';
import { Button, Col, Row, Spinner } from 'reactstrap';
import { PembelianContextForm } from '../form';
import { Services } from 'service';
import { useAction } from 'hooks';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ModalPreview = () => {
    const { open, setopen, data } = useContext(PembelianContextForm);
    const history = useHistory()
    const money = (number) => new Intl.NumberFormat('id-ID').format(number);
    const { hasFetch } = useAction();
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const handleSubmitted = () => {
        setIsLoadingSubmit(true);
        const requestBody = {
            "supplierId": data?.supplierId,
            "branchId": data?.branchId,
            "stockpointId": data?.stockpointId,
            "salespersonCode": data?.salespersonCode,
            "totalContainer18L": data?.totalContainer18L,
            "totalContainer20L": data?.totalContainer20L,
            "purchasePrice": data?.purchasePrice,
            "purchasePow": data?.purchasePow
        };
        Services().post('/api/back-office/direct-purchase/confirm', requestBody).then(result => {
            hasFetch({
                type: 'ALERT_TOAST_SUCCESS',
                payload: { message: `QR penjualan langsung berhasil dibuat.` }
            });
            setIsLoadingSubmit(false);
            history.push('/direct-purchase')
        }).catch((e) => {
            hasFetch({
                type: 'ALERT_TOAST_ERROR',
                payload: { message: e?.message ?? ` gagal dibuat.` }
            });
            setIsLoadingSubmit(false);
            setopen(true);
        });
    }

    return (
        <ModalPage
            isOpen={open}
            handleClose={() => setopen(!open)}
            component={<div>
                <div className='moda-password-title pb-4 align-items-center m-0' style={{ fontWeight: 500 }}>Detail Konfirmasi Penyelesaian</div>
                <div className='d-flex flex-column' style={{ gap: "12px" }}>
                    <div className='d-flex flex-column' style={{ gap: "6px" }}>
                        <span style={{ fontSize: 14, color: "#70727D" }}>No Tiket</span>
                        <span style={{ fontSize: 16, fontWeight: 700, color: "#151823" }}>-</span>
                    </div>
                    <Row className='d-flex'>
                        <Col lg={6} className='d-flex flex-column' style={{ gap: "6px" }}>
                            <span style={{ fontSize: 14, color: "#70727D" }}>Branch</span>
                            <span style={{ fontSize: 16, fontWeight: 700, color: "#151823" }}>{data?.branchName ?? "-"}</span>
                        </Col>
                        <Col lg={6} className='d-flex flex-column' style={{ gap: "6px" }}>
                            <span style={{ fontSize: 14, color: "#70727D" }}>Stockpoint</span>
                            <span style={{ fontSize: 16, fontWeight: 700, color: "#151823" }}>{data?.stockpointName ?? "-"}</span>
                        </Col>
                    </Row>
                    <Row className='d-flex'>
                        <Col lg={6} className='d-flex flex-column' style={{ gap: "6px" }}>
                            <span style={{ fontSize: 14, color: "#70727D" }}>Supplier</span>
                            <span style={{ fontSize: 16, fontWeight: 700, color: "#151823" }}>{data?.supplierName ?? "-"}</span>
                        </Col>
                        <Col lg={6} className='d-flex flex-column' style={{ gap: "6px" }}>
                            <span style={{ fontSize: 14, color: "#70727D" }}>No HP Supplier</span>
                            <span style={{ fontSize: 16, fontWeight: 700, color: "#151823" }}>{data?.phone ?? "-"}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='d-flex flex-column' style={{ gap: "6px" }} lg={12}>
                            <span style={{ fontSize: 14, color: "#70727D" }}>Outlet</span>
                            <span style={{ fontSize: 16, fontWeight: 700, color: "#151823", wordWrap: "break-word" }}>{data?.outletName ?? "-"}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='d-flex flex-column' style={{ gap: "6px" }} lg={12}>
                            <span style={{ fontSize: 14, color: "#70727D" }}>Alamat</span>
                            <span style={{ fontSize: 16, fontWeight: 700, color: "#151823", wordWrap: "break-word" }}>{data?.address ?? "-"}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className='d-flex flex-column' style={{ gap: "6px" }} lg={12}>
                            <span style={{ fontSize: 14, color: "#70727D" }}>Salesperson</span>
                            <span style={{ fontSize: 16, fontWeight: 700, color: "#151823", wordWrap: "break-word" }}>{data?.salespersonName ?? "-"}</span>
                        </Col>
                    </Row>
                    <Row className='d-flex'>
                        <Col lg={6} className='d-flex flex-column justify-content-between'>
                            <div className='d-flex flex-column' style={{ gap: "6px" }}>
                                <span style={{ fontSize: 14, color: "#70727D" }}>Jumlah Jeriken 18 L</span>
                                <span style={{ fontSize: 16, fontWeight: 700, color: "#151823" }}>{data?.totalContainer18L ?? "-"}</span>
                            </div>
                            <div className='d-flex flex-column' style={{ gap: "6px" }}>
                                <span style={{ fontSize: 14, color: "#70727D" }}>Jumlah Jeriken 20 L</span>
                                <span style={{ fontSize: 16, fontWeight: 700, color: "#151823" }}>{data?.totalContainer20L ?? "-"}</span>
                            </div>
                        </Col>
                        <Col lg={6} className='d-flex flex-column' style={{ gap: "6px" }}>
                            <div className='d-flex flex-column' style={{ gap: "6px" }}>
                                <span style={{ fontSize: 14, color: "#70727D" }}>Bukti Pembelian</span>
                                <div className='d-flex flex-column position-relative justify-content-center align-items-center' style={{ borderRadius: 4, width: 102, overflow: "hidden", gap: 2, cursor: 'pointer' }}>
                                    <span>
                                        <img src={data?.purchasePow ?? null} style={{ background: 'white', padding: 3 }} width={'100%'} height={'autoƒ'} alt='ic_cancel' />
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12} className='d-flex flex-column' style={{ gap: "6px" }}>
                            <span style={{ fontSize: 14, color: "#70727D" }}>Estimasi Jumlah Kg</span>
                            <span style={{ fontSize: 16, fontWeight: 700, color: "#151823" }}>{data?.totalOilWeight + " Kg" ?? "-"}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} className='d-flex flex-column' style={{ gap: "6px" }}>
                            <span style={{ fontSize: 14, color: "#70727D" }}>Total Pembelian</span>
                            <span style={{ fontSize: 16, fontWeight: 700, color: "#151823" }}>Rp {money(data?.purchasePrice) ?? "-"}</span>
                        </Col>
                        <Col lg={6} className='d-flex flex-column' style={{ gap: "6px" }}>
                            <span style={{ fontSize: 14, color: "#70727D" }}>Harga Rata rata per Kg</span>
                            <span style={{ fontSize: 16, fontWeight: 700, color: "#151823" }}>Rp {money(data?.averageOilPrice) ?? "-"}</span>
                        </Col>
                    </Row>
                    <div className='text-right mt-4'>
                        <Button onClick={() => { setopen(!open) }} size='sm' type="button" color="secondary" >
                            Kembali
                        </Button>
                        <Button onClick={handleSubmitted} disabled={isLoadingSubmit} className='font-weight-bold' size='sm' type="button" color="primary" >
                            {isLoadingSubmit &&
                                <span className='mr-2'>
                                    <Spinner size="sm" />
                                </span>}
                            Konfirmasi
                        </Button>
                    </div>
                </div>
            </div>
            }
        />
    )
}

export default ModalPreview