import { Services } from "service";

export const requestHomeSideMenu = (properties, navigate) => {
    return {
        type: "FETCH_CONTROLLERS",
        request: "REQUEST_HOME_SIDE_MENU",
        payload: {
            http: "get",
            url: '/api/back-office/home',
            parameter: properties,
            callback: (rawResponse) => async (dispatch) => {
                const getMenus = rawResponse?.data?.data?.menuGroups?.map(cp => cp.menus)
                const initialValue = {}
                let pola = []?.concat(...Object.values(getMenus)).reduce((obj, item) => {
                    delete item.accessRights.createdBy
                    delete item.accessRights.lastModifiedBy
                    return {
                        ...obj,
                        [item['code']]: item.accessRights,
                    };
                }, initialValue);
                pola = { ...pola, home: { id: 0, read: true } }
                const { data: branch } = await Services().get('/api/back-office/branch', { unpaged: true })
                let stockpoint = null
                if (rawResponse.data.data.profile.warehouseId === 0) {
                    const { data } = await Services().get('/api/back-office/stockpoint', { unpaged: true })
                    stockpoint = data;
                }
                const actionGroup = Object.fromEntries(
                    rawResponse?.data?.data?.actionGroups?.map((group) => [
                        group?.code,
                        Object.fromEntries(
                            group?.actions?.map((action) => ([action?.code, action?.allowed])) ?? []
                        )
                    ]) ?? []
                );
                dispatch({
                    type: "GET_HOME_SIDE_MENU",
                    payload: {
                        menus: rawResponse.data.data.menuGroups,
                        actionGroup,
                        profile: rawResponse.data.data.profile,
                        pola,
                        branch: branch.data.content.filter(i => i.id === rawResponse.data.data.profile.branchId),
                        stockpoint: stockpoint?.data?.content.filter(i => i.id === rawResponse.data.data.profile.stockpointId)
                    }
                })
            }
        }
    }
}