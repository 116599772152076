import ModalPage from 'components/Modal';
import React, { useContext, useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import icCancel from 'assets/svg/ic_cancel.svg'
import 'assets/css/printable.css';
import { useFormContext } from 'react-hook-form';
import { PembelianContextForm } from '../form';
const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});
const ImagePreview = () => {
    const { getValues } = useFormContext();
    const [img, setimage] = useState(null);
    const { openImage, setopenImage } = useContext(PembelianContextForm);
    const { buktiPembelian } = getValues();
    useEffect(() => {
        if (buktiPembelian) {
            toBase64(buktiPembelian).then(raw => {
                setimage(raw);
            });
        }
    }, [img, buktiPembelian, setimage])

    return (
        <ModalPage
            isOpen={openImage}
            handleClose={() => setopenImage(!openImage)}
            component={<div className='d-flex flex-column' style={{ gap: '2rem' }}>
                <div className='d-flex justify-content-between align-items-center'>
                    <span style={{ fontSize: 16, fontWeight: 500 }}>Lihat Gambar</span>
                    <div className='text-right'>
                        <span onClick={() => setopenImage(!openImage)} style={{ cursor: "pointer" }}>
                            <img src={icCancel} alt="pict-cancel" />
                        </span>
                    </div>
                </div>
                <Row className='justify-content-center align-items-center flex-column'>
                    <img width={360} src={img} alt='pict-lost' />
                </Row>
            </div>
            }
        />
    )
}

export default ImagePreview