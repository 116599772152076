const formatNumber = (number) => new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(number)

const message = (rule) => {
  return {
    'required': ['Perlu diisi'],
    'email': ['Alamat email tidak benar'],
    'length': ['Panjang harus ' + rule + ' karakter'],
    'minLength': ['Minimum ' + rule + ' karakter yang dibutuhkan'],
    'maxLength': ['Maksimum ' + rule + ' karakter yang dibutuhkan'],
    'minValue': ['Nilai minimalnya adalah ' + rule],
    'maxValue': ['Nilai maksimum adalah ' + rule],
    'equals': ['Bidang tidak cocok'],
    'matches': ['Bidang tidak benar'],
    'phone': ['Nomor telepon tidak benar'],
    'letterNumber': ['Hanya boleh huruf dan angka'],
    'letterNumberSp': ['Hanya boleh huruf, angka dan spasi'],
    'decimal': ['Haya boleh desimal (3 digit dibelakang koma)'],
    'number': ['Hanya boleh angka'],
    'numberSp': ['Hanya boleh angka dan spasi'],
    'numberColon': ['Hanya boleh angka dan koma'],
    'letter': ['Hanya boleh huruf'],
    'letterSp': ['Hanya boleh huruf dan spasi'],
    'npwp': ['Format npwp salah'],
    'password': ['Silahkan gunakan kombinasi huruf, angka dan simbol'],
    'strictPassword': ['Harus mengandung kombinasi special character, uppercase, dan numeric'],
    'fileSize': ['Ukuran file terlalu besar'],
    'fileType': ['Tipe file salah'],
    'moneyLackOfNumber': [`Harga harus kelipatan ${rule}`],
    'diskonRate': [`Diskon tidak boleh lebih dari ${rule} persen`],
    'attribute': [`Hanya boleh huruf kecil dan garis bawah`],
    'passwordWithCharacter': [`Minimal 8 karakter & kombinasi huruf dan angka`],
    'minThan': ['Margin tidak boleh kurang dari ' + formatNumber(rule) + ''],
    'moreThan': ['Margin uang melebihi Rp 10.000 dari jumlah penjualan'],
    'moreThanStandar': [`Margin uang melebihi ${formatNumber(rule)} dari jumlah batas maksimum`],
    'moreThanCurrent': ['Jumlah dimasukan melebihi Petty Cash'],
    'moreThanCash': ['Margin uang melebihi uang kas'],
    'numberDot': ['Format input salah contoh : 0.00'],
    'valueOfNoteKg': ['Masukan harga dengan benar contoh : 1.000'],
    'valueOfNumber': ['Masukan harga dengan benar contoh : 1.000.000'],
    'valueOfnull': ['Harga tidak boleh 0'],
    'moreThanCurrently': ['Margin uang melebihi Rp 10.000 dari jumlah penjualan'],
    'notSymbol': ['Tidak boleh symbol '],
    'notSymbolNumber': ['Tidak boleh symbol & number'],
    'notNull': ['Jeriken Tidak boleh 0 '],
    'minLengthNumber': ['Minimum ' + rule + ' Angka'],
  }
}

export default message;
