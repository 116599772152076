import React from 'react';
import { Button } from 'reactstrap';
import EditIcon from '@elevenia/edts-icon/glyph/Edit';

import ModalReuploadPurchaseNote, { useReuploadPurchaseNote } from './ModalReuploadPurchaseNote';

const ChangePurchaseNote = ({ onSucceed }) => {
    const modalReupload = useReuploadPurchaseNote();

    return (
        <>
            <ModalReuploadPurchaseNote config={modalReupload.config} onSucceed={onSucceed} />

            <Button
                outline
                size="sm"
                color="primary"
                onClick={(e) => {
                    modalReupload.show("confirm");
                    e.currentTarget.blur();
                }}
            >
                <div className="d-flex align-items-center">
                    <EditIcon color="#1178D4" size={15} />
                    <span className="ml-2">Ubah Gambar</span>
                </div>
            </Button>
        </>
    );
};

export default ChangePurchaseNote;
