import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, Row, Col, CardHeader, CardBody, FormGroup, Label, Button, InputGroup, Spinner } from 'reactstrap';
import { useFormContext } from 'react-hook-form';
import { useFetch, useAction } from 'hooks';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import Form from 'components-form/Form';
import Input from 'components-form/Input';
import Select from 'components-form/Select';
import Switches from 'components-form/Switches';
import { hideConfirmationModal } from 'store/actions/confirmationModal';
import Error404 from 'page/error/Error404';
import InputId from 'components-form/InputId';

// import { useSelector } from 'react-redux';

const posisi = [{
    label: "Salesman",
    value: "salesman",
    code: "S"
}, {
    label: "Sales Supervisor",
    value: "sales supervisor",
    code: "V"
}, {
    label: "Sales Manager",
    value: "sales manager",
    code: "M"
}]

const App = ({
    title: documentTitle,
    history
}) => {
    document.title = documentTitle;

    const [showPassword, setShowPassword] = useState(false);
    const params = useParams();
    const { hasFetch } = useAction();
    const isCreate = params?.id ? false : true;
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const dataBreadcrumb = isCreate ?
        [
            { to: '/salesman', label: 'Master Data Salesperson' },
            { to: null, label: 'Tambah Salesperson' }
        ]
        : [
            { to: '/salesman', label: 'Master Data Salesperson' },
            { to: `/salesman/${params.id}/${params.level}`, label: 'Detail Salesperson' },
            { to: null, label: 'Edit Salesperson' }
        ]

    const [integratedStocpoint, setintegratedStockpoint] = useState(null)

    let endpoint = params?.level?.split("-")?.map(i => i === "sales" ? i?.replace("sales", 'salesman') : i)?.join("/")

    /* fecthing data for detail page */
    const { data, status, error } = useFetch(
        `/api/back-office/${endpoint}/${params?.id}`,
        {},
        useCallback(data => ({
            data: {
                ...data?.data
            }
        }), []),
        { onMount: !isCreate }
    )
    const onSubmit = (input) => {
        setIsLoadingSubmit(true);
        const id = params?.id;
        delete input.userLevel;
        const requestBody = {
            ...input,
            branchId: input.branchId !== null ? input.branchId : [],
            stockpointIds: input.stockpointIds !== null ? input?.level === "salesman" && isCreate ? [input.stockpointIds] : input.stockpointIds : [],
            employeeId: input.employeeId.replace(/\//g, '').match(/.{1,4}/g).join('/')
        };
        if (requestBody.password === "") {
            delete requestBody.password
        }
        const action = ({ requestBody, id = null }) => isCreate
            ? Services().post('/api/back-office/salesman', requestBody)
            : Services().post(`/api/back-office/salesman/update`, { ...requestBody, id: parseInt(id) })

        action({ requestBody, id: params?.id })
            .then(() => {
                setIsLoadingSubmit(false);
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: isCreate ? `Salesman baru berhasil ditambah` : `Salesman baru berhasil diubah` }
                }) 
                // history.push(isCreate ? '/salesman' : `/salesman/${id}/${endpoint.replace("/", "-")}`);
                history.push(isCreate ? '/salesman' : `/salesman/${id}/${params?.level}`);
                hasFetch(hideConfirmationModal());
            })
            .catch(e => {
                setIsLoadingSubmit(false);
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Cannot update ${id}` } })
                hasFetch(hideConfirmationModal());
            })
    }

    const defaultValues = {
        level: data?.level.toLowerCase() ?? '',
        employeeId: data?.employeeId ?? '',
        name: data?.name ?? '',
        username: data?.username ?? '',
        email: data?.email ?? '',
        phone: data?.phone ?? '',
        password: data?.password ?? '',
        stockpointIds: data?.stockpoints?.map(i => i.id) ?? null,
        branchId: data?.branchId ?? null,
        active: data?.active ?? isCreate ? true : false
    }

    const [disabled, setdisabled] = useState(true)
    const [passedcode, setcode] = useState(null)
    const trigger = (id) => {
        setintegratedStockpoint(id)
    }
    useEffect(() => {
        const x = posisi.filter(i => i.value === data?.level.toLowerCase())
        !isCreate && setcode(x[0]?.code)
    }, [isCreate, data])
    if ((status === 'rejected' && error.code === 400) || (status === 'resolved' && data === null)) return <Error404 />
    return (status === 'resolved' || isCreate) && (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>{isCreate ? "Buat" : "Edit"} Salesperson</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Form id="penggunaForm" onSubmit={onSubmit} autoComplete="off" defaultValues={defaultValues}>
                <Card>
                    <CardHeader className="pb-0">
                        <div>Informasi Salesperson</div>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label htmlFor="Jabatan" sm={3}>Jabatan</Label>
                                    <Col sm={9}>
                                        {/* <Input id="nama_lengkap" validation={['required']} name="name" type="text" placeholder="Nama Lengkap" autoComplete="off" /> */}
                                        <Select
                                            id={"jabatan"}
                                            name={"level"}
                                            className="basic-single"
                                            classNamePrefix="select"
                                            options={posisi}
                                            placeholder={`Pilih Jabatan`}
                                            // isLoading={loading}
                                            // isMulti={true}
                                            // isDisabled={isDisabled}
                                            validation={['required']}
                                            onChange={(ev) => {
                                                setcode(ev.code)
                                                setdisabled(false)
                                            }}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="employee_id" sm={3}>Employee ID</Label>
                                    <Col sm={9}>
                                        <InputId
                                            inputCode={passedcode}
                                            disabled={isCreate && disabled}
                                            id="employee_id"
                                            validation={['required']}
                                            name="employeeId"
                                            type="text"
                                            placeholder={!passedcode ? "Pilih Jabatan Telebih Dahulu" : `Contoh ID: 0000/0000/0000/0000/${passedcode}001 `}
                                            autoComplete="off"
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="nama_lengkap" sm={3}>Nama Lengkap</Label>
                                    <Col sm={9}>
                                        <Input id="nama_lengkap" validation={['required']} name="name" type="text" placeholder="Nama Lengkap" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="username" sm={3}>username</Label>
                                    <Col sm={9}>
                                        <Input disabled={!isCreate} id="username" validation={['required', 'minLength[6]']} name="username" type="text" placeholder="Username" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="email" sm={3}>email</Label>
                                    <Col sm={9}>
                                        <Input id="email" validation={['required', 'email']} name="email" type="text" placeholder="Email" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="phone" sm={3}>Nomor Handphone</Label>
                                    <Col sm={9}>
                                        <Input id="phone" maxLength="15" validation={['required', 'phone', 'minLength[8]', 'maxLength[15]']} name="phone" type="text" placeholder="Nomor Handphone" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                                {!params.id && <FormGroup htmlFor="secret" row>
                                    <Label sm={3}>Password {!isCreate && "(Optional)"}</Label>
                                    <Col sm={9}>
                                        <InputGroup>
                                            <Input id="secret" type={`${showPassword ? "text" : "password"}`}
                                                name="password"
                                                placeholder="Password Awal"
                                                validation={isCreate ? ['required', 'minLength[8]', 'passwordWithCharacter'] : ['minLength[8]', 'passwordWithCharacter']}
                                                autoComplete="new-password"
                                                maxLength={50} />
                                            <i className={`text-14 icon-password fa ${(showPassword) ? "fa-eye" : "fa-eye-slash"}`} aria-hidden="true" onClick={() => setShowPassword(!showPassword)} />
                                        </InputGroup>
                                    </Col>
                                </FormGroup>}
                                <FormGroup row>
                                    <Label htmlFor="branch" sm={3}>Branch</Label>
                                    <Col sm={9}>
                                        <BranchField
                                            name="branchId"
                                            triggerID={trigger}
                                            defaultValues={defaultValues}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="stockPoint" sm={3}>StockPoint</Label>
                                    <Col sm={9}>
                                        <StockPoint name="stockpointIds" code={integratedStocpoint} isCreate={isCreate} />
                                    </Col>
                                </FormGroup>
                                {data?.level === "sales manager" && !isCreate && data?.status === "active" &&
                                    <FormGroup row>
                                        <Label htmlFor="active" sm={3}>Status</Label>
                                        <Col sm={9}>
                                            <Switches
                                                className="custom-switch-success mt-1"
                                                name="active"
                                                label="Aktif"
                                            />
                                        </Col>
                                    </FormGroup>
                                }
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <Row>
                    <Col className="text-right">
                        <Link to={(isCreate) ? "/salesman" : `/salesman/${params.id}`}>
                            <Button type="button" color="secondary" className="m-1" onClick={() => window.history.back()}>
                                Batal
                            </Button>
                        </Link>
                        <Button type="submit" color="primary" className="m-1" disabled={isLoadingSubmit}>
                            {isLoadingSubmit &&
                                <span className='mr-2'>
                                    <Spinner size="sm" />
                                </span>}
                            {(isCreate) ? 'Buat' : 'Simpan'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

const BranchField = ({ name, defaultValues, triggerID }) => {
    const { watch, trigger, setValue } = useFormContext();
    const { data, loading } = useFetch(
        `/api/back-office/branch`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }

    );

    const isHo = watch("userLevel")?.length > 0;
    const fillUnitCodes = watch("unitCodes")?.length > 0;
    return (
        <Select
            id={name}
            name={name}
            className="basic-single"
            classNamePrefix="select"
            options={data?.map(obj => ({ label: `${obj?.code} - ${obj?.name}`, value: obj?.id })).sort((a, b) => a.label.localeCompare(b.label))}
            placeholder={`Pilih Branch`}
            isLoading={loading}
            isClearable={true}
            // isDisabled={isLevel === ""}
            validation={(!isHo && !fillUnitCodes) && ['required']}
            onChange={(ev) => {
                if (ev) {
                    const { code } = data.filter(item => item.id === ev.value)[0]
                    trigger()
                    triggerID(code)
                    setValue('stockpointIds', null)
                }
                else {
                    trigger()
                    triggerID(null)
                    setValue('stockpointIds', null)
                }
            }}
        />
    )

}

const StockPoint = ({ name, code, isCreate }) => {
    const { trigger, setValue, getValues } = useFormContext();
    const { level } = getValues()

    /* fetching stock point list */
    const { data: stockPointList, loading: isLoadingStockPoint, setParams } = useFetch(
        `/api/back-office/stockpoint`,
        currentParams => ({
            unpaged: true,
        }),
        useCallback(data => ({
            data: data?.data?.content,
        }), []),
        { withQueryParams: false, showAlertOnError: false }
    )
    useEffect(() => {
        code && setParams(prev => ({
            ...prev,
            branch: code
        }))
    }, [code, setParams])

    useEffect(() => {
        stockPointList?.length === 0 && setValue(name, null)
    }, [stockPointList, name, setValue])

    const isDisabled = !code || stockPointList?.length === 0;
    return (
        <Select
            id={name}
            name={name}
            isMulti={level !== "salesman"}
            // trim={true}
            className="basic-multi-select"
            classNamePrefix="select"
            options={stockPointList?.map(obj => ({ label: `${obj?.code} - ${obj?.name}`, value: obj?.id }))}
            // filterOption={
            //     (option, inputValue) => (option.label.toString().match(inputValue) || []).length > 0
            // }
            placeholder={isDisabled ? `Stock Point Tidak Ada` : `Pilih Stock Point`}
            isLoading={isLoadingStockPoint}
            isDisabled={isDisabled && isCreate}
            isClearable={true}
            validation={['required']}
            onChange={() => {
                trigger()
            }}
        />
    )
}
export default App