import React, { useCallback } from 'react'
import {
    Card, CardBody, Row, Col,
    CardHeader,
    FormGroup,
    Label,
} from 'reactstrap';
import {
    useParams
} from 'react-router-dom';
import { Breadcrumb } from 'components';
import { useFetch } from 'hooks';
import Error404 from 'page/error/Error404';
import { format } from 'date-fns';

const getColorAmount = (amount) => {
    let getClass = amount > 0 ? "text-success" : amount < 0 ? "text-danger" : "";
    let isPlus = amount > 0 ? "+" : "";
    return <span className={getClass}>{isPlus}{amount ?? "-"}</span>;
};

const App = ({
    title: documentTitle,
    access: permissionAccess,
    history
}) => {
    document.title = documentTitle;
    const { id } = useParams();
    const dataBreadcrumb = [
        { to: '/oil', label: 'Stok Minyak' },
        { to: null, label: 'Detail Penyesuaian' }
    ]

    const { data, status, error } = useFetch(`/api/back-office/stockpoint/inventory/adjustment/${id}`,
        {},
        useCallback(data => ({ data: data?.data }), [])
    )

    if ((status === 'rejected' && error.code === 400) ||
        (status === 'resolved' && data === null)) return <Error404 />
    return status === 'resolved' && (
        <>
            <Row className="row mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>Detail Stok Minyak</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Card>
                <CardHeader className="pb-0">
                    Detail Penyesuaian
                </CardHeader>
                <CardBody>
                    <FormGroup row>
                        <Label sm={3}>Nomor Tiket</Label>
                        <Col sm={9}>
                            : {data?.referenceCode ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Tanggal</Label>
                        <Col sm={9}>
                            : {format(new Date(data?.date), "dd/MM/yyyy - HH:mm") ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Stock Point</Label>
                        <Col sm={9}>
                            : {data?.originName ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Stok Akhir Jeriken 18 L</Label>
                        <Col sm={9}>
                            : {data?.totalAfterContainer18 ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Penyesuaian Stok Jeriken 18 L</Label>
                        <Col sm={9}>
                            : {getColorAmount(data?.adjustmentContainer18) ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Stok Akhir Jeriken 20 L</Label>
                        <Col sm={9}>
                            : {data?.totalAfterContainer20 ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Penyesuaian Stok Jeriken 20 L</Label>
                        <Col sm={9}>
                            : {getColorAmount(data?.adjustmentContainer20) ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Catatan</Label>
                        <Col sm={9}>
                            : {data?.notes ?? "-"}
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Label sm={3}>Dibuat oleh</Label>
                        <Col sm={9}>
                            : {data?.createdBy ?? "-"}
                        </Col>
                    </FormGroup>
                </CardBody>
            </Card>
        </>
    )
}

export default App