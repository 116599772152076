import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Card, Row, Col, CardHeader, CardBody, FormGroup, Label, Button, Modal, ModalBody, Spinner } from 'reactstrap';
import { useFormContext } from 'react-hook-form';
import { useFetch, useAction } from 'hooks';
import { Services } from 'service';
import { Breadcrumb } from 'components';
import Form from 'components-form/Form';
import Input from 'components-form/Input';
import Select from 'components-form/Select';
import Error404 from 'page/error/Error404';
import IconUpload from 'assets/svg/ic_upload_filled.svg';
import IcReUpload from '../../assets/svg/ic_reupload.svg';
import IconPinPoint from 'assets/svg/ic_pin-point.svg';
import { TextArea } from 'components-form';
import cancel from 'assets/svg/ic_cancel.svg';
import { debounce } from 'helper';
import { components } from 'react-select';
import MapsDraggable from 'page/suplier/components/Maps';
import AsyncSelect from 'react-select/async';
import InputDecimal from './components/InputDecimal';
import { useSelector } from 'react-redux';

const App = ({
    title: documentTitle,
    history
}) => {
    document.title = documentTitle;

    const params = useParams();
    const { hasFetch } = useAction();
    const isCreate = params?.id ? false : true;
    const fileInputRef = useRef(null);
    const [selectedFile, setSelectedFile] = useState('');
    const [imgPath, setImgPath] = useState('');
    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

    const dataBreadcrumb = isCreate ?
        [
            { to: '/supplier', label: 'Master Data Supplier' },
            { to: null, label: 'Tambah Supplier' }
        ]
        : [
            { to: '/supplier', label: 'Master Data Supplier' },
            { to: `/supplier/${params.id}`, label: 'Detail Supplier' },
            { to: null, label: 'Edit Supplier' }
        ]

    /* fecthing data for detail page */
    const { data, status, error } = useFetch(
        `/api/back-office/supplier/${params?.id}`,
        {},
        useCallback(data => ({
            data: {
                ...data?.data
            }
        }), []),
        { onMount: !isCreate }
    )

    /* fecthing data for defaul price  */
    const { data: defaultPrice, setParams: setParamsDefaultPrice } = useFetch(
        `/api/back-office/supplier/commitment-price`,
        {},
        useCallback(data => ({
            data: {
                ...data?.data
            }
        }), []),
        { onMount: isCreate }
    )

    const defaultValues = {
        supplierName: data?.supplierInformation?.name ?? '',
        supplierBankName: data?.supplierInformation?.bankName ?? '',
        supplierBankAccount: data?.supplierInformation?.bankAccount ?? '',
        supplierPhone: data?.supplierInformation?.phone ?? '',
        outletName: data?.outletInformation?.outletName ?? '',
        iapoutletCode: data?.outletInformation?.iapoutletCode ?? '',
        supplierCategory: data?.outletInformation?.supplierCategoryId ?? '',
        supplierAddress: data?.outletInformation?.address ?? '',
        supplierLatitude: data?.outletInformation?.latitude ?? '',
        supplierLongitude: data?.outletInformation?.longitude ?? '',
        pickupAddress: data?.outletInformation?.pickupAddress ?? '',
        pickupLat: data?.outletInformation?.pickupLatitude ?? '',
        pickupLong: data?.outletInformation?.pickupLongitude ?? '',
        detailSupplierAddress: data?.outletInformation?.detailAddress ?? '',
        detailPickupAddress: data?.outletInformation?.detailPickupAddress ?? '',
        supplierOilAmountCommitment: data?.supplyCommitment?.oilAmountCommitment ?? '',
        supplierPriceCommitment: Intl.NumberFormat('id-ID').format(data?.supplyCommitment?.priceCommitment) ?? '',
        supplierPickupDay: data?.supplyCommitment?.pickupDay ?? '',
        pickupTime: data?.supplyCommitment?.pickupTime ?? '',
        selfDeclaration: data?.supplyCommitment?.selfDeclaration ?? '',
        branchId: data?.supervisorInformation?.branchId ?? '',
        stockpointId: data?.supervisorInformation?.stockpointId ?? '',
        userId: data?.supervisorInformation?.userCode ?? '',
        userName: data?.supervisorInformation?.name ?? ''
    }

    const timeList = [{
        label: "09.00 - 12.00",
        value: 1,
    }, {
        label: "13.00 - 16.00",
        value: 2,
    }]

    const dayList = [{
        label: "Senin",
        value: 1,
    }, {
        label: "Selasa",
        value: 2,
    }, {
        label: "Rabu",
        value: 3,
    }, {
        label: "Kamis",
        value: 4,
    }, {
        label: "Jum'at",
        value: 5,
    }]

    const bankList = [{
        label: "BCA (PT. Bank Central Asia. Tbk)",
        value: "BCA (PT. Bank Central Asia. Tbk)",
    }, {
        label: "BNI (PT. Bank Negara Indonesia)",
        value: "BNI (PT. Bank Negara Indonesia)",
    }, {
        label: "Mandiri (PT. Bank Mandiri)",
        value: "Mandiri (PT. Bank Mandiri)",
    }, {
        label: "Lain-lain",
        value: "Lain-lain"
    }]

    const handleButtonUpload = () => {
        // Trigger the file input click event when the button is clicked
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const getSelectedFile = e.target.files[0];
        if (getSelectedFile) {
            // Handle the selected file here (e.g., send it to the server)
            setSelectedFile(getSelectedFile);
        }
    };

    // const handleUpload = () => {
    //     const dataForm = new FormData()
    //     dataForm.append('file', selectedFile)

    //     if (selectedFile) {
    //         Services().post('/api/image/self-declaration', dataForm).then(({ data }) => {
    //             console.log("test", data)
    //             seTimgPath(data)
    //         }).catch(e => {
    //             console.log("error upload self declaration :", e)
    //         })
    //     }
    // };

    useEffect(() => {
        if (selectedFile) {
            const dataForm = new FormData()
            dataForm.append('file', selectedFile)

            if (selectedFile) {
                Services().post('/api/image/self-declaration', dataForm).then(({ data }) => {

                    setImgPath(data?.data?.storageFilePath)
                }).catch(e => {
                    console.log("error upload self declaration :", e)
                })
            }
        }
    }, [selectedFile])

    const onSubmit = (input) => {
        setIsLoadingSubmit(true);

        const id = params?.id;
        const requestBody = {
            ...input,
            selfDeclaration: imgPath,
            supplierPriceCommitment: input?.supplierPriceCommitment?.replaceAll('.', "")
        };

        const action = ({ requestBody, id = null }) => isCreate
            ? Services().post('/api/back-office/supplier/add', requestBody)
            : Services().post(`/api/back-office/supplier/edit`, { ...requestBody, id: parseInt(id) })

        action({ requestBody, id: params?.id })
            .then(() => {
                setIsLoadingSubmit(false);
                // handleUpload()
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: isCreate ? `Supplier baru berhasil ditambah` : `Supplier berhasil diubah` }
                })
                history.push(isCreate ? '/supplier' : `/supplier/${id}`);
            })
            .catch(e => {
                setIsLoadingSubmit(false);
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `Cannot update ${id}` } })
            })
    }

    if ((status === 'rejected' && error.code === 400) || (status === 'resolved' && data === null)) return <Error404 />
    return (status === 'resolved' || isCreate) && (
        <>
            <Row className="mb-4">
                <Col>
                    <div className="d-inline-block">
                        <h2>{isCreate ? "Tambah" : "Edit"} Supplier</h2>
                        <Breadcrumb data={dataBreadcrumb} />
                    </div>
                </Col>
            </Row>
            <Form id="penggunaForm" onSubmit={onSubmit} autoComplete="off" defaultValues={defaultValues}>
                <Card>
                    <CardHeader className="pb-0">
                        Informasi Supplier
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label htmlFor="supplierName" sm={3}>Nama Lengkap</Label>
                                    <Col sm={9}>
                                        <Input id="supplierName" validation={['required']} name="supplierName" type="text" placeholder="Ketik Nama Lengkap" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="supplierBankName" sm={3}>Nama Bank <span className='text-optional-grey'>(Opsional)</span></Label>
                                    <Col sm={9}>
                                        <Select
                                            name="supplierBankName"
                                            id="supplierBankName"
                                            placeholder="Pilih Nama Bank"
                                            options={bankList}
                                            isClearable={true}
                                        />
                                    </Col>
                                </FormGroup>

                                <NorekField />

                                <FormGroup row>
                                    <Label htmlFor="supplierPhone" sm={3}>Nomor Handphone</Label>
                                    <Col sm={9}>
                                        <Input id="supplierPhone" maxLength="15" validation={['required', 'phone', 'minLength[10]', 'maxLength[15]']} name="supplierPhone" type="text" placeholder="Ketik Nomor Handphone" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card>
                    <CardHeader className="pb-0">
                        Informasi Usaha
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <AddressForm />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card>
                    <CardHeader className="pb-0">
                        Komitment Supply
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label htmlFor="supplierOilAmountCommitment" sm={3}>Jumlah Minyak / Minggu</Label>
                                    <Col sm={9}>
                                        {/* <Input id="supplierOilAmountCommitment" validation={['required']} name="supplierOilAmountCommitment" type="text" placeholder="Ketik Jumlah Minyak / Minggu" autoComplete="off" /> */}

                                        <InputDecimal maxLength={5} max={999999} maxed={999999} validation={['required', `minLength[1]`, 'valueOfnull']} name="supplierOilAmountCommitment" type="text" placeholder="Ketik Jumlah Minyak / Minggu" autoComplete="off" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="supplierPriceCommitment" sm={3}>Harga Minyak / Kg</Label>
                                    <Col sm={9}>
                                        {/* <Input id="supplierPriceCommitment" validation={['required']} name="supplierPriceCommitment" type="text" placeholder="Ketik Harga Minyak" autoComplete="off" /> */}

                                        <InputDefaultPrice defaultPrice={defaultPrice?.price} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="supplierPickupDay" sm={3}>Hari Penjemputan</Label>
                                    <Col sm={9}>
                                        <Select
                                            name="supplierPickupDay"
                                            id="supplierPickupDay"
                                            placeholder="Pilih Hari Penjemputan"
                                            options={dayList}
                                            isClearable={true}
                                            validation={['required']}
                                            defaultValue={dayList[0]}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="pickupTime" sm={3}>Jam Penjemputan</Label>
                                    <Col sm={9}>
                                        <Select
                                            name="pickupTime"
                                            id="pickupTime"
                                            placeholder="Pilih Jam Penjemputan"
                                            options={timeList}
                                            isClearable={true}
                                            validation={['required']}
                                        />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="selfDeclaration" sm={3}>Self Declaration <span className='text-optional-grey'>(Opsional)</span></Label>
                                    <Col sm={9}>

                                        {!data?.supplyCommitment?.selfDeclaration ?
                                            <>
                                                <Button type="button" color="primary" className='font-weight-medium d-inline-flex align-items-center py-2 justify-content-center' style={{ minWidth: 132 }} onClick={handleButtonUpload}>
                                                    <img src={IconUpload} alt="maps" className='mr-2' />
                                                    Upload File

                                                    {/* hidden input for handle upload file */}
                                                    <input
                                                        type="file"
                                                        accept=".png, .jpeg, .jpg, .bmp"
                                                        ref={fileInputRef}
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileChange}
                                                    />
                                                </Button>

                                                {selectedFile && <span className='ml-2 text-primary'>
                                                    {selectedFile?.name}
                                                </span>}
                                            </>
                                            :
                                            <>

                                                <Button type="button" color="primary" className='font-weight-medium d-inline-flex align-items-center py-2 justify-content-center' style={{ minWidth: 132 }} onClick={handleButtonUpload} disabled={data?.supplierInformation?.statusSD === "verified"}>
                                                    <img src={IcReUpload} alt="maps" className='mr-2' />
                                                    Ganti File

                                                    {/* hidden input for handle upload file */}
                                                    <input
                                                        type="file"
                                                        accept=".png, .jpeg, .jpg, .bmp"
                                                        ref={fileInputRef}
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileChange}
                                                    />
                                                </Button>

                                                {data?.supplyCommitment?.selfDeclaration &&
                                                    <span className='ml-2 text-primary'>
                                                        {selectedFile ? selectedFile?.name : 'self-declaration.jpg'}
                                                    </span>
                                                }
                                            </>
                                        }


                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Card>
                    <CardHeader className="pb-0">
                        Supervisor
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup row>
                                    <Label htmlFor="branchId" sm={3}>Branch</Label>
                                    <Col sm={9}>
                                        <BranchField name="branchId" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="stockpointId" sm={3}>StockPoint</Label>
                                    <Col sm={9}>
                                        <StockpointField name="stockpointId" setParamsDefaultPrice={setParamsDefaultPrice} />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="userId" sm={3}>Employee ID</Label>
                                    <Col sm={9}>
                                        <EmployeeIdField name="userId" />
                                    </Col>
                                </FormGroup>
                                <FormGroup row>
                                    <Label htmlFor="userName" sm={3}>Nama Employee</Label>
                                    <Col sm={9}>
                                        <Input id="userName" validation={['required']} name="userName" type="text" placeholder="Ketik Nama Employee" autoComplete="off" disabled />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>

                <Row>
                    <Col className="text-right">
                        <Link to={(isCreate) ? "/supplier" : `/supplier/${params.id}`}>
                            <Button type="button" color="secondary" className="m-1" onClick={() => window.history.back()}>
                                Batal
                            </Button>
                        </Link>
                        <Button type="submit" color="primary" className="m-1" disabled={isLoadingSubmit}>
                            {isLoadingSubmit &&
                                <span className='mr-2'>
                                    <Spinner size="sm" />
                                </span>}
                            {(isCreate) ? 'Buat' : 'Simpan'}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

const AddressContext = createContext();
const AddressForm = () => {
    const { id } = useParams()
    const { setValue, getValues, watch } = useFormContext();

    const [showModal, setShowModal] = useState(false);
    const [addType, setAddType] = useState('')
    const [appliedStreet, setAppliedStreet] = useState(false);
    const [showChekbox, setShowChekbox] = useState(false);
    const [dataAddress, setDataAddress] = useState(null);
    const [loadingSearch, setLoadingSearch] = useState(false)

    const kategoryList = [{
        label: "Household (Rumah Tangga)",
        value: 1,
    }, {
        label: "HoReCa (Hotel, Restoran dan Cafe)",
        value: 2,
    }, {
        label: "Home Industry (Industri Rumah Tangga)",
        value: 3,
    }]

    const supplierAddress = getValues('supplierAddress');
    const supplierLat = getValues('supplierLatitude');
    const supplierLong = getValues('supplierLongitude');
    const supplierAddDetail = watch('detailSupplierAddress');

    useEffect(() => {
        if (supplierAddress && supplierLat && supplierLong && supplierAddDetail) {
            setShowChekbox(true)


            // if (appliedStreet) {
            //     setValue('pickupAddress', supplierAddress)
            //     setValue('pickupLat', supplierLat)
            //     setValue('pickupLong', supplierLong)
            //     setValue('detailPickupAddress', supplierAddDetail)
            // } else {
            //     setValue('pickupAddress', "")
            //     setValue('pickupLat', "")
            //     setValue('pickupLong', "")
            //     setValue('detailPickupAddress', "")
            // }

        } else {
            setShowChekbox(false)
        }
    }, [supplierAddress, supplierLat, supplierLong, supplierAddDetail, appliedStreet, setShowChekbox, setValue, getValues, showChekbox, id])

    const handleChecked = () => {
        setAppliedStreet(prevValue => !prevValue);

        if (!appliedStreet) {
            setValue('pickupAddress', supplierAddress)
            setValue('pickupLat', supplierLat)
            setValue('pickupLong', supplierLong)
            setValue('detailPickupAddress', supplierAddDetail)
        } else {
            setValue('pickupAddress', "")
            setValue('pickupLat', "")
            setValue('pickupLong', "")
            setValue('detailPickupAddress', "")
        }
    }

    return <>
        <AddressContext.Provider
            value={{
                setDataAddress,
                dataAddress,
                setLoadingSearch,
                loadingSearch
            }}
        >
            <FormGroup row>
                <Label htmlFor="outletName" sm={3}>Nama Outlet</Label>
                <Col sm={9}>
                    <Input id="outletName" validation={['required']} name="outletName" type="text" placeholder="Ketik Nama Outlet" autoComplete="off" />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="iapoutletCode" sm={3}>Kode Outlet IAP <span className='text-optional-grey'>(Opsional)</span></Label>
                <Col sm={9}>
                    <Input id="iapoutletCode" name="iapoutletCode" type="text" placeholder="Ketik Kode Outlet" autoComplete="off" />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="supplierCategory" sm={3}>Kategori</Label>
                <Col sm={9}>
                    <Select
                        name="supplierCategory"
                        id="supplierCategory"
                        placeholder="Pilih Kategori"
                        options={kategoryList}
                        isClearable={true}
                        validation={['required']}
                    />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="supplierAddress" sm={3}>Alamat</Label>
                <Col sm={9} className="d-flex">
                    <div className='mr-2'>
                        <Button type="button" onClick={() => { setShowModal(!showModal); setAddType('supplier'); }} color="primary" className='font-weight-medium d-inline-flex align-items-center py-2' style={{ minWidth: 132, height: 42 }}>
                            <img src={IconPinPoint} alt="maps" className='mr-2' />
                            Pilih Lokasi
                        </Button>
                    </div>
                    <Input id="supplierAddress" validation={['required']} name="supplierAddress" type="text" placeholder="Detail Alamat" autoComplete="off" disabled />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="supplierLatitude" sm={3}>Latitude</Label>
                <Col sm={9}>
                    <Input id="supplierLatitude" validation={['required']} name="supplierLatitude" type="text" placeholder="Latitude" autoComplete="off" disabled />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="supplierLongitude" sm={3}>Longitude</Label>
                <Col sm={9}>
                    <Input id="supplierLongitude" validation={['required']} name="supplierLongitude" type="text" placeholder="Longitude" autoComplete="off" disabled />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="detailSupplierAddress" sm={3}>Detail Alamat</Label>
                <Col sm={9}>
                    <TextArea
                        id="detailSupplierAddress"
                        rows="4"
                        name="detailSupplierAddress"
                        validation={['required']}
                        placeholder="Ketik Detail Alamat"
                    />
                </Col>
            </FormGroup>

            {!id && showChekbox &&
                <FormGroup row className='justify-content-end'>
                    <Col sm={9}>
                        <div className='d-inline-block'>
                            <label htmlFor="useSameAddress" className="d-flex align-items-center">
                                <input id="useSameAddress" name="useSameAddress" type="checkbox" checked={appliedStreet} onChange={() => handleChecked()} />
                                <div className='ml-2'>
                                    Sama dengan alamat usaha
                                </div>
                            </label>
                        </div>
                    </Col>
                </FormGroup>
            }

            <FormGroup row>
                <Label htmlFor="pickupAddress" sm={3}>Alamat Penjemputan</Label>
                <Col sm={9} className="d-flex">
                    <div className='mr-2'>
                        <Button type="button" disabled={appliedStreet === true} color="primary" className='font-weight-medium d-inline-flex align-items-center py-2' style={{ minWidth: 132, height: 42 }} onClick={() => { setShowModal(!showModal); setAddType('pickup'); }}>
                            <img src={IconPinPoint} alt="maps" className='mr-2' />
                            Pilih Lokasi
                        </Button>
                    </div>
                    <Input id="pickupAddress" validation={['required']} name="pickupAddress" type="text" placeholder="Detail Alamat Penjemputan" autoComplete="off" disabled />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="pickupLat" sm={3}>Latitude</Label>
                <Col sm={9}>
                    <Input id="pickupLat" validation={['required']} name="pickupLat" type="text" placeholder="Latitude" autoComplete="off" disabled />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="pickupLong" sm={3}>Longitude</Label>
                <Col sm={9}>
                    <Input id="pickupLong" validation={['required']} name="pickupLong" type="text" placeholder="Longitude" autoComplete="off" disabled />
                </Col>
            </FormGroup>
            <FormGroup row>
                <Label htmlFor="detailPickupAddress" sm={3}>Detail Alamat Penjemputan</Label>
                <Col sm={9}>
                    <TextArea
                        id="detailPickupAddress"
                        rows="4"
                        name="detailPickupAddress"
                        validation={['required']}
                        placeholder="Ketik Detail Alamat"
                    />
                </Col>
            </FormGroup>

            <ModalPilihLocation open={showModal} setOpen={setShowModal} addressType={addType} />
        </AddressContext.Provider>
    </>
}

const NorekField = () => {
    const { watch } = useFormContext();
    const watchBank = watch("supplierBankName")

    // useEffect(() => {
    //     setValue("supplierBankAccount", "")
    // }, [setValue])

    return watchBank ? <>
        <FormGroup row>
            <Label htmlFor="supplierBankAccount" sm={3}>Nomor Rekening</Label>
            <Col sm={9}>
                <Input id="supplierBankAccount" validation={['required']} name="supplierBankAccount" type="text" placeholder="Ketik Nomor Rekening" autoComplete="off" />
            </Col>
        </FormGroup>
    </> : null

}

const BranchField = ({ name }) => {
    const { id } = useParams()
    const { setValue } = useFormContext();
    const { branchId: isBranchId } = useSelector(state => state.homesidemenu.profile);

    useEffect(() => {
        if (!id && isBranchId > 0) {
            setValue("branchId", isBranchId)
        }
    }, [setValue, isBranchId, id])

    const { data, loading } = useFetch(
        `/api/back-office/branch/all`,
        { unpaged: true },
        useCallback(data => ({
            data: data?.data?.content
        }), []),
        { onMount: true }
    );

    return (
        <Select
            name={name}
            id={name}
            placeholder="Pilih Branch"
            isLoading={loading}
            options={data?.map(item => ({ label: item?.name, value: item?.id }))}
            isClearable={true}
            validation={['required']}
            onChange={(itemBranch) => {
                if (itemBranch) {
                    setValue("branchId", itemBranch?.value);
                    setValue("stockpointId", null);
                }
                else {
                    setValue("stockpointId", null)
                }
            }}
        />
    )

}

const StockpointField = ({ name, setParamsDefaultPrice }) => {
    const { watch, setValue } = useFormContext();
    const { id } = useParams()
    const branchId = watch('branchId');
    const { stockpointId: isStockpointId } = useSelector(state => state.homesidemenu.profile);

    const { data, loading, setParams: fetchParamsSpo } = useFetch(
        `/api/back-office/stockpoint/all`,
        currentParams => ({
            unpaged: true
        }),
        useCallback(data => ({
            data: data?.data?.content,
        }), [])
    );

    useEffect(() => {
        if (branchId) {
            fetchParamsSpo(prev => ({
                ...prev,
                branch: branchId
            }))
        }
    }, [branchId, fetchParamsSpo]);

    useEffect(() => {
        if (!id && isStockpointId > 0) {
            setValue("stockpointId", isStockpointId)
        }
    }, [setValue, isStockpointId, id])

    return (
        <Select
            id={name}
            name={name}
            placeholder="Pilih Stock Point"
            isLoading={loading}
            options={data?.map(item => ({ label: item?.name, value: item?.id }))}
            isClearable={true}
            isDisabled={!branchId}
            validation={['required']}
            onChange={(val) => {
                if (val) setParamsDefaultPrice(prev => ({
                    ...prev,
                    stockpoint: val?.value
                }))
                else setParamsDefaultPrice(prev => ({
                    ...prev,
                    stockpoint: ''
                }))
            }}
        />
    )
}

const EmployeeIdField = ({ name }) => {
    const { setValue, watch, clearErrors } = useFormContext();
    const stockpoint = watch('stockpointId');
    const { data: userData, loading: userLoading, setParams: setParamEmployeeId } = useFetch(
        `/api/back-office/user/get-users-from-stockpoint`,
        (currentParams) => ({
            ...currentParams,
            unpaged: true,
            stockpointId: 0
        }),
        useCallback(data => ({
            data: data?.data?.content,
        }), [])
    );
    useEffect(() => {
        if (stockpoint) {
            setParamEmployeeId(prev => ({
                ...prev,
                stockpointId: stockpoint
            }))
        }
    }, [stockpoint, setParamEmployeeId])
    return (
        <Select
            id={name}
            name={name}
            placeholder="Pilih Employee ID"
            isLoading={userLoading}
            isDisabled={!stockpoint}
            validation={['required']}
            options={userData?.map(item => ({ label: item?.employeeId, value: item?.employeeId, name: item?.name }))}
            onChange={(user) => {
                if (user) {
                    setValue('userName', user?.name);
                    clearErrors('userName')
                }
                else {
                    setValue('userName', '');
                }
            }}
            isClearable={true}
        />
    )
}

const InputDefaultPrice = ({ defaultPrice }) => {

    const { id } = useParams()
    const { setValue, getValues, clearErrors } = useFormContext();

    const getDefaultPrice = getValues('supplierPriceCommitment')

    useEffect(() => {
        if (!id && defaultPrice !== undefined && getDefaultPrice !== defaultPrice) {
            setValue('supplierPriceCommitment', Intl.NumberFormat('id-ID').format(defaultPrice));
            clearErrors('supplierPriceCommitment');
        }
    }, [id, setValue, defaultPrice, getDefaultPrice, clearErrors])


    return (
        <InputDecimal
            isCurrency={true}
            maxLength={6}
            max={999999}
            maxed={999999}
            validation={['required', `minLength[4]`, 'valueOfnull']}
            name="supplierPriceCommitment"
            type="text"
            placeholder="Ketik Harga Minyak"
            autoComplete="off"
        />
    )
}

const ModalPilihLocation = ({ open, setOpen, addressType }) => {
    const { dataAddress, setDataAddress, loadingSearch } = useContext(AddressContext);
    const { setValue } = useFormContext();

    const pilihLocation = () => {
        setOpen(!open)

        if (addressType === 'supplier') {
            setValue('supplierAddress', dataAddress?.address)
            setValue('supplierLatitude', dataAddress?.lat)
            setValue('supplierLongitude', dataAddress?.lon)
        } else if (addressType === 'pickup') {
            setValue('pickupAddress', dataAddress?.address)
            setValue('pickupLat', dataAddress?.lat)
            setValue('pickupLong', dataAddress?.lon)
        }
    }

    useEffect(() => {
        if (!open) setDataAddress(null)

    }, [setDataAddress, open])


    return (
        <Modal size='lg' isOpen={open} className="modal-dialog-centered">
            <ModalBody className="confirmation-modal">
                <div className='d-flex justify-content-between'>
                    <div className='pb-3' style={{ fontWeight: 600, fontSize: 16 }}>Pilih Lokasi</div>
                    <div className='text-right'>
                        <span onClick={() => setOpen(!open)} style={{ cursor: "pointer" }}><img src={cancel} alt="pict-cancel" /></span>
                    </div>
                </div>
                <div className='d-flex flex-column' style={{ gap: "1rem" }}>
                    <div className='d-flex flex-column' style={{ gap: 2 }}>
                        <SearchLocation />
                    </div>
                    <div>
                        <MapsDraggable mapsContext={AddressContext} />
                    </div>
                    <div className='d-flex justify-content-end' style={{ gap: "6px" }}>
                        <Button size='md' type="submit" color="primary" onClick={pilihLocation} disabled={loadingSearch || !dataAddress?.address}>
                            Pilih Lokasi
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

const SearchLocation = () => {
    const { dataAddress, setDataAddress, loadingSearch, setLoadingSearch } = useContext(AddressContext);
    const { hasFetch } = useAction();

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <div className='bg-primary justify-content-center align-items-center d-flex rounded' style={{ height: 32, width: 32 }}>
                    <i className="fa fa-search text-white"></i>
                </div>
            </components.DropdownIndicator>
        );
    };

    const loadOptions = debounce(async (inputValue, callback) => {
        setLoadingSearch(true)
        try {
            const raw = await Services().post('/api/open/map/place-autocomplete', {
                location: inputValue,
            })
            const getData = raw?.data?.data?.content;

            if (getData) setLoadingSearch(false)

            callback(
                getData.map(data => ({ label: data?.address, value: { lat: data?.lat, lon: data?.lon }, data: data }))
            )
        } catch (err) {
            setLoadingSearch(false)
            hasFetch({
                type: 'ALERT_TOAST_ERROR',
                payload: { message: err.message }
            })
        }
    }, 1500);

    return (
        <AsyncSelect
            id="location"
            name="location"
            loadOptions={loadOptions}
            validation={['required']}
            placeholder={`Ketik alamat`}
            cacheOptions
            classNamePrefix="select"
            components={{ DropdownIndicator }}
            onChange={(e) => {
                if (e) setDataAddress(e.data)
                else setDataAddress(null)
            }}
            value={dataAddress ? {
                label: dataAddress?.address,
                value: ""
            } : null}
            isLoading={loadingSearch}
        />
    )
}

export default App