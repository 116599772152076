import React from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ data }) => {
    return (
        <>
            {
                data.map(({ to, label }, key) => (
                    <span key={key} className="breadcrumb-custom">
                        {
                            (to) ? <Link to={to} className="breadcrumb-link">{label}</Link> : label
                        }
                        {(key !== data.length - 1) ? <span className="breadcrumb-arrow">&gt;</span> : null}
                    </span>
                ))
            }
        </>
    )
}

export default Breadcrumb