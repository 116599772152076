import ModalPage from 'components/Modal';
import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Label, Row, Spinner } from 'reactstrap';
import Form from 'components-form/Form';
import Input from 'components-form/Input';
import { useFormContext } from 'react-hook-form';
import { Services } from 'service';
import { useAction } from 'hooks';
import { useReactToPrint } from 'react-to-print';
import cancel from 'assets/svg/ic_cancel.svg';
import { ModalConfirm } from '../detail';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
// import TextInput from 'components-form/textInput';

const CustomCounterInput = ({ min = 10, max = 20, bind = null, defaultCount = 0, ...props }) => {
    const [count, setCount] = useState(defaultCount);
    const { setValue, getValues } = useFormContext();
    const { weight18L, weight20L, raw } = useContext(Counter);
    const handleIncrement = (name) => {
        if (name === "qty20L" && count < bind[1]) {
            setCount(count + 1);
            setValue(name, count + 1);
        }
        if (name === "qty18L" && count < bind[0]) {
            setCount(count + 1);
            setValue(name, count + 1);
        }
    };

    const handleDecrement = (name) => {
        if (count > 0) {
            setCount(count - 1);
            setValue(name, count - 1)
        }
    };

    const handleStepChange = (event) => {
        const { name, value } = event.target;
        if (name === "qty20L" && !isNaN(value) && Number(value) <= bind[1]) {
            setValue(name, value);
            setCount(Number(value));
        }
        if (name === "qty18L" && !isNaN(value) && Number(value) <= bind[0]) {
            setValue(name, value)
            setCount(Number(value));
        }
    };
    const calculateValue = () => {
        const { outletDone18L, outletDone20L,
            // adjustment18, adjustment20
        } = getValues();
        let x = 0
        x = parseFloat(outletDone18L) + parseFloat(outletDone20L);
        // + adjustment18 + adjustment20
        return x <= 0 ? 0 : x.toFixed(1);
    };
    const kerugianF = () => {
        const { qty18L, x } = getValues()
        const nilai18L = bind[0];
        let f = x;
        if (props?.name === "qty18L" && nilai18L >= qty18L) {
            const x = (qty18L - nilai18L)
            const a = ((x * -1) * weight18L);
            f = raw?.averageOilPrice * a
            setValue("x", f);
        }
    }
    const kerugianG = () => {
        const { qty20L, y } = getValues()
        const nilai20L = bind[1];
        let f = y;
        if (props?.name === "qty20L" && nilai20L >= qty20L) {
            const x = (qty20L - nilai20L)
            const a = ((x * -1) * weight20L);
            f = raw?.averageOilPrice * a
            setValue("y", f);
        }
    }
    useEffect(() => {
        const { qty18L, qty20L } = getValues()
        if (props.name === "qty18L") {
            setValue("outletDone18L", (parseFloat(weight18L) * qty18L).toFixed(1));
        }
        else if (props.name === "qty20L") {
            setValue("outletDone20L", (parseFloat(weight20L) * qty20L).toFixed(1));
        }
        setValue('oilAmount', calculateValue())
        kerugianF()
        kerugianG()
    })

    useEffect(() => {
        const { x, y, qty20L, qty18L } = getValues()
        const money = (number) => new Intl.NumberFormat('id-ID', { style: "currency", currency: "IDR" }).format(number)
        const nilai18L = bind[0];
        const nilai20L = bind[1];
        if (qty20L > nilai20L || qty18L > nilai18L) {
            setValue("kerugian", money(0));
        } else {
            setValue("kerugian", money((x + y).toFixed(1)));
        }
    })
    return (
        <div className="wrapper-input-counter">
            <div className="group-counter">
                <button className='button-counter-up' type='button' onClick={() => handleIncrement(props.name)}>
                    <i className="fa fa-chevron-up" />
                </button>
                <button className='button-counter-down' type='button' onClick={() => handleDecrement(props.name)}>
                    <i className="fa fa-chevron-down" />
                </button>
            </div>
            <Input
                {...props}
                type="text"
                defaultValue={count}
                onChange={handleStepChange}
            />
        </div >
    );
}
export const Counter = createContext()
const ModalFormsQRSolve = () => {
    const { open, close, raw } = useContext(ModalConfirm);
    const isWeight18 = raw?.oilPickupSummary?.containerPickedUpDetails?.filter(item => item.type === "jeriken").find(item => item?.volume === "18");
    const isWeight20 = raw?.oilPickupSummary?.containerPickedUpDetails?.filter(item => item.type === "jeriken").find(item => item?.volume === "20");
    const isWeightAdj18 = raw?.oilPickupSummary?.containerPickedUpDetails?.filter(item => item.type === "adjustment").find(item => item?.volume === "18");
    const isWeightAdj20 = raw?.oilPickupSummary?.containerPickedUpDetails?.filter(item => item.type === "adjustment").find(item => item?.volume === "20");
    const { hasFetch } = useAction()
    const [state, setState] = useState(null)
    const [currPage, setCurrPage] = useState("form")
    const [openQr, setOpenQR] = useState(null)
    const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
    const [isLoadingSettlement, setIsLoadingSettlement] = useState(false);
    const quantity18L = isWeight18.qty;
    const weight18L = isWeight18.weight
    const quantity20L = isWeight20.qty;
    const weight20L = isWeight20.weight;
    const adjJeriken18 = isWeightAdj18.qty;
    const adjJeriken20 = isWeightAdj20.qty;
    const defaultValues = {
        oilAmount: state?.oilAmount ?? raw?.oilPickupSummary?.oilPickedUpWeight ?? 0,
        qty18L: state?.qty18L ?? quantity18L,
        qty20L: state?.qty20L ?? quantity20L,
        outletDone18L: state?.outletDone18L ?? weight18L ?? 0,
        outletDone20L: state?.outletDone20L ?? weight20L ?? 0,
        adjustment18: state?.adjustment18 ?? adjJeriken18 ?? 0,
        adjustment20: state?.adjustment20 ?? adjJeriken20 ?? 0,
        moneyLimit: state?.moneyLimit ?? ""
    }
    const history = useHistory()
    const handleSubmit = (values) => {
        setIsLoadingConfirm(true);
        const str18L = values?.qty18L - raw?.oilPickupSummary?.containerPickedUpDetails?.filter(item => item.type === "jeriken").find(item => item?.volume === "18")?.qty
        const str20L = values?.qty20L - raw?.oilPickupSummary?.containerPickedUpDetails?.filter(item => item.type === "jeriken").find(item => item?.volume === "20")?.qty
        const isObject18L = {
            ...raw?.oilPickupSummary?.containerPickedUpDetails.filter(item => item.type === "adjustment")?.find(item => item.volume === "18"),
            qty: parseFloat(str18L) + parseFloat(adjJeriken18),
            type: "adjustment",
            weight: values?.outletDone18L
        }
        const isObject20L = {
            ...raw?.oilPickupSummary?.containerPickedUpDetails.filter(item => item.type === "adjustment")?.find(item => item.volume === "20"),
            qty: parseFloat(str20L) + parseFloat(adjJeriken20),
            type: "adjustment",
            weight: values?.outletDone20L
        }
        Services().post('/api/back-office/trip-plan/pickup/confirm', {
            id: raw?.id,
            oilContainerBeans: [isObject20L, isObject18L]
        }).then((result) => {
            setIsLoadingConfirm(false);
            setState({
                qty18L: values?.qty18L,
                qty20L: values?.qty20L,
                ...result?.data?.data
            });
            setCurrPage("preview")
        }).catch(e => {
            setIsLoadingConfirm(false);
            hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `No Data` } })
        });
    }
    const handlePostServices = () => {
        setIsLoadingSettlement(true);
        setOpenQR(true)
        const str18L = state?.qty18L - raw?.oilPickupSummary?.containerPickedUpDetails?.filter(item => item.type === "jeriken").find(item => item?.volume === "18")?.qty
        const str20L = state?.qty20L - raw?.oilPickupSummary?.containerPickedUpDetails?.filter(item => item.type === "jeriken").find(item => item?.volume === "20")?.qty
        const isObject18L = {
            ...raw?.oilPickupSummary?.containerPickedUpDetails.filter(item => item.type === "adjustment")?.find(item => item.volume === "18"),
            qty: parseFloat(str18L) + parseFloat(adjJeriken18),
            type: "adjustment",
            weight: state?.outletDone18L
        }
        const isObject20L = {
            ...raw?.oilPickupSummary?.containerPickedUpDetails.filter(item => item.type === "adjustment")?.find(item => item.volume === "20"),
            qty: parseFloat(str20L) + parseFloat(adjJeriken20),
            type: "adjustment",
            weight: state?.outletDone20L
        }
        Services().post('/api/back-office/trip-plan/pickup-settlement', {
            id: raw?.id,
            paidAmount: raw?.cashPickupSummary?.completeSalesPrice,
            completePickup: raw?.completedOutlet,
            compensationAmount: state?.compensationAmount,
            totalWeight: state?.totalWeight,
            oilContainerBeans: [isObject20L, isObject18L],
            restMoney: raw?.cashPickupSummary?.assignmentCash - raw?.cashPickupSummary?.completeSalesPrice,
            // compensationAmount: parseFloat((parseFloat((raw?.oilPickupSummary?.oilPickedUpWeight - state?.oilAmount).toFixed(1)) * raw?.cashPickupSummary?.averageOilPrice).toFixed(1))

        }, { responseType: "arraybuffer" })
            .then((result) => {
                setIsLoadingSettlement(false);
                const blob = new Blob([result.data], { type: "image/jpg" });
                const uris = URL.createObjectURL(blob);
                setOpenQR(uris)
                setCurrPage("QR")
                setState(null)
                hasFetch({
                    type: 'ALERT_TOAST_SUCCESS',
                    payload: { message: `QR Berhasil Dibuat` }
                })
            }).catch(e => {
                setIsLoadingSettlement(false);
                hasFetch({ type: 'ALERT_TOAST_ERROR', payload: { message: e?.message ?? `QR Gagal Dibuat` } })
            })
    }
    const refs = useRef(null)
    const handlePrint = useReactToPrint({
        content: () => refs.current
    });
    const money = (number) => new Intl.NumberFormat('id-ID', { style: "currency", currency: "IDR" }).format(number)
    return (
        <Counter.Provider
            value={{
                weight18L,
                weight20L,
                raw,
                state
            }}
        >
            <ModalPage
                isOpen={open}
                handleClose={close}
                component={<>
                    {currPage === "QR" && <div className='text-right'>
                        <span onClick={() => {
                            history.go(0)
                            setCurrPage('form')
                            close()
                            setState(null)
                            setOpenQR(null)
                        }} style={{ cursor: "pointer" }}><img src={cancel} alt="pict-cancel" /></span>
                    </div>}
                    {currPage !== "QR" && <div className='moda-password-title'>Konfirmasi Penyelesaian</div>}
                    {currPage === "form" && <Form onSubmit={handleSubmit} className="w-100" id="modalForm" autoComplete="off" defaultValue={defaultValues}>
                        <Row className="px-3">
                            <Row>
                                <Col md={6}>
                                    <Row className='pb-2' >
                                        <Col className='pb-2' md={12}>
                                            <Label>Jumlah Jeriken 18L</Label>
                                            <CustomCounterInput
                                                type="text"
                                                id="test"
                                                name="qty18L"
                                                placeholder="0"
                                                bind={[quantity18L, quantity20L]}
                                                defaultCount={defaultValues?.qty18L}
                                                autoComplete="off"
                                                validation={['required']}
                                            />
                                        </Col>
                                        {/* <Col md={12}>
                                            <Label>Dalam Ukuran Kg</Label>
                                            <TextInput
                                                id="outletDone"
                                                disabled={true}
                                                name="outletDone18L"
                                                defaultValue={(parseFloat(defaultValues?.outletDone18L) * quantity18L).toFixed(1).toString()}
                                                placeholder="0"
                                                autoComplete="off"
                                            />
                                        </Col> */}
                                    </Row>
                                </Col>
                                <Col md={6}>
                                    <Row className='pb-2' >
                                        <Col className='pb-2' md={12}>
                                            <Label>Jumlah Jeriken 20L</Label>
                                            <CustomCounterInput
                                                type="text"
                                                id="test"
                                                name="qty20L"
                                                placeholder="0"
                                                autoComplete="off"
                                                bind={[quantity18L, quantity20L]}
                                                defaultCount={defaultValues?.qty20L}
                                                validation={['required']}
                                            />
                                        </Col>
                                        {/* <Col md={12}>
                                            <Label>Dalam Ukuran Kg</Label>
                                            <TextInput
                                                id="outletDone"
                                                disabled={true}
                                                name="outletDone20L"
                                                defaultValue={(parseFloat(defaultValues?.outletDone20L) * quantity20L).toFixed(1).toString()}
                                                placeholder="0"
                                                autoComplete="off"
                                            />
                                        </Col> */}
                                    </Row>
                                </Col>
                            </Row>
                        </Row>
                        <Row className='px-2'>
                            <Col md={6} className="pb-2 px-2">
                                <Label>Penyesuaian Jeriken 18 L</Label>
                                <Input
                                    id="outletDone"
                                    disabled={true}
                                    name="adjustment18"
                                    defaultValue={defaultValues?.adjustment18}
                                    type="text"
                                    placeholder="0"
                                    autoComplete="off"
                                />
                            </Col>
                            <Col md={6} className="pb-2 px-2">
                                <Label>Penyesuaian Jeriken 20 L</Label>
                                <Input
                                    id="outletDone"
                                    disabled={true}
                                    name="adjustment20"
                                    defaultValue={defaultValues?.adjustment20}
                                    type="text"
                                    placeholder="0"
                                    autoComplete="off"
                                />
                            </Col>
                        </Row>
                        {/* <Row>
                            <Col md={12}>
                                <Label>Total Minyak Diangkut (Kg)</Label>
                                <div className='d-flex'>
                                    <h3>
                                        <Jeriken18L />
                                    </h3>
                                    <h3 className='px-1'>/</h3>
                                    <h5 className='mb-1' style={{ fontWeight: 500, display: "flex", alignItems: "center" }}>{raw?.oilPickupSummary?.oilPickedUpWeight}</h5>
                                </div>
                            </Col>
                        </Row> */}
                        <Col>
                            <div className='text-right mt-4'>
                                <Button onClick={() => {
                                    close()
                                    setState(null)
                                }} outline size='sm' type="button" color="primary" >
                                    Batal
                                </Button>
                                <TugaskanDriver isLoadingConfirm={isLoadingConfirm} />
                            </div>
                        </Col>
                    </Form>}
                    {
                        (
                            <Row style={{ display: currPage === "preview" ? "block" : "none" }}>
                                <Col md={12} className="pb-3">
                                    <Label>Jumlah Outlet Selesai</Label>
                                    <h4>{raw?.completedOutlet}/{raw?.totalOutlet}</h4>
                                </Col>
                                <Col md={12} className="pb-3">
                                    <Row>
                                        <Col md={6}>
                                            <Label>Jumlah Jeriken 18</Label>
                                            <h4>{state?.qty18L ?? 0}/{isWeight18?.qty}</h4>
                                        </Col>
                                        <Col md={6}>
                                            <Label>Jumlah Jeriken 20</Label>
                                            <h4>{state?.qty20L ?? 0}/{isWeight20?.qty}</h4>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={12} className="pb-3">
                                    <Label>Jumlah Minyak Diangkut</Label>
                                    <h4>{state?.oilAmount ?? state?.totalWeight}</h4>
                                </Col>
                                <Col md={12} className="pb-3">
                                    <Label>Jumlah Uang yang Dibayarkan</Label>
                                    <h4>{money(raw?.cashPickupSummary?.completeSalesPrice)}</h4>
                                </Col>
                                <Col md={12} className="pb-3">
                                    <Row>
                                        <Col md={6}>
                                            <Label>Jumlah Sisa Uang <span style={{ fontSize: 10, position: "relative", top: "-4px", color: "#9C9DA6" }}></span></Label>
                                            <h4>{money(raw?.cashPickupSummary?.assignmentCash - raw?.cashPickupSummary?.completeSalesPrice)}</h4>
                                        </Col>
                                        <Col md={6}>
                                            <Label>Ganti Rugi <span style={{ fontSize: 10, position: "relative", top: "-4px", color: "#9C9DA6" }}></span></Label>
                                            {/* <h4>{money(((raw?.oilPickupSummary?.oilPickedUpWeight - state?.oilAmount) * raw?.cashPickupSummary?.averageOilPrice))}</h4> */}
                                            <h4>{money(state?.compensationAmount)}</h4>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col md={12} className="pb-3">
                                    <Label>Total Setor Kas <span style={{ fontSize: 10, position: "relative", top: "-4px", color: "#9C9DA6" }}>(Jika ada sisa)</span></Label>
                                    <h4>{money(state?.totalSettlementAmount)}</h4>
                                    {/* <h4>{money((raw?.cashPickupSummary?.assignmentCash - raw?.cashPickupSummary?.completeSalesPrice) + ((raw?.oilPickupSummary?.oilPickedUpWeight - state?.oilAmount) * raw?.cashPickupSummary?.averageOilPrice))}</h4> */}
                                </Col>
                                <Col>
                                    <div className='text-right mt-4'>
                                        <Button onClick={() => setCurrPage("form")} outline size='sm' type="button" color="primary" >
                                            Kembali
                                        </Button>
                                        <Button onClick={() => handlePostServices()} size='sm' type="button" color={"primary"} disabled={isLoadingSettlement}>
                                            {isLoadingSettlement &&
                                                <span className='mr-2'>
                                                    <Spinner size="sm" />
                                                </span>}
                                            Konfirmasi
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        )
                    }
                    {
                        currPage === "QR" && (
                            <Row className='justify-content-center align-items-center flex-column'>
                                <div className='moda-password-title align-items-center m-0'>Qr Code Penugasan</div>
                                <img id='print-out' ref={refs} style={{ maxWidth: "100%" }} src={openQr} alt="pict-qr" />
                                <Button
                                    onClick={handlePrint}
                                    type="button" outline color='primary'>
                                    <i className='fa fa-print mr-2'></i>
                                    Print Rute Jemput
                                </Button>
                            </Row>
                        )
                    }
                </>
                }
            />
        </Counter.Provider>
    )
}
// const Jeriken18L = () => {
//     return <TextInput
//         id="outletDone"
//         disabled={true}
//         name="oilAmount"
//         // defaultValue={(parseFloat(defaultValues?.outletDone20L) * quantity20L).toFixed(1).toString()}
//         placeholder="0"
//         autoComplete="off"
//     />
// }
export const TugaskanDriver = ({ isLoadingConfirm }) => {
    const { formState: { isValid } } = useFormContext();
    return (
        <Button disabled={!isValid || isLoadingConfirm} size='sm' type="submit" color={!isValid ? "secondary" : "primary"} >
            {isLoadingConfirm &&
                <span className='mr-2'>
                    <Spinner size="sm" />
                </span>}
            Konfirmasi
        </Button>
    )
}

export default ModalFormsQRSolve