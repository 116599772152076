import React, { Fragment, useMemo } from "react";
import { Card, CardBody, CardHeader, Col, FormGroup, Label } from "reactstrap";

import IcEmptyHistory from "assets/svg/ic_empty_change_history.svg";
import { IdrFormat, capitalize, parseValue } from "helper";

import { editStatusOptions, formatDate } from "../constants";

const TabEditHistory = ({ data, referenceCode }) => (data?.length > 2
    ? <MultipleEdit data={data} referenceCode={referenceCode} />
    : data?.length === 2
        ? <EditedOnce data={data} />
        : <NoHistory />);

const NoHistory = () => (
    <Card body>
        <img src={IcEmptyHistory} alt="Empty icon" style={{ height: "200px" }} />
        <p className="font-weight-bold text-20 text-black-50 text-center pb-4">
            Belum ada riwayat perubahan
        </p>
    </Card>
);

const EditedOnce = ({ data }) => {
    const [latestEdit, originalData] = data;
    
    return (
        <>
            <LatestEditCard data={latestEdit} />
            <OriginalCard data={originalData} />
        </>
    );
}

const MultipleEdit = ({ data, referenceCode }) => {
    const [latestEdit, restHistory, originalData] = useMemo(
        () => ([
            data.at(0),
            data.slice(1, data.length - 1),
            data.at(-1)
        ]),
        [data]
    );

    return (
        <>
            <LatestEditCard data={latestEdit} />
            <Card>
                <CardHeader className="pb-0">Data Sebelum Perubahan</CardHeader>
                <CardBody>
                    {restHistory.map((it) => (
                        <Fragment key={it.reffNoChg}>
                            <RowInfo label="No. Tiket Perubahan" value={it.reffNoChg} />
                            <RowInfo label="Perubahan ke" value={it.perubahanKe} />
                            <RowInfo label="Status" value={it.status} format={formatStatus} />
                            <ChangesInfo data={it} />
                            <RowInfo label="Tanggal Perubahan" value={it.createdDate} format={formatDate} />
                            <RowInfo label="Diubah Oleh" value={it.createdBy} format={capitalize} />
                            <hr />
                        </Fragment>
                    ))}
                    <RowInfo label="No. Tiket" value={referenceCode} />
                    <RowInfo label="Perubahan ke" value={String(originalData?.perubahanKe)} />
                    <ChangesInfo data={originalData} />
                </CardBody>
            </Card>
        </>
    );
}

const OriginalCard = ({ data }) => (
    <Card>
        <CardHeader className="pb-0">Data Sebelum Perubahan</CardHeader>
        <CardBody>
            <RowInfo label="Perubahan ke" value={data?.perubahanKe} />
            <ChangesInfo data={data} />
        </CardBody>
    </Card>
);

const formatStatus = (status) => {
    const editStatus = editStatusOptions.find(({ value }) => value === status);

    const {
        label = capitalize(status),
        textClass,
        format
    } = editStatus ?? {};

    return format?.(label) ?? (
        <span className={`font-weight-bold ${textClass ?? ""}`}>
            {label}
        </span>
    );
};

const LatestEditCard = ({ data }) => (
    <Card>
        <CardBody>
            <CardHeader className="px-0 pt-0 pb-3">Informasi Perubahan Pembelian Langsung</CardHeader>
            <RowInfo label="Status" value={data?.status} format={formatStatus} />
            <RowInfo label="No. Tiket Perubahan" value={data?.reffNoChg} />
            <RowInfo label="Perubahan ke" value={data?.perubahanKe} />
            <RowInfo label="Tanggal Perubahan" value={data?.createdDate} format={formatDate} />
            <RowInfo label="Diubah Oleh" value={data?.createdBy} format={capitalize} />
            <hr />
            <CardHeader className="px-0 pt-0 pb-3">Data Perubahan</CardHeader>
            <ChangesInfo data={data} />
        </CardBody>
    </Card>
);

const ChangesInfo = ({ data }) => (
    <>
        <RowInfo label="Jumlah Jeriken 18L" value={data?.totalContainer18} />
        <RowInfo label="Jumlah Jeriken 20L" value={data?.totalContainer20} />
        <RowInfo label="Estimasi Jumlah Kg" value={data?.totalOilWeight} format={(value) => `${value} Kg`} />
        <RowInfo label="Total Pembelian (Rp)" value={data?.purchasePrice} format={IdrFormat} />
    </>
);

const RowInfo = ({
    label,
    value,
    format = (value) => value,
}) => (
    <FormGroup row>
        <Label sm={4}>{label}</Label>
        <Col sm={8}>
            : {parseValue(value, format)}
        </Col>
    </FormGroup>
);

export default TabEditHistory;

