import React, { useCallback, useState } from 'react';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { Breadcrumb } from 'components';
import waterOrange from 'assets/svg/ic_droplet-fill-orange.svg';
import waterGreen from 'assets/svg/ic_droplet-fill-green.svg';
import ModalForms from './component/modal.form';
import { useFetch } from 'hooks';

const jerikenBean = (volume, data) => data?.find(itemJeriken => itemJeriken?.volume === volume);

const Jeriken = props => {
    document.title = props.title;
    const dataBreadcrumb = [
        { to: '#', label: 'Konfigurasi Jeriken' }
    ]
    const [open, setopen] = useState(false);
    const [jerikenType, setJerikenType] = useState(null);
    const { data, fetchData } = useFetch(
        `/api/back-office/config-jeriken`,
        currentParams => ({
            ...currentParams,
            unpaged: true,
        }),
        useCallback(data => ({
            data: data?.data?.content,
        }), [])
    );

    const allowJerikenUpdated = props?.access?.update;

    return (
        <div className="animated fadeIn">
            <Row className="mb-4">
                <Col>
                    <div style={{ gap: "2rem" }} className='d-flex flex-wrap justify-content-between'>
                        <div className="d-inline-block">
                            <h2>Harga Standar</h2>
                            <Breadcrumb data={dataBreadcrumb} />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={4}>
                    <Card className="p-1" style={{ borderRadius: 20 }}>
                        <CardBody style={{ display: "flex", flexDirection: "column", gap: 12 }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div className='d-flex' style={{ gap: "12px", alignItems: "center" }}>
                                    <div className='p-2' style={{ background: "#FFF0E6", borderRadius: "100%" }}>
                                        <img src={waterOrange} alt="water-pict" />
                                    </div>
                                    <span style={{ color: "#70727D", fontWeight: 700, fontSize: 16 }}>Jeriken 18 L</span>
                                </div>
                                {allowJerikenUpdated && <div onClick={() => {
                                    setopen(!open);
                                    setJerikenType(jerikenBean('18', data));
                                }} style={{ cursor: 'pointer', color: "#1178D4", fontSize: 12, padding: 2, fontWeight: 400 }}>Edit</div>}
                            </div>
                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <div style={{ display: "flex", flexDirection: "column", gap: 6 }}>
                                    <span style={{ color: "#70727D", fontWeight: 700, fontSize: 16 }}>Berat Bruto (Kg)</span>
                                    <span style={{ fontSize: 28, fontWeight: 600, color: "#F29D0D" }}>{jerikenBean('18', data)?.grossWeight ?? '-'}</span>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", gap: 6 }}>
                                    <span style={{ color: "#70727D", fontWeight: 700, fontSize: 16 }}>Berat Nett (Kg)</span>
                                    <span style={{ fontSize: 28, fontWeight: 600, color: "#F29D0D" }}>{jerikenBean('18', data)?.weight ?? '-'}</span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={4}>
                    <Card className="p-1" style={{ borderRadius: 20 }}>
                        <CardBody style={{ display: "flex", flexDirection: "column", gap: 12 }}>
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div className='d-flex' style={{ gap: "12px", alignItems: "center" }}>
                                    <div className='p-2' style={{ background: "#EBFFD0", borderRadius: "100%" }}>
                                        <img src={waterGreen} alt="water-pict" />
                                    </div>
                                    <span style={{ color: "#70727D", fontWeight: 700, fontSize: 16 }}>Jeriken 20 L</span>
                                </div>
                                {allowJerikenUpdated && <div onClick={() => {
                                    setopen(!open);
                                    setJerikenType(jerikenBean('20', data));
                                }} style={{ cursor: 'pointer', color: "#1178D4", fontSize: 12, padding: 2, fontWeight: 400 }}>Edit</div>}
                            </div>
                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <div style={{ display: "flex", flexDirection: "column", gap: 6 }}>
                                    <span style={{ color: "#70727D", fontWeight: 700, fontSize: 16 }}>Berat Bruto (Kg)</span>
                                    <span style={{ fontSize: 28, fontWeight: 600, color: "#6FAA1E" }}>{jerikenBean('20', data)?.grossWeight ?? '-'}</span>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", gap: 6 }}>
                                    <span style={{ color: "#70727D", fontWeight: 700, fontSize: 16 }}>Berat Nett (Kg)</span>
                                    <span style={{ fontSize: 28, fontWeight: 600, color: "#6FAA1E" }}>{jerikenBean('20', data)?.weight ?? '-'}</span>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
                <ModalForms fetchData={fetchData} jerikenType={jerikenType} open={open} setopen={setopen} />
            </Row>
        </div >
    );
}

export default Jeriken;
