import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, Spinner } from "reactstrap";

import SmallButton from "components/SmallButton";
import { parseValue } from "helper";
import { showToast } from "hooks";
import { Services } from "service";


const apiClient = Services();

export const useBatchApproval = () => {
    const config = useState(null);
    const [, setState] = config;

    const show = (mode, payload) => setState({ mode, payload });

    return {
        config,
        show
    };
};

const approvalActions = [
    { code: "accept", verb: "disetujui", noun: "Persetujuan" },
    { code: "reject", verb: "ditolak", noun: "Tolak" },
];

const toastMessage = (verb, status = "berhasil") => `Permohonan perubahan ${status} ${verb}`;

const ModalBatchApproval = ({ config, onSucceed }) => {
    const [state, setState] = config;
    const [submitting, setSubmitting] = useState(false);

    const accepting = state?.mode === "accept";
    const rejecting = state?.mode === "reject";
    const partial = state?.mode === "partial";

    const close = () => setState(null);

    const submit = (action) => {
        const knownAction = approvalActions.find(({ code }) => code === action);

        // Prevent duplicated or invalid request
        if (submitting || !knownAction || !state?.payload?.ids?.length) return;

        setSubmitting(true);
        const { ids } = state.payload;

        apiClient.put(`/api/back-office/direct-purchase/history/bulk/${action}`, { ids })
            .then((res) => {
                const payload = res?.data?.data;
                const failList = payload?.failList;
                const successList = payload?.successList;

                if (failList?.length === 0 && successList?.length > 0) {
                    showToast({ name: "success", message: toastMessage(knownAction.verb) })
                    close();
                    onSucceed(payload);
                } else if ([failList?.length, successList?.length].every(Number.isInteger)) {
                    const message = successList.length
                        ? `Ada permohonan perubahan yang gagal ${knownAction.verb}`
                        : toastMessage(knownAction.verb, "gagal");

                    showToast({ name: "error", message })
                    setState({ mode: "partial", payload: { ...payload, noun: knownAction.noun } });
                } else {
                    throw new Error(toastMessage(knownAction.verb, "gagal"));
                }
            })
            .catch((e) => {
                showToast({ name: "error", message: e?.message ?? toastMessage(knownAction.verb, "gagal") })
            })
            .finally(() => {
                setSubmitting(false);
            })
    };

    return (
        <Modal
            centered
            size="sm"
            backdrop="static"
            keyboard={!submitting}
            returnFocusAfterClose={false}
            isOpen={Boolean(state?.mode)}
            toggle={close}
        >
            <ModalBody>
                <h5 className="b1 mb-4" style={{ fontWeight: 600 }}>
                    {accepting
                        ? "Persetujuan Perubahan"
                        : rejecting
                            ? "Tolak Permohonan"
                            : partial
                                ? `Hasil ${state.payload.noun} Masal`
                                : null}
                </h5>
                <div>
                    {accepting
                        ? <><b className="text-primary">{state.payload.ids.length}</b> Data Pembelian Langsung akan diubah</>
                        : rejecting
                            ? <><b className="text-primary">{state.payload.ids.length}</b> Permohonan perubahan akan ditolak</>
                            : partial
                                ? (
                                    <>
                                        <p className="mb-1">
                                            Berhasil di submit : <span className="text-success font-weight-bold">{parseValue(state.payload.successList.length)}</span>
                                        </p>
                                        <p className="mb-1">
                                            Gagal di submit : <span className="text-danger font-weight-bold">{parseValue(state.payload.failList.length)}</span>
                                        </p>
                                    </>
                                )
                                : null}
                </div>
            </ModalBody>
            <ModalFooter className="pt-0">
                {(accepting || rejecting)
                    ? (
                        <>
                            <SmallButton
                                disabled={submitting}
                                outline={!submitting}
                                color={submitting ? "secondary" : "primary"}
                                onClick={close}
                            >
                                Kembali
                            </SmallButton>
                            <SmallButton
                                color="primary"
                                disabled={submitting}
                                className={submitting ? "position-relative" : ""}
                                onClick={(e) => {
                                    e.target.blur();
                                    submit(state.mode);
                                }}
                            >
                                {submitting && <Spinner size="sm" className="position-absolute" style={{ top: "25%", left: "42%" }} />}
                                <span className={submitting ? "invisible" : ""}>Konfirmasi</span>
                            </SmallButton>
                        </>
                    )
                    : partial
                        ? (
                            <SmallButton
                                block
                                color="primary"
                                onClick={() => {
                                    onSucceed(state.payload);
                                    close();
                                }}
                            >
                                Mengerti
                            </SmallButton>
                        )
                        : null}
            </ModalFooter>
        </Modal>
    );
};

export default ModalBatchApproval;
